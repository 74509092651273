import { combineReducers } from '@reduxjs/toolkit';

import AuthorizedUser from './reducers/Auth.reducer';
import TaskReducer from './reducers/Task.reducer';
import UserReducer from './reducers/User.reducer';
import AdminReducer from './reducers/Admin.reducer';
import ConfigReducer from './reducers/Config.reducer';
import EmployeeReducer from './reducers/Employee.reducer';
import ReportReducer from './reducers/Report.reducer';
import ModalReducer from './reducers/Modal.reducer';
import TemplateReducer from './reducers/Template.reducer';
import PatientReducer from './reducers/Patient.reducer';
import RoundReducer from './reducers/Round.reducer';
import AccountReducer from './reducers/Account.reducer';
import StoplightReducer from './reducers/Stoplight.reducer';
import ToastReducer from './reducers/Toast.reducer';
import FeatureReducer from './reducers/Feature.reducer';

const rootReducers = combineReducers({
  AuthorizedUser,
  ConfigReducer,
  TaskReducer,
  UserReducer,
  AdminReducer,
  EmployeeReducer,
  ReportReducer,
  ModalReducer,
  TemplateReducer,
  PatientReducer,
  RoundReducer,
  AccountReducer,
  StoplightReducer,
  ToastReducer,
  FeatureReducer
});

export default rootReducers;
