import styled from 'styled-components';
import { NeuLabel } from '@neutron/react';

export const DateRangeSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  margin-left: -15px;
  margin-top: -20px;
`;

export const Range = styled.div`
  width: 183px;
  margin-right: 8px;
`;

export const StartEnd = styled.div`
  display: flex;
`;

export const SelectContainer = styled.div``;

export const DateHeading = styled(NeuLabel)`
  display: inline-block;
  font-size: 0.875rem;
  line-height: 22px;
`;
