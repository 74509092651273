/**
 * External Imports
 */
import { FC, Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { NeuApp } from '@neutron/react';
import { v4 as uuidv4 } from 'uuid';
import { useAnalyticsApi } from '@shared-web-analytics/react/dist';
import { RootState } from './redux/store';
/**
 * Internal Imports
 */
import InjectAxiosInterceptors from './components/injectAxiosInterceptors/InjectAxiosInterceptors';
import Loading from './components/shared/Loading';
import ErrorPage401 from './pages/errorPage/401';
import ErrorPage403 from './pages/errorPage/403';
import ErrorPage404 from './pages/errorPage/404';
import ProtectedRoute from './pages/protectedRoute/ProtectedRoute';
import Login from './pages/login/Login';

import ModalPortal from './services/ModalPortal';
import { updateCurrentFacility } from './redux/actions/Account.action';
import { getAuthorizedUser, setSessionId } from './redux/actions/Auth.action';
import { getSassToken } from './redux/actions/Config.action';
import { setFeatures } from './redux/actions/Feature.action';
import { getFacilities } from './redux/actions/User.action';

import { Authenticate, tokenRequest } from './services/AUTH/utils';
import { Toast } from './services/Toast';

import { mainNavRouteObjects } from './Route';

/**
 * Global Type Definition Imports
 */
import { AuthUserProps, Facility, RouteObj } from './config/interfaces';
/**
 * Style Imports
 */
import './styles/App.scss';
import Version from '../package.json';

const App: FC<{
  isAuthorized: boolean;
  authorizedUser: AuthUserProps;
  authRoles: Array<string>;
  facilities: Facility[];
}> = ({ isAuthorized, authorizedUser, authRoles, facilities }) => {
  const dispatch = useDispatch();

  const { setTrackingSessionId, setTrackingUserId, startAnalytics } =
    useAnalyticsApi();

  useEffect(() => {
    const ORBIT = 'Orbit';

    startAnalytics(
      ORBIT,
      Version.version,
      process.env.REACT_APP_ANALYTICS_API_KEY,
      process.env.REACT_APP_ENV
    );
  }, []);

  const returnRoute = (route: RouteObj, isAllowed: boolean) => {
    const component = isAllowed ? route.page : <ErrorPage401 />;
    return (
      <Route
        key={route.path}
        path={route.path}
        element={<ProtectedRoute component={component} />}
      />
    );
  };

  const createRoutes = () => {
    const approvedRoutes: JSX.Element[] = [];
    let isAllowed = false;
    mainNavRouteObjects.forEach(routeItem => {
      if (routeItem.allowedRoles.some(role => authRoles.includes(role))) {
        isAllowed = true;
        approvedRoutes.push(returnRoute(routeItem, isAllowed));
      }
    });
    return approvedRoutes;
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    if (isAuthorized && localStorage.getItem('34')) {
      console.log('Getting user from back-end!');
      const sessionId = uuidv4();
      if (!sessionStorage.getItem('sessionId')) {
        sessionStorage.setItem('sessionId', sessionId);
        dispatch(setSessionId(sessionId));
        setTrackingSessionId(sessionId);
        setTrackingUserId(localStorage.getItem('34') || '');
      }
      dispatch(getAuthorizedUser(localStorage.getItem('34')));
    } else if (
      !code &&
      !localStorage.getItem('authToken') &&
      process.env.REACT_APP_ENV === 'production'
    ) {
      Authenticate();
    } else if (code && !localStorage.getItem('authToken')) {
      tokenRequest(code);
    }
  }, [isAuthorized]);

  useEffect(() => {
    if (Object.keys(authorizedUser).length > 0) {
      dispatch(getSassToken());
      dispatch(getFacilities());
    }
  }, [authorizedUser]);

  useEffect(() => {
    if (
      authorizedUser &&
      authorizedUser?.access?.accessLevel === 'facility' &&
      !authorizedUser?.access?.accessLevelIds?.includes(
        authorizedUser?.facilityId
      )
    ) {
      dispatch(
        updateCurrentFacility({
          userId: authorizedUser.hcaid,
          facilityId: authorizedUser.access.accessLevelIds[0]
        })
      );
    } else if (
      authorizedUser &&
      authorizedUser?.access?.accessLevel === 'division' &&
      facilities.length > 0 &&
      !authorizedUser?.access?.accessLevelIds?.includes(
        facilities.filter(
          facility => facility.facilityId === authorizedUser?.facilityId
        )[0]?.division
      )
    ) {
      dispatch(
        updateCurrentFacility({
          userId: authorizedUser.hcaid,
          facilityId:
            facilities.find(
              facility =>
                facility.division === authorizedUser.access.accessLevelIds[0]
            )?.facilityId || authorizedUser?.facilityId
        })
      );
    }

    // Turning on any facility features
    if (authorizedUser.facilityId && facilities.length > 0) {
      dispatch(
        setFeatures({
          isCSRNEnabled:
            facilities.find(
              facility => facility.facilityId === authorizedUser?.facilityId
            )?.isCsrnEnabled ?? false,
          isCNEdEnabled:
            facilities.find(
              facility => facility.facilityId === authorizedUser?.facilityId
            )?.isCnedEnabled ?? false,
          isValidationEnabled:
            facilities.find(
              facility => facility.facilityId === authorizedUser?.facilityId
            )?.isValidationEnabled ?? false
        })
      );
    }
  }, [authorizedUser, facilities]);

  return (
    <NeuApp color="white">
      <Router>
        <InjectAxiosInterceptors />
        <Suspense fallback={<Loading />}>
          <Routes>
            {createRoutes()}
            <Route path="/login" element={<Login />} />
            <Route path="/401" element={<ErrorPage401 />} />
            <Route path="/403" element={<ErrorPage403 />} />
            <Route path="/404" element={<ErrorPage404 />} />
            <Route path="*" element={<Login />} />
          </Routes>
        </Suspense>
        <ModalPortal />
      </Router>

      <Toast />
    </NeuApp>
  );
};

const mapReduxStateToProps = (state: RootState) => ({
  isAuthorized: state.AuthorizedUser.isAuthenticated,
  authorizedUser: state.AuthorizedUser.authorizedUser,
  authRoles: state.AuthorizedUser.authRoles,
  facilities: state.ConfigReducer.facilities
});

export default connect(mapReduxStateToProps)(App);
