export const BASE_URL = process.env.REACT_APP_BASE_URL;

/**
 * Endpoints organized to align with Swagger
 */

// Admin endpoints
export const getApiVersionUrl = `${BASE_URL}/admin/apiVersion`;
export const getUsersUrl = `${BASE_URL}/admin/listUsers`;
export const getUserRolesUrl = `${BASE_URL}/admin/roleNames`;
export const postUserAccessRevokeUrl = `${BASE_URL}/admin/user/clear`;
export const getDischargeUrl = `${BASE_URL}/admin/dischargePatients`;
export const getEncountersUrl = `${BASE_URL}/encounters`;

// Config endpoints
export const getFacility = `${BASE_URL}/facility`;
export const getFacilitiesUrl = `${BASE_URL}/facility`; // Possible duplicate
export const getDivisionsUrl = `${BASE_URL}/division`;
export const getUnitsByFacilityUrl = `${BASE_URL}/unit`;
export const updateUnitTypeAndFlag = `${BASE_URL}/unit/config`;
export const getAllUnitsUrl = `${BASE_URL}/unit/config`; // Possible duplicate

// Discussion endpoints
export const discussionUrl = `${BASE_URL}/discussions`;

// Employee endpoints
export const postEmployeeNoteUrl = `${BASE_URL}/employee/profileNote/add`;
export const removeEmployeeNoteUrl = `${BASE_URL}/employee/profileNote/remove`;
export const employeeSearchUrl = `${BASE_URL}/employee/search`;
export const getEmployeeNotesUrl = `${BASE_URL}/employee/notes`;

// Patient endpoints
export const postPatientNoteUrl = `${BASE_URL}/patient/profileNote/add`;
export const removePatientNoteUrl = `${BASE_URL}/patient/profileNote/remove`;

// Report endpoints
export const patientHistorySearchUrl = `${BASE_URL}/report/patientHistory`;
export const patientRoundHistoryUrl = `${BASE_URL}/report/patientRoundHistory`;
export const employeeRoundHistoryUrl = `${BASE_URL}/report/employeeRoundHistory`;
export const getComplianceReportUrl = `${BASE_URL}/report/complianceReport`;
export const getPatientRoundsUrl = `${BASE_URL}/report/rounds/patient`;
export const getPatientRoundsReport = `${BASE_URL}/report/exportRounds`;
export const getEmployeeRoundsUrl = `${BASE_URL}/report/rounds/employee`;
export const getEmployeeFreqUrl = `${BASE_URL}/report/rounds/employee/frequency`;
export const getEmployeeRoundsReport = `${BASE_URL}/report/exportEmpRounds`;
export const feedbackByFacilityUrl = `${BASE_URL}/report/feedback`;
export const issuesByFacilityUrl = `${BASE_URL}/report/issue`;
export const getCensusReport = `${BASE_URL}/report/census`;

// Round endpoints
export const postRoundUrl = `${BASE_URL}/round`;
export const getRoundUrl = `${BASE_URL}/round`; // Possible duplicate
export const getRoundsUrl = `${BASE_URL}/roundsList`;

// Sas Endpoints
export const getSasToken = `${BASE_URL}/sas/pictures`;

// Stoplight endpoints
export const baseStoplightUrl = `${BASE_URL}/stoplights`;
export const getStoplightsByLocationUrl = `${BASE_URL}/stoplights/GetByLocation`;
export const searchStoplightsUrl = `${BASE_URL}/stoplights/search`;

// Task endpoints
export const tasksByIdUrl = `${BASE_URL}/task`;
export const postNewTaskUrl = `${BASE_URL}/task`; // Possible duplicate
export const updateTaskUrl = `${BASE_URL}/tasks`;
export const ticketDetailsUrl = `${BASE_URL}/task/ticket`;
export const getPatientIssuesUrl = `${BASE_URL}/task/getIssueByUnitId`;
export const getTasksByAccountNumberUrl = `${BASE_URL}/task/accountNumber`;
export const getTasksByEmployeeIdUrl = `${BASE_URL}/task/employeeId`;
export const verifyControlNumberUrl = `${BASE_URL}/task/getGEBiomedControlNumber`;
export const getECLocationsNewUrl = `${BASE_URL}/task/getECLocationsNew`;
export const getGELocationsUrl = `${BASE_URL}/task/getGELocations`;
export const getIssueStatusesUrl = `${BASE_URL}/task/issueStatuses`;
export const completeTaskUrl = `${BASE_URL}/task/complete`;
export const shareTaskUrl = `${BASE_URL}/task/share`;
export const deleteTaskUrl = `${BASE_URL}/task/delete`;
export const issueSearchUrl = `${BASE_URL}/task/issueSearch`;
export const getFeedbackByUnitsIdsUrl = `${BASE_URL}/task/getFeedbackByUnitId`;
export const getEmployeeIssuesUrl = `${BASE_URL}/task/getIssueByEmployeeList`;
export const getFeedbackByEmployeeListUrl = `${BASE_URL}/task/getFeedbackByEmployeeList`;
export const getCategoriesByType = `${BASE_URL}/task/getCategoriesByType`;

// Template endpoints
export const getTemplatesMasterUrl = `${BASE_URL}/template/master`;
export const getTemplateById = `${BASE_URL}/template`;
export const getQuestionsUrl = `${BASE_URL}/template/question`;
export const addNewQuestionUrl = `${BASE_URL}/template/question`;
export const getRoundSurveyUrl = `${BASE_URL}/template/roundSurvey`;
export const getTemplatesTreeUrl = `${BASE_URL}/template/treeView`;
export const postTemplateConfigUrl = `${BASE_URL}/template/config`;
export const getTemplateDocUrl = `${BASE_URL}/template/doc`;
export const getPatientTemplatesUrl = `${BASE_URL}/template/isPublished`;

// User endpoints
export const userAuthUrl = `${BASE_URL}/user/auth`;
export const addEmployeeToUSer = `${BASE_URL}/user/employee/add`;
export const updateCurrentFacilityUrl = `${BASE_URL}/user/facility`;
export const removeEmployeeFromUser = `${BASE_URL}/user/employee/remove`;
export const postNewProfilePicUrl = `${BASE_URL}/user/picture`;
export const addFavoriteUnitsUrl = `${BASE_URL}/user/units/add`;
export const removeFavoriteUnitsUrl = `${BASE_URL}/user/units/remove`;
export const addDelegateToUser = `${BASE_URL}/user/employee/grant-list-access`;
export const removeDelegateFromUser = `${BASE_URL}/user/employee/revoke-list-access`;
export const getEmployeesConfigUrl = `${BASE_URL}/user/employee/config`;
export const getSupportEmployeesConfigUrl = `${BASE_URL}/user/employee/config/support`;
export const updateSupportHiddenStatusUrl = `${BASE_URL}/user/employee/config/support/status`;
export const postEmployeeListConfigUrl = `${BASE_URL}/user/employee/employee-list-config
`;
export const getPatientsToRoundUrl = `${BASE_URL}/user/patientsToRound`;
export const getEmployeesToRoundUrl = `${BASE_URL}/user/employeesToRound`;
export const getSupportSubjectsToRoundUrl = `${BASE_URL}/user/supportSubjectsToRound`;
export const getUserRoundHistoryUrl = `${BASE_URL}/user/roundHistory`;
export const postUserAccessUrl = `${BASE_URL}/user/access`;
export const getUserImageUrl =
  'https://orb-qa-api-centralus.azurewebsites.net/rounding-docs/api/v1/user/picture';
