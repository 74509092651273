import ACTIONS from './actionType';

export const getAuthorizedUser = data => {
  return { type: ACTIONS.AUTH.GET_USER, data };
};

export const getAuthToken = data => {
  return { type: ACTIONS.AUTH.GET_AUTH_TOKEN, data };
};

export const getUser34 = data => {
  return { type: ACTIONS.AUTH.GET_USER34, data };
};

// USER
export const setSessionId = data => {
  return { type: ACTIONS.AUTH.SET_SESSION_ID, data };
};
export const clearUser = data => {
  return { type: ACTIONS.AUTH.CLEAR_USER, data };
};
