import { RootState, useAppSelector } from '../../redux/store';

export const useFeatures: () => any = () =>
  useAppSelector((state: RootState) => {
    return {
      isCSRNEnabled: state.FeatureReducer.isCSRNEnabled,
      isCNEdEnabled: state.FeatureReducer.isCNEdEnabled,
      isValidationEnabled: state.FeatureReducer.isValidationEnabled
    };
  });
