import { call, put } from 'redux-saga/effects';
import { getData } from '../../services/API/axios';

import ACTIONS from '../actions/actionType';
import API from '../../services/API';

export function* getAuthorizedUser(action) {
  const url = `${API.userAuthUrl}?userId=${action.data}`;
  try {
    const { data, status } = yield call(getData, url);

    if (status === 200) {
      yield put({
        type: ACTIONS.AUTH.GET_USER_SUCCESS,
        data
      });
    }
  } catch (error) {
    console.log(error);
  }
}

export function* getAuthToken() {
  yield put({
    type: ACTIONS.AUTH.GET_AUTH_TOKEN_SUCCESS,
    data: true
  });
}

export function* getUser34(action) {
  yield put({
    type: ACTIONS.AUTH.GET_USER34_SUCCESS,
    data: action.data
  });
}
