import { call, put } from 'redux-saga/effects';

import { getData, postData } from '../../services/API/axios';

import store from '../store';
import ACTIONS from '../actions/actionType';
import API from '../../services/API';
import { toast } from '../../services/Toast';

import {
  getAllUnits as refetchAllUnits
  // getFacilityUnits as refetchFacilityUnits
} from '../actions/Config.action';

export function* getSassToken() {
  const url = `${API.getSasToken}`;
  try {
    const { data, status } = yield call(getData, url);
    const dataPlusTimestamp = {
      ...data,
      sasToken: `${data.sasToken}&${Date.now()}`
    };
    if (status === 200) {
      yield put({
        type: ACTIONS.CONFIG.GET_SASS_TOKEN_SUCCESS,
        data: dataPlusTimestamp
      });
    } else {
      yield put({
        type: ACTIONS.CONFIG.GET_SASS_TOKEN_FAIL,
        data: { sasToken: '' }
      });
    }
  } catch (error) {
    console.log(error);
  }
}

// Config sagas
export function* getFacilityUnits(action) {
  const {
    data: { facilityId, isUnfiltered }
  } = action;
  const url = `${API.getUnitsByFacilityUrl}?facilityId=${facilityId}&isUnfiltered=${isUnfiltered}`;
  try {
    const { data, status } = yield call(getData, url);
    if (status === 200) {
      // filter out inactive units from list
      const cleanData = data
        .map(unit => unit)
        .filter(unit => unit.active !== 'N');
      yield put({
        type: ACTIONS.CONFIG.GET_UNITS_BY_FACILITY_SUCCESS,
        data: {
          facilityId,
          units: cleanData
        }
      });
    }
  } catch (error) {
    console.log(error);
  }
}

export function* changeUnitLocTypeAndFlag(action) {
  const url = API.updateUnitTypeAndFlag;
  const {
    data: { facilityId }
  } = action;
  try {
    const { status } = yield call(postData, url, action.data);
    if (status === 200) {
      yield put({
        type: ACTIONS.CONFIG.CHANGE_UNIT_LOC_TYPE_FLAG_SUCCESS
      });
      store.dispatch(refetchAllUnits({ facilityId }));
      // store.dispatch(refetchFacilityUnits({ facilityId, isUnfiltered: false }));
    } else {
      toast('Failed to update!', 'error', null, 500, true);
      yield put({
        type: ACTIONS.CONFIG.CHANGE_UNIT_LOC_TYPE_FLAG_FAILURE
      });
    }
  } catch (error) {
    console.log(error);
    toast('Failed to update!', 'error', null, 500, true);
  }
}

export function* getAllUnits(action) {
  const {
    data: { facilityId }
  } = action;
  const url = `${API.getAllUnitsUrl}?facilityId=${facilityId}`;
  try {
    const { data, status } = yield call(getData, url);
    if (status === 200) {
      const cleanData = Object.values(data);
      yield put({
        type: ACTIONS.CONFIG.GET_ALL_UNITS_SUCCESS,
        data: cleanData
      });
    } else {
      yield put({
        type: ACTIONS.CONFIG.GET_ALL_UNITS_FAILURE
      });
    }
  } catch (error) {
    console.log(error);
  }
}
