/**
 * External Imports
 */
import {
  FC,
  useEffect,
  useMemo
  // useState
} from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
/**
 * Internal Imports
 */
import ReusableTabs from '../../components/reusableTabs';
import FeatureRoute from '../featureRoute/FeatureRoute';

// import { setTaskSearchQuery } from '../../redux/actions/Task.action';
import {
  resetUserSectionType,
  setUserSectionType
} from '../../redux/actions/User.action';
import { useFeatures } from '../../services/Features/features';

import { ValidationContainer } from './styles/Validation.styles';

// import { useDebounceValue } from '../../utils/debouncers';

const Validation: FC<{}> = () => {
  const dispatch = useDispatch();
  const { isValidationEnabled } = useFeatures();

  // const [searchTerm, setSearchTerm] = useState<string>('');

  // const dbSearchTerm = useDebounceValue(searchTerm, 250);

  const { pathname } = useLocation();

  const roundingType = useMemo(() => pathname.split('/')[1], [pathname]);

  const view = useMemo(() => pathname.split('/')[2], [pathname]);

  const reusableTabItems = useMemo(
    () => ({
      base: '/validation',
      links: []
    }),
    []
  );

  useEffect(() => {
    return () => {
      dispatch(resetUserSectionType());
    };
  }, []);

  useEffect(() => {
    dispatch(setUserSectionType(roundingType));
  }, [roundingType]);

  return (
    <div>
      {reusableTabItems.links.length > 0 && (
        <ReusableTabs tabItems={reusableTabItems} />
      )}
      <Routes>
        <Route index element={<Navigate to="list" />} />
        <Route
          path="list"
          element={
            <FeatureRoute
              // TODO: Add List page with searchTerm and setSearchTerm once it's created
              component={
                <ValidationContainer>
                  <strong>Validation {view.toUpperCase()}</strong>
                </ValidationContainer>
              }
              isFeatureEnabled={isValidationEnabled}
              offsetHeight={72}
            />
          }
        >
          <Route
            path=":validationFilter"
            // TODO: Add List page with searchTerm and setSearchTerm once it's created
            element={
              <ValidationContainer>
                <strong>Validation {view.toUpperCase()} Filtered</strong>
              </ValidationContainer>
            }
          />
        </Route>
        <Route
          path="*"
          element={
            <Navigate to={localStorage.getItem('orbitLastPath') || '/'} />
          }
        />
      </Routes>
    </div>
  );
};

export default Validation;
