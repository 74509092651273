import { ComplianceObj, Unit } from '../config/interfaces';

const isPercentLessThanOne = (numerator: number, denominator: number) =>
  !Number.isNaN(numerator) &&
  !Number.isNaN(denominator) &&
  numerator > 0 &&
  denominator > 0 &&
  (numerator * 100) / denominator < 1;

const isPercentRoundedToOneHundred = (numerator: number, denominator: number) =>
  !Number.isNaN(numerator) &&
  !Number.isNaN(denominator) &&
  numerator > 0 &&
  denominator > 0 &&
  (numerator * 100) / denominator > 99.5 &&
  (numerator * 100) / denominator < 100;

const calculateComplianceNumbers = (compliance: any) => {
  const roundedTotal = compliance.rounded + compliance.unable;
  const census = roundedTotal + compliance.none;
  const roundedPct = Math.round((roundedTotal / census) * 100) || 0; // Future: const roundedPct = Math.round((compliance.rounded / roundedTotal) * 100) || 0;
  const unablePct = Math.round((compliance.unable / roundedTotal) * 100) || 0;

  return {
    census,
    roundedTotal,
    none: compliance.none,
    rounded: compliance.rounded,
    roundedPct,
    unable: compliance.unable,
    unablePct
  };
};

const addComplianceNumbers = (prevData: any, newData: any) => {
  const none = prevData.none + newData.none;
  const rounded = prevData.rounded + newData.rounded;
  const unable = prevData.unable + newData.unable;
  const roundedTotal = rounded + unable;
  const census = roundedTotal + none;
  const roundedPct = isPercentLessThanOne(Number(roundedTotal), Number(census))
    ? 1
    : Math.round((roundedTotal / census) * 100) || 0; // Future: const roundedPct = Math.round((rounded / roundedTotal) * 100) || 0;
  const unablePct = isPercentLessThanOne(Number(unable), Number(roundedTotal))
    ? 1
    : Math.round((unable / roundedTotal) * 100) || 0;

  return Object.assign(prevData, {
    census,
    none,
    rounded,
    roundedTotal,
    roundedPct,
    unable,
    unablePct
  });
};

const calculateTotalCompliance = (compliance: any) => {
  return compliance.reduce(
    (acc: any, unitCompliance: any) => {
      return {
        unit: 'Facility Total',
        unitId: 'FACILITY',
        unitType: '',
        census: unitCompliance.census + acc.census,
        roundedPercent: isPercentLessThanOne(
          Number(unitCompliance.roundedNumber + acc.roundedNumber),
          Number(unitCompliance.census + acc.census)
        )
          ? 1
          : Math.round(
              ((unitCompliance.roundedNumber + acc.roundedNumber) * 100) /
                (unitCompliance.census + acc.census)
            ) || 0,
        roundedNumber: unitCompliance.roundedNumber + acc.roundedNumber, // Future: possibly change to just rounded Yes
        unablePercent: isPercentLessThanOne(
          Number(unitCompliance.unableNumber + acc.unableNumber),
          Number(unitCompliance.roundedNumber + acc.roundedNumber)
        )
          ? 1
          : Math.round(
              ((unitCompliance.unableNumber + acc.unableNumber) * 100) /
                (unitCompliance.roundedNumber + acc.roundedNumber)
            ) || 0,
        unableNumber: unitCompliance.unableNumber + acc.unableNumber
      };
    },
    {
      unit: 'All',
      unitId: 'All',
      unitType: '',
      census: 0,
      roundedPercent: 0,
      roundedNumber: 0, // Future: possibly change to just rounded Yes
      unablePercent: 0,
      unableNumber: 0
    }
  );
};

const reduceComplianceByUnit = (
  compliance: ComplianceObj[],
  unitsById: Unit[]
) => {
  return compliance.reduce((prev: any, curr: any) => {
    const { unitId } = curr;
    const prevUnit = prev[unitId];

    const dayData = {
      dt: curr.dt,
      facilityId: curr.facilityId,
      unitId: curr.unitId,
      locType: curr.locType,
      locTypeDisplay:
        curr.locType === 'O'
          ? 'Outpatient'
          : curr.locType === 'I'
          ? 'Inpatient'
          : 'ED',
      ...calculateComplianceNumbers(curr)
    };

    // If we've already created a property for this unit
    if (prevUnit) {
      // Push this day into the data for that unit
      prevUnit.data.push(dayData);
      // Combine the data from this day with the combinedData for that unit
      prevUnit.combinedData = addComplianceNumbers(
        prevUnit.combinedData,
        calculateComplianceNumbers(dayData)
      );
      // Otherwise, create a property for this unit and initialize with group
      // info, starting data, and starting combinedData
    } else {
      /* eslint-disable no-param-reassign */
      const filteredUnit = unitsById.filter(
        (unit: Unit) => unit.unitId === unitId
      );
      const currentUnit: Unit =
        filteredUnit.length > 0 ? filteredUnit[0] : unitsById[0];

      prev[currentUnit.unitId] = {
        groupId: currentUnit.unitId,
        groupName: currentUnit.unit,
        groupType: 'unit',
        locTypeDisplay:
          curr.locType === 'O'
            ? 'Outpatient'
            : curr.locType === 'I'
            ? 'Inpatient'
            : 'ED',
        data: [dayData],
        combinedData: calculateComplianceNumbers(dayData)
      };
    }

    return prev;
  }, {});
};

export {
  isPercentLessThanOne,
  isPercentRoundedToOneHundred,
  calculateTotalCompliance,
  reduceComplianceByUnit
};
