import styled from 'styled-components';

interface CSSProps {
  offset: number;
}

export const SupportContainer = styled.div<CSSProps>`
  display: flex;
  flex-direction: row;
  height: calc(100vh - ${({ offset }) => offset}px);
`;

export const TaskContainer = styled.div`
  width: 100%;
  height: calc(100vh - 206px);
`;
