import { Dispatch, FC, SetStateAction } from 'react';
import { NeuIcon, NeuOption } from '@neutron/react';

import { Dropdown, IDropdownProps } from '../../shared/dropdowns';

interface IDeptMultiSelectDropdownProps extends IDropdownProps {
  options: any[];
  searchResults?: string[];
  selectedDepts: string[];
  setActiveSearch: Dispatch<SetStateAction<string>>;
  setQuery: Dispatch<SetStateAction<string>>;
  setSelectedDepts: Dispatch<SetStateAction<string[]>>;
}

const DeptMultiSelectDropdown: FC<IDeptMultiSelectDropdownProps> = ({
  focused,
  options = [],
  query,
  searchResults,
  selectedDepts,
  setActiveSearch,
  setQuery,
  setSelectedDepts,
  ...rest
}) => {
  return (
    <Dropdown focused={focused} query={query} {...rest}>
      {!query && (
        // TODO: Add space around checkmark thru option padding-right: calc(1.6rem + 20px);
        <NeuOption
          className={`dropdown-hover-item checkable ${
            options.length > 0 && selectedDepts.length === options.length
              ? 'selected'
              : ''
          }`}
          key="All-Departments"
          value="All"
          onClick={() =>
            setSelectedDepts(
              selectedDepts.length === options.length ? [] : [...options]
            )
          }
        >
          <div>
            All
            {options.length > 0 && selectedDepts.length === options.length && (
              <NeuIcon className="custom-checkmark" color="primary">
                check
              </NeuIcon>
            )}
          </div>
        </NeuOption>
      )}
      {focused && searchResults && searchResults.length > 0 ? (
        [...searchResults].sort().map((dept: string) => {
          const selected = options.length > 0 && selectedDepts.includes(dept);
          return (
            <NeuOption
              className={`dropdown-hover-item checkable ${
                selected ? 'selected' : ''
              }`}
              key={dept}
              value={dept}
              onClick={() => {
                setSelectedDepts(
                  selectedDepts.findIndex((d: string) => dept === d) === -1
                    ? [...selectedDepts, dept]
                    : selectedDepts.filter((d: string) => dept !== d)
                );
                setActiveSearch('');
                setQuery('');
              }}
            >
              <div>
                {dept}
                {selected && (
                  <NeuIcon className="custom-checkmark" color="primary">
                    check
                  </NeuIcon>
                )}
              </div>
            </NeuOption>
          );
        })
      ) : searchResults && searchResults.length === 0 ? (
        <NeuOption className="dropdown-search-no-result">
          No results found!
        </NeuOption>
      ) : (
        [...options].sort().map((dept: string) => {
          const selected = options.length > 0 && selectedDepts.includes(dept);
          return (
            <NeuOption
              className={`dropdown-hover-item checkable ${
                selected ? 'selected' : ''
              }`}
              key={dept}
              value={dept}
              onClick={() =>
                setSelectedDepts(
                  selectedDepts.findIndex((d: string) => dept === d) === -1
                    ? [...selectedDepts, dept]
                    : selectedDepts.filter((d: string) => dept !== d)
                )
              }
            >
              <div>
                {dept}
                {selected && (
                  <NeuIcon className="custom-checkmark" color="primary">
                    check
                  </NeuIcon>
                )}
              </div>
            </NeuOption>
          );
        })
      )}
    </Dropdown>
  );
};

export default DeptMultiSelectDropdown;
