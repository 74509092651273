/* eslint-disable func-names */
import axios from 'axios';

import store from '../../redux/store';
import {
  clearUser,
  getAuthToken,
  setSessionId
} from '../../redux/actions/Auth.action';

import { requestNewAccessToken } from '../AUTH/utils';

const axiosInstance = axios.create({
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  }
});

const refreshAndRetryQueue = [];

let isRefreshing = false;

const clearUserFromMemory = () => {
  window.localStorage.removeItem('authToken');
  window.localStorage.removeItem('34');
  sessionStorage.removeItem('sessionId');
  store.dispatch(setSessionId(null));
  store.dispatch(clearUser());
};

class API {
  constructor(instance) {
    this.instance = instance;
  }

  setupInterceptors(navigate) {
    const self = this;
    this.navigate = navigate;
    this.instance.interceptors.request.use(
      async request => {
        const currentAccessToken = window.localStorage.getItem('authToken');
        if (currentAccessToken) {
          request.headers.common.Authorization = currentAccessToken;
          return request;
        }
        clearUserFromMemory();
        return navigate('/401');
      },
      error => {
        clearUserFromMemory();
        return Promise.reject(error);
      }
    );

    this.instance.interceptors.response.use(
      function (response) {
        return response;
      },
      async function (error) {
        const originalRequest = error.config;
        const { response: failedResponse } = error;
        // Handle error page redirects by checking all api call rejections
        if (failedResponse && failedResponse.config.url.indexOf('api') > 0) {
          if (failedResponse.status === 401) {
            if (!isRefreshing) {
              isRefreshing = true;
              let currentAccessToken = window.localStorage.getItem('authToken');
              console.log('Refreshing token');
              try {
                const { accessToken: newAccessToken, refreshToken } =
                  await requestNewAccessToken();
                const authToken = `bearer ${newAccessToken}`;
                window.localStorage.setItem('authToken', authToken);
                window.localStorage.setItem('refreshToken', refreshToken);
                currentAccessToken = authToken;
                // eslint-disable-next-line no-param-reassign
                originalRequest.headers.Authorization = currentAccessToken;
                store.dispatch(getAuthToken());
                refreshAndRetryQueue.forEach(({ config, resolve, reject }) => {
                  const updatedConfig = config;
                  updatedConfig.headers.Authorization = currentAccessToken;
                  self.instance
                    .request(updatedConfig)
                    .then(response => resolve(response))
                    .catch(err => reject(err));
                });

                // Clear the queue
                refreshAndRetryQueue.length = 0;
                return self.instance(originalRequest);
              } catch (err) {
                console.log('Failed refreshing token', err);
                clearUserFromMemory();
                navigate('/401');
              } finally {
                isRefreshing = false;
              }
            }
            return new Promise((resolve, reject) => {
              refreshAndRetryQueue.push({
                config: originalRequest,
                resolve,
                reject
              });
            });
          }
          if (failedResponse.status === 403) {
            // If forbidden statuses are thrown send to error screen
            clearUserFromMemory();
            return navigate('/403');
          }
          if (failedResponse.status === 404) {
            // Only on auth endpoint if not authorized show error screnn
            if (originalRequest.config.url.indexOf('user/auth') > 0) {
              clearUserFromMemory();
              return navigate('/404');
            }
          }
        }
        return Promise.reject(error);
      }
    );
  }

  getInstance() {
    return this.instance;
  }
}

export const apiInstance = new API(axiosInstance);

const instance = apiInstance.getInstance();

const apiCall = ({ method, url, data = {} }) => {
  return instance({ method, url, data })
    .then(response => response)
    .catch(error => error.response);
};

const postData = (url, data) => apiCall({ method: 'post', url, data });

const getData = (url, data) => apiCall({ method: 'get', url, data });

const putData = (url, data) => apiCall({ method: 'put', url, data });

const deleteData = (url, data) => apiCall({ method: 'delete', url, data });

const patchData = (url, data) => apiCall({ method: 'patch', url, data });

const downloadFile = url =>
  instance.request({
    url,
    method: 'get',
    responseType: 'blob'
  });

export {
  getData,
  postData,
  putData,
  deleteData,
  patchData,
  downloadFile,
  instance
};
