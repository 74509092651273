/**
 * External Imports
 */
import { FC, useMemo } from 'react';
import { NeuInput } from '@neutron/react';
import { NeuInputChangeEventDetail } from '@neutron/core';
import { sub } from 'date-fns';
/**
 * Internal Imports
 */
import { date as dt } from '../../../../utils/helpers';
/**
 * Style Imports
 */
import {
  DateContainer,
  SelectContainer,
  InputHeading
} from './DateSelectsSection.styles';

const DateSelectsSection: FC<{
  filterType?: string;
  startDate?: string;
  endDate?: string;
  style?: {};
  handleSelects: (e: CustomEvent<NeuInputChangeEventDetail>) => void;
}> = ({ filterType, startDate, endDate, style, handleSelects }) => {
  const minStartDate = useMemo(
    () => dt.convertToDayOfYear(sub(new Date(), { years: 2 })),
    []
  );
  const maxStartDate = useMemo(
    () =>
      endDate
        ? dt.convertToDayOfYear(dt.localDayOfYearDate(endDate))
        : dt.convertToDayOfYear(new Date()),
    [endDate]
  );
  const minEndDate = useMemo(
    () =>
      startDate
        ? dt.convertToDayOfYear(dt.localDayOfYearDate(startDate))
        : dt.convertToDayOfYear(new Date()),
    [startDate]
  );
  const maxEndDate = useMemo(
    () =>
      filterType === 'complianceReport'
        ? dt.convertToDayOfYear(sub(new Date(), { days: 1 }))
        : dt.convertToDayOfYear(new Date()),
    []
  );

  return (
    <DateContainer id="Modal-Date-Selection" style={style}>
      <SelectContainer>
        <InputHeading>Start Date</InputHeading>
        <NeuInput
          id="Modal-Date-Selection-Start-Day"
          name="startDate"
          type="date"
          autocomplete="off"
          inputmode="search"
          enterkeyhint="search"
          autocorrect="off"
          onNeuChange={handleSelects}
          placeholder="Select a date"
          value={startDate}
          min={minStartDate}
          max={maxStartDate}
        />
      </SelectContainer>
      <SelectContainer>
        <InputHeading>End Date</InputHeading>
        <NeuInput
          id="Modal-Date-Selection-End-Day"
          name="endDate"
          type="date"
          autocomplete="off"
          inputmode="search"
          enterkeyhint="search"
          autocorrect="off"
          placeholder="Select a date"
          onNeuChange={handleSelects}
          value={endDate}
          min={minEndDate}
          max={maxEndDate}
        />
      </SelectContainer>
    </DateContainer>
  );
};

export default DateSelectsSection;
