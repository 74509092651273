import { FC, Dispatch, SetStateAction } from 'react';
import { TaskContainer } from './styles/PatientViews.styles';
import SearchHeader from '../../components/searchHeader';
import StoplightTable from '../../components/customTables/stoplightTable';

interface StoplightPageProps {
  searchTerm: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;
}

const PatientStoplight: FC<StoplightPageProps> = ({
  searchTerm,
  setSearchTerm
}) => {
  return (
    <TaskContainer id="Patient-Stoplight">
      <SearchHeader searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      <StoplightTable searchTerm={searchTerm} />
    </TaskContainer>
  );
};

export default PatientStoplight;
