/**
 * External Imports
 */
import { FC, useEffect, useState, Dispatch, SetStateAction } from 'react';
import { connect, useDispatch } from 'react-redux';
import { RootState } from '../../redux/store';
/**
 * Internal Imports
 */
import SideNav from '../../components/sideNav';
import SearchHeader from '../../components/searchHeader';
import EmployeesTable from '../../components/customTables/employeesTable';
import Loading from '../../components/shared/Loading';
import UnlistedEmployeeRoundModal from '../../components/modals/unlistedEmployeeRoundModal';

import { getDelegateSettings } from '../../redux/actions/Employee.action';
import { fireSnow } from '../../services/Confetti';
import {
  isPercentLessThanOne,
  isPercentRoundedToOneHundred
} from '../../utils/complianceFunctions';
/**
 * Global Type Definition Imports
 */
import { AuthUserProps, Employee } from '../../config/interfaces';
/**
 * Style Imports
 */
import { ModalContainer } from '../../services/ModalPortal/ModalPortal.Styles';
import { EmployeeContainer } from './styles/Employee.styles';

export interface EmployeeListProps {
  authorizedUser?: AuthUserProps;
  delegateSettings: {
    listRoundingFrequency: string;
    newHireStrategy: string[];
  };
  employeesToRound: Employee[];
  isLoading: boolean;
  searchTerm: string;
  selectedDelegate: string;
  showModalFlag: boolean;
  setSearchTerm: Dispatch<SetStateAction<string>>;
}

const EmployeeList: FC<EmployeeListProps> = ({
  authorizedUser,
  delegateSettings,
  employeesToRound,
  isLoading,
  searchTerm,
  selectedDelegate,
  showModalFlag,
  setSearchTerm
}) => {
  const dispatch = useDispatch();

  const [selectedTab, setSelectedTab] = useState<string>('All');
  const [rendered, setRendered] = useState(false);

  // TODO: Convert to memo
  const roundsCompleted: Array<Employee> = [];
  const roundsToDo: Array<Employee> = [];
  const newHireToDos: Array<Employee> = [];
  const newHireCompleted: Array<Employee> = [];

  employeesToRound.forEach(employee => {
    if (employee.complianceStatus === 'Completed') {
      if (employee.newHire) {
        newHireCompleted.push(employee);
      }
      roundsCompleted.push(employee);
    } else {
      if (employee.newHire) {
        newHireToDos.push(employee);
      }
      roundsToDo.push(employee);
    }
  });

  // TODO: Convert to memo
  const completePercent = isPercentLessThanOne(
    roundsCompleted.length,
    employeesToRound.length
  )
    ? 1
    : isPercentRoundedToOneHundred(
        roundsCompleted.length,
        employeesToRound.length
      )
    ? 99
    : Math.round((roundsCompleted.length / employeesToRound.length) * 100) || 0;

  const todoPercent = employeesToRound.length === 0 ? 0 : 100 - completePercent;

  useEffect(() => {
    if (selectedDelegate && selectedDelegate.length === 7) {
      // ALSO GET CONFIG HERE
      dispatch(getDelegateSettings({ employeeId: selectedDelegate }));
    }
  }, [selectedDelegate]);

  let roundingFrequency;
  if (delegateSettings.listRoundingFrequency) {
    roundingFrequency = delegateSettings.listRoundingFrequency;
  } else {
    roundingFrequency =
      authorizedUser?.employeeInfo &&
      authorizedUser?.employeeInfo?.myRoundingListConfig &&
      authorizedUser?.employeeInfo?.myRoundingListConfig.listRoundingFrequency;
  }

  let newHireFrequency;
  if (delegateSettings.newHireStrategy) {
    newHireFrequency = delegateSettings.newHireStrategy;
  } else {
    newHireFrequency =
      authorizedUser?.employeeInfo &&
      authorizedUser?.employeeInfo?.myRoundingListConfig &&
      authorizedUser?.employeeInfo?.myRoundingListConfig.newHireStrategy;
  }

  const startToNum =
    newHireFrequency && newHireFrequency?.length > 0
      ? newHireFrequency.map((strat: string) => {
          switch (strat) {
            case '30-days':
              return 30;
            case '60-days':
              return 60;
            case '90-days':
              return 90;
            default:
              return 30;
          }
        })
      : 30;
  const newHireFreq =
    (Array.isArray(startToNum) && Math.max(...startToNum)) || 30;

  useEffect(() => {
    setRendered(true);
  }, []);

  useEffect(() => {
    if (selectedDelegate && selectedDelegate.length === 7) {
      // ALSO GET CONFIG HERE
      dispatch(getDelegateSettings({ employeeId: selectedDelegate }));
    }
  }, [selectedDelegate]);

  useEffect(() => {
    if (
      window.sessionStorage.getItem('droppedEmployeeSnow') &&
      employeesToRound.length > 0 &&
      roundsToDo.length > 0
    ) {
      window.sessionStorage.removeItem('droppedEmployeeSnow');
    }
  }, [employeesToRound, roundsToDo]);

  useEffect(() => {
    if (
      rendered &&
      !window.sessionStorage.getItem('droppedEmployeeSnow') &&
      employeesToRound.length > 0 &&
      completePercent === 100
    ) {
      setTimeout(() => {
        fireSnow(
          4,
          1,
          { x: 0.16, y: 0.5 },
          [-0.4, 0.4],
          [0.4, 0.8],
          [0.4, 1],
          ['circle'],
          ['#a8a9ad']
        );
        fireSnow(
          4,
          1,
          { x: 0.16, y: 0.5 },
          [-0.4, 0.4],
          [0.4, 0.8],
          [0.4, 1],
          ['circle'],
          ['##e05a29']
        );
        fireSnow(
          4,
          1,
          { x: 0.16, y: 0.5 },
          [-0.4, 0.4],
          [0.4, 0.8],
          [0.4, 1],
          ['circle'],
          ['#206da3']
        );
      }, 1000);
      window.sessionStorage.setItem('droppedEmployeeSnow', 'true');
    }
  }, [employeesToRound, completePercent]);

  return (
    <EmployeeContainer id="Employee-Container">
      <SideNav
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        completeCount={roundsCompleted.length}
        completePercent={completePercent}
        todoPercent={todoPercent}
        todoCount={roundsToDo.length}
        totalCount={employeesToRound.length}
        newHires={newHireToDos.length + newHireCompleted.length}
        unableCount={0}
      />
      <div style={{ width: '100%' }}>
        <SearchHeader searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
        {isLoading ? (
          <Loading centered={false} />
        ) : (
          <EmployeesTable
            searchTerm={searchTerm}
            selectedTab={selectedTab}
            newHireToDo={newHireToDos}
            newHireComplete={newHireCompleted}
            completedRounds={roundsCompleted}
            toDoRounds={roundsToDo}
            frequency={roundingFrequency}
            newHireFrequency={newHireFreq}
          />
        )}
      </div>

      {showModalFlag && (
        <ModalContainer>
          <UnlistedEmployeeRoundModal />
        </ModalContainer>
      )}
    </EmployeeContainer>
  );
};

const mapReduxStateToProps = (state: RootState) => {
  return {
    authorizedUser: state.AuthorizedUser.authorizedUser,
    employeesToRound: state.EmployeeReducer.employeesToRound,
    isLoading: state.EmployeeReducer.loadingEmployeesToRound,
    selectedDelegate: state.EmployeeReducer.selectedDelegatedEmployee.hcaid,
    showModalFlag: state.UserReducer.showUnlistedEmployeeModalFlag,
    delegateSettings: state.EmployeeReducer.delegateSettings
  };
};

export default connect(mapReduxStateToProps)(EmployeeList);
