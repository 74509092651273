/**
 * External Imports
 */
import { FC } from 'react';
/**
 * Global Type Definition Imports
 */
import { AuthUserProps } from '../../../config/interfaces';
/**
 * Style Imports
 */
import {
  UserInfoCard,
  Avatar,
  UserInfoAvatar,
  UserInfoContent,
  UserInfoName,
  UserInfoDetails
} from './UserModal.styles';

interface IUserInfoProps {
  userDetails: AuthUserProps;
}

const UserInfo: FC<IUserInfoProps> = ({ userDetails }): JSX.Element => {
  return (
    <UserInfoCard>
      <UserInfoAvatar>
        <Avatar slot="avatar" color="gray-60">
          {/* <NeuImage src={imageUrl} alt="User Image" /> */}
        </Avatar>
      </UserInfoAvatar>
      <UserInfoContent>
        <UserInfoName>{`${userDetails.firstName} ${userDetails.lastName} | ${userDetails.hcaid}`}</UserInfoName>
        <UserInfoDetails>
          {userDetails.email
            ? userDetails.email.toString().toLowerCase()
            : 'Unknown'}
        </UserInfoDetails>
        <UserInfoDetails>{userDetails.title || 'Unknown'}</UserInfoDetails>
        <UserInfoDetails>{userDetails.dept || 'Unknown'}</UserInfoDetails>
      </UserInfoContent>
    </UserInfoCard>
  );
};

export default UserInfo;
