import { NeuLabel } from '@neutron/react';
import styled from 'styled-components';

export const TemplateView = styled.div`
  position: relative;
  width: 500px;
  height: calc(100vh - 300px);
  left: 0px;
  background: #f6f6f6;
  box-shadow: 0px 2px 4px rgba(25, 25, 25, 0.2);
  flex-direction: row;
`;

export const TemplateHeaderView = styled.div`
  position: relative;
  width: 484px;
  height: 112px;
  left: 0px;
  background: #ffffff;
`;
export const HeaderText = styled(NeuLabel)`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  color: #4b4b4b;
  margin-left: 10px;
  margin-top: 5px;
`;
export const QuestionNumberLabel = styled(NeuLabel)`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #191919;
  margin-right: 10px;
`;
export const TitleLabel = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: #4b4b4b;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const TemplateInfo = styled.span`
  white-space: pre;
  font-weight: normal;
  font-weight: 400;
  font-size: 14px;
  color: #686868;
`;

export const TemplateInfoContainer = styled.div`
  position: relative;
  left: 0px;
  top: 10px;
  background: white;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 10px;
  color: #4b4b4b;
  font-size: 14;
  line-height: 22px;
  font-style: normal;
  font-weight: 700;
  // box-shadow: 0px 2px 4px rgba(25, 25, 25, 0.2);
`;

export const TemplateQuestionCard = styled.div`
  gap: 16px;
  width: 445px;
  height: auto;
  min-height: 91px;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(25, 25, 25, 0.2);
  // clip-path: inset(-5px -5px -5px 0px);
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  align-self: stretch;
  margin-bottom: 10px;
`;

export const QuestionCardHeader = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px;
`;

export const UpDownStrip = styled.div`
  gap: 16px;
  width: 18px;
  height: 91px;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(25, 25, 25, 0.2);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  flex: none;
  order: 0;
  align-self: center;
  flex-grow: 1;
  margin-bottom: 10px;
  margin-right: 1px;
`;

export const QuestionNameLabel = styled(NeuLabel)`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  width: 320px;
  font-color: #206da3;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const EditTemplateView = styled.div`
  position: relative;
  width: 100%;
  left: 0px;
  background: #f6f6f6;
  box-shadow: 0px 2px 4px rgba(25, 25, 25, 0.2);
  flex-direction: row;
  display: flex;
`;

export const QuestionBankHeaderView = styled.div`
  position: relative;
  width: 484px;
  height: 112px;
  left: 0px;
  background: #ffffff;
`;

export const QuestionBankView = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 480px;
  height: calc(100vh - 300px);
  left: 0px;
  background: #f6f6f6;
  box-shadow: 0px 2px 4px rgba(25, 25, 25, 0.2);
`;

export const ExportButton = styled.button`
  display: flex;
  align-items: center;
  background: #2075ad;
  color: #ffffff;
  border-radius: 5px;
  padding: 4px 8px;
  position: relative;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  margin: 0px 10px;
  font-size: 0.75rem;
  font-weight: bold;
  justify-content: flex-end;
  flew-grow: 1;
`;

export const QuestionBankQuestionCard = styled.div`
  padding: 0px;
  width: 409px;
  height: auto;
  min-height: 101px;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(25, 25, 25, 0.2);
  border-radius: 5px;
  margin-bottom: 10px;
  margin-right: 15px;
  z-index: 2;
`;

export const QuestionSideStrip = styled.div`
  width: 18px;
  min-height: 101px;
  height: auto;
  flex: none;
  order: 0;
  align-self: center;
  flex-grow: 1;
  margin-right: 0px;
`;

export const QuestionBankQuestionOverlayCard = styled.div`
  position: absolute;
  padding: 16px;
  width: 409px;
  height: 97%;
  // box-shadow: 0px 2px 4px rgba(25, 25, 25, 0.2);
  border-radius: 5px;
  margin-bottom: 10px;
  margin-right: 15px;
  z-index: 1;
  // opacity: 0.5;
  // background-color: lightblue;
  display: flex;
  flex-direction: row;
`;

export const UtilityBar = styled.div`
  position: relative;
  height: 69px;
  width: 454px;
  left: 2%;
  right: 0%;
  background: #191919;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
`;

export const TagContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 16px;
`;

export const Tag = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  flex: none;
  order: 1;
  flex-grow: 0;
  background-color: #d3d3d3;
  margin-right: 5px;
  margin-top: 5px;
  border-radius: 5px;
  padding: 2px;
`;
