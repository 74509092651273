import styled from 'styled-components';

interface ISelectProps {
  disabled?: boolean;
  focused?: boolean;
  textAlign?: string;
}

interface ISelectDivProps {
  disabled?: boolean;
  query?: string;
}

export const StyledDropdownDiv = styled.div`
  min-width: 380px;
  width: 100%;
  padding: 8px 16px 8px 16px;
  border-radius: 5px;
  line-height: 26px;
  position: relative;
`;

export const StyledSelect = styled.select<ISelectProps>`
  font-size: 1rem;
  width: 100%;
  height: 42px;
  appearance: none;
  padding-left: 0.75rem;
  padding-right: 1.5rem;
  border: 1px solid var(--neu-color-gray-20, #c9c9c9);
  border-radius: 6px;
  &:active,
  &:focus,
  &:hover {
    border: 1px solid #2075ad !important;
  }
  ${({ disabled }) =>
    disabled ? 'background-color: var(--background-disabled, #c9c9c9);' : ''}
  ${({ focused }) => (focused ? 'visibility: hidden;' : '')};
  ${({ textAlign }) => `text-align: ${textAlign}`}
`;

export const StyledSelectDiv = styled.div<ISelectDivProps>`
  position: relative;
  &:after {
    content: '▼';
    font-size: 0.52rem;
    top: 8px;
    right: 10px;
    position: absolute;
    ${({ disabled, query }) => (disabled || query ? 'display: none' : '')};
  }
`;

export const StyledSelectHiddenDiv = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;

export const StyledOptionsDiv = styled.div`
  box-shadow: var(
    --neu-elevation-2,
    0 6px 12px 0 rgba(var(--neu-color-gray-100-rgb, 25, 25, 25), 0.2)
  );
  transition: all 1s ease-in;
  background: white;
  border: 1px #dadada solid;
  width: 100%;
  padding: 0.25rem 0;
  position: absolute;
  border-radius: inherit;
  max-height: 18.5em;
  overflow-x: auto;
  overflow-y: scroll;
  scroll-behavior: smooth;
  z-index: 1000;
  ${({ query }: { query?: boolean }) => (query ? `min-height: 3rem;` : '')}
`;

export const StyledSelectHiddenInput = styled.input`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  ${({ typeAhead }: { typeAhead?: boolean }) =>
    typeAhead
      ? `
  padding-left: 0.75rem;
  padding-top: 0.3rem;
  border-radius: 6px;
  border: 1px solid var(--neu-color-gray-20, #c9c9c9);
  text-align: left;`
      : ''}
`;
