import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { useSelector } from 'react-redux';

import rootReducers from './rootReducer';
import rootSaga from './rootSaga';

const sagaMiddleware = createSagaMiddleware();
export const useAppSelector = useSelector;

const store = configureStore({
  devTools: process.env.REACT_APP_ENV !== 'production',
  reducer: rootReducers,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['SET-TOAST-OPTIONS'],
        ignoredActionPaths: ['payload.handleConfirm'],
        ignoredPaths: ['ToastReducer.options']
      },
      immutableCheck: false
    }).concat([sagaMiddleware])
});

sagaMiddleware.run(rootSaga);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
