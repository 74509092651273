import styled, { css } from 'styled-components';
import {
  NeuCard,
  NeuCardHeader,
  NeuLabel,
  NeuButton,
  NeuCardActions,
  NeuCardContent
} from '@neutron/react';

interface DropdownProps {
  showPopover: boolean;
}

interface CSSProps {
  autoWidth?: boolean | undefined;
  centered?: boolean | undefined;
}

export const ModalContainer = styled(NeuCard)`
  display: flex;
  flex-direction: column;
  background: #f6f6f6 !important;
  z-index: 10;
  padding-inline-start: 0;
  padding-inline-end: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin-inline-end: 0;
  margin-top: 0;
`;

export const CardHeader = styled(NeuCardHeader)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  width: 100%;
  --background: white;
`;

export const ModalCardContent = styled(NeuCardContent)`
  flex: 1;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 2px;
  }
  ::-webkit-scrollbar-thumb {
    background: rgb(224, 224, 224);
    border-radius: 10px;
  }
  overflow-x: hidden;
  scroll-behavior: smooth;
  // width: 432px;
`;

export const InputHeading = styled(NeuLabel)<CSSProps>`
  display: inline-block;
  font-size: 0.875rem;
  line-height: 22px;
  width: 413px;
  margin-top: 12px;
  ${({ autoWidth }) =>
    autoWidth &&
    css`
      width: auto;
    `}
`;

export const CreateNewButton = styled(NeuButton)`
  margin-top: 0.875rem;
`;

export const ModalFooter = styled(NeuCardActions)`
  background: white !important;
  bottom: 0;
  height: 69px !important;
  border-top: 1px solid #b0b0b0;
  ${props =>
    !props.right &&
    css`
      display: flex;
      align-items: center;
      justify-content: end;
    `};
`;

export const ListContainer = styled.div`
  margin-top: 15px;
`;

export const ListRow = styled.div`
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  column-gap: 10px;
  border-top: 1px solid #b0b0b0;
`;

export const ListHeader = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
`;

export const EmployeeNameInTable = styled.p`
  font-size: 14px;
  // font-weight: 700;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #093865;
`;

export const TextInTable = styled.p`
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #4b4b4b;
`;

export const RemoveButton = styled.div`
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #ce3637;
  display: flex;
  align-items: center;
  :hover {
    cursor: pointer;
  }
`;
