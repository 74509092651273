import { FC, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  NeuTable,
  NeuTableBody,
  NeuTableRow,
  NeuPaginator
} from '@neutron/react';
import { PaginationContainer } from './Table.styles';
import NoDataSplashView from '../../noDataSplashView/NoDataSplashView';
import { setCurrentStoplightPage } from '../../../redux/actions/Stoplight.action';
import {
  setCurrentFeedbackPage,
  setCurrentIssuePage
} from '../../../redux/actions/Task.action';
import Loading from '../Loading';

const Table: FC<{
  cols: string | [];
  headers: JSX.Element[];
  rows: JSX.Element[] | (boolean | JSX.Element)[];
  totalResults?: number;
  tableType?: string;
  isTableEmpty?: boolean;
  isTableEmptySearch?: boolean;
  isTableEmptyCompleted?: boolean;
  isIssueTableEmpty?: boolean;
  isIssueTableEmptySearch?: boolean;
  isFeedbackTableEmpty?: boolean;
  isFeedbackTableEmptySearch?: boolean;
  isStoplightTableEmpty?: boolean;
  isStoplightTableEmptySearch?: boolean;
  customClass?: string;
  loading?: boolean;
}> = ({
  cols,
  headers,
  rows,
  totalResults,
  tableType,
  isTableEmpty = false,
  isTableEmptySearch = false,
  isTableEmptyCompleted = false,
  isIssueTableEmpty = false,
  isFeedbackTableEmpty = false,
  isStoplightTableEmpty = false,
  isIssueTableEmptySearch = false,
  isFeedbackTableEmptySearch = false,
  isStoplightTableEmptySearch = false,
  customClass = '',
  loading
}) => {
  const dispatch = useDispatch();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize] = useState(50);

  const pageNumberChanged = (pageNum: number) => {
    if (tableType === 'Stoplight') {
      dispatch(setCurrentStoplightPage(pageNum));
    } else if (tableType === 'Feedback') {
      dispatch(setCurrentFeedbackPage(pageNum));
    } else if (tableType === 'Issue') {
      dispatch(setCurrentIssuePage(pageNum));
    }

    if (pageNum) setPageNumber(pageNum);
  };

  const rowSubset = useMemo(() => {
    let rowsForTable;
    if (
      tableType === 'Feedback' ||
      tableType === 'Stoplight' ||
      tableType === 'Issue'
    ) {
      rowsForTable = rows;
    } else {
      rowsForTable = rows.slice(
        pageNumber * pageSize - pageSize,
        pageNumber * pageSize
      );
    }
    return rowsForTable;
  }, [pageNumber, pageSize, rows]);

  return (
    <NeuTable color="gray-80" id="Shared-Table" className={customClass}>
      <NeuTableRow header columns={cols} size="large" color="plain-0">
        {headers}
      </NeuTableRow>
      {loading ? (
        <Loading centered={false} />
      ) : isTableEmpty && !loading ? (
        <NoDataSplashView type="employeeCensus" />
      ) : isTableEmptySearch && !loading ? (
        <NoDataSplashView type="employeeCensusSearch" />
      ) : isTableEmptyCompleted && !loading ? (
        <NoDataSplashView type="employeeCensusCompleted" />
      ) : isIssueTableEmpty && !loading ? (
        <NoDataSplashView type="issue" />
      ) : isIssueTableEmptySearch && !loading ? (
        <NoDataSplashView type="issueSearch" />
      ) : isFeedbackTableEmpty && !loading ? (
        <NoDataSplashView type="feedback" />
      ) : isStoplightTableEmpty && !loading ? (
        <NoDataSplashView type="stoplight" />
      ) : isFeedbackTableEmptySearch && !loading ? (
        <NoDataSplashView type="feedbackSearch" />
      ) : isStoplightTableEmptySearch && !loading ? (
        <NoDataSplashView type="stoplightSearch" />
      ) : (
        <NeuTableBody>{rowSubset}</NeuTableBody>
      )}
      {!(
        isTableEmpty ||
        isTableEmptySearch ||
        isTableEmptyCompleted ||
        isIssueTableEmpty ||
        isFeedbackTableEmpty ||
        isStoplightTableEmpty ||
        isStoplightTableEmptySearch ||
        isFeedbackTableEmptySearch ||
        isIssueTableEmptySearch
      ) && (
        <PaginationContainer>
          <NeuPaginator
            total={!totalResults ? rows.length : totalResults}
            rowsPerPage={pageSize}
            onNeuChange={e => pageNumberChanged(e.detail.pageNumber)}
          />
        </PaginationContainer>
      )}
    </NeuTable>
  );
};

export default Table;
