// Importing action file
import ACTIONS from '../actions/actionType';

import { ActionType } from '../../config/interfaces';

const initialState = {
  searchedPatients: [],
  patientRoundHistory: [],
  employeeRoundHistory: [],
  complianceReport: [],
  todoCount: 0,
  completeCount: 0,
  todoPercent: 0,
  completePercent: 0,
  roundsReportFilter: {},
  patientRoundsReport: [],
  employeeRoundsReport: [],
  employeeFreqReport: [],
  totalTableCount: 0,
  currentPatientRoundsReportPage: 1,
  currentEmployeeRoundsReportPage: 1,
  currentEmployeeFreqReportPage: 1,
  censusReport: [],
  sortRoundsKey: 'roundDate',
  sortRoundsDir: 'desc',
  sortFreqKey: 'employeeDept',
  sortFreqDir: 'asc',
  reportSearchQuery: '',
  loading: false
};

const ReportReducer = (state = initialState, action: ActionType = {}) => {
  const tempState = { ...state };

  switch (action.type) {
    case ACTIONS.REPORT.SEARCH_PATIENT_HISTORY_SUCCESS: {
      return {
        ...state,
        searchedPatients: action.data
      };
    }

    case ACTIONS.REPORT.CLEAR_PATIENT_SEARCH: {
      return {
        ...state,
        searchedPatients: []
      };
    }

    case ACTIONS.REPORT.GET_PATIENT_ROUND_HISTORY_SUCCESS: {
      return {
        ...state,
        patientRoundHistory: action.data
      };
    }

    case ACTIONS.REPORT.CLEAR_PATIENT_ROUND_HISTORY: {
      return {
        ...state,
        patientRoundHistory: []
      };
    }

    case ACTIONS.REPORT.GET_EMPLOYEE_ROUND_HISTORY_SUCCESS: {
      return {
        ...state,
        employeeRoundHistory: action.data
      };
    }

    case ACTIONS.REPORT.CLEAR_EMPLOYEE_ROUND_HISTORY: {
      return {
        ...state,
        employeeRoundHistory: []
      };
    }

    case ACTIONS.REPORT.GET_PATIENT_ROUNDS: {
      return {
        ...state,
        loading: true
      };
    }

    case ACTIONS.REPORT.GET_PATIENT_ROUNDS_SUCCESS: {
      return {
        ...state,
        patientRoundsReport: action.data.roundReportList,
        totalTableCount: action.data.totalRecordCount,
        loading: false
      };
    }

    case ACTIONS.REPORT.GET_PATIENT_ROUNDS_FAILURE: {
      return {
        ...state,
        patientRoundsReport: [],
        loading: false
      };
    }

    case ACTIONS.REPORT.GET_COMPLIANCE_REPORT: {
      return {
        ...state,
        loading: true
      };
    }

    case ACTIONS.REPORT.GET_COMPLIANCE_REPORT_SUCCESS: {
      return {
        ...state,
        complianceReport: action.data,
        loading: false
      };
    }

    case ACTIONS.REPORT.GET_COMPLIANCE_REPORT_FAILURE: {
      return {
        ...state,
        complianceReport: [],
        loading: false
      };
    }

    case ACTIONS.REPORT.GET_CENSUS_REPORT: {
      return {
        ...state,
        loading: true
      };
    }

    case ACTIONS.REPORT.GET_CENSUS_REPORT_SUCCESS: {
      return {
        ...state,
        censusReport: action.data,
        loading: false
      };
    }

    case ACTIONS.REPORT.GET_CENSUS_REPORT_FAILURE: {
      return {
        ...state,
        censusReport: [],
        loading: false
      };
    }

    case ACTIONS.REPORT.SET_CURRENT_PATIENT_ROUNDS_PAGE: {
      return {
        ...state,
        currentPatientRoundsReportPage: action.data
      };
    }

    case ACTIONS.REPORT.SET_PATIENT_ROUNDS_SEARCH_QUERY: {
      return {
        ...state,
        reportSearchQuery: action.data,
        currentPatientRoundsReportPage: 1
      };
    }

    case ACTIONS.REPORT.SET_PATIENT_ROUNDS_SORT: {
      return {
        ...state,
        ...{
          sortRoundsKey: action.data.sortKey,
          sortRoundsDir: action.data.sortDir
        }
      };
    }

    case ACTIONS.REPORT.GET_EMPLOYEE_ROUNDS: {
      return {
        ...state,
        loading: true
      };
    }

    case ACTIONS.REPORT.GET_EMPLOYEE_ROUNDS_SUCCESS: {
      return {
        ...state,
        employeeRoundsReport: action.data.roundReportList,
        totalTableCount: action.data.totalRecordCount,
        loading: false
      };
    }

    case ACTIONS.REPORT.GET_EMPLOYEE_ROUNDS_FAILURE: {
      return {
        ...state,
        employeeRoundsReport: [],
        loading: false
      };
    }

    case ACTIONS.REPORT.CLEAR_EMPLOYEE_ROUNDS: {
      return {
        ...state,
        employeeRoundsReport: []
      };
    }

    case ACTIONS.REPORT.SET_CURRENT_EMPLOYEE_ROUNDS_PAGE: {
      return {
        ...state,
        currentEmployeeRoundsReportPage: action.data
      };
    }

    case ACTIONS.REPORT.SET_EMPLOYEE_ROUNDS_SEARCH_QUERY: {
      return {
        ...state,
        reportSearchQuery: action.data,
        currentEmployeeRoundsReportPage: 1
      };
    }

    case ACTIONS.REPORT.SET_EMPLOYEE_ROUNDS_SORT: {
      return {
        ...state,
        ...{
          sortRoundsKey: action.data.sortKey,
          sortRoundsDir: action.data.sortDir
        }
      };
    }

    case ACTIONS.REPORT.GET_EMPLOYEE_FREQ: {
      return {
        ...state,
        loading: true
      };
    }

    case ACTIONS.REPORT.GET_EMPLOYEE_FREQ_SUCCESS: {
      return {
        ...state,
        employeeFreqReport: action.data.freqReportList,
        totalTableCount: action.data.totalRecordCount,
        loading: false
      };
    }

    case ACTIONS.REPORT.GET_EMPLOYEE_FREQ_FAILURE: {
      return {
        ...state,
        employeeFreqReport: [],
        loading: false
      };
    }

    case ACTIONS.REPORT.CLEAR_EMPLOYEE_FREQ: {
      return {
        ...state,
        employeeFreqReport: []
      };
    }

    case ACTIONS.REPORT.SET_CURRENT_EMPLOYEE_FREQ_PAGE: {
      return {
        ...state,
        currentEmployeeFreqReportPage: action.data
      };
    }

    case ACTIONS.REPORT.SET_EMPLOYEE_FREQ_SEARCH_QUERY: {
      return {
        ...state,
        reportSearchQuery: action.data,
        currentEmployeeFreqReportPage: 1
      };
    }

    case ACTIONS.REPORT.SET_EMPLOYEE_FREQ_SORT: {
      return {
        ...state,
        ...{
          sortFreqKey: action.data.sortKey,
          sortFreqDir: action.data.sortDir
        }
      };
    }

    case ACTIONS.REPORT.SET_ROUNDS_REPORT_FILTER: {
      return {
        ...state,
        roundsReportFilter: action.data,
        currentPatientRoundsReportPage: 1,
        currentEmployeeRoundsReportPage: 1
      };
    }

    case ACTIONS.REPORT.CLEAR_ROUNDS_REPORT_FILTER: {
      return {
        ...state,
        roundsReportFilter: {},
        currentPatientRoundsReportPage: 1,
        currentEmployeeRoundsReportPage: 1
      };
    }

    default:
      return tempState;
  }
};

export default ReportReducer;
