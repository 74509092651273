import ACTIONS from './actionType';

export const addFavorite = (data: {
  userId: string;
  unitId: string;
  facilityId: string;
}) => {
  return { type: ACTIONS.ACCOUNT.POST_ADD_PRIMARY, data };
};

export const removeFavorite = (data: {
  userId: string;
  unitId: string;
  facilityId: string;
}) => {
  return { type: ACTIONS.ACCOUNT.POST_REMOVE_PRIMARY, data };
};

export const updateCurrentFacility = (data: {
  userId: string;
  facilityId: String;
}) => {
  return { type: ACTIONS.ACCOUNT.POST_CURRENT_FACILITY, data };
};
