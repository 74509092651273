import { FC, Dispatch, SetStateAction } from 'react';
import { NeuSubLeftNav, NeuItem, NeuStepper, NeuLabel } from '@neutron/react';

interface INavItemType {
  tabLabel: string;
  tabId: string;
}

interface ISideNavProps {
  navItems: INavItemType[];
  defaultColor: string;
  activeColor: string;
  className: string;
  selectedTab: string;
  setSelectedTab: Dispatch<SetStateAction<string>>;
}

const SideNav: FC<ISideNavProps> = ({
  navItems,
  defaultColor = 'plain-0',
  activeColor = 'primary-10',
  className = 'pl-3',
  selectedTab,
  setSelectedTab
}) => {
  const changeTab = (tab: string) => {
    setSelectedTab(tab);
  };

  return (
    <NeuSubLeftNav
      id="Admin-Side-Nav"
      name="MENU"
      color="plain"
      side="left"
      style={{ marginTop: '-3px' }}
    >
      {navItems &&
        navItems.map((item: INavItemType) => {
          return (
            <NeuItem
              key={item.tabId}
              color={selectedTab === item.tabId ? activeColor : defaultColor}
              className={className}
              onClick={() => changeTab(item.tabId)}
            >
              <NeuStepper slot="start" />
              <NeuLabel>{item.tabLabel}</NeuLabel>
            </NeuItem>
          );
        })}
    </NeuSubLeftNav>
  );
};

export default SideNav;
