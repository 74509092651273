/**
 * External Imports
 */
import { NeuContainer, NeuLabel } from '@neutron/react';
/**
 * Style Imports
 */
import styled from 'styled-components';

export const AttachedContainer = styled(NeuContainer)`
  // max-height: 0px;
  // transition: max-height 0.75s ease-out;
  // -webkit-transition: max-height 0.75s ease-out;
`;

export const ViewIssuesLabel = styled(NeuLabel)`
  font-style: normal;
  font-size: 1rem;
  line-height: 26px;
  color: #2075ad;
`;

export const ShowHideSpan = styled.span`
  font-size: 0.75rem;
`;

export const NoneAttachedLabel = styled(NeuLabel)`
  text-align: center !important;
  font-size: 1rem;
  font-weight: 500 !important;
`;
