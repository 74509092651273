import styled from 'styled-components';
import { NeuButton } from '@neutron/react';

export const DrawerContainer = styled.aside`
  flex: 1 0 24%;
  max-width: 270px;
  background-color: white;
  height: calc(100vh - 186px);
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.15);
`;

export const SaveNoteButton = styled(NeuButton)`
  float: right;
`;
export const CardsContainer = styled.div`
  height: calc(100% - 50px);
  overflow-y: scroll;
`;
export const NoteCardsContainer = styled(CardsContainer)`
  height: calc(100% - 200px);
`;
export const NotesContainer = styled(CardsContainer)`
  overflow-y: visible;
`;

export const TaskCardContainer = styled.div`
  :hover {
    cursor: pointer;
  }
`;

interface TaskCardContainerForRoundProps {
  activeColor?: string;
}
export const TaskCardContainerForRound = styled.div<TaskCardContainerForRoundProps>`
  padding: 15px;
  border-bottom: 1px solid #b0b0b0;
  :hover {
    cursor: pointer;
  }
  background: ${props => props.activeColor || ''};
`;
export const HorizontalDivider = styled.hr`
  margin-top: 45px;
  border-top: 1px solid rgb(224, 224, 224);
  margin-left: -15px;
  margin-right: -15px;
`;
