import styled from 'styled-components';
import { NeuImage, NeuLabel } from '@neutron/react';

export const MessageContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 65px;
  justify-content: center;
  top: 150px;
`;
export const StyledDiv = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  left: 20px;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 32px;
  color: #093865;
  justify-content: space-around;
`;

export const StyledImage = styled(NeuImage)`
  position: static;
  display: flex;
  align-items: center;
  width: 65px;
  height: 65px;
`;

export const SubTitle = styled(NeuLabel)`
  top: 0px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #000000;
  white-space: pre-wrap;
`;
