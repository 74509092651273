import styled from 'styled-components';

export const CensusReportHeaderContainer = styled.div`
  position: static;
  height: 70px;
  background: #f6f6f6;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  justify-content: space-between;
  border-bottom: 1px solid #b0b0b0;
`;

export const Section = styled.div`
  display: flex;
`;

export const DateSect = styled.div`
  width: 183px;
`;

export const HintSection = styled.div`
  margin-left: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
