// Importing action file
import ACTIONS from '../actions/actionType';

import { ActionType } from '../../config/interfaces';

const initialState = {
  feedback: [],
  issues: [],
  singleTaskToComplete: [],
  issueTypes: [],
  empIssueTypes: [],
  loadingIssuesByFacility: false,
  loadingPostNewTask: false,
  loadingCompleteTask: false,
  loadingDeleteTask: false,
  loadingFeedbackByUnitIds: false,
  loadingFeedbackByEmployeeList: false,
  loadingIssueById: false,
  loadingIssueListByUnitsOrEmpList: false,
  loadingFeedbackListByFacility: false,
  sharingQ: [],
  sharing: false,
  feedbackCategories: [],
  selectedData: null,
  employeeSharedWith: [],
  isLoadingSharedWith: false,
  patientTasks: [],
  employeeTask: [],
  searchedIssues: [],
  tempTaskObject: {},
  tempModalState: {},
  ticketDetails: {},
  isLoadingTicket: false,
  scLocations: [],
  isLoadingSCLocations: false,
  geLocations: [],
  isLoadingGELocations: false,
  controlNumberResponse: null,
  isLoadingControlNumber: false,
  totalCount: 0,
  currentFeedbackPage: 1,
  employeeIssues: [],
  employeeFeedback: [],
  currentIssuePage: 1,
  issueFilter: {},
  feedbackFilter: {},
  issueById: {},
  sortKey: 'created',
  sortDir: 'desc',
  taskSearchQuery: '',
  searchedTableTasks: [],
  categories: []
};

const TaskReducer = (state = initialState, action: ActionType = {}) => {
  const tempState = { ...state };

  switch (action.type) {
    case ACTIONS.TASK.GET_SINGLE_TASK_TO_COMPLETE_SUCCESS: {
      return {
        ...state,
        singleTaskToComplete: action.data.singleTaskToComplete
      };
    }
    case ACTIONS.TASK.CLEAR_SINGLE_TASK_TO_COMPLETE: {
      return {
        ...state,
        singleTaskToComplete: []
      };
    }
    case ACTIONS.TASK.GET_ISSUES_BY_FACILITY: {
      return {
        ...state,
        loadingIssuesByFacility: true
      };
    }
    case ACTIONS.TASK.GET_ISSUES_BY_FACILITY_SUCCESS: {
      return {
        ...state,
        loadingIssuesByFacility: false,
        issues: action.data.issues,
        totalCount: action.data.totalCount
      };
    }
    case ACTIONS.TASK.GET_CATEGORIES_SUCCESS: {
      return {
        ...state,
        categories: action.data.categories
      };
    }
    case ACTIONS.TASK.GET_CATEGORIES_FAILURE: {
      return {
        ...state,
        categories: []
      };
    }
    case ACTIONS.TASK.POST_NEW_TASK: {
      return {
        ...state,
        loadingPostNewTask: true
      };
    }
    case ACTIONS.TASK.POST_NEW_TASK_SUCCESS: {
      return {
        ...state,
        loadingPostNewTask: false
      };
    }
    case ACTIONS.TASK.POST_NEW_TASK_FAIL: {
      return {
        ...state,
        loadingPostNewTask: false
      };
    }
    case ACTIONS.TASK.SHARE_ALL_TASK_SUCCESS: {
      return {
        ...state,
        sharing: false
      };
    }
    case ACTIONS.TASK.SHARE_TASK_SUCCESS: {
      return {
        ...state,
        sharing: true
      };
    }
    case ACTIONS.TASK.SHARE_TASK_FAILED: {
      return {
        ...state,
        sharing: false
      };
    }
    case ACTIONS.TASK.ADD_TASK_TO_SHARING_Q: {
      return {
        ...state,
        sharingQ: [...state.sharingQ, action.data]
      };
    }
    case ACTIONS.TASK.CLEAR_SHARING_Q: {
      return {
        ...state,
        sharingQ: []
      };
    }
    case ACTIONS.TASK.GET_FEEDBACK_CATEGORIES_SUCCESS: {
      return {
        ...state,
        feedbackCategories: action.data
      };
    }
    case ACTIONS.TASK.SELECT_DATA_FOR_EDIT: {
      return {
        ...state,
        selectedData: action.data
      };
    }
    case ACTIONS.TASK.RESET_SELECTED_DATA_FOR_EDIT: {
      return {
        ...state,
        selectedData: null
      };
    }
    case ACTIONS.TASK.GET_EMP_SHARED_WITH: {
      return {
        ...state,
        isLoadingSharedWith: true
      };
    }
    case ACTIONS.TASK.GET_EMP_SHARED_WITH_SUCCESS: {
      return {
        ...state,
        employeeSharedWith: action.data,
        isLoadingSharedWith: false
      };
    }
    case ACTIONS.TASK.RESET_EMP_SHARED_WITH: {
      return {
        ...state,
        employeeSharedWith: []
      };
    }
    case ACTIONS.TASK.COMPLETE_TASK: {
      return {
        ...state,
        loadingCompleteTask: true
      };
    }
    case ACTIONS.TASK.COMPLETE_TASK_SUCCESS: {
      return {
        ...state,
        loadingCompleteTask: false
      };
    }
    case ACTIONS.TASK.DELETE_TASK: {
      return {
        ...state,
        loadingDeleteTask: true
      };
    }
    case ACTIONS.TASK.DELETE_TASK_SUCCESS: {
      return {
        ...state,
        loadingDeleteTask: false
      };
    }
    case ACTIONS.TASK.GET_TASKS_BY_ACCOUNT_NUMBER_SUCCESS: {
      return {
        ...state,
        patientTasks: action.data
      };
    }
    case ACTIONS.TASK.CLEAR_TASKS_BY_ACCOUNT_NUMBER: {
      return {
        ...state,
        patientTasks: []
      };
    }
    case ACTIONS.TASK.GET_TASKS_BY_EMPLOYEE_ID_SUCCESS: {
      return {
        ...state,
        employeeIssues: action.data.issues,
        employeeFeedback: action.data.feedback
      };
    }
    case ACTIONS.TASK.SEARCH_ISSUES_SUCCESS: {
      return {
        ...state,
        searchedIssues: action.data
      };
    }
    case ACTIONS.TASK.CLEAR_SEARCH_ISSUES: {
      return {
        ...state,
        searchedIssues: []
      };
    }
    // Save task object saved temporarily
    case ACTIONS.TASK.SAVE_TASK_DATA: {
      return {
        ...state,
        tempTaskObject: action.data
      };
    }
    // Clear task object saved temporarily
    case ACTIONS.TASK.CLEAR_TASK_DATA: {
      return {
        ...state,
        tempTaskObject: {}
      };
    }
    // Save modal state temporarily
    case ACTIONS.TASK.SAVE_TASK_MODAL_STATE: {
      return {
        ...state,
        tempModalState: action.data
      };
    }
    // Clear modal state saved temporarily
    case ACTIONS.TASK.CLEAR_TASK_MODAL_STATE: {
      return {
        ...state,
        tempModalState: {},
        redirect: false
      };
    }
    // Service central ticket details
    case ACTIONS.TASK.GET_TICKET_DETAILS: {
      return {
        ...state,
        isLoadingTicket: true
      };
    }
    case ACTIONS.TASK.GET_TICKET_DETAILS_SUCCESS: {
      return {
        ...state,
        isLoadingTicket: false,
        ticketDetails: action.data
      };
    }
    case ACTIONS.TASK.CLEAR_TICKET_DETAILS: {
      return {
        ...state,
        ticketDetails: {}
      };
    }

    case ACTIONS.TASK.GET_SC_LOCATIONS: {
      return {
        ...state,
        isLoadingSCLocations: true
      };
    }

    case ACTIONS.TASK.GET_SC_LOCATIONS_SUCCESS: {
      return {
        ...state,
        isLoadingSCLocations: false,
        scLocations: action.data
      };
    }

    case ACTIONS.TASK.GET_GE_LOCATIONS: {
      return {
        ...state,
        isLoadingGELocations: true
      };
    }

    case ACTIONS.TASK.GET_GE_LOCATIONS_SUCCESS: {
      return {
        ...state,
        isLoadingGELocations: false,
        geLocations: action.data
      };
    }

    case ACTIONS.TASK.VERIFY_CONTROL_NUMBER: {
      return {
        ...state,
        isLoadingControlNumber: true
      };
    }

    case ACTIONS.TASK.VERIFY_CONTROL_NUMBER_SUCCESS: {
      return {
        ...state,
        controlNumberResponse: action.data,
        isLoadingControlNumber: false
      };
    }
    case ACTIONS.TASK.CLEAR_VERIFY_CONTROL_NUMBER: {
      return {
        ...state,
        controlNumberResponse: null,
        isLoadingControlNumber: false
      };
    }
    // FEEDBACK REDUCERS
    case ACTIONS.TASK.GET_FEEDBACK_BY_UNIT_IDS: {
      return {
        ...state,
        loadingFeedbackByUnitIds: true
      };
    }
    case ACTIONS.TASK.GET_FEEDBACK_BY_UNIT_IDS_SUCCESS: {
      return {
        ...state,
        loadingFeedbackByUnitIds: false,
        feedback: action.data.feedbackList,
        totalCount: action.data.totalCount
      };
    }
    case ACTIONS.TASK.GET_FEEDBACK_BY_UNIT_IDS_FAILED: {
      return {
        ...state,
        loadingFeedbackByUnitIds: false,
        feedback: action.data.feedbackList,
        totalCount: action.data.totalCount
      };
    }
    case ACTIONS.TASK.GET_FEEDBACK_BY_EMPLOYEE_LIST: {
      return {
        ...state,
        loadingFeedbackByEmployeeList: true
      };
    }
    case ACTIONS.TASK.GET_FEEDBACK_BY_EMPLOYEE_LIST_SUCCESS: {
      return {
        ...state,
        loadingFeedbackByEmployeeList: false,
        feedback: action.data.feedbackList,
        totalCount: action.data.totalCount
      };
    }
    case ACTIONS.TASK.GET_FEEDBACK_BY_EMPLOYEE_LIST_FAILED: {
      return {
        ...state,
        loadingFeedbackByEmployeeList: false,
        feedback: action.data.feedbackList,
        totalCount: action.data.totalCount
      };
    }
    case ACTIONS.TASK.SET_CURRENT_FEEDBACK_PAGE: {
      return {
        ...state,
        currentFeedbackPage: action.data
      };
    }

    case ACTIONS.TASK.SET_CURRENT_ISSUE_PAGE: {
      return {
        ...state,
        currentIssuePage: action.data
      };
    }

    case ACTIONS.TASK.SET_ISSUE_FILTER: {
      return {
        ...state,
        issueFilter: action.data,
        currentIssuePage: 1
      };
    }
    case ACTIONS.TASK.SET_FEEDBACK_FILTER: {
      return {
        ...state,
        feedbackFilter: action.data,
        currentFeedbackPage: 1
      };
    }
    case ACTIONS.TASK.CLEAR_FEEDBACK_FILTER: {
      return {
        ...state,
        feedbackFilter: {},
        currentFeedbackPage: 1
      };
    }
    case ACTIONS.TASK.CLEAR_ISSUE_FILTER: {
      return {
        ...state,
        issueFilter: {},
        currentIssuePage: 1
      };
    }
    case ACTIONS.TASK.SET_TASK_SORT: {
      return {
        ...state,
        ...action.data
      };
    }
    case ACTIONS.TASK.GET_ISSUE_BY_ID: {
      return {
        ...state,
        loadingIssueById: true
      };
    }
    case ACTIONS.TASK.GET_ISSUE_BY_ID_SUCCESS: {
      return {
        ...state,
        loadingIssueById: false,
        issueById: action.data
      };
    }
    case ACTIONS.TASK.CLEAR_ISSUE_BY_ID: {
      return {
        ...state,
        issueById: {}
      };
    }

    // Get issues list by facility
    case ACTIONS.TASK.GET_ISSUE_LIST_BY_FACILITY: {
      return {
        ...state,
        loadingIssueListByUnitsOrEmpList: true
      };
    }
    case ACTIONS.TASK.GET_ISSUE_LIST_BY_FACILITY_SUCCESS: {
      return {
        ...state,
        loadingIssueListByUnitsOrEmpList: false,
        issues: action.data.issues,
        totalCount: action.data.totalCount
      };
    }
    case ACTIONS.TASK.GET_ISSUE_LIST_BY_FACILITY_FAILURE: {
      return {
        ...state,
        loadingIssueListByUnitsOrEmpList: false
      };
    }
    // Get feedback list by facility
    case ACTIONS.TASK.GET_FEEDBACK_LIST_BY_FACILITY: {
      return {
        ...state,
        loadingFeedbackListByFacility: true
      };
    }
    case ACTIONS.TASK.GET_FEEDBACK_LIST_BY_FACILITY_SUCCESS: {
      return {
        ...state,
        loadingFeedbackListByFacility: false,
        feedback: action.data.feedbackList,
        totalCount: action.data.totalCount
      };
    }
    case ACTIONS.TASK.GET_FEEDBACK_LIST_BY_FACILITY_FAILURE: {
      return {
        ...state,
        loadingFeedbackListByFacility: false
      };
    }
    case ACTIONS.TASK.SET_TASK_SEARCH_QUERY: {
      return {
        ...state,
        taskSearchQuery: action.data,
        currentIssuePage: 1
      };
    }
    default:
      return tempState;
  }
};

export default TaskReducer;
