import ACTIONS from './actionType';

// Config actions
export const getFacilityUnits = (data: {
  facilityId: string;
  isUnfiltered: boolean;
}) => {
  return { type: ACTIONS.CONFIG.GET_UNITS_BY_FACILITY, data };
};

export const setSelectedUnits = (data: { id: string; unit: string }[]) => {
  return { type: ACTIONS.CONFIG.SET_SELECTED_UNITS, data };
};

export const setAllPrevChecked = (data: boolean) => {
  return { type: ACTIONS.CONFIG.SET_ALL_PREV_CHECKED, data };
};

export const setFavsPrevChecked = (data: boolean) => {
  return { type: ACTIONS.CONFIG.SET_FAVS_PREV_CHECKED, data };
};

export const changeUnitLocTypeAndFlag = (data: {
  facilityId: string;
  unitId: string;
  locType: any;
  active: string | undefined;
}) => {
  return { type: ACTIONS.CONFIG.CHANGE_UNIT_LOC_TYPE_FLAG, data };
};

export const getAllUnits = (data: { facilityId: string }) => {
  return { type: ACTIONS.CONFIG.GET_ALL_UNITS, data };
};

export const getSassToken = () => {
  return { type: ACTIONS.CONFIG.GET_SASS_TOKEN };
};
