import { FC, Dispatch, SetStateAction } from 'react';

import { NeuCard } from '@neutron/react';

import QuestionPart from './QuestionPart';

import { NextQuestionIcon } from './Question.styles';

interface IQuestionProps {
  data: any;
  viewOnly: boolean;
  q: any;
  setData: Dispatch<SetStateAction<{}>>;
}

const Question: FC<IQuestionProps> = ({ data, q, setData, viewOnly }) => {
  return (
    <NeuCard
      id={`Round-Question-Card-${q.id}`}
      className={`ml-4${
        q?.questionTags.includes('REQUIRED') ? ' required-content' : ''
      }`}
      type="button"
      small
      style={{ overflow: 'visible' }}
      width="75%"
    >
      <QuestionPart
        data={data}
        quesId={q.id}
        questionPart={q.questionParts[0]}
        setData={setData}
        viewOnly={viewOnly}
      />
      {q.questionParts.slice(1).length > 0
        ? q.questionParts.slice(1).map((questionPart: any) =>
            questionPart?.questionCondition?.some((cond: string) =>
              data?.selectedConditions?.some(
                (c: { questionId: string; flags: string[] }) =>
                  c.flags.indexOf(cond) !== -1 && q.id === c.questionId
              )
            ) || !questionPart?.questionCondition?.length ? (
              viewOnly ? (
                <div
                  className="d-flex flex-grow-1"
                  key={questionPart.questionPartId}
                >
                  <NextQuestionIcon className="pt-3" color="primary-70">
                    subdirectory_arrow_right
                  </NextQuestionIcon>
                  <QuestionPart
                    key={questionPart.questionPartId}
                    data={data}
                    quesId={q.id}
                    questionPart={questionPart}
                    setData={setData}
                    viewOnly={viewOnly}
                  />
                </div>
              ) : (
                <QuestionPart
                  key={questionPart.questionPartId}
                  data={data}
                  quesId={q.id}
                  questionPart={questionPart}
                  setData={setData}
                  viewOnly={viewOnly}
                />
              )
            ) : null
          )
        : null}
    </NeuCard>
  );
};

export default Question;
