import confetti, { Shape } from 'canvas-confetti';

const randomInRange = (min: number, max: number) => {
  return Math.random() * (max - min) + min;
};

export const fireSnow = (
  duration: number,
  particleCount: number,
  origin: { [key: string]: number },
  drift: number[],
  gravity: number[],
  scalar: number[],
  shapes: Shape[],
  colors: string[] = ['#ffffff'],
  startVelocity: number = 0,
  skew: number = Math.max(0.8, 1 - 0.001)
) => {
  const animationEnd = Date.now() + duration * 1000;

  const frame = () => {
    const timeLeft = animationEnd - Date.now();
    const ticks = Math.max(75, 150 * (timeLeft / duration));

    const [a, b] = drift;
    const [c, d] = gravity;
    const [e, f] = scalar;
    const { x, y } = origin;

    confetti({
      particleCount,
      startVelocity,
      ticks,
      origin: {
        x: x + Math.random(),
        // since particles fall down, skew start toward the top
        y: y + Math.random() * Math.max(0.8, skew - 0.001) - 0.2
      },
      colors,
      shapes,
      drift: randomInRange(a, b),
      gravity: randomInRange(c, d),
      scalar: randomInRange(e, f)
    });

    if (timeLeft > 0) {
      window.requestAnimationFrame(frame);
    }
  };

  frame();
};
