import styled from 'styled-components';
import { NeuTableRow, NeuLabel } from '@neutron/react';

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 6px;
`;

export const ActiveText = styled.span`
  margin-left: 2px;
`;
export const StyledNeuTableRow = styled(NeuTableRow)`
  align-items: center !important;
`;

export const StyledLabelLoader = styled(NeuLabel)`
  font-size: 14px;
  line-height: 22px;
  margin-right: 2px;
  padding-top: 7px;
  padding-bottom: 8px;
`;
