import { FC, Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useAnalyticsApi } from '@shared-web-analytics/react/dist';

import {
  logListClick,
  logReportsClick,
  logTaskClick
} from '../../../utils/analyticsHelpers';
import { useDebounceValue } from '../../../utils/debouncers';

import { SearchInput, SearchIcon, SearchContainer } from './SearchField.styles';

interface SearchFieldProps {
  searchTerm: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;
}

const SearchField: FC<SearchFieldProps> = ({ searchTerm, setSearchTerm }) => {
  const { '*': view } = useParams();

  const { logTrackingEvent } = useAnalyticsApi();

  const dbSearchTerm = useDebounceValue(searchTerm.trim(), 350);

  const handleSearch = (event: any) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchFocus = useCallback(() => {
    switch (view) {
      case 'census':
      case 'list':
      case 'csc/list':
      case 'cned/list':
        logTrackingEvent(logListClick('search', view));
        break;
      case 'issues':
      case 'cned/issues':
      case 'feedback':
      case 'cned/feedback':
      case 'stoplight':
        logTrackingEvent(logTaskClick(view, 'search'));
        break;
      case 'employee':
        logTrackingEvent(logReportsClick('employee-rounds', 'search'));
        break;
      case 'employee/rounds':
      case 'employee/frequency':
      case 'patient/rounds':
      case 'patient/census':
        logTrackingEvent(logReportsClick(view?.replace('/', '-'), 'search'));
        break;
      default:
        console.log('No view for event');
    }
  }, [view, logTrackingEvent, logListClick, logTaskClick, logReportsClick]);

  const setSearchPlaceholder = () => {
    if (view?.includes('csc/list') || view?.includes('cned/list')) {
      return 'Search by employee name or 3/4 ID';
    }
    switch (view) {
      case 'census':
      case 'patient/census':
        return 'Search by name or MRN';
        break;
      case 'discharges':
        return 'Search by patient name';
      case 'list':
      case 'users':
        return 'Search by name or 3/4 ID';
        break;
      case 'question-bank':
        return 'Search by title';
      case 'patient/rounds':
        return 'Search by patient name or MRN';
      case 'employee':
      case 'employee/rounds':
      case 'employee/frequency':
        return 'Search by employee name or 3/4 ID';
        break;
      case 'templates':
        return 'Search for a question';
      case 'feedback':
      case 'cned/feedback':
      case 'issues':
      case 'cned/issues':
      case 'stoplight':
        return 'Search - enter minimum 3 characters';
      default:
        return 'Search by ID';
    }
  };

  useEffect(() => {
    if (dbSearchTerm.length > 0) handleSearchFocus();
  }, [dbSearchTerm]);

  return (
    <SearchContainer id="Search-Field">
      <SearchInput
        id="Search-Input-Field"
        type="search"
        autocomplete="off"
        placeholder={setSearchPlaceholder()}
        inputmode="search"
        enterkeyhint="search"
        autocorrect="off"
        value={searchTerm}
        onNeuInput={handleSearch}
        width={view?.includes('employee') ? 345 : undefined}
      />
      <SearchIcon color="plain-0">search</SearchIcon>
    </SearchContainer>
  );
};

export default SearchField;
