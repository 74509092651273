import { useCallback, FC, Dispatch, SetStateAction, useState } from 'react';

import {
  NeuButton,
  NeuCardActions,
  NeuCardContent,
  NeuCardHeader,
  NeuCardTitle,
  NeuContainer,
  NeuOption,
  NeuTextarea
} from '@neutron/react';

import { BlueChip } from '../../../shared/chips/blueChip';
import { Dropdown } from '../../../shared/dropdowns';

import { toTitleCase } from '../../../../utils/helpers';

import { ChipWrapper } from '../../../modals/feedbackModal/FeedbackModal.styles';
import {
  BlueSpanText,
  GreyCardTitle,
  GreyPastQuestionTitle,
  MultiContainer,
  PastAnswerContent,
  PastAnswerText,
  PastQuestionTitle
} from './Question.styles';

interface IQuestionPartProps {
  data: any;
  quesId: string;
  questionPart: any;
  setData: Dispatch<SetStateAction<{}>>;
  viewOnly: boolean;
}

const QuestionPart: FC<IQuestionPartProps> = ({
  data,
  quesId,
  questionPart,
  viewOnly,
  setData
}) => {
  const [openOptions, setOpenOptions] = useState(false);
  const [openScale, setOpenScale] = useState(false);

  const updateConditions = useCallback(
    (
      arr: any,
      flag: string,
      id: string,
      isMultiSelect: boolean,
      remove?: boolean
    ) => {
      if (
        arr.findIndex(
          (cond: { questionPartId: string; flag: string }) =>
            cond.questionPartId === id
        ) !== -1
      ) {
        if (flag) {
          return arr.map((cond: { questionPartId: string; flag: string[] }) =>
            cond.questionPartId === id
              ? {
                  questionPartId: cond.questionPartId,
                  flag:
                    isMultiSelect && remove
                      ? cond.flag.filter((f: string) => flag !== f)
                      : isMultiSelect
                      ? [...cond.flag, flag]
                      : [flag]
                }
              : cond
          );
        }
        return arr.filter(
          (cond: { questionPartId: string; flag: string }) =>
            cond.questionPartId !== id
        );
      }
      if (flag) {
        return [...arr, { questionPartId: id, flag: [flag] }];
      }
      return [...arr];
    },
    []
  );

  const handleChangeAnswer = useCallback(
    (
      id: string,
      questionPartId: string,
      option: any,
      isMultiSelect: boolean,
      remove?: true
    ) => {
      const newQuestions = data.questions.map((ques: any) => {
        return id === ques.id
          ? {
              ...ques,
              conditions: updateConditions(
                ques.conditions,
                option.flag,
                questionPartId,
                isMultiSelect,
                remove
              ),
              questionParts: [
                ...ques.questionParts.map((part: any) => {
                  return questionPartId === part.questionPartId
                    ? {
                        ...part,
                        selectedAnswer:
                          isMultiSelect && part.selectedAnswer
                            ? remove &&
                              part.selectedAnswer?.findIndex(
                                (val: any) => val.optionId === option.optionId
                              ) !== -1
                              ? part.selectedAnswer?.filter(
                                  (val: any) => val.optionId !== option.optionId
                                )
                              : part.selectedAnswer?.findIndex(
                                  (val: any) => val.optionId === option.optionId
                                ) !== -1
                              ? [...part.selectedAnswer]
                              : [
                                  ...part.selectedAnswer,
                                  {
                                    optionId: option.optionId,
                                    value: option.value,
                                    title: option.title,
                                    charge: option.charge,
                                    flag: option.flag
                                  }
                                ]
                            : [
                                {
                                  optionId: option.optionId,
                                  value: option.value,
                                  title: option.title,
                                  charge: option.charge,
                                  flag: option.flag
                                }
                              ]
                      }
                    : part;
                })
              ]
            }
          : ques;
      });

      setData({
        ...data,
        selectedConditions:
          data.selectedConditions.findIndex(
            (cond: { questionId: string; flags: string[] }) =>
              cond.questionId === id
          ) !== -1
            ? data.selectedConditions.map(
                (c: { questionId: string; flags: string[] }) =>
                  c.questionId === id
                    ? {
                        questionId: id,
                        flags: newQuestions
                          .find((ques: any) => ques.id === id)
                          .conditions.reduce(
                            (
                              acc: string[],
                              cond: { questionPartId: string; flag: string }
                            ) => [...acc, ...cond.flag],
                            []
                          )
                      }
                    : c
              )
            : [
                ...data.selectedConditions,
                {
                  questionId: id,
                  flags: newQuestions
                    .find((ques: any) => ques.id === id)
                    .conditions.reduce(
                      (
                        acc: string[],
                        cond: { questionPartId: string; flag: string }
                      ) => [...acc, ...cond.flag],
                      []
                    )
                }
              ],
        questions: newQuestions
      });
    },
    [data]
  );

  const handleTextChange = useCallback(
    (id: string, questionPartId: string, val: string) => {
      setData({
        ...data,
        questions: data.questions.map((ques: any) => {
          return id === ques.id
            ? {
                ...ques,
                questionParts: [
                  ...ques.questionParts.map((part: any) => {
                    return questionPartId === part.questionPartId
                      ? {
                          ...part,
                          selectedAnswer: [
                            {
                              optionId: null,
                              value: val,
                              title: null,
                              charge: null,
                              flag: null
                            }
                          ]
                        }
                      : part;
                  })
                ]
              }
            : ques;
        })
      });
    },
    [data]
  );

  if (viewOnly) {
    return (
      <div
        className="d-flex flex-column flex-grow-1"
        id={`Round-Question-Part-${questionPart.questionPartId}`}
      >
        <NeuCardHeader className="flex-row flex-grow-1 justify-content-between">
          {questionPart.questionType === 'info' ? (
            <GreyPastQuestionTitle>
              {questionPart.question}
            </GreyPastQuestionTitle>
          ) : (
            <PastQuestionTitle>{questionPart.question}</PastQuestionTitle>
          )}
          {questionPart.questionType === 'single' &&
            questionPart.selectedAnswer &&
            questionPart.selectedAnswer.length > 0 && (
              <PastAnswerContent right color="primary">
                <NeuButton
                  disabled
                  fill="flat"
                  color="primary"
                  value={toTitleCase(
                    questionPart.selectedAnswer[0].title ||
                      questionPart.selectedAnswer[0].value
                  )}
                >
                  <BlueSpanText>
                    {toTitleCase(
                      questionPart.selectedAnswer[0].title ||
                        questionPart.selectedAnswer[0].value
                    )}
                  </BlueSpanText>
                </NeuButton>
              </PastAnswerContent>
            )}
        </NeuCardHeader>
        {questionPart.questionType !== 'single' &&
          questionPart.selectedAnswer && (
            <NeuCardHeader className="px-0 pt-0">
              <NeuCardContent>
                {questionPart.selectedAnswer.length === 1 ? (
                  <PastAnswerText className="px-0" color="primary">
                    {questionPart.selectedAnswer[0].title ||
                      questionPart.selectedAnswer[0].value}
                  </PastAnswerText>
                ) : questionPart.selectedAnswer &&
                  questionPart.selectedAnswer.length > 1 ? (
                  questionPart.selectedAnswer.length > 0 &&
                  questionPart.selectedAnswer.map((ans: any = {}) => {
                    return (
                      <ChipWrapper key={ans.optionId}>
                        <BlueChip
                          icon=" "
                          disabled
                          key={ans.title}
                          name={ans.title}
                          removable={false}
                        />
                      </ChipWrapper>
                    );
                  })
                ) : (
                  ''
                )}
              </NeuCardContent>
            </NeuCardHeader>
          )}
      </div>
    );
  }

  return (
    <div
      id={`Round-Question-Part-${questionPart.questionPartId}`}
      className="pr-1"
    >
      <NeuCardHeader className="flex-row justify-content-between">
        {questionPart.questionType === 'info' ? (
          <GreyCardTitle className="mr-6" style={{ fontSize: '0.90rem' }}>
            {questionPart.question}
          </GreyCardTitle>
        ) : (
          <NeuCardTitle className="mr-6" style={{ fontSize: '1rem' }}>
            {questionPart.question}
          </NeuCardTitle>
        )}
        {questionPart.questionType === 'scale' && (
          <Dropdown
            classes="pr-0"
            open={openScale}
            placeholder={
              questionPart.selectedAnswer &&
              questionPart.selectedAnswer.length === 1
                ? questionPart.selectedAnswer[0].value
                : 'Select a choice'
            }
            placeholderAlign={
              questionPart.selectedAnswer &&
              questionPart.selectedAnswer.length === 1
                ? 'right'
                : 'left'
            }
            styles={{
              minWidth: '235px',
              width: 'auto'
            }}
            width="91%"
            setOpen={setOpenScale}
          >
            {[...Array(10).keys()]
              .map(i => String(i + 1))
              .map((option: string) => (
                <NeuOption
                  id={`Question-Option-${option}`}
                  className="dropdown-hover-item"
                  key={option}
                  value={option}
                  onClick={(e: any) => {
                    handleChangeAnswer(
                      quesId,
                      questionPart.questionPartId,
                      {
                        optionId: `scale-${e.target.value}`,
                        value: e.target.value,
                        title: null,
                        charge: null,
                        flag: null
                      },
                      questionPart.questionType === 'multi'
                    );
                  }}
                >
                  {option}
                </NeuOption>
              ))}
          </Dropdown>
        )}
        {questionPart.options.length ? (
          <NeuCardActions className="d-flex justify-content-end px-0" small>
            {questionPart.questionType === 'single' &&
            questionPart.options.length <= 2 ? (
              questionPart.options.map((option: any) => (
                <NeuButton
                  key={option.optionId}
                  className="mx-2"
                  value={option}
                  fill={
                    questionPart.selectedAnswer &&
                    questionPart.selectedAnswer.length > 0 &&
                    questionPart.selectedAnswer[0].value === option.value
                      ? 'raised'
                      : 'outline'
                  }
                  color="primary"
                  onClick={() =>
                    handleChangeAnswer(
                      quesId,
                      questionPart.questionPartId,
                      option,
                      false
                    )
                  }
                >
                  {option.title || option.value}
                </NeuButton>
              ))
            ) : (
              <MultiContainer className="mx-0 justify-content-end pr-0">
                {questionPart.questionType === 'multi' &&
                  questionPart.selectedAnswer && (
                    <NeuContainer className="flex-row flex-wrap justify-content-end w-100 mb-2 pr-0">
                      {questionPart.selectedAnswer.map(
                        (ansOption: any = {}) => {
                          return (
                            <ChipWrapper
                              key={ansOption.optionId}
                              onClick={() =>
                                handleChangeAnswer(
                                  quesId,
                                  questionPart.questionPartId,
                                  ansOption,
                                  questionPart.questionType === 'multi',
                                  true
                                )
                              }
                            >
                              <BlueChip
                                icon=" "
                                key={ansOption.title}
                                name={ansOption.title}
                              />
                            </ChipWrapper>
                          );
                        }
                      )}
                    </NeuContainer>
                  )}
                <Dropdown
                  classes="pr-0"
                  open={openOptions}
                  placeholder={
                    questionPart.selectedAnswer &&
                    questionPart.selectedAnswer.length === 1
                      ? questionPart.selectedAnswer[0].title ||
                        questionPart.selectedAnswer[0].value
                      : questionPart.selectedAnswer &&
                        questionPart.selectedAnswer.length > 1
                      ? `${questionPart.selectedAnswer.length} selected`
                      : questionPart.questionType === 'multi' &&
                        (!questionPart.selectedAnswer ||
                          (questionPart.selectedAnswer &&
                            questionPart.selectedAnswer.length === 0))
                      ? 'Select choices'
                      : 'Select a choice'
                  }
                  styles={{
                    minWidth: '325px',
                    width: 'auto'
                  }}
                  width="91%"
                  setOpen={setOpenOptions}
                >
                  {questionPart.options.map((option: any) => (
                    <NeuOption
                      id={`Question-Option-${option.title}`}
                      className="dropdown-hover-item"
                      key={option.optionId}
                      value={option}
                      onClick={(e: any) =>
                        handleChangeAnswer(
                          quesId,
                          questionPart.questionPartId,
                          e.target.value,
                          questionPart.questionType === 'multi'
                        )
                      }
                    >
                      {option.title || option.value}
                    </NeuOption>
                  ))}
                </Dropdown>
              </MultiContainer>
            )}
          </NeuCardActions>
        ) : null}
      </NeuCardHeader>
      {questionPart.questionType === 'text' && (
        <NeuCardContent center>
          <NeuTextarea
            id="Question-Part-Question-Type-Text-Area"
            placeholder="Do not include PHI"
            wrap="hard"
            inputmode="search"
            enterkeyhint="search"
            spellCheck
            value={
              questionPart.selectedAnswer &&
              questionPart.selectedAnswer.length > 0
                ? questionPart.selectedAnswer[0].title ||
                  questionPart.selectedAnswer[0].value
                : ''
            }
            onNeuChange={(e: any) =>
              handleTextChange(
                quesId,
                questionPart.questionPartId,
                e.target?.value
              )
            }
          />
        </NeuCardContent>
      )}
    </div>
  );
};

export default QuestionPart;
