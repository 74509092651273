import styled from 'styled-components';
import { NeuTab } from '@neutron/react';

export const ReusableToolbar = styled.div`
  position: static;
  width: 100%;
  // height: 54px;
  left: 0px;
  top: 56px;
  background: #f6f6f6;
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;
  box-shadow: 0px 2px 4px rgba(25, 25, 25, 0.2);
`;
export const Tab = styled(NeuTab)`
  font-weight: bold;
  margin-left: 15px;
`;
