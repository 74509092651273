import { FC } from 'react';

import { NeuButton, NeuIcon } from '@neutron/react';

interface CloseBtnProps {
  id: string;
  text: string;
  handleCloseClick: () => void;
}

const CloseBtn: FC<CloseBtnProps> = ({ id, text, handleCloseClick }) => {
  return (
    <NeuButton
      id={id}
      color="primary"
      size="small"
      fill="raised"
      onClick={handleCloseClick}
    >
      <NeuIcon small>close</NeuIcon>
      {`Close ${text}`}
    </NeuButton>
  );
};

export default CloseBtn;
