import { NeuLabel } from '@neutron/react';
import styled from 'styled-components';

export const InputHeading = styled(NeuLabel)`
  display: inline-block;
  font-size: 0.875rem;
  line-height: 22px;
  width: 413px;
  margin-top: 12px;
`;
