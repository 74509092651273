/**
 * External Imports
 */
import { NeuIcon } from '@neutron/react';
/**
 * Style Imports
 */
import styled from 'styled-components';

export const CustomPaperClipIcon = styled(NeuIcon)`
  font-size: 1.5rem;
  -webkit-transform: rotate(-90deg); /* Safari and Chrome */
  -moz-transform: rotate(-90deg); /* Firefox */
  -ms-transform: rotate(-90deg); /* IE 9 */
  -o-transform: rotate(-90deg); /* Opera */
  transform: rotate(-90deg);
`;

export const IdLabel = styled.span`
  font-weight: 700;
  color: #206da3;
`;

export const NeuIconStatus = styled(NeuIcon)`
  font-size: 12px;
  width: 14px;
  height: 9px;
`;

export const StoplightStatus = styled.span`
  font-weight: 500;
  line-height: 20px;
`;
