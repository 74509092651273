import { FC, useState } from 'react';
import { connect } from 'react-redux';
import { NeuOption } from '@neutron/react';
import { Dropdown } from '../../../shared/dropdowns';
import { RootState } from '../../../../redux/store';
import { InputHeading, TextDiv } from './AdminFilterSection.styles';
import { Filter, IFacility } from '../../../../config/interfaces';

const userRoles = [
  { value: 'patientRounder', displayName: 'Patient Rounder' },
  { value: 'employeeRounder', displayName: 'Employee Rounder' },
  { value: 'validationRounder', displayName: 'Validation Rounder' },
  { value: 'csrnRounder', displayName: 'CSC Rounder' },
  { value: 'cnedRounder', displayName: 'CNEd Rounder' },
  { value: 'templateConfigurator', displayName: 'Configurator' },
  { value: 'userAdmin', displayName: 'User Admin' },
  { value: 'enterpriseAdmin', displayName: 'Enterprise Admin' }
];

const accessLevels = [
  { value: 'facility', displayName: 'Facility' },
  { value: 'division', displayName: 'Division' },
  { value: 'master', displayName: 'Corporate' }
];

const AdminFilterSection: FC<{
  filterValues: Filter;
  facilities: IFacility[];
  divisions: { id: string; value: string }[];
  adminUserTab: string;
  handleDropdownChange: (
    e: React.BaseSyntheticEvent<MouseEvent>,
    name: string
  ) => void;
}> = ({
  filterValues,
  facilities,
  divisions,
  adminUserTab,
  handleDropdownChange
}) => {
  const [openUserRole, setOpenUserRole] = useState(false);
  const [openAccessLevel, setOpenAccessLevel] = useState(false);
  const [openAccessPlace, setOpenAccessPlace] = useState(false);

  return (
    <div id="Admin-Filter-Section">
      <InputHeading>User Role</InputHeading>
      {adminUserTab === 'allUsers' ? (
        <Dropdown
          id="Admin-Filter-User-Role-Select"
          classes="pl-0"
          name="userRole"
          open={openUserRole}
          placeholder={
            (filterValues.userRole &&
              userRoles.find(role => role.value === filterValues.userRole)
                ?.displayName) ||
            'Select a Role'
          }
          width="96%"
          setOpen={setOpenUserRole}
        >
          {userRoles.map(role => (
            <NeuOption
              className={`dropdown-hover-item${
                filterValues.userRole === role.value ? ' selected' : ''
              }`}
              value={role.value}
              key={role.value}
              onClick={e => handleDropdownChange(e, 'userRole')}
            >
              {role.displayName}
            </NeuOption>
          ))}
        </Dropdown>
      ) : adminUserTab === 'patientRounder' ? (
        <TextDiv>Patient Rounder</TextDiv>
      ) : adminUserTab === 'employeeRounder' ? (
        <TextDiv>Employee Rounder</TextDiv>
      ) : adminUserTab === 'validationRounder' ? (
        <TextDiv>Validation Rounder</TextDiv>
      ) : adminUserTab === 'csrnRounder' ? (
        <TextDiv>CSC Rounder</TextDiv>
      ) : adminUserTab === 'cnedRounder' ? (
        <TextDiv>CNEd Rounder</TextDiv>
      ) : adminUserTab === 'templateConfigurator' ? (
        <TextDiv>Configurator</TextDiv>
      ) : adminUserTab === 'userAdmin' ? (
        <TextDiv>User Admin</TextDiv>
      ) : (
        <TextDiv>Enterprise Admin</TextDiv>
      )}

      <InputHeading>Access Level</InputHeading>
      <Dropdown
        id="Admin-Filter-Access-Level-Select"
        classes="pl-0"
        name="accessLevel"
        open={openAccessLevel}
        placeholder={
          (filterValues.accessLevel &&
            accessLevels.find(level => level.value === filterValues.accessLevel)
              ?.displayName) ||
          'Select an Access Level'
        }
        width="96%"
        setOpen={setOpenAccessLevel}
      >
        {accessLevels.map(level => (
          <NeuOption
            className={`dropdown-hover-item${
              filterValues.accessLevel === level.value ? ' selected' : ''
            }`}
            value={level.value}
            // eslint-disable-next-line react/no-array-index-key
            key={level.value}
            onClick={e => handleDropdownChange(e, 'accessLevel')}
          >
            {level.displayName}
          </NeuOption>
        ))}
      </Dropdown>

      {filterValues.accessLevel === 'facility' ? (
        <>
          <InputHeading>Access Places</InputHeading>
          <Dropdown
            id="Admin-Filter-Facility-Access-Place-Select"
            classes="pl-0"
            name="accessPlace"
            open={openAccessPlace}
            placeholder={
              (filterValues.accessPlace &&
                facilities.find(f => f.id === filterValues.accessPlace)
                  ?.value) ||
              'Select a Facility'
            }
            width="96%"
            setOpen={setOpenAccessPlace}
          >
            {facilities &&
              facilities.map(
                (facility: { id: string; value: string; division: string }) => (
                  <NeuOption
                    className={`dropdown-hover-item${
                      filterValues.accessPlace === facility.id
                        ? ' selected'
                        : ''
                    }`}
                    value={facility.id}
                    key={facility.id}
                    onClick={e => handleDropdownChange(e, 'accessPlace')}
                  >
                    {facility.value}
                  </NeuOption>
                )
              )}
          </Dropdown>
        </>
      ) : filterValues.accessLevel === 'division' ? (
        <>
          <InputHeading>Access Places</InputHeading>
          <Dropdown
            id="Admin-Filter-Division-Access-Place-Select"
            classes="pl-0"
            name="accessPlace"
            open={openAccessPlace}
            placeholder={
              (filterValues.accessPlace &&
                divisions.find(d => d.id === filterValues.accessPlace)
                  ?.value) ||
              'Select a Division'
            }
            width="96%"
            setOpen={setOpenAccessPlace}
          >
            {divisions &&
              divisions.map((division: { id: string; value: string }) => (
                <NeuOption
                  className={`dropdown-hover-item${
                    filterValues.accessPlace === division.id ? ' selected' : ''
                  }`}
                  value={division.id}
                  key={division.id}
                  onClick={e => handleDropdownChange(e, 'accessPlace')}
                >
                  {division.value}
                </NeuOption>
              ))}
          </Dropdown>
        </>
      ) : null}
    </div>
  );
};

const mapReduxStateToProps = (state: RootState) => {
  return {
    facilities: state.UserReducer.facilities,
    adminUserTab: state.AdminReducer.adminUserTab,
    divisions: state.UserReducer.divisions
  };
};

export default connect(mapReduxStateToProps)(AdminFilterSection);
