import { FC } from 'react';

import { NeuButton } from '@neutron/react';
import { useDispatch } from 'react-redux';

import { updateUnlistedEmployeeModalFlag } from '../../../redux/actions/User.action';

const SearchEmployeesBtn: FC<{}> = () => {
  const dispatch = useDispatch();

  const roundUnlistedEmployee = () => {
    dispatch(updateUnlistedEmployeeModalFlag(true));
  };

  return (
    <NeuButton
      id="Search-Employees-Button"
      style={{ marginRight: '10px' }}
      color="primary"
      fill="raised"
      onClick={roundUnlistedEmployee}
    >
      Search All Employees
    </NeuButton>
  );
};

export default SearchEmployeesBtn;
