import styled from 'styled-components';
import { NeuIcon } from '@neutron/react';

export const NotesContainer = styled.div`
  margin-top: 40px;
`;
export const NotesHeader = styled.p`
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #124e7f;
`;

export const NotesHeaderContainer = styled.div`
  display: flex;
`;

export const NotesContent = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const IconsContainer = styled.div`
  margin-left: auto;
`;

export const NoteIcon = styled(NeuIcon)`
  margin-left: auto;
  color: #b0b0b0;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  margin-right: 10px;
  :hover {
    cursor: pointer;
    color: grey;
  }
`;
