/**
 * External Imports
 */
import { NeuImage, NeuLabel } from '@neutron/react';
/**
 * Style Imports
 */
import styled from 'styled-components';

export const InputHeading = styled(NeuLabel)`
  display: inline-block;
  font-size: 0.875rem;
  line-height: 22px;
  width: 413px;
  margin-top: 12px;
`;

export const SCLogo = styled(NeuImage)`
  margin-left: -4px;
`;
