// Importing action file
import ACTIONS from '../actions/actionType';

import { ActionType } from '../../config/interfaces';

const initialState = {
  loadingPostNotes: false,
  loadingRemoveNotes: false,
  patientDetails: null
};

const PatientReducer = (state = initialState, action: ActionType = {}) => {
  const tempState = { ...state };

  switch (action.type) {
    case ACTIONS.PATIENT.ADD_NOTE: {
      return {
        ...state,
        loadingPostNotes: true
      };
    }
    case ACTIONS.PATIENT.ADD_NOTE_SUCCESS: {
      return {
        ...state,
        loadingPostNotes: false
      };
    }
    case ACTIONS.PATIENT.REMOVE_NOTE: {
      return {
        ...state,
        loadingRemoveNotes: true
      };
    }
    case ACTIONS.PATIENT.REMOVE_NOTE_SUCCESS: {
      return {
        ...state,
        loadingRemoveNotes: false
      };
    }
    case ACTIONS.PATIENT.PATIENT_DETAILS_SUCCESS: {
      return {
        ...state,
        patientDetails: action.data
      };
    }
    case ACTIONS.PATIENT.CLEAR_PATIENT_DETAILS: {
      return {
        ...state,
        patientDetails: null
      };
    }

    default:
      return tempState;
  }
};

export default PatientReducer;
