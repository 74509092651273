/* eslint-disable no-nested-ternary */
import { FC, useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  NeuTable,
  NeuTableRow,
  NeuTableHeading,
  NeuTableBody,
  NeuLabel,
  NeuPaginator
} from '@neutron/react';
import { useAnalyticsApi } from '@shared-web-analytics/react/dist';

import NoDataSplashView from '../../noDataSplashView/NoDataSplashView';
import Loading from '../../shared/Loading';

import { RootState } from '../../../redux/store';
import { searchEmployees } from '../../../redux/actions/Employee.action';
import {
  clearEmployeeRoundHistory,
  setEmployeeFreqPage,
  setEmployeeFreqSort
} from '../../../redux/actions/Report.action';
import { clearTasksByEmployeeId } from '../../../redux/actions/Task.action';
import {
  setSelectedEmployee,
  getEmployeeNotes
} from '../../../redux/actions/User.action';

import { logRoundProfileFromReports } from '../../../utils/analyticsHelpers';

import { Employee, EmpFreq } from '../../../config/interfaces';

interface EmployeeFrequencyReportTableProps {
  loading: boolean;
  frequencyReportList: EmpFreq[];
  searchedEmployees: Employee[];
  selectedEmployee: Employee;
  totalTableCount: number;
}
const EmployeeFrequencyReportTable: FC<EmployeeFrequencyReportTableProps> = ({
  loading,
  frequencyReportList,
  searchedEmployees,
  selectedEmployee,
  totalTableCount
}) => {
  const dispatch = useDispatch();

  const [sortDir, setSortDir] = useState<'desc' | 'asc'>('asc');
  const [sortKey, setSortKey] = useState<string>('employeeDept');
  const [active, setActive] = useState<string>('Dept');
  const [isTableEmpty, setIsTableEmpty] = useState(false);

  const firstUpdate: { current: boolean } = useRef(true); // to detect first render

  const { logTrackingEvent } = useAnalyticsApi();

  const navigate = useNavigate();

  const sortByHeading = (key: string, column: string) => {
    if (key === sortKey && sortDir === 'asc') {
      setSortDir('desc');
    } else {
      setSortDir('asc');
    }
    setActive(column);
    setSortKey(key);
  };

  const openEmployee = (round: EmpFreq) => {
    logTrackingEvent(logRoundProfileFromReports('employee'));
    dispatch(searchEmployees(round?.employeeId));
  };

  useEffect(() => {
    return () => {
      dispatch(
        setEmployeeFreqSort({ sortKey: 'employeeDept', sortDir: 'asc' })
      );
    };
  }, []);

  useEffect(() => {
    if (frequencyReportList.length === 0) {
      setIsTableEmpty(true);
    } else {
      setIsTableEmpty(false);
    }
  }, [frequencyReportList]);

  useEffect(() => {
    if (firstUpdate.current) {
      return;
    }
    if (searchedEmployees.length > 0) {
      dispatch(setSelectedEmployee(searchedEmployees[0]));
    }
  }, [searchedEmployees]);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (selectedEmployee) {
      dispatch(clearEmployeeRoundHistory());
      dispatch(clearTasksByEmployeeId());
      dispatch(setSelectedEmployee(selectedEmployee));
      dispatch(getEmployeeNotes(selectedEmployee.hcaid));
      navigate('/employees/profile');
    }
  }, [selectedEmployee]);

  useEffect(() => {
    dispatch(setEmployeeFreqSort({ sortKey, sortDir }));
  }, [sortDir, sortKey]);

  return (
    <NeuTable>
      <NeuTableRow
        style={{ backgroundColor: 'white', height: '56px' }}
        columns="{'Dept': '42%', 'Employee': '42%', 'Count': '16%'}"
        header
      >
        <NeuTableHeading
          slot="Dept"
          icon={
            !(active === 'Dept') ? 'asc' : sortDir === 'asc' ? 'desc' : 'asc'
          }
          onClick={() => sortByHeading('employeeDept', 'Dept')}
          active={active === 'Dept'}
        >
          Department
        </NeuTableHeading>
        <NeuTableHeading
          slot="Employee"
          icon={
            !(active === 'Employee')
              ? 'asc'
              : sortDir === 'asc'
              ? 'desc'
              : 'asc'
          }
          onClick={() => sortByHeading('employeeName', 'Employee')}
          active={active === 'Employee'}
        >
          Employee
        </NeuTableHeading>
        <NeuTableHeading
          slot="Count"
          icon={
            !(active === 'Count') ? 'asc' : sortDir === 'asc' ? 'desc' : 'asc'
          }
          onClick={() => sortByHeading('total', 'Count')}
          active={active === 'Count'}
        >
          Round Total
        </NeuTableHeading>
      </NeuTableRow>
      {loading ? (
        <Loading centered={false} />
      ) : !loading && isTableEmpty ? (
        <NoDataSplashView type="frequencyReport" />
      ) : (
        <NeuTableBody>
          {frequencyReportList?.map((round: EmpFreq) => {
            return (
              <NeuTableRow
                columns="{'Dept': '42%', 'Employee': '42%', 'Count': '16%'}"
                size="large"
                key={`${round.employeeId}:${round?.department}`}
              >
                <NeuLabel style={{ whiteSpace: 'normal' }} slot="Dept">
                  {round.department}
                </NeuLabel>
                <NeuLabel
                  slot="Employee"
                  style={{ whiteSpace: 'normal' }}
                  onClick={() => openEmployee(round)}
                >
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        color: '#206DA3',
                        fontWeight: 700
                      }}
                    >
                      {round.employee}
                    </div>
                  </div>
                </NeuLabel>
                <NeuLabel slot="Count" style={{ whiteSpace: 'normal' }}>
                  <div>
                    <div style={{ marginLeft: '3.75rem' }}>{round.count}</div>
                  </div>
                </NeuLabel>
              </NeuTableRow>
            );
          })}
        </NeuTableBody>
      )}
      {!isTableEmpty && (
        <div>
          <NeuPaginator
            total={totalTableCount}
            rowsPerPage={50}
            onNeuChange={e =>
              e.detail.pageNumber &&
              dispatch(setEmployeeFreqPage(e.detail.pageNumber))
            }
          />
        </div>
      )}
    </NeuTable>
  );
};

const mapReduxStateToProps = (state: RootState) => {
  return {
    loading: state.ReportReducer.loading,
    searchedEmployees: state.EmployeeReducer.searchedEmployees,
    selectedEmployee: state.UserReducer.selectedEmployee,
    modalType: state.ModalReducer.modalType,
    totalTableCount: state.ReportReducer.totalTableCount
  };
};

export default connect(mapReduxStateToProps)(EmployeeFrequencyReportTable);
