/**
 * External Imports
 */
import {
  FC,
  useEffect,
  useState,
  useMemo,
  SyntheticEvent,
  MouseEvent
} from 'react';
import { connect, useDispatch } from 'react-redux';
import {
  NeuTableHeading,
  NeuOption,
  NeuCheckbox,
  NeuContainer,
  NeuSpinner
} from '@neutron/react';
import { format } from 'date-fns';
/**
 * Internal Imports
 */
import Table from '../../shared/table';
import { Dropdown } from '../../shared/dropdowns';
import Loading from '../../shared/Loading';

import { RootState } from '../../../redux/store';
import { changeUnitLocTypeAndFlag } from '../../../redux/actions/Config.action';
/**
 * Global Type Definition Imports
 */
import { Unit } from '../../../config/interfaces';
/**
 * Styled Imports
 */
import { StyledLabel } from '../../shared/table/Table.styles';
import {
  ActiveText,
  StyledNeuTableRow,
  StyledLabelLoader
} from './AreasTable.styles';

const unitTypes = [
  { id: 'I', value: 'Inpatient' },
  { id: 'E', value: 'ED' },
  { id: 'O', value: 'Outpatient' }
];

interface IUnitWithOpen extends Unit {
  open: boolean;
}

interface IUnitsTableProps {
  units?: Unit[];
  isLoadingUnits: boolean;
}

const UnitsTable: FC<IUnitsTableProps> = ({ units, isLoadingUnits }) => {
  const dispatch = useDispatch();
  const [sortDir, setSortDir] = useState<'desc' | 'asc'>('asc');
  const [sortKey, setSortKey] = useState<string>('unitId');
  const [active, setActive] = useState<string>('Unit ID');
  const [filteredUnits, setFilteredUnits] = useState<IUnitWithOpen[]>([]);
  const [updatedUnit, setUpdatedUnit] = useState<IUnitWithOpen>();
  const [mounted, setMount] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(isLoadingUnits);

  const sortedUnits = useMemo(() => {
    const stateCopy = [...filteredUnits];
    const sorted = stateCopy.sort((a: IUnitWithOpen, b: IUnitWithOpen) => {
      if (a[sortKey] === b[sortKey]) {
        return 0;
      }
      if (!a[sortKey]) {
        return sortDir === 'asc' ? 1 : -1;
      }
      if (!b[sortKey]) {
        return sortDir === 'asc' ? -1 : 1;
      }
      if (sortDir === 'asc') {
        return a[sortKey]! > b[sortKey]! ? 1 : -1;
      }
      return a[sortKey]! > b[sortKey]! ? -1 : 1;
    });
    return sorted;
  }, [filteredUnits, sortDir, sortKey]);

  const sortByHeading = (key: string, column: string) => {
    if (key === sortKey && sortDir === 'asc') {
      setSortDir('desc');
    } else {
      setSortDir('asc');
    }
    setActive(column);
    setSortKey(key);
  };

  const handleChangeLocType = (
    e: SyntheticEvent<HTMLSelectElement | HTMLNeuOptionElement>,
    unit: IUnitWithOpen
  ) => {
    const { value } = e.currentTarget;
    const { facilityId, unitId, locType } = unit;
    const isActive = unit.active;
    if (locType !== value) {
      const postData = {
        facilityId,
        unitId,
        locType: value,
        active: isActive
      };
      setUpdatedUnit({ ...unit, locType: value });
      dispatch(changeUnitLocTypeAndFlag(postData));
    }
  };

  const handleChangeFlag = (
    e: MouseEvent<HTMLNeuCheckboxElement>,
    unit: IUnitWithOpen
  ) => {
    const { checked } = e.currentTarget;
    const { facilityId, unitId, locType } = unit;
    const isActive = unit.active;
    let flag;
    if (checked) {
      flag = 'Y';
    } else {
      flag = 'N';
    }
    if (flag !== isActive) {
      const postData = {
        facilityId,
        unitId,
        locType,
        active: flag
      };
      setUpdatedUnit({ ...unit, active: flag });
      dispatch(changeUnitLocTypeAndFlag(postData));
    }
  };

  useEffect(() => {
    if (units) {
      // Sorting the list A-Z for initial display
      const sorted = units.sort((a: Unit, b: Unit) =>
        a.unit > b.unit ? 1 : -1
      );
      setFilteredUnits(sorted.map((unit: Unit) => ({ ...unit, open: false })));
    }
  }, [units]);

  useEffect(() => {
    // loading at first render
    if (mounted) {
      setIsLoading(false);
    } else {
      setMount(true);
      setIsLoading(true);
    }
  });

  const columns =
    "{'Unit ID': '12%', 'Unit Name': '26%', 'Last Updated': '16%', 'Updated By': '16%', 'Unit Type': '20%', 'Active': '10%'}";

  const headers: JSX.Element[] = [
    <NeuTableHeading
      id="Units-Table-Unit-Id-Column"
      key="Unit ID"
      slot="Unit ID"
      icon={
        !(active === 'Unit ID') ? 'asc' : sortDir === 'asc' ? 'desc' : 'asc'
      }
      onClick={() => sortByHeading('unitId', 'Unit ID')}
      active={active === 'Unit ID'}
    >
      Unit ID
    </NeuTableHeading>,
    <NeuTableHeading
      id="Units-Table-Unit-Name-Column"
      key="Unit Name"
      slot="Unit Name"
      icon={
        !(active === 'Unit Name') ? 'asc' : sortDir === 'asc' ? 'desc' : 'asc'
      }
      onClick={() => sortByHeading('unit', 'Unit Name')}
      active={active === 'Unit Name'}
    >
      Unit Name
    </NeuTableHeading>,
    <NeuTableHeading
      id="Units-Table-Updated-Column"
      key="Last Updated"
      slot="Last Updated"
      icon={
        !(active === 'Last Updated')
          ? 'asc'
          : sortDir === 'asc'
          ? 'desc'
          : 'asc'
      }
      onClick={() => sortByHeading('lastUpdated', 'Last Updated')}
      active={active === 'Last Updated'}
    >
      Last Updated
    </NeuTableHeading>,
    <NeuTableHeading
      id="Units-Table-Updated-By-Column"
      key="Updated By"
      slot="Updated By"
      icon={
        !(active === 'Updated By') ? 'asc' : sortDir === 'asc' ? 'desc' : 'asc'
      }
      onClick={() => sortByHeading('userFullName', 'Updated By')}
      active={active === 'Updated By'}
    >
      Updated By
    </NeuTableHeading>,
    <NeuTableHeading
      id="Units-Table-Type-Column"
      key="Unit Type"
      slot="Unit Type"
      icon={
        !(active === 'Unit Type') ? 'asc' : sortDir === 'asc' ? 'desc' : 'asc'
      }
      onClick={() => sortByHeading('locType', 'Unit Type')}
      active={active === 'Unit Type'}
    >
      Unit Type
    </NeuTableHeading>,
    <NeuTableHeading
      id="Units-Table-Active-Column"
      key="Active"
      slot="Active"
      icon={!(active === 'Active') ? 'asc' : sortDir}
      onClick={() => sortByHeading('active', 'Active')}
      active={active === 'Active'}
    >
      Active
    </NeuTableHeading>
  ];

  const rows = sortedUnits.map((unit: IUnitWithOpen) => (
    <StyledNeuTableRow
      id={`Unit-Row-${unit.unitId}`}
      key={unit.unitId}
      columns={columns}
      size="normal"
      style={{ position: 'relative', minHeight: '3.75rem' }}
    >
      <NeuContainer className="h-100 px-0" slot="Unit ID">
        <StyledLabel>{unit.unitId}</StyledLabel>
      </NeuContainer>
      <NeuContainer className="h-100 px-0" slot="Unit Name">
        <StyledLabel>{unit.unit}</StyledLabel>
      </NeuContainer>
      <NeuContainer className="h-100 px-0" slot="Last Updated">
        <StyledLabel>
          {unit.lastUpdated
            ? format(new Date(Date.parse(unit.lastUpdated)), 'MM/dd/yyyy')
            : ''}
        </StyledLabel>
      </NeuContainer>
      <NeuContainer className="h-100 px-0" slot="Updated By">
        <StyledLabel>
          <p>{unit.userFullName || ''}</p>
          <p>{unit.lastUpdatedBy || 'Unknown'}</p>
        </StyledLabel>
      </NeuContainer>
      <NeuContainer
        className="h-100 px-0"
        slot="Unit Type"
        style={{ position: 'absolute', top: 0, width: '18%' }}
      >
        {isLoadingUnits &&
        updatedUnit?.unitId === unit.unitId &&
        updatedUnit?.locType !== unit.locType ? (
          <StyledLabelLoader>
            <NeuSpinner ariaLabel="default" width="18" height="18" />
          </StyledLabelLoader>
        ) : (
          <StyledLabel>
            <Dropdown
              classes="pl-0"
              placeholder={
                unitTypes.find(type => type.id === unit.locType)?.value || ''
              }
              open={unit.open}
              styles={{ minWidth: '170px' }}
              width="94%"
              setOpen={(bool: boolean) => {
                return setFilteredUnits(
                  filteredUnits.map((u: IUnitWithOpen) => {
                    return u.unitId === unit.unitId
                      ? { ...u, open: bool }
                      : { ...u, open: false };
                  })
                );
              }}
            >
              {unitTypes &&
                unitTypes.map((item: { id: string; value: string }) => {
                  return (
                    <NeuOption
                      className={`dropdown-hover-item${
                        unit.locType === item.id ? ' selected' : ''
                      }`}
                      key={item.id}
                      value={item.id}
                      onClick={e => handleChangeLocType(e, unit)}
                    >
                      {item.value}
                    </NeuOption>
                  );
                })}
            </Dropdown>
          </StyledLabel>
        )}
      </NeuContainer>
      <NeuContainer className="h-100 px-0" slot="Active">
        {isLoadingUnits &&
        updatedUnit?.unitId === unit.unitId &&
        updatedUnit?.active !== unit.active ? (
          <StyledLabel>
            <NeuSpinner ariaLabel="default" width="18" height="18" />
          </StyledLabel>
        ) : (
          <StyledLabel>
            <NeuCheckbox
              color="primary"
              checked={unit.active === 'Y'}
              onClick={e => handleChangeFlag(e, unit)}
            />
            <ActiveText>Active</ActiveText>
          </StyledLabel>
        )}
      </NeuContainer>
    </StyledNeuTableRow>
  ));
  return isLoading ? (
    <Loading centered={false} />
  ) : (
    <Table
      cols={columns}
      headers={headers}
      rows={rows}
      loading={isLoadingUnits}
      customClass="admin-areas-unit-table"
    />
  );
};

const mapReduxStateToProps = (state: RootState) => {
  return {
    units: state.ConfigReducer.allUnitsByFacility,
    isLoadingUnits: state.ConfigReducer.isLoadingAllUnits
  };
};

export default connect(mapReduxStateToProps)(UnitsTable);
