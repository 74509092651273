import styled from 'styled-components';

import { NeuLabel, NeuInput } from '@neutron/react';

export const InputHeading = styled(NeuLabel)`
  display: inline-block;
  font-size: 0.875rem;
  line-height: 22px;
  width: 413px;
  margin-top: 12px;
`;

export const StatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 97%;
  margin-top: 12px;
`;

export const CategorySelect = styled.select`
  appearance: none;
  width: 97%;
  height: 42px;
  padding: 8px 12px;
  background: #ffffff;
  border: 1px solid #939393;
  box-sizing: border-box;
  border-radius: 5px;
`;

export const CheckboxDiv = styled.div`
  margin-right: 2rem;
`;

export const CheckboxLabel = styled(NeuLabel)`
  margin-left: 0.25rem;
`;

export const StyledCheckbox = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  &:checked ~ span {
    background-color: #2075ad;
  }
  &:checked ~ span:after {
    display: block;
  }
`;

export const ModalInput = styled(NeuInput)`
  width: 97%;
  height: 42px;
`;
