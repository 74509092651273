import {
  FC,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
  useMemo
} from 'react';
import { useParams } from 'react-router-dom';
import { NeuInput, NeuOption } from '@neutron/react';
import { format, parse, sub, startOfQuarter } from 'date-fns';

import { useAnalyticsApi } from '@shared-web-analytics/react/dist';

import MonthSelectsSection from './monthSelectsSection';
import { Dropdown } from '../../shared/dropdowns';

import { logReportsClick } from '../../../utils/analyticsHelpers';
import { date as dt, unCamelCase } from '../../../utils/helpers';

import { DatesStartEnd } from '../../../config/interfaces';

import {
  DateHeading,
  DateRangeSection,
  Range,
  SelectContainer,
  StartEnd
} from './CustomDateRangeSelect.styles';
import { InputHeading } from '../../modals/reusableModalPieces/dateSelectsSection/DateSelectsSection.styles';

interface ICustomDateRangeSelectProps {
  customDates: DatesStartEnd;
  setCustomDates: Dispatch<SetStateAction<DatesStartEnd>>;
}

const dateRanges = [
  { value: 'custom', displayName: 'Custom' },
  { value: 'monthToDate', displayName: 'Month to date' },
  { value: 'lastFullMonth', displayName: 'Last full month' },
  { value: 'lastFullQuarter', displayName: 'Last full quarter' }
];

const CustomDateRangeSelect: FC<ICustomDateRangeSelectProps> = ({
  customDates,
  setCustomDates
}) => {
  const [reportDateRangeType, setReportDateRangeType] =
    useState<string>('Month To Date');
  const [showCustomDate, setShowCustomDate] = useState<boolean>(false);
  const [open, setOpen] = useState(false);

  const { logTrackingEvent } = useAnalyticsApi();
  const { '*': view } = useParams();

  const handleMonthSelects = (e: any) => {
    const { value } = e.detail;
    const parsedMonth = value.split('-');
    const year = Number(parsedMonth[0]);
    const month = Number(parsedMonth[1]) - 1;
    setCustomDates({
      startDate: format(new Date(year, month, 1), 'yyyy-MM-dd'),
      endDate: format(sub(new Date(year, month + 1), { days: 1 }), 'yyyy-MM-dd')
    });
  };

  const handleStartSelect = (e: any) => {
    const { value } = e.detail;
    setCustomDates({
      ...customDates,
      startDate: value || customDates.startDate
    });
  };

  const handleEndSelect = (e: any) => {
    const { value } = e.detail;
    setCustomDates({
      ...customDates,
      endDate: value || customDates.endDate
    });
  };

  const handleDateRangeSelect = (ev: any) => {
    const selectedRange = ev.target.value;
    setReportDateRangeType(unCamelCase(selectedRange));

    const today = new Date();

    if (selectedRange === 'monthToDate') {
      setShowCustomDate(false);

      const firstDayOfMonth = format(
        new Date(today.getFullYear(), today.getMonth(), 1),
        'yyyy-MM-dd'
      );

      const dayYesterday = format(today, 'yyyy-MM-dd');

      setCustomDates({
        startDate: firstDayOfMonth,
        endDate: dayYesterday
      });
    }

    if (selectedRange === 'lastFullMonth') {
      setShowCustomDate(false);

      const lastMonthFirstDay = format(
        new Date(today.getFullYear(), today.getMonth() - 1, 1),
        'yyyy-MM-dd'
      );

      const lastMonthLastDay = format(
        sub(new Date(today.getFullYear(), today.getMonth(), 1), { days: 1 }),
        'yyyy-MM-dd'
      );

      setCustomDates({
        startDate: lastMonthFirstDay,
        endDate: lastMonthLastDay
      });
    }

    if (selectedRange === 'lastFullQuarter') {
      setShowCustomDate(false);

      const lastQuarterLastDay = sub(startOfQuarter(today), { days: 1 });
      const lastQuarterLastDayFormatted = format(
        lastQuarterLastDay,
        'yyyy-MM-dd'
      );
      const lastQuarterFirstDay = format(
        startOfQuarter(
          new Date(
            lastQuarterLastDay.getFullYear(),
            lastQuarterLastDay.getMonth(),
            lastQuarterLastDay.getDate() - 1
          )
        ),
        'yyyy-MM-dd'
      );

      setCustomDates({
        startDate: lastQuarterFirstDay,
        endDate: lastQuarterLastDayFormatted
      });
    }

    if (selectedRange === 'custom') {
      setShowCustomDate(true);
    }
  };

  const customMonth = useMemo(
    () =>
      format(parse(customDates.startDate, 'yyyy-MM-dd', new Date()), 'yyyy-MM'),
    [customDates.startDate]
  );

  const minStartDate = useMemo(
    () => dt.convertToDayOfYear(sub(new Date(), { years: 2 })),
    []
  );
  const maxStartDate = useMemo(
    () =>
      customDates.endDate &&
      dt.convertToDayOfYear(dt.localDayOfYearDate(customDates.endDate)),
    [customDates.endDate]
  );
  const minEndDate = useMemo(
    () =>
      customDates.startDate &&
      dt.convertToDayOfYear(dt.localDayOfYearDate(customDates.startDate)),
    [customDates.startDate]
  );
  const maxEndDate = useMemo(() => dt.convertToDayOfYear(new Date()), []);

  useEffect(() => {}, [customDates]);
  useEffect(() => {
    if (open) {
      switch (view) {
        case 'employee':
        case 'employee/rounds':
          logTrackingEvent(logReportsClick('employee-rounds', 'date-range'));
          break;
        case 'employee/frequency':
          logTrackingEvent(logReportsClick('employee-frequency', 'date-range'));
          break;
        default:
          console.log('No view for event');
      }
    }
  }, [open]);

  return (
    <DateRangeSection>
      <Range>
        <InputHeading className="pt-2">Date Range</InputHeading>
        <Dropdown
          classes="pl-0 pt-0"
          open={open}
          placeholder={reportDateRangeType}
          styles={{ minWidth: '200px' }}
          width="93%"
          setOpen={setOpen}
        >
          {dateRanges.map(range => (
            <NeuOption
              className={`dropdown-hover-item${
                unCamelCase(range.value) === reportDateRangeType
                  ? ' selected'
                  : ''
              }`}
              key={range.value}
              value={range.value}
              onClick={(e: any) => handleDateRangeSelect(e)}
            >
              {range.displayName}
            </NeuOption>
          ))}
        </Dropdown>
      </Range>
      {showCustomDate && (
        <StartEnd className="mx-2">
          {view === 'employee/frequency' ? (
            <>
              <SelectContainer className="mr-1">
                <DateHeading>Starting</DateHeading>
                <NeuInput
                  id="Reports-Freq-Start-Date"
                  name="startDate"
                  type="date"
                  onNeuChange={handleStartSelect}
                  value={customDates.startDate}
                  min={minStartDate}
                  max={maxStartDate}
                />
              </SelectContainer>
              <SelectContainer className="ml-1">
                <DateHeading>Ending</DateHeading>
                <NeuInput
                  id="Reports-Freq-End-Date"
                  name="endDate"
                  type="date"
                  onNeuChange={handleEndSelect}
                  value={customDates.endDate}
                  min={minEndDate}
                  max={maxEndDate}
                />
              </SelectContainer>
            </>
          ) : (
            <MonthSelectsSection
              handleSelects={handleMonthSelects}
              month={customMonth}
            />
          )}
        </StartEnd>
      )}
    </DateRangeSection>
  );
};

export default CustomDateRangeSelect;
