import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { NeuButton } from '@neutron/react';

import { clearUser, setSessionId } from '../../redux/actions/Auth.action';
import { Authenticate } from '../../services/AUTH/utils';

import {
  BackgroundImage,
  ErrorContainer,
  H1,
  H4,
  StyledDiv,
  Text
} from './ErrorPages.styles';
import ErrorPageImg from '../../assets/images/ErrorPageImg.png';

const ErrorPage403 = () => {
  const dispatch = useDispatch();
  // appInsights.trackEvent({
  //   name: 'Login_Failure'
  // });
  const navigate = useNavigate();

  const gotoLogin = () => {
    window.localStorage.removeItem('authToken');
    window.localStorage.removeItem('orbitLastPath');
    window.localStorage.removeItem('34');
    sessionStorage.removeItem('sessionId');
    dispatch(setSessionId(null));
    dispatch(clearUser());
    if (process.env.REACT_APP_ENV === 'production') {
      Authenticate();
    } else {
      navigate('/login');
    }
  };

  return (
    <StyledDiv>
      <BackgroundImage src={ErrorPageImg} alt="error page" />
      <ErrorContainer>
        <H1>403</H1>
        <H4>Access Denied</H4>
        <Text>Please contact your manager for access.</Text>
        <NeuButton
          color="primary"
          fill="raised"
          size="large"
          onClick={gotoLogin}
        >
          Back to Login
        </NeuButton>
      </ErrorContainer>
    </StyledDiv>
  );
};

export default ErrorPage403;
