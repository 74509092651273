// Importing action file
import ACTIONS from '../actions/actionType';

import { ActionType } from '../../config/interfaces';

const initialState = {
  modal: false,
  modalType: '',
  modalAction: '',
  modalData: {}
};

const ModalReducer = (state = initialState, action: ActionType = {}) => {
  const tempState = { ...state };

  switch (action.type) {
    case ACTIONS.MODAL.OPEN_MODAL: {
      return {
        ...state,
        modal: true
      };
    }
    case ACTIONS.MODAL.CLOSE_MODAL: {
      return {
        ...state,
        modal: false,
        modalType: '',
        modalAction: '',
        modalData: {}
      };
    }
    case ACTIONS.MODAL.SET_MODAL_TYPE: {
      return {
        ...state,
        modalType: action.data
      };
    }
    case ACTIONS.MODAL.SET_MODAL_ACTION: {
      return {
        ...state,
        modalAction: action.data
      };
    }
    case ACTIONS.MODAL.SET_MODAL_DATA: {
      return {
        ...state,
        modalData: action.data
      };
    }

    default:
      return tempState;
  }
};

export default ModalReducer;
