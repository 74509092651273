import { FC, useCallback, useEffect, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAnalyticsApi } from '@shared-web-analytics/react/dist';

import FeedbackModal from '../../components/modals/feedbackModal';
import IssueModal from '../../components/modals/addIssueModal';
import { ModalContainer } from './ModalPortal.Styles';
import IssueModalView from '../../components/modals/addIssueModal/IssueModalView';
import AddProvisioningModal from '../../components/modals/addProvisioningModal';
import AddQuestionModal from '../../components/modals/addQuestionModal/AddQuestionModal';
import EmployeeRoundingSettingsModal from '../../components/modals/employeeRoundingSettingsModal';
import FilterModal from '../../components/modals/filterModal/FilterModal';
import StoplightModal from '../../components/modals/stoplightModal';
import UnlistedEmployeeRoundModal from '../../components/modals/unlistedEmployeeRoundModal';
import UnlistedPatientRoundModal from '../../components/modals/unlistedPatientRoundModal';
import UserModal from '../../components/modals/userModal';

import { RootState } from '../../redux/store';
import { closeModal } from '../../redux/actions/Modal.action';
import { resetEditQuestion } from '../../redux/actions/Template.action';
import { logTaskClick } from '../../utils/analyticsHelpers';

import { Task } from '../../config/interfaces';

const ModalPortal: FC<{
  modalState: boolean;
  modalType: string;
  singleTaskToComplete: Array<Task>;
}> = ({ modalState, modalType, singleTaskToComplete }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const modalContainerRef = useRef<HTMLDivElement>(null);
  const localStorageDeepLinkPath = window.localStorage.getItem('task');
  const isCompleteTask =
    localStorageDeepLinkPath?.includes('task/complete') ||
    pathname.includes('task/complete');

  const { logTrackingEvent } = useAnalyticsApi();

  const closeFromOutside = useCallback(
    (e: Event) => {
      const target = e.target as HTMLElement;
      if (
        modalContainerRef.current &&
        modalContainerRef.current.nextElementSibling!.classList.contains(
          'hidden'
        ) &&
        target.classList.contains('modal-content')
      ) {
        modalContainerRef.current.children[0].classList.add('hide-modal');
        setTimeout(() => {
          dispatch(resetEditQuestion());
          switch (modalType) {
            case 'Add Issue':
              // case 'Add Patient Issue On Rounds':
              // case 'Add Employee Issue On Rounds':
              // case 'Add Support Issue On Rounds':
              logTrackingEvent(logTaskClick('issues', 'close'));
              break;
            case 'Feedback':
              // case 'Add Patient Feedback On Rounds':
              // case 'Add Employee Feedback On Rounds':
              // case 'Add Support Feedback On Rounds':
              logTrackingEvent(logTaskClick('feedback', 'close'));
              break;
            case 'Add Stoplight':
              logTrackingEvent(logTaskClick('stoplight', 'close'));
              break;
            default:
              console.log('No type was selected');
          }
          dispatch(closeModal());
        }, 300);
      }
      if (isCompleteTask) {
        navigate(
          singleTaskToComplete[0].roundingType === 'employee'
            ? '/employees/issues'
            : '/patients/issues'
        );
        // Clear temporarily stored task data
        window.localStorage.removeItem('task');
      }
    },
    [logTaskClick, logTrackingEvent, modalType]
  );

  useEffect(() => {
    if (modalState) {
      document.addEventListener('mousedown', closeFromOutside);
    }
    return () => document.removeEventListener('mousedown', closeFromOutside);
  }, [modalState]);

  let modalContent;

  switch (modalType) {
    case 'Add Issue':
    case 'Add Patient Issue On Rounds':
    case 'Add Employee Issue On Rounds':
    case 'Add Support Issue On Rounds':
      modalContent = <IssueModal />;
      break;
    case 'Edit Issue':
      modalContent = <IssueModal edit />;
      break;
    case 'Add Patient Feedback On Rounds':
    case 'Add Employee Feedback On Rounds':
    case 'Add Support Feedback On Rounds':
    case 'Feedback':
      modalContent = <FeedbackModal />;
      break;
    case 'Provisioning':
      modalContent = <AddProvisioningModal />;
      break;
    case 'Filter Issues':
      modalContent = <FilterModal filterType="issues" />;
      break;
    case 'Filter Feedback':
      modalContent = <FilterModal filterType="feedback" />;
      break;
    case 'Filter Stoplights':
      modalContent = <FilterModal filterType="stoplights" />;
      break;
    case 'Filter Admin Users':
      modalContent = <FilterModal filterType="adminUsers" />;
      break;
    case 'Filter Rounds Report':
      modalContent = <FilterModal filterType="roundsReport" />;
      break;
    case 'Add Stoplight':
    case 'Edit Stoplight':
      modalContent = <StoplightModal />;
      break;
    case 'UnlistedPatientRound':
      modalContent = <UnlistedPatientRoundModal />;
      break;
    case 'UnlistedEmployeeRound':
      modalContent = <UnlistedEmployeeRoundModal />;
      break;
    case 'Add Delegates':
    case 'Add Employees':
      modalContent = <EmployeeRoundingSettingsModal />;
      break;
    case 'Edit User':
      modalContent = <UserModal />;
      break;
    case 'View Issue':
      modalContent = <IssueModalView />;
      break;
    case 'AddQuestion':
      modalContent = <AddQuestionModal />;
      break;
    default:
      console.log('No type was selected');
  }

  return modalState ? (
    <ModalContainer
      id="Modal-Container"
      className="modal-content"
      ref={modalContainerRef}
    >
      {modalContent}
    </ModalContainer>
  ) : null;
};

const mapReduxStateToProps = (state: RootState) => ({
  modalState: state.ModalReducer.modal,
  modalType: state.ModalReducer.modalType,
  singleTaskToComplete: state.TaskReducer.singleTaskToComplete
});

export default connect(mapReduxStateToProps)(ModalPortal);
