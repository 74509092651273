/**
 * External Imports
 */
import {
  NeuCard,
  NeuCardHeader,
  NeuInput,
  NeuLabel,
  NeuTextarea,
  NeuButton,
  NeuItem,
  NeuCardActions,
  NeuCardContent,
  NeuIcon,
  NeuRadioGroup,
  NeuContainer
} from '@neutron/react';
/**
 * Style Imports
 */
import styled, { css } from 'styled-components';

interface CSSProps {
  autoWidth?: boolean | undefined;
  centered?: boolean | undefined;
}

interface DropdownProps {
  showPopover: boolean;
}

export const StoplightCard = styled(NeuCard)`
  display: flex;
  flex-direction: column;
  background: #f6f6f6 !important;
  z-index: 10;
  padding-inline-start: 0;
  padding-inline-end: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin-inline-end: 0;
  margin-top: 0;
`;

export const CardHeader = styled(NeuCardHeader)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  width: 100%;
  --background: white;
`;

export const ModalCardContent = styled(NeuCardContent)`
  flex: 1;
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-behavior: smooth;
  width: 432px;
`;

export const RadioContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 0.9375rem;
  margin-bottom: 0.9375rem;
  max-width: 97%;
`;

export const InputHeading = styled(NeuLabel)<CSSProps>`
  display: inline-block;
  font-size: 0.875rem;
  line-height: 22px;
  width: 413px;
  margin-top: 12px;
  ${({ autoWidth }) =>
    autoWidth &&
    css`
      width: auto;
    `}
`;

export const RadioLabel = styled(NeuLabel)`
  margin-right: 30px;
  margin-left: 3px;
`;

export const ModalInput = styled(NeuInput)`
  width: 97%;
  min-height: 42px;
`;

export const ModalTextArea = styled(NeuTextarea)`
  width: 97%;
  height: 88px;
  margin-bottom: 1.75rem;
`;

export const DropDownSearchContainer = styled.div<DropdownProps>`
  box-shadow: 0px 2px 4px rgba(25, 25, 25, 0.2);
  border-radius: 5px;
  width: 97%;
  min-height: 27px;
  max-height: 160px;
  z-index: 10;
  position: absolute;
  top: 42px;
  background: white !important;
  opacity: 1;
  overflow-y: scroll;
  display: ${props => (props.showPopover ? 'block' : 'none')};
`;

export const DropItem = styled(NeuItem)`
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 26px;
  padding: 1px;
  opacity: 1;
  border: 0.5px solid #b0b0b0;
  overflow: hidden;
`;

export const ModalFooter = styled(NeuCardActions)`
  display: flex;
  align-items: center;
  justify-content: end;
  background: white !important;
  bottom: 0;
  height: 69px !important;
`;
export const InProgressIcon = styled(NeuIcon)`
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  color: #a37318;
  margin-right: 5px;
`;
export const CompletedIcon = styled(NeuIcon)`
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  color: #548a2c;
  margin-right: 5px;
`;
export const BlockedIcon = styled(NeuIcon)`
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  color: #df4445;
  margin-right: 5px;
`;
export const TitleSpan = styled.span`
  display: flex;
  font-size: 16px;
  line-height: 26px;
  color: #06173d;
  width: 280px;
`;
export const TitleIcon = styled(NeuIcon)`
  margin-right: 8px;
  color: #5d99c8;
`;
export const ChipWrapper = styled.span``;

export const CategoryRadioGroup = styled(NeuRadioGroup)``;
export const RadioLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const RadioLabelWithIcon = styled.span`
  display: flex;
  align-items: center;
`;

export const ItemContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const BlueText = styled.div`
  color: #206da3;
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
`;

export const SearchIcons = styled(NeuIcon)`
  display: flex;
  align-items: center;
  text-align: center;
  margin-right: 8px;
`;

export const StyledEditHeaderRow = styled(NeuContainer)<CSSProps>`
  display: flex;
  flex-direction: row;
  align-items: ${({ centered }) => (centered ? 'center' : 'start')};
`;

export const StyledEditHeaderCol = styled(NeuContainer)`
  display: flex;
  flex-direction: column;
`;

export const StyledBoldHeading = styled(InputHeading)<CSSProps>`
  font-weight: bold;
  ${({ autoWidth }) =>
    autoWidth &&
    css`
      width: auto;
    `}
`;

export const StyledRightAlignButton = styled(NeuButton)`
  align-self: end;
`;

export const StyledCircleDoneIcon = styled(NeuIcon)`
  background-color: #548a2c;
  border-radius: 50%;
`;

export const ViewIssuesClickableLabel = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 22px;
  color: #2075ad;
  cursor: pointer;
`;
