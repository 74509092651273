import { FC } from 'react';

import {
  CardContainer,
  CardHeader,
  CardHeaderContainer,
  CardContent,
  CardContentContainer,
  ChevronRightIcon
} from './PastRoundCard.styles';

interface PastRoundCardProps {
  dateTime: string;
  roundId: string;
  name: string;
  userId: string;
  facility: string;
  room: string;
  template?: string;
  unitId: string;
}
const PastRoundCard: FC<PastRoundCardProps> = ({
  dateTime,
  roundId,
  name,
  userId,
  facility,
  room,
  template,
  unitId
}) => {
  return (
    <CardContainer id={`Round-Card-${roundId}`}>
      <CardHeaderContainer>
        <CardHeader>{dateTime}</CardHeader>
      </CardHeaderContainer>

      <CardContentContainer>
        <div>
          <CardContent>{`${name} ${userId}`}</CardContent>
          <CardContent>{facility}</CardContent>
          <CardContent>{`${room || ''} ${unitId || ''}`}</CardContent>
          {template && <CardContent>{template}</CardContent>}
        </div>

        <ChevronRightIcon>chevron_right</ChevronRightIcon>
      </CardContentContainer>
    </CardContainer>
  );
};

// export default AddIssueFromPatientRound;
export default PastRoundCard;
