import styled from 'styled-components';

export const SearchHeaderBar = styled.div`
  height: 70px;
  background: #f6f6f6;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  // box-shadow: inset 0px 8px 6px -6px rgba(25, 25, 25, 0.2);
  border-bottom: 1px solid #b0b0b0;
`;

export const HintSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 5px;
  width: 100%;
`;
