import { all, call, delay, put, select } from 'redux-saga/effects';

import {
  getData,
  postData,
  putData,
  deleteData
} from '../../services/API/axios';
import ACTIONS from '../actions/actionType';
import API from '../../services/API';
import { toast } from '../../services/Toast';
import { useStoplightsApi } from '../../utils/api';
import { date, text } from '../../utils/helpers';
import { setCsvToExport } from '../actions/Admin.action';
import { getStoplightById } from '../actions/Stoplight.action';
import { getEmployeeSharedWith, saveTaskData } from '../actions/Task.action';
import store from '../store';

// Stoplight sagas
export function* getStoplightsByLocation(action) {
  const params = new URLSearchParams(action.data).toString();
  const url = `${API.getStoplightsByLocationUrl}?${params}`;

  if (action.data.locationIds.length === 0)
    return yield put({
      type: ACTIONS.STOPLIGHT.GET_STOPLIGHTS_BY_LOCATION_SUCCESS,
      data: {
        results: [],
        totalCount: 0
      }
    });
  try {
    const { data, status } = yield call(getData, url);
    if (status === 200) {
      return yield put({
        type: ACTIONS.STOPLIGHT.GET_STOPLIGHTS_BY_LOCATION_SUCCESS,
        data
      });
    }
    return yield put({
      type: ACTIONS.STOPLIGHT.GET_STOPLIGHTS_BY_LOCATION_FAILED
    });
  } catch (error) {
    console.log(error);
    return yield put({
      type: ACTIONS.STOPLIGHT.GET_STOPLIGHTS_BY_LOCATION_FAILED
    });
  }
}

export function* getAllStoplightsByLocation(action) {
  let totalData = [];
  yield put({
    type: ACTIONS.ADMIN.GET_CSV_TO_EXPORT
  });

  try {
    const { data, status } = yield call(
      getData,
      `${API.getStoplightsByLocationUrl}?${new URLSearchParams({
        ...action.data,
        page: 1
      }).toString()}`
    );
    if (status === 200) {
      if (data.totalCount > 50) {
        const totalPages = Math.ceil(data.totalCount / 50);
        const restData = yield all(
          [...Array(totalPages + 1).keys()].slice(2).map(page =>
            call(
              getData,
              `${API.getStoplightsByLocationUrl}?${new URLSearchParams({
                ...action.data,
                page
              }).toString()}`
            )
          )
        );
        if (restData && restData.length > 0) {
          totalData = [
            ...data.results,
            ...restData.reduce(
              (acc, response) => acc.concat(response.data.results),
              []
            )
          ];
        }
      } else {
        totalData = [...data.results];
      }
    } else {
      yield put({
        type: ACTIONS.ADMIN.GET_CSV_TO_EXPORT_FAIL
      });
    }
  } catch (err) {
    yield put({
      type: ACTIONS.ADMIN.GET_CSV_TO_EXPORT_FAIL
    });
  }
  yield put(
    setCsvToExport(
      totalData.map(item => {
        const tempItem = item;
        delete tempItem?.id;
        delete tempItem?.completedDate;
        delete tempItem?.discussion;
        delete tempItem?.discussionId;
        delete tempItem?.issues;
        delete tempItem?.partitionKey;
        delete tempItem?.sharedWith;
        return {
          ...tempItem,
          createdDate: date.dayOfYrFull(item.created),
          title: text.escCSVChars(tempItem.title),
          details: text.escCSVChars(tempItem.details),
          attachedIssues: tempItem?.issueIds,
          facility: tempItem.locations?.facility[0]?.displayName,
          latestComment: tempItem.latestComment
            ? `${
                tempItem.latestComment.date &&
                date.dayOfYr(tempItem.latestComment.date)
              } | ${tempItem.latestComment.authorFullName} ${text.escCSVChars(
                tempItem.latestComment.body
              )}`
            : '',
          location:
            tempItem.origin === 'employee'
              ? tempItem.locations?.department[0]?.displayName
              : tempItem.origin === 'patient'
              ? tempItem.locations?.unit[0]?.displayName
              : ''
        };
      })
    )
  );
}

export function* shareStoplight(share) {
  const url = `${API.baseStoplightUrl}/share`;
  try {
    const { status } = yield call(postData, url, share);
    if (status === 200) {
      return yield put({
        type: ACTIONS.STOPLIGHT.SHARE_STOPLIGHT_SUCCESS
      });
    }
    return toast('Share failed to Post!', 'error', null, 500, true);
  } catch (error) {
    console.log(error);
    yield put({
      type: ACTIONS.STOPLIGHT.SHARE_STOPLIGHT_FAILED,
      data: error
    });
    return toast('Share failed to Post!', 'error', null, 500, true);
  }
}

export function* postNewStoplight(action) {
  const { authorizedUser } = yield select(state => state.AuthorizedUser);
  const { selectedUnits } = yield select(state => state.ConfigReducer);
  const {
    currentStoplightPage,
    sortDir,
    sortKey,
    stoplightFilter,
    stoplightSearchQuery
  } = yield select(state => state.StoplightReducer);
  const { userSection: location } = yield select(state => state.UserReducer);

  const url = `${API.baseStoplightUrl}`;

  try {
    const { data, status } = yield call(postData, url, action.data);
    if (status === 200) {
      toast('Stoplight Posted', 'success', 1000, 500);
      yield put({
        type: ACTIONS.STOPLIGHT.POST_NEW_STOPLIGHT_SUCCESS,
        data
      });
      // add stoplightId to store task from here
      const tempTask = yield select(state => state.TaskReducer.tempTaskObject);
      if (Object.keys(tempTask).length > 0) {
        store.dispatch(saveTaskData({ ...tempTask, stoplightId: data.id }));
      }

      let sharingQ = yield select(state => state.StoplightReducer.stopSharingQ);
      sharingQ = { ...sharingQ, id: data.id };
      // Only call the share saga in there are users in the Q to share task with
      if (sharingQ.email.length > 0) {
        yield call(shareStoplight, sharingQ);
        yield delay(1000);
        toast('Stoplight has been shared!', 'success', 1000, 500);
      }
      const stoplightAction = useStoplightsApi({
        authorizedUser,
        currentStoplightPage,
        searchTerm: stoplightSearchQuery,
        location,
        selectedUnits,
        sortKey,
        sortDir,
        stoplightFilter
      });
      return yield put(stoplightAction);
    }
    return toast('Stoplight Failed to Post!', 'error', null, 500, true);
  } catch (error) {
    console.log(error);
    return toast('Stoplight Failed to Post!', 'error', null, 500, true);
  }
}

export function* getStoplightsById(action) {
  const url = `${API.baseStoplightUrl}/${action.data}`;

  try {
    const { data, status } = yield call(getData, url);
    if (status === 200) {
      /**
       * Get employee details in order to show on chips if the sharedWith array is not empty.
       */
      if (data.sharedWith && data.sharedWith.length > 0) {
        data.sharedWith.forEach(employeeId => {
          store.dispatch(getEmployeeSharedWith(employeeId));
        });
      }
      yield put({
        type: ACTIONS.STOPLIGHT.GET_STOPLIGHT_BY_ID_SUCCESS,
        data
      });
    }
  } catch (error) {
    console.log(error);
  }
}

export function* postCommentToStoplight(action) {
  const { id, comment } = action.data;
  const { authorizedUser } = yield select(state => state.AuthorizedUser);
  const { selectedUnits } = yield select(state => state.ConfigReducer);
  const {
    currentStoplightPage,
    sortDir,
    sortKey,
    stoplightFilter,
    stoplightSearchQuery
  } = yield select(state => state.StoplightReducer);
  const { userSection: location } = yield select(state => state.UserReducer);

  const url = `${API.discussionUrl}/${id}/comment`;

  try {
    const { data, status } = yield call(postData, url, comment);

    if (status === 200) {
      toast('Comment Posted', 'success', 1000, 500);
      yield put({
        type: ACTIONS.STOPLIGHT.POST_COMMENT_TO_STOPLIGHT_SUCCESS,
        data
      });
      store.dispatch(getStoplightById(id));
      const stoplightAction = useStoplightsApi({
        authorizedUser,
        currentStoplightPage,
        searchTerm: stoplightSearchQuery,
        location,
        selectedUnits,
        sortKey,
        sortDir,
        stoplightFilter
      });
      return yield put(stoplightAction);
    }
    return toast('Comment Failed to Post!', 'error', null, 500, true);
  } catch (error) {
    console.log(error);
    return toast('Comment Failed to Post!', 'error', null, 500, true);
  }
}

export function* updateStoplight(action) {
  const { authorizedUser } = yield select(state => state.AuthorizedUser);
  const { hcaid, firstName, lastName } = authorizedUser;
  const { selectedUnits } = yield select(state => state.ConfigReducer);
  const {
    currentStoplightPage,
    sortDir,
    sortKey,
    stoplightFilter,
    stoplightSearchQuery
  } = yield select(state => state.StoplightReducer);
  const { userSection: location } = yield select(state => state.UserReducer);

  const url = `${API.baseStoplightUrl}`;

  try {
    const { data: currentStoplight } = yield call(
      getData,
      `${url}/${action.data.id}`
    );
    const { data, status } = yield call(putData, url, action.data);
    if (status === 200) {
      const removedIssues = currentStoplight?.issues?.filter(
        val => !action.data.issueIds.find(issueId => val.taskId === issueId)
      );
      if (removedIssues && removedIssues.length > 0) {
        // eslint-disable-next-line no-restricted-syntax
        for (const issue of removedIssues) {
          const commentPayload = {
            body: `Issue ${
              issue.friendlyId || '------'
            } was removed from Stoplight ${action.data.friendlyId || '------'}`,
            authorId: hcaid,
            authorFullName: `${firstName} ${lastName}`
          };
          // eslint-disable-next-line no-await-in-loop
          yield call(
            postData,
            `${API.discussionUrl}/${action.data.id}/comment`,
            commentPayload
          );
        }
        console.log(`${removedIssues.length} issues removed!`);
      }
      // appInsights.trackEvent({
      //   name: 'Stoplight_Edited'
      // });
      yield put({
        type: ACTIONS.STOPLIGHT.UPDATE_STOPLIGHT_SUCCESS,
        data
      });
      toast('Stoplight Updated', 'success', 1000, 500);
      let sharingQ = yield select(state => state.StoplightReducer.stopSharingQ);
      sharingQ = { ...sharingQ, id: data.id };
      // Only call the share saga in there are users in the Q to share task with
      if (sharingQ.email.length > 0) {
        yield call(shareStoplight, sharingQ);
        yield delay(1000);
        toast('Stoplight has been shared!', 'success', 1000, 500);
      }
      const stoplightAction = useStoplightsApi({
        authorizedUser,
        currentStoplightPage,
        searchTerm: stoplightSearchQuery,
        location,
        selectedUnits,
        sortKey,
        sortDir,
        stoplightFilter
      });
      return yield put(stoplightAction);
    }
    if (status === 400) {
      return toast(
        `Failed to Update Stoplight! Status: ${status}!`,
        'error',
        null,
        500,
        true
      );
    }
    return undefined;
  } catch (error) {
    console.log(error);
    return toast('Stoplight Failed to Update!', 'error', null, 500, true);
  }
}

export function* deleteStoplight(action) {
  const url = `${API.baseStoplightUrl}/${action.data}`;

  try {
    const { data, status } = yield call(deleteData, url);
    if (status === 200) {
      toast('Stoplight Deleted!', 'success', 1000, 500);
      yield put({
        type: ACTIONS.STOPLIGHT.DELETE_STOPLIGHT_SUCCESS,
        data
      });
      const { authorizedUser } = yield select(state => state.AuthorizedUser);
      const { selectedUnits } = yield select(state => state.ConfigReducer);
      const {
        currentStoplightPage,
        sortDir,
        sortKey,
        stoplightFilter,
        stoplightSearchQuery
      } = yield select(state => state.StoplightReducer);
      const { userSection: location } = yield select(
        state => state.UserReducer
      );
      const stoplightAction = useStoplightsApi({
        authorizedUser,
        currentStoplightPage,
        searchTerm: stoplightSearchQuery,
        location,
        selectedUnits,
        sortKey,
        sortDir,
        stoplightFilter
      });
      return yield put(stoplightAction);
    }
    return toast('Stoplight Failed to Delete!', 'error', null, 500, true);
  } catch (error) {
    console.log(error);
    return toast('Stoplight Failed to Delete!', 'error', null, 500, true);
  }
}

export function* searchStoplight(action) {
  const { query, page, origin, facilityId } = action.data;

  const url = `${API.searchStoplightsUrl}?facilityId=${facilityId}&query=${query}&origin=${origin}&page=${page}`;

  try {
    const { data, status } = yield call(getData, url);
    if (status === 200) {
      yield put({
        type: ACTIONS.STOPLIGHT.SEARCH_STOPLIGHTS_SUCCESS,
        data
      });
    }
  } catch (error) {
    console.log(error);
  }
}
