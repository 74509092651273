import { FC } from 'react';
import { connect, useDispatch } from 'react-redux';
import { NeuButton } from '@neutron/react';

import { RootState } from '../../../redux/store';
import { openModal, setModalType } from '../../../redux/actions/Modal.action';

interface IProvisioningBtnProps {
  modalState: boolean;
}

const ProvisioningBtn: FC<IProvisioningBtnProps> = ({ modalState }) => {
  const dispatch = useDispatch();

  const toggleModal = (mType: string) => {
    if (modalState === false) {
      dispatch(setModalType(mType));
      dispatch(openModal());
    }
  };

  return (
    <NeuButton
      id="Provision-Button"
      className="mr-5"
      color="primary"
      fill="raised"
      onClick={() => toggleModal('Provisioning')}
    >
      Provisioning
    </NeuButton>
  );
};

const mapReduxStateToProps = (state: RootState) => {
  return {
    modalState: state.ModalReducer.modal
  };
};

export default connect(mapReduxStateToProps)(ProvisioningBtn);
