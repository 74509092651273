import { FC } from 'react';
import { NeuIcon, NeuOption } from '@neutron/react';

import { Dropdown, IDropdownProps } from '../../shared/dropdowns';
import { formatRoundingType } from '../../../utils/helpers';

interface IRoundingTypeDropdownProps extends IDropdownProps {
  options: any[];
  selectedRoundingType: string;
  setSelectedRoundingType: (type: string) => void;
}

const RoundingTypeDropdown: FC<IRoundingTypeDropdownProps> = ({
  options = [],
  selectedRoundingType,
  setSelectedRoundingType,
  ...rest
}) => {
  return (
    <Dropdown classes="px-0" {...rest}>
      {[...options].map((type: string) => {
        const selected = selectedRoundingType === type;
        return (
          <NeuOption
            className={`dropdown-hover-item ${selected ? 'selected' : ''}`}
            key={type}
            value={type}
            onClick={() => setSelectedRoundingType(type)}
          >
            <div>
              {formatRoundingType(type)}
              {selected && (
                <NeuIcon className="custom-checkmark" color="primary">
                  check
                </NeuIcon>
              )}
            </div>
          </NeuOption>
        );
      })}
    </Dropdown>
  );
};

export default RoundingTypeDropdown;
