import { FC, Dispatch, SetStateAction } from 'react';

import IssuesTable from '../../components/customTables/issuesTable';
import SearchHeader from '../../components/searchHeader';

import { TaskContainer } from './styles/Employee.styles';

interface IEmployeeIssuesProps {
  searchTerm: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;
}

const EmployeeIssues: FC<IEmployeeIssuesProps> = ({
  searchTerm,
  setSearchTerm
}) => {
  return (
    <TaskContainer id="Employee-Issues">
      <SearchHeader searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      <IssuesTable searchTerm={searchTerm} />
    </TaskContainer>
  );
};

export default EmployeeIssues;
