// Importing action file
import ACTIONS from '../actions/actionType';

import { ActionType } from '../../config/interfaces';

const initialState = {
  dischargeParams: {},
  patientsToRound: [],
  patientsForCensus: [],
  roundsList: [],
  userRoles: [],
  facilities: [],
  divisions: [],
  provisionResponse: null,
  loadingUserRoles: false,
  loadingFacilities: false,
  loadingDivisions: false,
  loadingPostUserAccess: false,
  usersList: [],
  isLoading: false,
  unlistedPatientRoundParams: {},
  userSection: '',
  selectedPatient: null,
  selectedEmployee: null,
  isLoadingGetPatientsToRound: false,
  isLoadingPatientsForCensus: false,
  showUnlistedPatientModalFlag: false,
  showUnlistedEmployeeModalFlag: false
};

const UserReducer = (state = initialState, action: ActionType = {}) => {
  const tempState = { ...state };

  switch (action.type) {
    case ACTIONS.USER.GET_PATIENTS_TO_ROUND_SUCCESS: {
      return {
        ...state,
        patientsToRound: action.data,
        isLoadingGetPatientsToRound: false
      };
    }
    case ACTIONS.USER.GET_ROUNDS_SUCCESS: {
      return {
        ...state,
        roundsList: action.data
      };
    }
    case ACTIONS.USER.GET_USER_ROLES: {
      return {
        ...state,
        loadingUserRoles: true
      };
    }
    case ACTIONS.USER.GET_USER_ROLES_SUCCESS: {
      return {
        ...state,
        userRoles: action.data,
        loadingUserRoles: false
      };
    }
    case ACTIONS.USER.GET_FACILITIES: {
      return {
        ...state,
        loadingFacilities: true
      };
    }
    case ACTIONS.USER.GET_FACILITIES_SUCCESS: {
      return {
        ...state,
        facilities: action.data,
        loadingFacilities: false
      };
    }
    case ACTIONS.USER.GET_FACILITIES_FAILURE: {
      return {
        ...state,
        facilities: [],
        loadingFacilities: false
      };
    }
    case ACTIONS.USER.GET_DIVISIONS: {
      return {
        ...state,
        loadingDivisions: true
      };
    }
    case ACTIONS.USER.GET_DIVISIONS_SUCCESS: {
      return {
        ...state,
        divisions: action.data,
        loadingDivisions: false
      };
    }
    case ACTIONS.USER.POST_USER_ACCESS: {
      return {
        ...state,
        provisionResponse: action.data,
        loadingPostUserAccess: true
      };
    }
    case ACTIONS.USER.POST_USER_ACCESS_SUCCESS: {
      return {
        ...state,
        provisionResponse: action.data,
        loadingPostUserAccess: false
      };
    }
    case ACTIONS.USER.GET_DISCHARGE_PATIENT_PARAMS: {
      return {
        ...state,
        dischargeParams: action.data
      };
    }
    case ACTIONS.USER.GET_DISCHARGE_PATIENT: {
      return {
        ...state,
        dischargeParams: action.data,
        isLoading: true
      };
    }
    case ACTIONS.USER.GET_DISCHARGE_PATIENT_SUCCESS: {
      return {
        ...state,
        dischargeStatus: action.data,
        isLoading: false,
        patientsToRound: []
      };
    }
    case ACTIONS.USER.GET_DISCHARGE_PATIENT_PARAMS_SUCCESS: {
      return {
        ...state,
        dischargeParamStatus: action.data
      };
    }
    case ACTIONS.USER.SET_SELECTED_PATIENT: {
      return {
        ...state,
        selectedPatient: action.data
      };
    }
    case ACTIONS.USER.CLEAR_SELECTED_PATIENT: {
      return {
        ...state,
        selectedPatient: null
      };
    }
    case ACTIONS.USER.UPDATE_UNLISTED_PATIENT_ROUND_PARAMS: {
      return {
        ...state,
        unlistedPatientRoundParams: action.data
      };
    }
    case ACTIONS.USER.SET_USER_ACTION_TYPE: {
      return {
        ...state,
        userSection: action.data
      };
    }
    case ACTIONS.USER.RESET_USER_ACTION_TYPE: {
      return {
        ...state,
        userSection: null
      };
    }
    case ACTIONS.USER.SET_SELECTED_EMPLOYEE: {
      return {
        ...state,
        selectedEmployee: action.data
      };
    }
    case ACTIONS.USER.GET_PATIENTS_TO_ROUND: {
      return {
        ...state,
        isLoadingGetPatientsToRound: true
      };
    }
    case ACTIONS.USER.CLEAR_PATIENTS_TO_ROUND: {
      return {
        ...state,
        patientsToRound: []
      };
    }
    case ACTIONS.USER.POST_UPDATE_USER_PIC: {
      return {
        ...state,
        isLoading: true
      };
    }
    case ACTIONS.USER.POST_UPDATE_USER_PIC_SUCCESS: {
      return {
        ...state,
        isLoading: false
      };
    }
    case ACTIONS.USER.POST_UPDATE_USER_PIC_FAILURE: {
      return {
        ...state,
        isLoading: false
      };
    }
    case ACTIONS.USER.UPDATE_UNLISTED_PATIENT_MODAL_FLAG: {
      return {
        ...state,
        showUnlistedPatientModalFlag: action.data
      };
    }
    case ACTIONS.USER.UPDATE_UNLISTED_EMPLOYEE_MODAL_FLAG: {
      return {
        ...state,
        showUnlistedEmployeeModalFlag: action.data
      };
    }
    case ACTIONS.USER.GET_PATIENTS_TO_ROUND_FOR_CENSUS: {
      return {
        ...state,
        isLoadingPatientsForCensus: true
      };
    }
    case ACTIONS.USER.GET_PATIENTS_TO_ROUND_FOR_CENSUS_SUCCESS: {
      return {
        ...state,
        patientsForCensus: action.data,
        isLoadingPatientsForCensus: false
      };
    }
    case ACTIONS.USER.CLEAR_SELECTED_EMPLOYEE: {
      return {
        ...state,
        selectedEmployee: null
      };
    }
    default:
      return tempState;
  }
};

export default UserReducer;
