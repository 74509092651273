import { FC } from 'react';

import { connect, useDispatch } from 'react-redux';
import { NeuButton, NeuIcon } from '@neutron/react';

import { RootState } from '../../../../redux/store';
import {
  openModal,
  setModalType,
  setModalData
} from '../../../../redux/actions/Modal.action';
import { Patient, Employee } from '../../../../config/interfaces';

interface AddIssueFromRoundProps {
  modalData: Patient | Employee;
  type: string;
}
const AddIssueFromRound: FC<AddIssueFromRoundProps> = ({ modalData, type }) => {
  const dispatch = useDispatch();
  const addNewIssue = () => {
    if (type === 'patient') {
      dispatch(setModalType('Add Patient Issue On Rounds'));
      dispatch(setModalData(modalData));
      dispatch(openModal());
    } else if (type === 'employee') {
      dispatch(setModalType('Add Employee Issue On Rounds'));
      dispatch(setModalData(modalData));
      dispatch(openModal());
    } else {
      dispatch(setModalType('Add Support Issue On Rounds'));
      dispatch(setModalData(modalData));
      dispatch(openModal());
    }
  };

  return (
    <NeuButton
      id="Add-Issue-Button-Round"
      className="mx-2"
      color="primary"
      fill="outline"
      onClick={addNewIssue}
    >
      <NeuIcon className="mr-2" left>
        flag
      </NeuIcon>
      Add issue
    </NeuButton>
  );
};

const mapReduxStateToProps = (state: RootState) => {
  return {
    authorizedUser: state.AuthorizedUser.authorizedUser,
    modalState: state.ModalReducer.modal
  };
};

export default connect(mapReduxStateToProps)(AddIssueFromRound);
