import { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';

import { NeuSnackbar } from '@neutron/react';

import { BasicToast, ConfirmToast } from '../../components/toasts';

import store, { RootState } from '../../redux/store';
import {
  hide,
  setToastOptions,
  slideIn,
  slideOut
} from '../../redux/actions/Toast.action';

import { IToastOptionsData } from '../../config/interfaces';

import './Toast.scss';

interface IToastCompProps {
  hidden: boolean;
  options: IToastOptionsData;
  slide: string;
  type: string;
  hideToast: () => Dispatch<SetStateAction<void>>;
  slideToastOut: () => Dispatch<SetStateAction<void>>;
}

const mapReduxStateToProps = (state: RootState) => ({
  hidden: state.ToastReducer.hidden,
  options: state.ToastReducer.options,
  slide: state.ToastReducer.slide,
  type: state.ToastReducer.type
});

// this had to stay any because of the toast below
const mapDispatchToProps = (dispatch: any) => ({
  hideToast: () => dispatch(hide()),
  slideToastOut: () => dispatch(slideOut())
});

const Toast = connect(
  mapReduxStateToProps,
  mapDispatchToProps
)(
  ({
    hidden,
    options: {
      msg,
      feedback,
      duration,
      speed,
      question,
      warning,
      handleConfirm
    },
    slide,
    type,
    hideToast,
    slideToastOut
  }: IToastCompProps) => {
    const styles = useMemo(
      () => ({
        show: {
          bottom: '100px',
          minHeight:
            question && !(feedback === 'error' || feedback === 'success')
              ? '220px'
              : '100px',
          height:
            feedback === 'error' || feedback === 'success' ? '110px' : 'auto',
          animation: `${
            !question ? 'toast-in-alert-bottom' : 'toast-in-question-bottom'
          } ${speed}ms`
        },
        'hide-toast': {
          bottom: question ? '-220px' : '-100px',
          minHeight:
            question && !(feedback === 'error' || feedback === 'success')
              ? '220px'
              : '100px',
          height:
            feedback === 'error' || feedback === 'success' ? '110px' : 'auto',
          animation: `${
            !question ? 'toast-out-alert-bottom' : 'toast-out-question-bottom'
          } ${speed}ms`
        }
      }),
      [feedback, speed, question]
    );

    const handleClose = () => {
      slideToastOut();
      setTimeout(() => hideToast(), speed + 25);
    };

    const confirm = () => {
      handleClose();
      if (handleConfirm) setTimeout(() => handleConfirm(), speed + 25);
    };

    const toastContent = useMemo(() => {
      switch (type) {
        case 'Complete':
        case 'Delete Delegated To Me':
        case 'Delete Issue':
        case 'Delete Stoplight':
        case 'Delete Note':
          return (
            <ConfirmToast
              msg={msg}
              warning={warning}
              confirmText={
                type === 'Delete Stoplight' || type === 'Delete Issue'
                  ? 'Yes'
                  : type === 'Delete Delegated To Me' || type === 'Delete Note'
                  ? 'Delete'
                  : 'Complete'
              }
              closeText={
                type === 'Delete Stoplight' || type === 'Delete Note'
                  ? 'No'
                  : 'Cancel'
              }
              confirm={confirm}
              handleClose={handleClose}
            />
          );
          break;
        default:
          return (
            <BasicToast
              msg={msg}
              feedback={feedback}
              tryAgain={question}
              confirm={confirm}
            />
          );
      }
    }, [type, msg, feedback, warning, confirm]);

    useEffect(() => {
      let timeout: NodeJS.Timeout;
      if (!question && duration) {
        timeout = setTimeout(() => handleClose(), duration);
      }
      return () => clearTimeout(timeout);
    }, [duration, slide]);

    return (
      <div className={`custom-toast-container ${hidden ? 'hidden' : ''}`}>
        <NeuSnackbar
          className={`flex-column ${
            !question ? 'justify-content-center' : ''
          }} align-items-center p-4 toast toast-shadow-primary`}
          feedback={feedback === 'info' ? null : feedback}
          style={slide === 'show' ? styles.show : styles['hide-toast']}
          width="200px"
        >
          {toastContent}
        </NeuSnackbar>
      </div>
    );
  }
);

const toast = (
  msg: string,
  feedback: 'info' | 'success' | 'error' | 'warning',
  duration: number | null,
  speed: number,
  question: boolean = false,
  warning?: string | null,
  handleConfirm?: () => void
) => {
  store.dispatch(
    setToastOptions({
      msg,
      feedback,
      duration,
      speed,
      question,
      warning,
      handleConfirm
    })
  );
  store.dispatch(slideIn());
};

export { toast, Toast };
