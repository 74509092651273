import styled from 'styled-components';

export const CompHeaderContainer = styled.div`
  position: static;
  height: 90px;
  left: 0px;
  top: 56px;
  background: #f6f6f6;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
`;

export const DateRangeSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const HintSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 5px;
  margin-top: 18px;
`;

export const Range = styled.div`
  width: 183px;
  margin-right: 5px;
`;

export const StartEnd = styled.div`
  display: flex;
  width: 400px;
`;
