/**
 * External Imports
 */
import { FC, useEffect, useState, Dispatch, SetStateAction } from 'react';
import { connect, useDispatch } from 'react-redux';

import {
  NeuTable,
  NeuTableRow,
  NeuTableHeading,
  NeuTableBody,
  NeuLabel,
  NeuPaginator
} from '@neutron/react';
/**
 * Internal Imports
 */
import NoDataSplashView from '../../noDataSplashView/NoDataSplashView';
import Loading from '../../shared/Loading';

import { RootState } from '../../../redux/store';
import {
  openModal,
  setModalType,
  setModalData
} from '../../../redux/actions/Modal.action';

import { toTitleCase, formatRoundingType } from '../../../utils/helpers';
/**
 * Global Type Definition Imports
 */
import { Facility, UserList } from '../../../config/interfaces';
/**
 * Style Imports
 */
import { PaginationContainer } from './UsersTable.styles';

interface UsersTableProps {
  facilities?: Facility[];
  isLoading?: boolean;
  totalUsers: number;
  usersList: UserList[];
  setCurrentPage: Dispatch<SetStateAction<number>>;
}
const UsersTable: FC<UsersTableProps> = ({
  facilities,
  isLoading,
  totalUsers,
  usersList,
  setCurrentPage
}) => {
  const dispatch = useDispatch();
  type NumberBooleanMap = {
    [key: number]: boolean;
  };
  const [filteredList, setFilteredList] = useState<UserList[]>([]);
  const [listAllAccessPlaces, setListAllAccessPlaces] =
    useState<NumberBooleanMap>({});
  const [active, setActive] = useState<string>('fullName');
  const [sortDir, setSortDir] = useState<'desc' | 'asc'>('asc');

  useEffect(() => {
    if (usersList) {
      // Sorting the list A-Z for initial display
      const sorted = usersList?.sort((a: UserList, b: UserList) =>
        a.fullName > b.fullName ? 1 : -1
      );

      setFilteredList(sorted);
    } else {
      setFilteredList([]);
    }
  }, [usersList]);

  const pageNumberChanged = (pageNum: number) => {
    if (pageNum) setCurrentPage(pageNum);
  };

  const sortByHeading = (key: string) => {
    const stateCopy = usersList?.length
      ? // eslint-disable-next-line no-unsafe-optional-chaining
        [...usersList]
      : [];

    // let sorted = [];
    if (sortDir === 'desc') {
      // already sorted descending
      setSortDir('asc');
      stateCopy.sort((a: UserList, b: UserList) => (a[key] > b[key] ? 1 : -1));
      setActive(key);
    }
    if (sortDir === 'asc') {
      // already sorted ascending
      setSortDir('desc');
      stateCopy.sort((a: UserList, b: UserList) => (a[key] > b[key] ? -1 : 1));
      setActive(key);
    }

    setFilteredList(stateCopy);
  };

  const getAccessPlacesById = (id: string) => {
    const obj = facilities?.find(
      (facility: Facility) => facility.facilityId === id
    );
    return obj ? obj.facility : '';
  };

  const showAllAccessPlaces = (i: number) => {
    const obj = {
      [i]: true
    };
    setListAllAccessPlaces(obj);
  };

  const openUserForEdit = (user: UserList) => {
    dispatch(setModalData(user));
    dispatch(setModalType('Edit User'));
    dispatch(openModal());
  };

  return (
    <NeuTable id="User-Table">
      <NeuTableRow
        style={{ backgroundColor: 'white', height: '56px' }}
        columns="{'Employee': '20%', '3/4 ID': '10%', 'User Role': '20%', 'Access Level': '10%', 'Access Places': '20%', 'Position': '20%'}"
        header
      >
        <NeuTableHeading
          id="User-Table-Employee-Column"
          slot="Employee"
          icon={
            !(active === 'fullName')
              ? 'asc'
              : sortDir === 'asc'
              ? 'desc'
              : 'asc'
          }
          active={active === 'fullName'}
          onClick={() => sortByHeading('fullName')}
        >
          Employee
        </NeuTableHeading>
        <NeuTableHeading
          id="User-Table-34-Column"
          slot="3/4 ID"
          icon={
            !(active === 'hcaid') ? 'asc' : sortDir === 'asc' ? 'desc' : 'asc'
          }
          active={active === 'hcaid'}
          onClick={() => sortByHeading('hcaid')}
        >
          3/4 ID
        </NeuTableHeading>
        <NeuTableHeading
          id="User-Table-Role-Column"
          slot="User Role"
          icon={
            !(active === 'roles') ? 'asc' : sortDir === 'asc' ? 'desc' : 'asc'
          }
          active={active === 'roles'}
          onClick={() => sortByHeading('roles')}
        >
          User Role
        </NeuTableHeading>
        <NeuTableHeading
          id="User-Table-Access-Level-Column"
          slot="Access Level"
          icon="none"
        >
          Access Level
        </NeuTableHeading>
        <NeuTableHeading
          id="User-Table-Access-Places-Column"
          slot="Access Places"
          icon="none"
        >
          Access Places
        </NeuTableHeading>
        <NeuTableHeading
          id="User-Table-Position-Column"
          slot="Position"
          icon={
            !(active === 'title') ? 'asc' : sortDir === 'asc' ? 'desc' : 'asc'
          }
          active={active === 'title'}
          onClick={() => sortByHeading('title')}
        >
          Position
        </NeuTableHeading>
      </NeuTableRow>
      {isLoading ? (
        <Loading centered={false} />
      ) : !isLoading && (!filteredList || filteredList.length === 0) ? (
        <NoDataSplashView type="users" />
      ) : (
        <NeuTableBody>
          {filteredList?.map((user: UserList, i: number) => {
            return (
              <NeuTableRow
                columns="{'Employee': '20%', '3/4 ID': '10%', 'User Role': '20%', 'Access Level': '10%', 'Access Places': '20%', 'Position': '20%'}"
                size="large"
                key={user.hcaid}
                onClick={() => openUserForEdit(user)}
              >
                <NeuLabel slot="Employee">
                  {toTitleCase(`${user.firstName} ${user.lastName}`)}
                </NeuLabel>
                <NeuLabel slot="3/4 ID">{user.hcaid.toUpperCase()}</NeuLabel>
                <NeuLabel slot="User Role" style={{ whiteSpace: 'normal' }}>
                  {user.roles.map(
                    (role: string, index: number) =>
                      `${formatRoundingType(role)}${
                        index + 1 < user.roles.length ? ', ' : ''
                      }`
                  )}
                </NeuLabel>
                <NeuLabel slot="Access Level">
                  {toTitleCase(user.access.accessLevel) === 'Master'
                    ? 'Corporate'
                    : toTitleCase(user.access.accessLevel)}
                </NeuLabel>
                <NeuLabel slot="Access Places" style={{ whiteSpace: 'normal' }}>
                  {user.access.accessLevel === 'facility'
                    ? user.access.accessLevelIds.map(
                        (id: string, j: number) => {
                          const facility = getAccessPlacesById(id);
                          if (j <= 2)
                            return `${facility}${
                              j === user.access.accessLevelIds.length - 1
                                ? ' '
                                : ', '
                            }`;
                          if (j >= 2 && listAllAccessPlaces[i]) {
                            return `${facility}${
                              j === user.access.accessLevelIds.length - 1
                                ? ' '
                                : ', '
                            }`;
                          }
                          return false; // for consistent-return
                        }
                      )
                    : // if access place is master or division, just display the accessLevelIds
                      user.access.accessLevelIds.map(
                        (id: string, j: number) => {
                          const facility = id;
                          if (j <= 2)
                            return `${facility}${
                              j === user.access.accessLevelIds.length - 1
                                ? ' '
                                : ', '
                            }`;
                          if (j >= 2 && listAllAccessPlaces[i]) {
                            return `${facility}${
                              j === user.access.accessLevelIds.length - 1
                                ? ' '
                                : ', '
                            }`;
                          }
                          return false; // for consistent-return
                        }
                      )}

                  {user.access.accessLevelIds.length > 2 &&
                  !listAllAccessPlaces[i] ? (
                    <span
                      className="show-more-text"
                      role="link"
                      tabIndex={0}
                      onClick={() => showAllAccessPlaces(i)}
                      onKeyDown={() => showAllAccessPlaces(i)}
                    >
                      Show more
                    </span>
                  ) : null}
                </NeuLabel>
                <NeuLabel slot="Position" style={{ whiteSpace: 'normal' }}>
                  {user.title ? user.title : ''}
                  {user.title && user.dept ? ', ' : ''}
                  {user.dept ? user.dept : ''}
                </NeuLabel>
              </NeuTableRow>
            );
          })}
        </NeuTableBody>
      )}
      {filteredList && filteredList.length > 0 && (
        <PaginationContainer>
          <NeuPaginator
            total={totalUsers || 1} // this value is only an assumption. The actual count must come from backend
            rowsPerPage={50}
            onNeuChange={e => pageNumberChanged(e.detail.pageNumber)}
          />
        </PaginationContainer>
      )}
    </NeuTable>
  );
};

const mapReduxStateToProps = (state: RootState) => {
  return {
    facilities: state.ConfigReducer.facilities,
    adminUserFilter: state.AdminReducer.adminUserFilter,
    usersList: state.AdminReducer.usersList,
    totalUsers: state.AdminReducer.totalUsers,
    isLoading: state.AdminReducer.isLoading
  };
};

export default connect(mapReduxStateToProps)(UsersTable);
