import { FC } from 'react';
import { NeuButton, NeuContainer, NeuLabel } from '@neutron/react';

const BasicToast: FC<{
  msg: string;
  feedback: 'info' | 'success' | 'error' | 'warning';
  tryAgain?: boolean;
  confirm?: () => void;
}> = ({ msg, feedback, tryAgain, confirm }) => (
  <>
    <NeuLabel
      className={`w-100 mb-${feedback !== 'info' ? '0' : '2'} ${
        feedback === 'info' ? 'toast-small-text' : ''
      }`}
      color={
        feedback === 'info'
          ? 'primary'
          : feedback === 'success'
          ? 'green-60'
          : feedback === 'warning'
          ? 'yellow-60'
          : 'red-60'
      }
      slot="title"
    >
      {msg}
    </NeuLabel>
    {tryAgain && confirm && (
      <NeuContainer className="flex-row justify-content-center" slot="action">
        <NeuButton
          color={undefined}
          size="small"
          fill="raised"
          onClick={() => confirm()}
        >
          Try Again
        </NeuButton>
      </NeuContainer>
    )}
  </>
);

export default BasicToast;
