/**
 * External Imports
 */
import {
  NeuCard,
  NeuCardHeader,
  NeuCardContent,
  NeuCardActions,
  NeuCardTitle,
  NeuIcon
} from '@neutron/react';
/**
 * Style Imports
 */
import styled from 'styled-components';

export const ModCard = styled(NeuCard)`
  display: flex;
  flex-direction: column;
  background: #f6f6f6 !important;
  z-index: 10;
  padding-inline-start: 0;
  padding-inline-end: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin-inline-end: 0;
  margin-top: 0;
`;

export const CardHeader = styled(NeuCardHeader)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  width: 100%;
  --background: white;
`;

export const ModalTitle = styled(NeuCardTitle)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const TitleIcon = styled(NeuIcon)`
  margin-right: 10px;
`;

export const ModalCardContent = styled(NeuCardContent)`
  flex: 1;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 432px;
`;

export const ModalFooter = styled(NeuCardActions)`
  display: flex;
  justify-content: space-between;
  background: white !important;
  bottom: 0;
  height: 69px !important;
`;
