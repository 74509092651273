import { sub } from 'date-fns';

import store from '../redux/store';

import { getStoplightByLocation } from '../redux/actions/Stoplight.action';

import {
  getFacilityIssues,
  getFeedbacksByEmployeeList,
  getFeedbacksByUnitIds,
  getFeedbackListByFacility,
  getIssueListByFacility
} from '../redux/actions/Task.action';

import { date } from './helpers';

import {
  AuthUserProps,
  Employee,
  Filter,
  GetTaskParams
} from '../config/interfaces';

interface ITaskApiProps {
  authorizedUser: AuthUserProps;
  searchTerm: string;
  selectedUnits: { id: string; unit: string }[];
  sortDir: string;
  sortKey: string;
}

interface IIssuesApiProps extends ITaskApiProps {
  currentIssuePage: number;
  issueFilter: Filter;
  selectedDelegate: string;
}

interface IFeedbackApiProps extends ITaskApiProps {
  currentFeedbackPage: number;
  feedbackFilter: Filter;
  selectedDelegate: string;
}

interface IStoplightsApiProps extends ITaskApiProps {
  currentStoplightPage: number;
  location: string;
  stoplightFilter: Filter;
}

export const useIssuesApi = ({
  authorizedUser,
  currentIssuePage,
  issueFilter,
  searchTerm,
  selectedDelegate,
  selectedUnits,
  sortDir,
  sortKey
}: IIssuesApiProps) => {
  const { employeesToRound } = store.getState().EmployeeReducer;
  const { userSection: location } = store.getState().UserReducer;

  // Issue Filter is clear
  if (Object.keys(issueFilter).length === 0) {
    const filterData: GetTaskParams = {
      page: currentIssuePage,
      sortBy: sortKey,
      sortOrder: sortDir,
      query: searchTerm,
      startDate: date.formatFilterStartDate(
        date.convertToDayOfYear(sub(new Date(), { days: 30 }))
      )
    };
    if (location === 'patient') {
      return getFacilityIssues({
        location,
        filterData: {
          ...filterData,
          facilityId: authorizedUser.facilityId,
          units: selectedUnits.map(val => val.id).join(',')
        }
      });
    }
    if (selectedDelegate === authorizedUser.facilityId) {
      return getIssueListByFacility({
        location,
        filterData: {
          ...filterData,
          facilityId: authorizedUser.facilityId
        },
        roundingTypes: ['employee', 'cned']
      });
    }
    return getFacilityIssues({
      location,
      filterData: {
        ...filterData,
        userId: selectedDelegate || authorizedUser.hcaid,
        employeeIds: employeesToRound
          .map((val: Employee) => val.hcaid)
          .join(',')
      }
    });
  }

  // Issue filter is applied
  const filterData = {
    page: currentIssuePage,
    sortBy: sortKey,
    sortOrder: sortDir,
    query: searchTerm
  } as GetTaskParams;
  if (location === 'patient') {
    filterData.facilityId = authorizedUser.facilityId;
    filterData.units = selectedUnits.map(val => val.id).join(',');
  } else {
    filterData.employeeIds = employeesToRound
      .map((val: Employee) => val.hcaid)
      .join(',');
  }

  const {
    startDate,
    endDate,
    category,
    subCategory,
    createdBy,
    employeeId,
    complete,
    opened,
    attachedToStoplight,
    hasUpdate,
    patient
  } = issueFilter;
  const statuses: string[] = [];
  if (complete) {
    statuses.push('completed');
  }
  if (opened) {
    statuses.push('opened');
  }
  if (attachedToStoplight) {
    filterData.attachedToStoplight = attachedToStoplight;
  }
  if (hasUpdate) {
    filterData.hasComment = hasUpdate;
  }
  filterData.startDate = startDate
    ? date.formatFilterStartDate(startDate)
    : date.formatFilterStartDate(
        date.convertToDayOfYear(sub(new Date(), { days: 30 }))
      );
  filterData.endDate = endDate
    ? date.formatFilterEndDate(endDate)
    : date.formatFilterEndDate(date.convertToDayOfYear(new Date()));

  if (location === 'patient') {
    filterData.category = category;
    filterData.subCategory = subCategory;
    filterData.createdBy = createdBy;
    filterData.patientName = patient;
    filterData.status = statuses;

    return getFacilityIssues({
      location,
      filterData
    });
  }
  if (selectedDelegate === authorizedUser.facilityId) {
    delete filterData.employeeIds;
    return getIssueListByFacility({
      location,
      filterData: {
        ...filterData,
        facilityId: authorizedUser.facilityId,
        category,
        subCategory,
        createdBy,
        employeeId,
        status: statuses
      },
      roundingTypes: ['employee', 'cned']
    });
  }
  filterData.category = category;
  filterData.subCategory = subCategory;
  filterData.createdBy = createdBy;
  filterData.employeeId = employeeId;
  filterData.status = statuses;
  filterData.userId = selectedDelegate || authorizedUser.hcaid;
  return getFacilityIssues({
    location,
    filterData
  });
};

export const useFeedbackApi = ({
  authorizedUser,
  currentFeedbackPage,
  feedbackFilter,
  searchTerm,
  selectedDelegate,
  selectedUnits,
  sortKey,
  sortDir
}: IFeedbackApiProps) => {
  const { employeesToRound } = store.getState().EmployeeReducer;
  const { userSection: location } = store.getState().UserReducer;

  // Feedback filter is clear
  if (Object.keys(feedbackFilter).length === 0) {
    const filterData = {
      sortOrder: sortDir,
      sortBy: sortKey,
      limit: 50,
      page: currentFeedbackPage,
      startDate: date.formatFilterStartDate(
        date.convertToDayOfYear(sub(new Date(), { days: 30 }))
      ),
      query: searchTerm
    };
    if (location === 'patient') {
      return getFeedbacksByUnitIds({
        ...filterData,
        facilityId: authorizedUser.facilityId,
        units: selectedUnits.map(item => item.id).join(',')
      });
    }
    // For feedback list based on facility
    const filterObj = {
      ...filterData,
      facilityId: selectedDelegate
    };
    if (selectedDelegate === authorizedUser.facilityId) {
      return getFeedbackListByFacility({
        location,
        filterData: filterObj,
        roundingTypes: ['employee', 'cned']
      });
    }
    // TODO: This is causing issues
    if (employeesToRound.length === 0) {
      return getFeedbacksByEmployeeList({
        ...filterData,
        employeeIds: selectedDelegate || authorizedUser.hcaid,
        userId: selectedDelegate || authorizedUser.hcaid
      });
    }
    return getFeedbacksByEmployeeList({
      ...filterData,
      userId: selectedDelegate || authorizedUser.hcaid,
      employeeIds: employeesToRound.map((item: Employee) => item.hcaid)
    });
  }

  // Feedback filter is applied
  const {
    startDate,
    endDate,
    category,
    subCategory,
    createdBy,
    patient,
    employeeId,
    complete,
    opened
  } = feedbackFilter;
  const statuses = [];
  if (complete) {
    statuses.push('completed');
  }
  if (opened) {
    statuses.push('opened');
  }

  if (location === 'patient') {
    return getFeedbacksByUnitIds({
      query: searchTerm,
      facilityId: authorizedUser.facilityId,
      units: selectedUnits.map(item => item.id).join(','),
      startDate: startDate
        ? date.formatFilterStartDate(startDate)
        : date.formatFilterStartDate(
            date.convertToDayOfYear(sub(new Date(), { days: 30 }))
          ),
      endDate: endDate
        ? date.formatFilterEndDate(endDate)
        : date.formatFilterEndDate(date.convertToDayOfYear(new Date())),
      patientName: patient,
      searchEmployeeId: employeeId,
      category,
      subCategory,
      createdBy,
      status: statuses,
      page: currentFeedbackPage,
      sortBy: sortKey,
      sortOrder: sortDir
    });
  }
  const filterObj = {
    query: searchTerm,
    startDate: startDate
      ? date.formatFilterStartDate(startDate)
      : date.formatFilterStartDate(
          date.convertToDayOfYear(sub(new Date(), { days: 30 }))
        ),
    endDate: endDate
      ? date.formatFilterEndDate(endDate)
      : date.formatFilterEndDate(date.convertToDayOfYear(new Date())),
    searchEmployeeId: employeeId,
    category,
    subCategory,
    createdBy,
    status: statuses,
    page: currentFeedbackPage,
    sortBy: sortKey,
    sortOrder: sortDir
  };
  if (selectedDelegate === authorizedUser.facilityId) {
    return getFeedbackListByFacility({
      location,
      filterData: {
        ...filterObj,
        facilityId: [authorizedUser.facilityId]
      },
      roundingTypes: ['employee', 'cned']
    });
  }
  return getFeedbacksByEmployeeList({
    ...filterObj,
    userId: selectedDelegate || authorizedUser.hcaid,
    employeeIds: employeesToRound.map((item: Employee) => item.hcaid)
  });
};

export const useStoplightsApi = ({
  authorizedUser,
  currentStoplightPage,
  location,
  searchTerm,
  selectedUnits,
  sortKey,
  sortDir,
  stoplightFilter
}: IStoplightsApiProps) => {
  // Stoplight filter is clear
  if (Object.keys(stoplightFilter).length === 0) {
    if (location === 'patient') {
      return getStoplightByLocation({
        facilityId: authorizedUser.facilityId,
        locationIds: selectedUnits.map(item => item.id),
        locationType: 'unit',
        origin: 'patient',
        page: currentStoplightPage,
        sortBy: sortKey,
        sortOrder: sortDir,
        query: searchTerm || '',
        startDate: date.formatFilterStartDate(
          date.convertToDayOfYear(sub(new Date(), { days: 30 }))
        )
      });
    }
    return getStoplightByLocation({
      locationIds: [authorizedUser.facilityId],
      locationType: 'facility',
      origin: 'employee',
      page: currentStoplightPage,
      sortBy: sortKey,
      sortOrder: sortDir,
      query: searchTerm || '',
      startDate: date.formatFilterStartDate(
        date.convertToDayOfYear(sub(new Date(), { days: 30 }))
      )
    });
  }

  // Stoplight filter is applied
  const {
    startDate,
    endDate,
    category,
    subCategory,
    createdBy,
    complete,
    opened,
    deferred,
    hasAttachedIssues,
    hasUpdate
  } = stoplightFilter;
  let statuses = [];
  if (complete) {
    statuses.push('completed');
  }
  if (opened) {
    statuses.push('inProgress');
  }
  if (deferred) {
    statuses.push('deferred');
  }
  if (!complete && !opened && !deferred) {
    statuses = ['completed', 'deferred', 'inProgress'];
  }

  if (location === 'patient') {
    return getStoplightByLocation({
      locationIds: selectedUnits.map(item => item.id),
      facilityId: authorizedUser.facilityId,
      locationType: 'unit',
      origin: 'patient',
      startDate: startDate
        ? date.formatFilterStartDate(startDate)
        : date.formatFilterStartDate(
            date.convertToDayOfYear(sub(new Date(), { days: 30 }))
          ),
      endDate: endDate
        ? date.formatFilterEndDate(endDate)
        : date.formatFilterEndDate(date.convertToDayOfYear(new Date())),
      createdBy,
      category,
      subCategory,
      hasIssues: hasAttachedIssues,
      hasComment: hasUpdate,
      status: statuses,
      page: currentStoplightPage,
      sortBy: sortKey,
      sortOrder: sortDir,
      query: searchTerm || ''
    });
  }
  return getStoplightByLocation({
    locationIds: [authorizedUser.facilityId],
    locationType: 'facility',
    origin: 'employee',
    startDate: startDate
      ? date.formatFilterStartDate(startDate)
      : date.formatFilterStartDate(
          date.convertToDayOfYear(sub(new Date(), { days: 30 }))
        ),
    endDate: endDate
      ? date.formatFilterEndDate(endDate)
      : date.formatFilterEndDate(date.convertToDayOfYear(new Date())),
    createdBy,
    category,
    subCategory,
    hasIssues: hasAttachedIssues,
    hasComment: hasUpdate,
    status: statuses,
    page: currentStoplightPage,
    sortBy: sortKey,
    sortOrder: sortDir,
    query: searchTerm || ''
  });
};
