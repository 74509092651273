import {
  FC,
  useEffect,
  useMemo,
  useState,
  Dispatch,
  SetStateAction
} from 'react';
import { connect, useDispatch } from 'react-redux';
import { format, add } from 'date-fns';

import Loading from '../../components/shared/Loading';
import SideNav from '../../components/sideNav';
import SearchHeader from '../../components/searchHeader';
import CensusTable from '../../components/customTables/censusTable';
import UnlistedPatientRoundModal from '../../components/modals/unlistedPatientRoundModal';
import { CensusContainer } from './styles/PatientViews.styles';

import { RootState } from '../../redux/store';
import { getPatientsToRoundForCensus } from '../../redux/actions/User.action';
import { fireSnow } from '../../services/Confetti';
import { ModalContainer } from '../../services/ModalPortal/ModalPortal.Styles';
import {
  isPercentLessThanOne,
  isPercentRoundedToOneHundred
} from '../../utils/complianceFunctions';
import { date as dt } from '../../utils/helpers';

import { AuthUserProps, Patient } from '../../config/interfaces';

interface CensusPageProps {
  authorizedUser?: AuthUserProps;
  storePatientsForCensus: Patient[];
  searchTerm: string;
  selectedUnits: { id: string; unit: string }[];
  showModalFlag: boolean;
  setSearchTerm: Dispatch<SetStateAction<string>>;
  isLoading: boolean;
}

const PatientCensus: FC<CensusPageProps> = ({
  authorizedUser,
  storePatientsForCensus = [],
  searchTerm,
  selectedUnits,
  showModalFlag,
  setSearchTerm,
  isLoading
}) => {
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState<string>('All');
  const [patientsToRound, setPatientsToRound] = useState<Patient[]>([]);
  const [rendered, setRendered] = useState(false);

  const completedPatients = useMemo(
    () =>
      patientsToRound.filter((patient: Patient) => {
        return patient.roundStatus === 'Complete';
      }),
    [patientsToRound]
  );
  const toDoPatients = useMemo(
    () =>
      patientsToRound.filter((patient: Patient) => {
        return patient.roundStatus === 'To Do';
      }),
    [patientsToRound]
  );

  const completePercent = useMemo(
    () =>
      isPercentLessThanOne(completedPatients.length, patientsToRound.length)
        ? 1
        : isPercentRoundedToOneHundred(
            completedPatients.length,
            patientsToRound.length
          )
        ? 99
        : Math.round(
            (completedPatients.length / patientsToRound.length) * 100
          ) || 0,
    [patientsToRound, completedPatients]
  );

  const todoPercent = useMemo(
    () => (patientsToRound.length === 0 ? 0 : 100 - completePercent),
    [patientsToRound, completePercent]
  );

  // *******************************************
  // ********* Removing till Phase II **********
  // *******************************************
  // const unablePatients = useMemo(
  //   () =>
  //     patientsToRound.filter((patient: Patient) => {
  //       return patient.roundStatus === 'Unable to Round';
  //     }),
  //   [patientsToRound]
  // );

  useEffect(() => {
    setRendered(true);
  }, []);

  useEffect(() => {
    if (authorizedUser?.facilityId) {
      const endDate = dt.formatFilterEndDayToUTCTimestamp(
        format(add(new Date(), { days: 1 }), 'yyyyMMdd')
      );
      const startDate = dt.formatFilterStartDayToUTCTimestamp(
        format(new Date(), 'yyyyMMdd')
      );
      dispatch(
        getPatientsToRoundForCensus({
          facilityId: authorizedUser.facilityId,
          startDate,
          endDate,
          roundingType: 'patient'
        })
      );
    }
  }, [authorizedUser]);

  useEffect(() => {
    if (storePatientsForCensus) {
      const ptr = storePatientsForCensus.filter(patient =>
        selectedUnits.some(unit => unit.id === patient.unitId)
      );
      if (storePatientsForCensus && selectedUnits) {
        setPatientsToRound(ptr);
      }
    }
  }, [storePatientsForCensus, selectedUnits]);

  useEffect(() => {
    if (
      window.sessionStorage.getItem('droppedPatientSnow') &&
      patientsToRound.length > 0 &&
      toDoPatients.length > 0
    ) {
      window.sessionStorage.removeItem('droppedPatientSnow');
    }
  }, [patientsToRound, toDoPatients]);

  useEffect(() => {
    if (
      rendered &&
      !window.sessionStorage.getItem('droppedPatientSnow') &&
      patientsToRound.length > 0 &&
      completePercent === 100
    ) {
      setTimeout(() => {
        fireSnow(
          4,
          1,
          { x: 0.16, y: 0.5 },
          [-0.4, 0.4],
          [0.4, 0.8],
          [0.4, 1],
          ['circle'],
          ['#a8a9ad']
        );
        fireSnow(
          4,
          1,
          { x: 0.16, y: 0.5 },
          [-0.4, 0.4],
          [0.4, 0.8],
          [0.4, 1],
          ['circle'],
          ['##e05a29']
        );
        fireSnow(
          4,
          1,
          { x: 0.16, y: 0.5 },
          [-0.4, 0.4],
          [0.4, 0.8],
          [0.4, 1],
          ['circle'],
          ['#206da3']
        );
      }, 1000);
      window.sessionStorage.setItem('droppedPatientSnow', 'true');
    }
  }, [patientsToRound, completePercent]);

  return (
    <CensusContainer id="Patient-Census">
      <SideNav
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        completeCount={completedPatients.length}
        completePercent={completePercent}
        todoPercent={todoPercent}
        todoCount={toDoPatients.length}
        totalCount={patientsToRound.length}
        // ********* Removing till Phase II **********
        // unableCount={unablePatients.length}
      />
      <div
        style={{
          // TODO: review why webkit and overflowx was added in divs
          // width: '-webkit-fill-available', overflowX: 'scroll'
          width: '100%'
        }}
      >
        <SearchHeader searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
        {isLoading ? (
          <Loading centered={false} />
        ) : (
          <CensusTable
            searchTerm={searchTerm}
            patientsToRound={
              selectedTab === 'All'
                ? [...new Set(patientsToRound)]
                : selectedTab === 'Completed'
                ? [...new Set(completedPatients)]
                : [...new Set(toDoPatients)]
              // ********* Removing till Phase II **********
              // : selectedTab === 'To Do' ? toDoPatients : unablePatients ? toDoPatients : unablePatients
            }
            selectedTab={selectedTab}
          />
        )}
      </div>
      {showModalFlag && (
        <ModalContainer>
          <UnlistedPatientRoundModal />
        </ModalContainer>
      )}
    </CensusContainer>
  );
};

const mapReduxStateToProps = (state: RootState) => {
  return {
    authorizedUser: state.AuthorizedUser.authorizedUser,
    storePatientsForCensus: state.UserReducer.patientsForCensus,
    selectedUnits: state.ConfigReducer.selectedUnits,
    showModalFlag: state.UserReducer.showUnlistedPatientModalFlag,
    isLoading: state.UserReducer.isLoadingPatientsForCensus
  };
};

export default connect(mapReduxStateToProps)(PatientCensus);
