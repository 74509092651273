import { FC } from 'react';

import {
  CardContainer,
  CardHeader,
  CardSubHeader,
  CardHeaderContainer,
  CardContent,
  CardContentContainer,
  ChevronRightIcon,
  StatusIconCompleted,
  StatusContainer,
  StatusIconOpened,
  CardHeaderRight,
  HorizontalDivider
} from './PastTasksCard.styles';

interface PastTasksCardProps {
  taskId: string;
  subtype: string;
  taskDate: string;
  taskTitle: string;
  taskDescription: string;
  completed: boolean;
  facility: string;
}
const PastTasksCard: FC<PastTasksCardProps> = ({
  taskId,
  subtype,
  taskDate,
  taskTitle,
  taskDescription,
  completed,
  facility
}) => {
  return (
    <CardContainer id={`Task-Card-${taskId}`}>
      <CardHeaderContainer>
        <CardHeader>{subtype}</CardHeader>
        <CardHeaderRight>{taskDate}</CardHeaderRight>
      </CardHeaderContainer>
      <CardSubHeader>{taskTitle}</CardSubHeader>
      <CardContent>{facility}</CardContent>
      <CardContentContainer>
        <div>
          <CardContent>{taskDescription}</CardContent>
          {completed ? (
            <StatusContainer>
              <StatusIconCompleted>check_circle</StatusIconCompleted>
              <CardContent>Completed</CardContent>
            </StatusContainer>
          ) : (
            <StatusContainer>
              <StatusIconOpened>check_circle</StatusIconOpened>
              <CardContent>Opened</CardContent>
            </StatusContainer>
          )}
        </div>

        <ChevronRightIcon>chevron_right</ChevronRightIcon>
      </CardContentContainer>
      <HorizontalDivider />
    </CardContainer>
  );
};

// export default AddIssueFromPatientRound;
export default PastTasksCard;
