// Importing action file
import ACTIONS from '../actions/actionType';
import { ActionType } from '../../config/interfaces';

const initialState = {
  loading: false
};

const AccountReducer = (state = initialState, action: ActionType = {}) => {
  const tempState = { ...state };

  switch (action.type) {
    case ACTIONS.ACCOUNT.POST_ADD_PRIMARY: {
      return {
        ...state,
        loading: true
      };
    }
    case ACTIONS.ACCOUNT.POST_ADD_PRIMARY_SUCCESS: {
      return {
        ...state,
        loading: false
      };
    }
    case ACTIONS.ACCOUNT.POST_REMOVE_PRIMARY: {
      return {
        ...state,
        loading: true
      };
    }
    case ACTIONS.ACCOUNT.POST_REMOVE_PRIMARY_SUCCESS: {
      return {
        ...state,
        loading: false
      };
    }
    case ACTIONS.ACCOUNT.POST_CURRENT_FACILITY: {
      return {
        ...state,
        loading: true
      };
    }
    case ACTIONS.ACCOUNT.POST_CURRENT_FACILITY_SUCCESS:
    case ACTIONS.ACCOUNT.POST_CURRENT_FACILITY_FAILURE: {
      return {
        ...state,
        loading: false
      };
    }
    default:
      return tempState;
  }
};

export default AccountReducer;
