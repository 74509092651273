import ACTIONS from './actionType';

export const getUsers = data => {
  return { type: ACTIONS.ADMIN.GET_USERS, data };
};

export const getUsersToExport = data => {
  return { type: ACTIONS.ADMIN.GET_USERS_TO_EXPORT, data };
};

export const getEncounters = data => {
  return { type: ACTIONS.ADMIN.GET_ENCOUNTERS, data };
};

export const setAdminUserTab = data => {
  return { type: ACTIONS.ADMIN.SET_ADMIN_USER_TAB, data };
};

export const setAdminUserFilter = data => {
  return { type: ACTIONS.ADMIN.SET_ADMIN_USER_FILTER, data };
};

export const clearAdminUserFilter = () => {
  return { type: ACTIONS.ADMIN.CLEAR_ADMIN_USER_FILTER };
};

export const setAdminSearchTerm = data => {
  return { type: ACTIONS.ADMIN.SET_ADMIN_SEARCH_TERM, data };
};

export const clearCsvToExport = () => {
  return { type: ACTIONS.ADMIN.CLEAR_CSV_TO_EXPORT };
};

export const setCsvToExport = data => {
  return { type: ACTIONS.ADMIN.GET_CSV_TO_EXPORT_SUCCESS, data };
};

export const setAdminFacility = data => {
  return { type: ACTIONS.ADMIN.SET_ADMIN_FACILITY, data };
};

export const getApiVersion = data => {
  return { type: ACTIONS.ADMIN.GET_API_VERSION, data };
};
