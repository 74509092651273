import { useRef, useEffect, FC } from 'react';

import { useLocation } from 'react-router-dom';

// D3 data viz library
import { select, pie, arc, interpolate, scaleOrdinal } from 'd3';

// Custom Neutron components
import { StyledCensusSVG } from './CircPieChart.styles';

// Type def for custom props
interface CPCProps {
  data: any;
}

// Arc pie chart for rounds data on all patients
const Chart: FC<CPCProps> = ({ data }) => {
  const { pathname } = useLocation();

  // grabbing a reference to the svg container in the DOM
  const chartRef = useRef<SVGSVGElement>(null);

  // setting the size of the chart
  const dimensions = { width: 150, height: 150 };

  // calculate radius for the pie
  const { width, height } = dimensions;
  const radius = Math.min(width, height) / 2;

  // create a Pie generator sub svg
  const pieGen = pie<void, { label: string; value: number }>()
    .sort(null)
    .value((d: any) => d.value);

  // Draw the arc
  const arcGen = arc()
    .outerRadius(radius * 0.8)
    .innerRadius(radius * 0.55);

  // Map the fill colors to the data
  let color: any;
  if (pathname.includes('/employees')) {
    color = scaleOrdinal()
      .domain(['Completed', 'To Do'])
      .range(['#00558C', '#E05929']);
  } else {
    color = scaleOrdinal()
      .domain(['Completed', 'To Do', 'Unable'])
      // leaving Unable for now here
      .range(['#00558C', '#E05929', '#279BBB']);
  }

  const labels = color.domain();

  // Function that redraws the chart in the DOM
  const drawChart = () => {
    // Dont draw the chart if cant find the svg in the DOM
    if (chartRef.current) {
      // Process the new data with color labels
      const chartData = labels.map((label: string, i: number) => {
        return { label, value: data[i] };
      });
      // Selecting the pie container from the ref for updating
      const pieElement = select(chartRef.current);

      // Offset the pie from svg origin
      pieElement.attr(
        'transform',
        `translate(${width / 1.5}, ${height / 1.8})`
      );

      // Find existing slice and text containers
      const currentSlices = pieElement.selectAll('.slices');
      const currentText = pieElement.selectAll('.census-num');

      // Load the old data
      const currentSlicesWithData = currentSlices.data(
        pieGen(chartData),
        (d: any) => d.data.label
      );
      const currentTextWithData = currentText.data([
        chartData.reduce((acc: any, d: any) => {
          let sum = acc;
          sum += d.value ? d.value : 0;
          return sum;
        }, 0)
      ]);

      // Remove the old slices and text
      currentSlicesWithData.exit().remove();
      currentTextWithData.exit().remove();

      // creating a container for the pie slices
      const newSlices = currentSlicesWithData
        .enter()
        .append('g')
        .attr('class', 'slices');

      // Load the data to the slices container
      const slice = newSlices
        .append('path')
        .merge(currentSlicesWithData.select('path.slice'));

      // On new data draw each part of the arc with correct colors
      // using d3 arc interpolator
      (slice as any)
        .style('fill', (d: any) => color(d.data.label))
        .attr('class', 'slice')
        .transition()
        .duration(500)
        .attrTween('d', (d: any, i: number, el: any) => {
          const self = el[i];
          self._current = self._current || d;
          const angleInterpolator = interpolate(self._current, d);
          self._current = angleInterpolator(0);
          return (t: any) => arcGen(angleInterpolator(t));
        });

      // creating a container for the text labels
      const newText = currentTextWithData
        .enter()
        .append('g')
        .attr('class', 'census-num');

      // Load the data to the text container
      const census = newText
        .append('text')
        .merge(currentTextWithData.select('text'));

      const text = newText.append('text');

      // On new data add patient sum data text
      census
        .attr('text-anchor', 'middle')
        .attr('style', 'font-size: 1.25rem; font-weight: 800')
        .attr('dy', '-7')
        .text((d: any) => d);

      // On new data add Patients label
      const textValue = pathname.includes('/employees')
        ? 'Employees'
        : 'Patients';
      text
        .attr('text-anchor', 'middle')
        .attr('dy', '12')
        .attr('style', 'font-size: 0.65rem;')
        .text(textValue);

      text.transition().duration(500);
    }
  };
  // repainting the chart when the census data changes
  useEffect(() => {
    if (data.length) {
      drawChart();
    }
  }, [data]);
  return (
    <StyledCensusSVG id="Pie-Chart">
      <g ref={chartRef} />
    </StyledCensusSVG>
  );
};

export default Chart;
