/**
 * External Imports
 */
import { FC, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import { NeuTableRow, NeuTableHeading, NeuButton } from '@neutron/react';
/**
 * Internal Imports
 */
import NoDataSplashView from '../../noDataSplashView/NoDataSplashView';
import Loading from '../../shared/Loading';

import { RootState } from '../../../redux/store';
import { getEmployeesConfig } from '../../../redux/actions/Employee.action';
import { openModal, setModalType } from '../../../redux/actions/Modal.action';
import { removeDelegateFromUser } from '../../../redux/actions/User.action';

import { handleBlobPicUrl, userProfilePicUrl } from '../../../utils/helpers';
/**
 * Global Type Definition Imports
 */
import { AuthUserProps, Employee } from '../../../config/interfaces';

/**
 * Style Imports
 */
import {
  DelegateNameInTable,
  TextInTable,
  MyDelegatesTableBody,
  RemoveButton,
  ContentHeader,
  Meta,
  DelegateAvatar,
  MyDelegatesContainer,
  MyDelegatesTable,
  DelegateSlot,
  DelegatesConfigContainer
} from './MyDelegatesTable.styles';

import defaultImage from '../../../assets/images/defaultImage.png';

interface MyDelegatesProps {
  authorizedUser: AuthUserProps;
  loadingMyDelegates: boolean;
  myDelegatesList?: Employee[];
  sasToken: string;
}
const MyDelegatesListTable: FC<MyDelegatesProps> = ({
  authorizedUser,
  loadingMyDelegates,
  myDelegatesList,
  sasToken
}) => {
  const dispatch = useDispatch();
  const [filteredList, setFilteredList] = useState<Employee[]>([]);
  const [active, setActive] = useState<string>('lastName');
  const [sortDir, setSortDir] = useState<'desc' | 'asc'>('asc');

  const handleRemoveEmployee = (employee: Employee) => {
    const payload = {
      userId: authorizedUser.hcaid,
      delegateId: employee.hcaid
    };
    dispatch(removeDelegateFromUser(payload));
  };

  const openEditModal = () => {
    dispatch(setModalType('Add Delegates'));
    dispatch(openModal());
  };

  const sortByHeading = (heading: string) => {
    const stateCopy = myDelegatesList?.length
      ? // eslint-disable-next-line no-unsafe-optional-chaining
        [...myDelegatesList]
      : [];

    if (sortDir === 'desc') {
      setSortDir('asc');
      stateCopy.sort((a: Employee, b: Employee) =>
        a[heading as keyof Employee]?.toLowerCase() >
        b[heading as keyof Employee]?.toLowerCase()
          ? 1
          : -1
      );
      setActive(heading);
    }
    if (sortDir === 'asc') {
      setSortDir('desc');
      stateCopy.sort((a: Employee, b: Employee) =>
        a[heading as keyof Employee]?.toLowerCase() >
        b[heading as keyof Employee]?.toLowerCase()
          ? -1
          : 1
      );
      setActive(heading);
    }

    setFilteredList(stateCopy);
  };

  useEffect(() => {
    dispatch(getEmployeesConfig(authorizedUser?.hcaid));
  }, [authorizedUser]);

  useEffect(() => {
    if (myDelegatesList?.length) {
      // Sorting the list A-Z for initial display
      const sorted = myDelegatesList.sort((a: Employee, b: Employee) =>
        a.lastName > b.lastName ? 1 : -1
      );
      setFilteredList(sorted);
    } else {
      setFilteredList([]);
    }
  }, [myDelegatesList]);

  return (
    <MyDelegatesContainer id="My-Delegates-Container">
      <DelegatesConfigContainer>
        <ContentHeader style={{ paddingTop: '15px' }}>
          <NeuButton
            style={{ marginRight: '10px' }}
            color="primary"
            fill="raised"
            onClick={openEditModal}
          >
            Add Delegates
          </NeuButton>
        </ContentHeader>
      </DelegatesConfigContainer>
      <MyDelegatesTable>
        <NeuTableRow
          style={{ backgroundColor: 'white' }}
          columns="{'Delegate': '34%','Department':'27%', 'Title': '27%', 'Delete': '12%'}"
          header
        >
          <NeuTableHeading
            id="My-Delegate-List-Table-Delegate-Column"
            slot="Delegate"
            icon={
              !(active === 'lastName')
                ? 'asc'
                : sortDir === 'asc'
                ? 'desc'
                : 'asc'
            }
            onClick={() => sortByHeading('lastName')}
            active={active === 'lastName'}
          >
            Delegate
          </NeuTableHeading>
          <NeuTableHeading
            id="My-Delegate-List-Table-Department-Column"
            slot="Department"
            icon={
              !(active === 'department')
                ? 'asc'
                : sortDir === 'asc'
                ? 'desc'
                : 'asc'
            }
            onClick={() => sortByHeading('department')}
            active={active === 'department'}
          >
            Department
          </NeuTableHeading>
          <NeuTableHeading
            id="My-Delegate-List-Table-Title-Column"
            slot="Title"
            icon={
              !(active === 'title') ? 'asc' : sortDir === 'asc' ? 'desc' : 'asc'
            }
            onClick={() => sortByHeading('title')}
            active={active === 'title'}
          >
            Title
          </NeuTableHeading>
          <NeuTableHeading
            id="My-Delegate-List-Table-Delete-Column"
            slot="Delete"
            icon="none"
          />
        </NeuTableRow>
        {loadingMyDelegates ? (
          <Loading centered={false} />
        ) : filteredList.length === 0 && !loadingMyDelegates ? (
          <NoDataSplashView type="delegate-list" />
        ) : (
          <MyDelegatesTableBody>
            {filteredList?.map((delegate: Employee) => {
              return (
                delegate && (
                  <NeuTableRow
                    columns="{'Delegate': '34%','Department':'27%', 'Title': '27%', 'Delete':'12%'}"
                    size="normal"
                    key={delegate?.hcaid}
                    style={{ alignItems: 'center' }}
                  >
                    <DelegateSlot slot="Delegate">
                      {sasToken ? (
                        <DelegateAvatar
                          src={
                            handleBlobPicUrl(delegate.pictureUrl, sasToken) ??
                            userProfilePicUrl(delegate.hcaid, sasToken)
                          }
                          alt="Delegate Image"
                          onError={e => {
                            e.currentTarget.src = defaultImage;
                            return null;
                          }}
                        />
                      ) : (
                        <DelegateAvatar
                          src={defaultImage}
                          alt="Default Image"
                        />
                      )}
                      <div>
                        <DelegateNameInTable>{`${delegate.firstName} ${delegate.lastName}`}</DelegateNameInTable>
                        <TextInTable>{delegate?.hcaid}</TextInTable>
                      </div>
                    </DelegateSlot>
                    <Meta slot="Department">
                      <TextInTable>
                        {delegate.department || 'Unknown'}
                      </TextInTable>
                    </Meta>
                    <Meta slot="Title">
                      <TextInTable>{delegate.title || 'Unknown'}</TextInTable>
                    </Meta>
                    <RemoveButton
                      slot="Delete"
                      onClick={() => handleRemoveEmployee(delegate)}
                    >
                      <NeuButton color="#CE3637" fill="outline" size="small">
                        Remove
                      </NeuButton>
                    </RemoveButton>
                  </NeuTableRow>
                )
              );
            })}
          </MyDelegatesTableBody>
        )}
      </MyDelegatesTable>
    </MyDelegatesContainer>
  );
};

const mapReduxStateToProps = (state: RootState) => {
  return {
    authorizedUser: state.AuthorizedUser.authorizedUser,
    loadingMyDelegates: state.EmployeeReducer.loadingMyEmployees,
    myDelegatesList: state.EmployeeReducer.employeesDelegatedTo,
    sasToken: state.ConfigReducer.sasToken
  };
};
export default connect(mapReduxStateToProps)(MyDelegatesListTable);
