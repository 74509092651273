/**
 * External Imports
 */
import {
  NeuContainer,
  NeuImage,
  NeuLabel,
  NeuTableHeading,
  NeuTableRow
} from '@neutron/react';
/**
 * Style Imports
 */
import styled from 'styled-components';

export const StyledTableHeading = styled(NeuTableHeading)`
  font-size: 14px;
  line-height: 22px;
`;

export const StyledLabel = styled.div`
  font-size: 14px;
  line-height: 22px;
  margin-right: 2px;
  white-space: normal;
`;

export const StyledNoWrapLabel = styled(StyledLabel)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CategoryContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto;
  align-items: start;
  font-size: 14px;
  line-height: 22px;
  white-space: normal;
`;
export const CategoryLabel = styled(NeuLabel)`
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  max-width: 150px;
`;
export const ServiceCentralContainer = styled(NeuContainer)`
  min-width: 50px;
  min-height: 50px;
  max-width: 50px;
  max-height: 50px;
  padding: 5px;
`;
export const ServiceCentralLogo = styled(NeuImage)`
  min-width: 25px;
  min-height: 25px;
  max-width: 25px;
  max-height: 25px;
  margin-right: 0px;
`;

export const CategoryText = styled.div`
  font-weight: bold;
`;

export const TextContainer = styled.div`
  grid-column: 2;
  display: flex;
  flex-direction: column;
`;

export const StyledBoldLabel = styled(NeuLabel)`
  font-size: 14px;
  line-height: 22px;
  font-weight: bold;
  white-space: normal;
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  position: absolute;
  bottom: 0;
  width: 100%;
`;

export const ToDoRow = styled(NeuTableRow)`
  background-color: white;
  height: 64px;
  align-items: center;
`;

export const CompleteRow = styled(NeuTableRow)`
  background-color: #f6f6f6;
  height: 64px;
  align-items: center;
`;

export const ToDoLabel = styled(NeuLabel)`
  font-size: 14px;
  line-height: 22px;
  font-weight: bold;
`;

export const CategoryIndent = styled.div`
  margin-left: 25px;
`;
