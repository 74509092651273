import { FC, RefObject, useCallback, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { NeuButton, NeuSpinner } from '@neutron/react';

import { RootState } from '../../../../redux/store';
import { deleteTask } from '../../../../redux/actions/Task.action';
import { setToastType } from '../../../../redux/actions/Toast.action';

import { handleModal } from '../../../../services/ModalPortal/utils';
import { toast } from '../../../../services/Toast';

import { FeedbackPost, TaskPost } from '../../../../config/interfaces';

interface IDeleteProps {
  loading: boolean;
  modalContentRef?: RefObject<HTMLElement | null>;
  task?: FeedbackPost | TaskPost;
}

const DeleteBtn: FC<IDeleteProps> = ({ loading, modalContentRef, task }) => {
  const dispatch = useDispatch();

  const [rendered, setRendered] = useState(false);

  const handleDelete = useCallback(
    e => {
      e.stopPropagation();
      dispatch(setToastType({ type: 'Delete Issue' }));
      toast(
        `Are you sure you want to delete this ${task?.taskType}?`,
        'info',
        null,
        500,
        true,
        null,
        () => {
          if (task) {
            dispatch(deleteTask(task));
          }
        }
      );
    },
    [task]
  );

  useEffect(() => {
    setRendered(true);
  }, []);

  useEffect(() => {
    if (rendered && modalContentRef && !loading) {
      handleModal(modalContentRef);
    }
  }, [loading]);

  return (
    <NeuButton
      id="Delete-Button"
      color="red-60"
      onClick={handleDelete}
      style={{ textAlign: 'right' }}
    >
      {loading ? (
        <NeuSpinner ariaLabel="spinner" color="plain-0" tiny />
      ) : (
        'Delete'
      )}
    </NeuButton>
  );
};

const mapReduxStateToProps = (state: RootState) => ({
  loading: state.TaskReducer.loadingDeleteTask,
  taskData: state.ModalReducer.modalData
});

export default connect(mapReduxStateToProps)(DeleteBtn);
