import styled, { css } from 'styled-components';
import {
  NeuCard,
  NeuCardHeader,
  NeuContainer,
  NeuDivider,
  NeuIcon,
  NeuInput,
  NeuLabel,
  NeuTextarea,
  NeuButton,
  NeuItem,
  NeuCardActions,
  NeuCardContent,
  NeuSpinner
} from '@neutron/react';

interface DropdownProps {
  showPopover: boolean;
}

interface CSSProps {
  autoWidth?: boolean | undefined;
  centered?: boolean | undefined;
  cursorType?: boolean;
  color?: string;
}

export const AddIssueCard = styled(NeuCard)`
  display: flex;
  flex-direction: column;
  background: #f6f6f6 !important;
  z-index: 10;
  padding-inline-start: 0;
  padding-inline-end: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin-inline-end: 0;
  margin-top: 0;
`;

export const CardHeader = styled(NeuCardHeader)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  width: 100%;
  --background: white;
`;

export const ModalCardContent = styled(NeuCardContent)`
  flex: 1;
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-behavior: smooth;
  width: 432px;
`;

export const RadioContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 0.9375rem;
  margin-bottom: 0.9375rem;
  max-width: 413px;
`;

export const InputHeading = styled(NeuLabel)<CSSProps>`
  display: inline-block;
  font-size: 0.875rem;
  line-height: 22px;
  width: 413px;
  margin-top: 12px;
  ${({ autoWidth }) =>
    autoWidth &&
    css`
      width: auto;
    `}
`;

export const RadioLabel = styled(NeuLabel)`
  margin-right: 30px;
  margin-left: 3px;
`;

export const ModalInput = styled(NeuInput)`
  width: 97%;
  min-height: 42px;
`;

export const ModalTextArea = styled(NeuTextarea)`
  width: 97%;
  min-height: 88px;
  margin-bottom: 0.5rem;
`;

export const DropDownSearchContainer = styled.div<DropdownProps>`
  box-shadow: 0px 2px 4px rgba(25, 25, 25, 0.2);
  border-radius: 5px;
  width: 97%;
  min-height: 27px;
  max-height: 160px;
  z-index: 10;
  position: absolute;
  top: 42px;
  background: white !important;
  opacity: 1;
  overflow-y: scroll;
  display: ${props => (props.showPopover ? 'block' : 'none')};
`;

export const DropItem = styled(NeuItem)`
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 26px;
  padding: 1px;
  opacity: 1;
  border-bottom: 1px solid #b0b0b0;
  padding: 5px 0px;
  overflow: hidden;
`;

export const CreateNewButton = styled(NeuButton)`
  margin-top: 0.875rem;
`;

export const ModalFooter = styled(NeuCardActions)`
  background: white !important;
  bottom: 0;
  height: 69px !important;
  ${props =>
    !props.right &&
    css`
      display: flex;
      justify-content: space-between;
    `}
`;

export const StyledEditHeaderRow = styled(NeuContainer)<CSSProps>`
  display: flex;
  flex-direction: row;
  align-items: ${({ centered }) => (centered ? 'center' : 'start')};
`;

export const StyledEditHeaderCol = styled(NeuContainer)`
  display: flex;
  flex-direction: column;
`;

export const StyledBoldHeading = styled(InputHeading)<CSSProps>`
  font-weight: bold;
  ${({ autoWidth }) =>
    autoWidth &&
    css`
      width: auto;
    `}
  ${({ cursorType }) =>
    cursorType &&
    css`
      cursor: pointer;
    `}
    color: ${props => props.color} !important;
`;
export const StyledBoldHeadingCompleted = styled(InputHeading)<CSSProps>`
  font-weight: bold;
  ${({ autoWidth }) =>
    autoWidth &&
    css`
      width: auto;
    `}
  ${({ cursorType }) =>
    cursorType &&
    css`
      cursor: pointer;
    `}
  color: ${props => props.color} !important;
  min-width: 180px;
`;
export const StyledRightAlignButton = styled(NeuButton)`
  align-self: end;
`;

export const StyledCircleDoneIcon = styled(NeuIcon)`
  background-color: #548a2c;
  border-radius: 50%;
`;

export const StyledDivider = styled(NeuDivider)`
  width: 98%;
  margin-right: 1.5rem;
`;

export const StoplightCategory = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  min-height: 12px;
  color: #373737;
`;

export const BtnSpinner = styled(NeuSpinner)`
  margin: 0 1.57rem;
`;

export const ItemContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const BlueText = styled.div`
  color: #206da3;
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
`;

export const SearchIcons = styled(NeuIcon)`
  display: flex;
  align-items: center;
  text-align: center;
  margin-right: 8px;
`;
export const TitleSpan = styled.span`
  display: flex;
  font-size: 16px;
  line-height: 26px;
  color: #06173d;
  width: 280px;
`;
export const TitleIcon = styled(NeuIcon)`
  margin-right: 8px;
  color: #5d99c8;
`;

/* View modal styles */
export const ViewIssueCard = styled(NeuCard)`
  display: flex;
  flex-direction: column;
  background: #f6f6f6 !important;
  z-index: 10;
  padding-inline-start: 0;
  padding-inline-end: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin-inline-end: 0;
  margin-top: 0;
`;
export const TitleSpanView = styled.span`
  display: flex;
  font-size: 16px;
  line-height: 26px;
  color: #06173d;
  width: 280px;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
export const IssueViewDiv = styled.div`
  margin-top: 15px;
`;
export const IssueViewSubDiv = styled.div`
  width: 50%;
`;
export const IssueViewDiv2Column = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 15px;
`;
export const LabelHeading = styled(NeuLabel)`
  width: 100%;
  margin-top: 15px;
  font-size: 14px;
  line-height: 22px;
`;

export const FooterDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const LabelContentPatient = styled.div<CSSProps>`
  width: 100%;
  margin-top: 5px;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  ${({ cursorType }) =>
    cursorType &&
    css`
      cursor: pointer;
    `}
  color: ${props => props.color};
`;
export const LabelContentBold = styled.div`
  width: 100%;
  margin-top: 5px;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  color: #191919;
`;

export const FooterText = styled.div`
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  line-height: 26px;
  color: #000000;
  white-space: pre-wrap;
`;
export const TicketNumber = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #2075ad;
  border-bottom: 2px solid #2075ad;
  cursor: pointer;
`;

export const ServiceCentralSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ViewStoplightsClickableLabel = styled.span`
  display: block;
  font-style: normal;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 22px;
  color: #2075ad;
  cursor: pointer;
`;
