/**
 * External Imports
 */
import { FC, useState } from 'react';
import { connect } from 'react-redux';
/**
 * Internal Imports
 */
import SideNav from '../../components/sideNav/adminSideNav/AdminSideNav';
import UnitsTable from '../../components/customTables/areasTable/UnitsTable';

import { RootState } from '../../redux/store';
/**
 * Style Imports
 */
import {
  AreasContainer,
  AreasContainerInner
} from './styles/AdminViews.styles';

const menuItems = [
  { tabLabel: 'Units', tabId: 'units' }
  // { tabLabel: 'Departments', tabId: 'departments' }
];

const Areas: FC = () => {
  const [selectedTab, setSelectedTab] = useState<string>('units');
  return (
    <AreasContainer id="Admin-Area-List">
      <SideNav
        navItems={menuItems}
        defaultColor="plain-0"
        activeColor="primary-10"
        className="pl-3"
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />
      <AreasContainerInner>
        {selectedTab && selectedTab === 'units' && <UnitsTable />}
        {/* {selectedTab && selectedTab === 'departments' && <DepartmentsTable />} */}
      </AreasContainerInner>
    </AreasContainer>
  );
};

const mapReduxStateToProps = (state: RootState) => {
  return {
    usersList: state.AdminReducer.usersList
  };
};

export default connect(mapReduxStateToProps)(Areas);
