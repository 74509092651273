import styled, { css } from 'styled-components';
import {
  NeuCard,
  NeuCardHeader,
  NeuInput,
  NeuLabel,
  NeuTextarea,
  NeuButton,
  NeuItem,
  NeuCardActions,
  NeuCardContent,
  NeuIcon,
  NeuRadioGroup
} from '@neutron/react';

interface ColorProps {
  color?: string;
  cursorType?: boolean;
}
interface DropdownProps {
  showPopover: boolean;
}

export const FeedbackCard = styled(NeuCard)`
  display: flex;
  flex-direction: column;
  background: #f6f6f6 !important;
  z-index: 10;
  padding-inline-start: 0;
  padding-inline-end: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin-inline-end: 0;
  margin-top: 0;
`;

export const CardHeader = styled(NeuCardHeader)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  width: 100%;
  --background: white;
`;

export const ModalCardContent = styled(NeuCardContent)`
  flex: 1;
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-behavior: smooth;
  width: 432px;
`;

export const RadioContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 0.9375rem;
  margin-bottom: 0.5rem;
  max-width: 413px;
`;

export const InputHeading = styled(NeuLabel)`
  display: inline-block;
  font-size: 0.875rem;
  line-height: 22px;
  width: 413px;
  margin-top: 12px;
`;

export const RadioLabel = styled(NeuLabel)`
  margin-right: 30px;
  margin-left: 3px;
`;

export const ModalInput = styled(NeuInput)`
  width: 97%;
  min-height: 42px;
`;

export const ModalTextArea = styled(NeuTextarea)`
  width: 97%;
  height: 88px;
  margin-bottom: 1.75rem;
`;

export const DropDownSearchContainer = styled.div<DropdownProps>`
  box-shadow: 0px 2px 4px rgba(25, 25, 25, 0.2);
  border-radius: 5px;
  width: 97%;
  min-height: 27px;
  max-height: 160px;
  z-index: 10;
  position: absolute;
  top: 42px;
  background: white !important;
  opacity: 1;
  overflow-y: scroll;
  display: ${props => (props.showPopover ? 'block' : 'none')};
`;

export const DropItem = styled(NeuItem)`
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 26px;
  padding: 1px;
  opacity: 1;
  overflow: hidden;
`;

export const CreateNewButton = styled(NeuButton)`
  margin-top: 0.875rem;
`;

export const ModalFooter = styled(NeuCardActions)`
  background: white !important;
  bottom: 0;
  height: 69px !important;
`;

export const CheckIcon = styled(NeuIcon)`
  margin-right: 8px;
  color: #548a2c;
`;
export const MarkCompleteButton = styled(NeuButton)`
  color: #548a2c !important;
`;
export const TitleSpan = styled.span`
  display: flex;
  font-size: 16px;
  line-height: 26px;
  color: #06173d;
  width: 280px;
`;
export const TitleSpanView = styled.span`
  display: flex;
  font-size: 16px;
  line-height: 26px;
  color: #06173d;
  width: 280px;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
export const TitleIcon = styled(NeuIcon)`
  margin-right: 8px;
  color: #5d99c8;
`;
export const FeedbackViewDiv = styled.div`
  margin-top: 15px;
`;
export const CompletedTextDiv = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  color: #000000;
`;
export const FeedbackViewDiv2Column = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // margin-top: 15px;
`;
export const LabelHeading = styled(NeuLabel)`
  width: 100%;
  margin-top: 15px;
  font-size: 14px;
  line-height: 22px;
`;
export const LabelContent = styled.div`
  width: 100%;
  margin-top: 5px;
  font-size: 16px;
  line-height: 26px;
  color: #191919;
`;
export const LabelContentPatient = styled.div<ColorProps>`
  width: 100%;
  margin-top: 5px;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  ${({ cursorType }) =>
    cursorType &&
    css`
      cursor: pointer;
    `}
  color: ${props => props.color}
`;
export const LabelContentBold = styled.div`
  width: 100%;
  margin-top: 5px;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  color: #191919;
`;
export const FeedbackViewSubDiv = styled.div`
  width: 50%;
`;

export const FooterDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const DateDiv = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
`;
export const ChipWrapper = styled.span``;

export const UpdatesWrapper = styled.div`
  margin-top: 23px;
`;
export const UpdatesItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 5px;
`;
export const UpdatesIconContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;
export const UpdatesInfoContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0px 10px;
  border-bottom: 1px solid #b0b0b0;
  padding-bottom: 5px;
`;
export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;
export const UpdateTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 26px;
  color: #124e7f;
`;
export const UpdateDate = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 22px;
  color: #686868;
  margin-left: 10px;
`;
export const DetailsContainer = styled.div`
  font-size: 16px;
  line-height: 26px;
  color: #373737;
`;
export const InputContainer = styled.div`
  margin-bottom: 36px;
`;
export const UpdatesTextArea = styled(NeuTextarea)`
  width: 100%;
`;
export const UpdateButton = styled(NeuButton)`
  float: right;
`;
export const CategoryRadioGroup = styled(NeuRadioGroup)``;
