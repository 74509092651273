/**
 * External Imports
 */
import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
/**
 * Internal Imports
 */
import ReusableTabs from '../../components/reusableTabs';
import FeatureRoute from '../featureRoute/FeatureRoute';
import CSRNList from './supportLists/CSRNList';
import CNEdList from './supportLists/CNEdList';
import SupportProfile from './SupportProfile';
import SupportIssues from './SupportIssues';
import SupportFeedback from './SupportFeedback';

import { clearAllDepts } from '../../redux/actions/Employee.action';
import { setTaskSearchQuery } from '../../redux/actions/Task.action';
import {
  resetUserSectionType,
  setUserSectionType
} from '../../redux/actions/User.action';
import { useFeatures } from '../../services/Features/features';
import { useUserRolesRef } from '../../services/UserRoles/userRoles';

import { useDebounceValue } from '../../utils/debouncers';

const Support: FC<{}> = () => {
  const dispatch = useDispatch();

  const { isCSRNEnabled, isCNEdEnabled } = useFeatures();
  const { isCSRNRounder, isCNEdRounder } = useUserRolesRef();

  const [searchTerm, setSearchTerm] = useState<string>('');

  const dbSearchTerm = useDebounceValue(searchTerm, 250);

  const { pathname } = useLocation();

  const view = useMemo(() => pathname.split('/')[2], [pathname]);

  const subPath = useMemo(() => pathname.split('/')[3], [pathname]);

  const supportType = useMemo(
    () => (view !== 'profile' ? view : subPath),
    [view, subPath]
  );

  const reusableTabItems = useMemo(
    () => ({
      base: '/support',
      links: [
        { label: 'List', path: `/${supportType}/list` },
        { label: 'Issues', path: `/${supportType}/issues` },
        { label: 'Feedback', path: `/${supportType}/feedback` }
      ]
    }),
    [supportType]
  );

  useEffect(() => {
    return () => {
      dispatch(resetUserSectionType());
      dispatch(clearAllDepts());
    };
  }, []);

  useEffect(() => {
    if (supportType) dispatch(clearAllDepts());
    dispatch(setUserSectionType(supportType));
  }, [supportType]);

  useEffect(() => {
    if (subPath?.includes('issues')) dispatch(setTaskSearchQuery(searchTerm));
    if (subPath?.includes('feedback')) dispatch(setTaskSearchQuery(searchTerm));
  }, [dbSearchTerm]);

  return (
    <div>
      {view !== 'profile' && supportType === 'cned' && (
        <ReusableTabs tabItems={reusableTabItems} />
      )}
      <Routes>
        {isCSRNRounder && (
          <Route path="csc" element={<Outlet />}>
            <Route index element={<Navigate to="csc/list" replace />} />
            <Route
              path="list"
              element={
                <FeatureRoute
                  component={
                    <CSRNList
                      searchTerm={searchTerm}
                      setSearchTerm={setSearchTerm}
                    />
                  }
                  isFeatureEnabled={isCSRNEnabled}
                  offsetHeight={72}
                />
              }
            >
              <Route
                path=":csrnFilter"
                element={
                  <CSRNList
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                  />
                }
              />
            </Route>
          </Route>
        )}
        {isCNEdRounder && (
          <Route path="cned" element={<Outlet />}>
            <Route index element={<Navigate to="cned/list" replace />} />
            <Route
              path="list"
              element={
                <FeatureRoute
                  component={
                    <CNEdList
                      searchTerm={searchTerm}
                      setSearchTerm={setSearchTerm}
                    />
                  }
                  isFeatureEnabled={isCNEdEnabled}
                  offsetHeight={72}
                />
              }
            >
              <Route
                path=":cnedFilter"
                element={
                  <CNEdList
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                  />
                }
              />
            </Route>
            <Route
              path="issues"
              element={
                <FeatureRoute
                  component={
                    <SupportIssues
                      searchTerm={searchTerm}
                      setSearchTerm={setSearchTerm}
                    />
                  }
                  isFeatureEnabled={isCNEdEnabled}
                  offsetHeight={72}
                />
              }
            />
            <Route
              path="feedback"
              element={
                <FeatureRoute
                  component={
                    <SupportFeedback
                      searchTerm={searchTerm}
                      setSearchTerm={setSearchTerm}
                    />
                  }
                  isFeatureEnabled={isCNEdEnabled}
                  offsetHeight={72}
                />
              }
            />
          </Route>
        )}
        <Route path="profile" element={<Outlet />}>
          <Route path=":supportType" element={<SupportProfile />} />
        </Route>
        <Route
          path="*"
          element={
            <Navigate
              to={localStorage.getItem('orbitLastPath') || '/'}
              replace
            />
          }
        />
      </Routes>
    </div>
  );
};

export default Support;
