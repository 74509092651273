import { FC, Dispatch, SetStateAction } from 'react';

import {
  NotesContainer,
  NotesHeader,
  NotesHeaderContainer,
  NotesContent,
  NoteIcon,
  IconsContainer
} from './NotesCard.styles';

interface NotesCardProps {
  date: string;
  noteId: string;
  author: string;
  message: string;
  deleteNote: Dispatch<SetStateAction<string>>;
}
const NotesCard: FC<NotesCardProps> = ({
  date,
  noteId,
  author,
  message,
  deleteNote
}) => {
  return (
    <NotesContainer id="Notes-Card">
      <NotesHeaderContainer id={`Notes-Header-${noteId}`}>
        <NotesHeader>{`${date} - ${author}`}</NotesHeader>
        <IconsContainer>
          <NoteIcon onClick={() => deleteNote(noteId)}>
            {' '}
            delete_outline{' '}
          </NoteIcon>
        </IconsContainer>
      </NotesHeaderContainer>
      <NotesContent
        id={`Notes-Content-${noteId}-${author}`}
      >{`${message}`}</NotesContent>
    </NotesContainer>
  );
};

// export default AddIssueFromPatientRound;
export default NotesCard;
