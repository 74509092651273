import ACTIONS from './actionType';

// Task actions

export const getFacilityIssues = data => {
  return { type: ACTIONS.TASK.GET_ISSUES_BY_FACILITY, data };
};

export const getAllFacilityIssues = data => {
  return { type: ACTIONS.TASK.GET_ALL_ISSUES_BY_FACILITY, data };
};

export const getCategories = data => {
  return { type: ACTIONS.TASK.GET_CATEGORIES, data };
};

export const postNewTask = data => {
  return { type: ACTIONS.TASK.POST_NEW_TASK, data };
};

export const addTaskToSharingQ = data => {
  return { type: ACTIONS.TASK.ADD_TASK_TO_SHARING_Q, data };
};

export const clearSharingQ = () => {
  return { type: ACTIONS.CLEAR_SHARING_Q };
};

export const selectDataForEdit = data => {
  return { type: ACTIONS.TASK.SELECT_DATA_FOR_EDIT, data };
};

export const resetSelectedDataForEdit = () => {
  return { type: ACTIONS.TASK.RESET_SELECTED_DATA_FOR_EDIT };
};

export const getEmployeeSharedWith = data => {
  return { type: ACTIONS.TASK.GET_EMP_SHARED_WITH, data };
};

export const resetEmployeeSharedWith = () => {
  return { type: ACTIONS.TASK.RESET_EMP_SHARED_WITH };
};

export const completeTask = data => {
  return { type: ACTIONS.TASK.COMPLETE_TASK, data };
};

export const deleteTask = data => {
  return { type: ACTIONS.TASK.DELETE_TASK, data };
};

export const getTaskByAccountNumber = data => {
  return {
    type: ACTIONS.TASK.GET_TASKS_BY_ACCOUNT_NUMBER,
    data
  };
};

export const clearTasksByAccountNumber = data => {
  return {
    type: ACTIONS.TASK.CLEAR_TASKS_BY_ACCOUNT_NUMBER,
    data
  };
};

export const getSingleIssueOrFeedbackFromEmail = data => {
  return {
    type: ACTIONS.TASK.GET_SINGLE_TASK_TO_COMPLETE,
    data
  };
};

export const clearSingleIssueFromEmail = () => {
  return {
    type: ACTIONS.TASK.CLEAR_SINGLE_TASK_TO_COMPLETE
  };
};

export const getTasksByEmployeeId = data => {
  return {
    type: ACTIONS.TASK.GET_TASKS_BY_EMPLOYEE_ID,
    data
  };
};

export const clearTasksByEmployeeId = data => {
  return {
    type: ACTIONS.TASK.CLEAR_TASKS_BY_EMPLOYEE_ID,
    data
  };
};

export const searchIssues = data => {
  return { type: ACTIONS.TASK.SEARCH_ISSUES, data };
};

export const clearSearchIssues = () => {
  return { type: ACTIONS.TASK.CLEAR_SEARCH_ISSUES };
};

// Save task data to store
export const saveTaskData = data => {
  return { type: ACTIONS.TASK.SAVE_TASK_DATA, data };
};

// Clear task data in store
export const clearTaskData = () => {
  return { type: ACTIONS.TASK.CLEAR_TASK_DATA };
};

// Save modal state to store
export const saveModalState = data => {
  return { type: ACTIONS.TASK.SAVE_TASK_MODAL_STATE, data };
};

// Clear modal state in store
export const clearModalState = () => {
  return { type: ACTIONS.TASK.CLEAR_TASK_MODAL_STATE };
};

export const getDetailsByTicketNumber = data => {
  return { type: ACTIONS.TASK.GET_TICKET_DETAILS, data };
};

export const clearTicketDetails = () => {
  return { type: ACTIONS.TASK.CLEAR_TICKET_DETAILS };
};

export const getSCLocations = () => {
  return { type: ACTIONS.TASK.GET_SC_LOCATIONS };
};

export const getGELocations = () => {
  return { type: ACTIONS.TASK.GET_GE_LOCATIONS };
};

export const verifyControlNumber = data => {
  return { type: ACTIONS.TASK.VERIFY_CONTROL_NUMBER, data };
};

export const clearControlNumberResponse = () => {
  return { type: ACTIONS.TASK.CLEAR_VERIFY_CONTROL_NUMBER };
};

export const setCurrentIssuePage = data => {
  return { type: ACTIONS.TASK.SET_CURRENT_ISSUE_PAGE, data };
};

// FEEDBACK ACTIONS

export const getFeedbacksByUnitIds = data => {
  return { type: ACTIONS.TASK.GET_FEEDBACK_BY_UNIT_IDS, data };
};

export const getFeedbacksByEmployeeList = data => {
  return { type: ACTIONS.TASK.GET_FEEDBACK_BY_EMPLOYEE_LIST, data };
};

export const setCurrentFeedbackPage = data => {
  return { type: ACTIONS.TASK.SET_CURRENT_FEEDBACK_PAGE, data };
};

// filters

export const setIssueFilter = data => {
  return { type: ACTIONS.TASK.SET_ISSUE_FILTER, data };
};

export const setFeedbackFilter = data => {
  return { type: ACTIONS.TASK.SET_FEEDBACK_FILTER, data };
};

export const clearFeedbackFilter = () => {
  return { type: ACTIONS.TASK.CLEAR_FEEDBACK_FILTER };
};

export const clearIssueFilter = () => {
  return { type: ACTIONS.TASK.CLEAR_ISSUE_FILTER };
};

export const setTaskSort = data => {
  return { type: ACTIONS.TASK.SET_TASK_SORT, data };
};

export const getTaskDiscussions = data => {
  return { type: ACTIONS.TASK.GET_TASK_DISCUSSION, data };
};

export const postTaskComment = data => {
  return { type: ACTIONS.TASK.POST_TASK_COMMENT, data };
};

export const getIssueById = data => {
  return { type: ACTIONS.TASK.GET_ISSUE_BY_ID, data };
};

export const clearIssueById = data => {
  return { type: ACTIONS.TASK.CLEAR_ISSUE_BY_ID, data };
};

// Get issues by facility from the dropdown in header section in employee
export const getIssueListByFacility = data => {
  return { type: ACTIONS.TASK.GET_ISSUE_LIST_BY_FACILITY, data };
};

// Get all issues by facility for printing csv
export const getAllIssuesListByFacility = data => {
  return { type: ACTIONS.TASK.GET_ALL_ISSUES_LIST_BY_FACILITY, data };
};

// Get feedback by facility from the dropdown in header section in employee
export const getFeedbackListByFacility = data => {
  return { type: ACTIONS.TASK.GET_FEEDBACK_LIST_BY_FACILITY, data };
};

export const getAllFacilityFeedback = data => {
  return { type: ACTIONS.TASK.GET_ALL_FACILITY_FEEDBACK, data };
};

export const getAllFeedbackListByFacilityId = data => {
  return {
    type: ACTIONS.TASK.GET_ALL_FEEDBACK_LIST_BY_FACILITY_ID,
    data
  };
};

export const setTaskSearchQuery = data => {
  return { type: ACTIONS.TASK.SET_TASK_SEARCH_QUERY, data };
};
