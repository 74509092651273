/**
 * External Imports
 */
import { FC, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import { NeuDivider } from '@neutron/react';
import { NeuInputChangeEventDetail } from '@neutron/core';
/**
 * Internal Imports
 */
import { BlueChip } from '../../../shared/chips/blueChip';
import { useDebounceValue } from '../../../../utils/debouncers';

import { RootState } from '../../../../redux/store';
import {
  searchEmployees,
  clearEmployeeSearch
} from '../../../../redux/actions/Employee.action';
/**
 * Global Type Definition Imports
 */
import { Employee } from '../../../../config/interfaces';
/**
 * Style Imports
 */
import { ChipWrapper } from '../../feedbackModal/FeedbackModal.styles';
import {
  DropItem,
  DropDownSearchContainer,
  ModalInput,
  InputHeading
} from './EmployeeSearch.styles';

interface EmpSearchProps {
  chipVal?: string;
  searchedEmployees: [];
  showLabel?: boolean; // true by default
  // using type any here so that this can be re-used in multiple places by passing down whatever state object is being modified with a patient search
  val: any;
  handleRemoveEmployee: (data: string) => void;
  setEmployeeSelection: (employee: Employee) => void;
}

const EmployeeSearch: FC<EmpSearchProps> = ({
  chipVal,
  searchedEmployees,
  showLabel,
  val,
  setEmployeeSelection,
  handleRemoveEmployee
}) => {
  const dispatch = useDispatch();
  const [empSearchTerm, setEmpSearchTerm] = useState('');
  const [empPopState, setEmpPopState] = useState<boolean>(false);
  const [empSearchState, setEmpSearchState] = useState<boolean>(true);
  const [showLabelState] = useState<boolean>(showLabel !== false);

  const dbEmpSearchTerm = useDebounceValue(empSearchTerm, 250);

  // ending the employee search on blur so that it doesn't persist when re-opened
  const handleBlur = () => {
    setEmpSearchState(false);
    setEmpSearchTerm('');
    setEmpPopState(false);
    dispatch(clearEmployeeSearch());
  };

  useEffect(() => {
    handleBlur();
  }, []);

  // watching for debounced search terms before dispatching searches
  useEffect(() => {
    if (empSearchTerm.length > 2) {
      dispatch(searchEmployees(dbEmpSearchTerm));
    }
  }, [dbEmpSearchTerm]);

  // watching for search results, then opening the respective dropdowns
  useEffect(() => {
    if (empSearchState && searchedEmployees.length > 0) {
      setEmpPopState(true);
    }
  }, [searchedEmployees]);

  const handleSearching = (e: CustomEvent<NeuInputChangeEventDetail>) => {
    const target = e.target as HTMLInputElement;
    setEmpSearchState(true);
    setEmpSearchTerm(target.value);
  };

  const selectEmployee = (emp: Employee) => {
    // setting selection for the modal it is in
    setEmployeeSelection(emp);
    // handling local state
    handleBlur();
  };

  return (
    <div id="Modal-Employee-Search">
      {showLabelState ? <InputHeading>Employee</InputHeading> : null}
      {chipVal ? (
        <ChipWrapper onClick={() => handleRemoveEmployee('employee')}>
          <BlueChip name={chipVal} removable />
        </ChipWrapper>
      ) : (
        <div style={{ position: 'relative' }} onBlur={handleBlur}>
          <ModalInput
            id="Modal-Employee-Search-Input"
            type="search"
            name="employee"
            autocomplete="off"
            placeholder="Start typing name or 3-4 ID"
            inputmode="search"
            enterkeyhint="search"
            autocorrect="off"
            value={empSearchState ? empSearchTerm : val.employee}
            onNeuChange={empSearchState ? handleSearching : () => {}}
            onNeuFocus={() => setEmpSearchState(true)}
          />
          <DropDownSearchContainer
            id="Modal-Employee-Search-Dropdown"
            showPopover={empPopState}
          >
            {searchedEmployees.map((emp: Employee) => (
              <div key={emp.employeeId}>
                <DropItem onMouseDown={() => selectEmployee(emp)} button>
                  <p>
                    {emp.firstName.toUpperCase()} {emp.lastName.toUpperCase()} |{' '}
                    {emp.employeeId || 'Unknown'}
                  </p>
                  <p style={{ fontSize: '14px' }}>
                    {emp.facility || 'Unknown'}
                  </p>
                  <p style={{ fontSize: '14px' }}>{emp.title}</p>
                </DropItem>
                {searchedEmployees.length > 1 && <NeuDivider />}
              </div>
            ))}
          </DropDownSearchContainer>
        </div>
      )}
    </div>
  );
};

const mapReduxStateToProps = (state: RootState) => {
  return {
    searchedEmployees: state.EmployeeReducer.searchedEmployees
  };
};

export default connect(mapReduxStateToProps)(EmployeeSearch);
