import ACTIONS from './actionType';

interface FeatureType {
  isCSRNEnabled: boolean;
  isCNEdEnabled: boolean;
  isValidationEnabled: boolean;
}

export const setFeatures = (data: FeatureType) => {
  return { type: ACTIONS.FEATURE.SET_FEATURES, data };
};
