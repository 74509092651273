import { FC } from 'react';
import {
  BackgroundImage,
  ErrorContainer,
  FeatureNotEnabledContainer,
  H1,
  H4,
  Text
} from './ErrorPages.styles';
import ErrorPageImg from '../../assets/images/ErrorPageImg.png';

const FeatureNotEnabled: FC<{ offsetHeight: number }> = ({ offsetHeight }) => {
  return (
    <FeatureNotEnabledContainer offsetHeight={offsetHeight}>
      <BackgroundImage src={ErrorPageImg} alt="error page" />
      <ErrorContainer>
        <H1>Oops!</H1>
        <H4>This feature is under construction.</H4>
        <Text>
          Please verify your facility is provisioned and you have access via
          eSAF.
        </Text>
      </ErrorContainer>
    </FeatureNotEnabledContainer>
  );
};

export default FeatureNotEnabled;
