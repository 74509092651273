/**
 * External Imports
 */
import { useMemo, FC, Dispatch, SetStateAction } from 'react';
import { connect } from 'react-redux';
import { NeuAvatar, NeuContainer, NeuItem } from '@neutron/react';

import { RootState } from '../../../../redux/store';

interface IModalSubHeaderProps {
  items?: string | string[];
  completed: boolean;
  type: string;
  view?: string;
  modalType: string;
  setView?: Dispatch<SetStateAction<'info' | 'search'>>;
}

const StopSubHeader: FC<IModalSubHeaderProps> = ({
  items,
  completed,
  type,
  view,
  modalType,
  setView
}) => {
  const styles = useMemo(
    () => ({
      customSubheaderLabel: {
        flex: '1 0 50%',
        fontSize: '0.8rem',
        fontWeight: 700,
        textAlign: 'center'
      },
      blueColorBorderInfo: {
        borderBottom:
          view === 'info' ? '2px solid rgb(32, 117, 173, .75)' : 'none',
        color: view === 'info' ? '#2075AD' : '#6F6F6F'
      },
      blueColorBorderIssues: {
        borderBottom:
          view === 'search' ? '2px solid rgb(32, 117, 173, .75)' : 'none',
        color: view === 'search' ? '#2075AD' : '#6F6F6F'
      }
    }),
    [view]
  );

  return (
    <NeuContainer
      id="modal-sub-header"
      className={`flex-row justify-content-${
        modalType === 'Edit Issue' ? 'start align-items-center' : 'around'
      } px-0 custom-subheader-container`}
    >
      <>
        <NeuItem
          onClick={() => (setView ? setView('info') : {})}
          style={{
            ...styles.customSubheaderLabel,
            ...styles.blueColorBorderInfo
          }}
        >
          {type === 'stoplight' ? 'Stoplight' : 'Issue'} Information
        </NeuItem>
        <NeuItem
          onClick={() => (setView ? setView('search') : {})}
          style={{
            ...styles.customSubheaderLabel,
            ...styles.blueColorBorderIssues
          }}
        >
          {type === 'stoplight'
            ? items && (items.length > 0 || (completed && items.length === 0))
              ? 'Attached Issues'
              : 'Attach an Issue'
            : items || (completed && !items)
            ? 'Attached Stoplight'
            : 'Attach to Stoplight'}
          {((type === 'stoplight' && items && items.length > 0) ||
            (type === 'issue' && items)) && (
            <NeuAvatar
              className="d-inline-flex ml-2"
              color="primary"
              initials={String(Array.isArray(items) ? items.length : 1)}
            />
          )}
        </NeuItem>
      </>
    </NeuContainer>
  );
};

const mapReduxStateToProps = (state: RootState) => {
  return {
    modalType: state.ModalReducer.modalType
  };
};

export default connect(mapReduxStateToProps)(StopSubHeader);
