import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { CSVLink } from 'react-csv';

import { NeuButton, NeuIcon, NeuSpinner } from '@neutron/react';
import { useAnalyticsApi } from '@shared-web-analytics/react/dist';

import { RootState } from '../../../redux/store';
import { clearCsvToExport } from '../../../redux/actions/Admin.action';

import { logReportsClick } from '../../../utils/analyticsHelpers';
import { date as dt, unCamelCase } from '../../../utils/helpers';

import {
  QuestionList,
  RoundsReport,
  EmpFreqReport,
  EmpRoundsReport
} from '../../../config/interfaces';

interface ExportCSVProps {
  handleExportCsv?: () => void;
  dataToCsvExport?: any;
  isLoading?: boolean;
  fileNamePrefix: string;
}

const ExportCsvBtn: FC<ExportCSVProps> = ({
  handleExportCsv,
  dataToCsvExport,
  isLoading,
  fileNamePrefix
}) => {
  const { '*': view } = useParams();
  const dispatch = useDispatch();
  const [csvData, setCsvData] = useState<any>([]);
  const [csvHeaders, setCsvHeaders] = useState<
    { label: string; key: string }[]
  >([]);

  const [csvFileName, setCsvFileName] = useState<string>('');

  const csvLinkRef = useRef<
    (CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }) | null
  >(null);
  const firstUpdate: { current: boolean } = useRef(true); // to detect first render

  const { logTrackingEvent } = useAnalyticsApi();

  const handleClickExportCSV = useCallback(() => {
    switch (view) {
      case 'employee':
        logTrackingEvent(logReportsClick('employee-rounds', 'export'));
        break;
      case 'patient':
        logTrackingEvent(logReportsClick('patient-compliance', 'export'));
        break;
      case 'employee/rounds':
      case 'employee/frequency':
      case 'patient/compliance':
      case 'patient/rounds':
      case 'patient/census':
        logTrackingEvent(logReportsClick(view?.replace('/', '-'), 'export'));
        break;
      default:
        console.log('No view for event');
    }
    if (handleExportCsv) handleExportCsv();
  }, [view, handleExportCsv, logTrackingEvent, logReportsClick]);

  // clear the data on first render
  useEffect(() => {
    dispatch(clearCsvToExport());
  }, []);

  useEffect(() => {
    // to make this useEffect not to run on first render
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    if (dataToCsvExport?.length) {
      let cleanCsvData = dataToCsvExport;
      // TODO: Add emp rounds view in
      if (view === 'patient/rounds') {
        cleanCsvData = dataToCsvExport.map(
          ({ roundId, roundDate, ...rest }: RoundsReport) => rest
        );
      } else if (view === 'employee' || view === 'employee/rounds') {
        cleanCsvData = dataToCsvExport.map(
          ({
            roundId,
            createdUserId,
            date,
            facilityId,
            templateName,
            answers,
            ...rest
          }: EmpRoundsReport) => ({
            ...rest
          })
        );
      } else if (view === 'employee/frequency') {
        cleanCsvData = dataToCsvExport
          .reduce((acc: EmpFreqReport[], freq: EmpFreqReport) => {
            return [
              ...acc,
              ...freq.rounds.sort((a, b) =>
                a.date === b.date
                  ? 0
                  : !a.date
                  ? -1
                  : !b.date
                  ? 1
                  : a.date! > b.date!
                  ? -1
                  : 1
              )
            ];
          }, [])
          .map(({ createdUserId, date, ...rest }: EmpFreqReport) => ({
            roundDate:
              date && (date as string).length === 14
                ? dt.dayOfYr(date as string)
                : '',
            ...rest
          }));
      } else if (view === 'question-bank') {
        cleanCsvData = dataToCsvExport.map(
          ({ questionId, ...rest }: QuestionList) => rest
        );
      }

      // This code finds the object with the most columns & uses that to map the csv
      const keysArr = cleanCsvData.map((obj: string) => Object.keys(obj));
      const lengths = keysArr.map((a: string[]) => a.length);
      const index = lengths.indexOf(Math.max(...lengths));

      const headersList: string[] = Object.keys(cleanCsvData[index]);
      const headerList: { label: string; key: string }[] = headersList.map(
        (header: string) => {
          return { label: unCamelCase(header), key: header };
        }
      );
      setCsvHeaders(headerList);
      setCsvData(cleanCsvData);

      // get current date to make it to the file name
      const today = new Date();
      const timestamp = today.toISOString().slice(0, 16).replace(/T/g, '_');
      const fileName = `${fileNamePrefix}-${timestamp}`;
      setCsvFileName(fileName);
    }
  }, [dataToCsvExport]);

  useEffect(() => {
    if (csvData?.length && csvHeaders?.length) {
      csvLinkRef?.current?.link.click();
      dispatch(clearCsvToExport());
    }
  }, [csvData]);

  return (
    <div>
      <NeuButton
        id="Export-CSV-Button"
        className="w-100 mr-2"
        color="primary"
        fill="outline"
        onClick={handleClickExportCSV}
        disabled={isLoading}
      >
        {isLoading ? (
          <NeuSpinner
            style={{ marginRight: '10px' }}
            ariaLabel="default"
            width="18"
            height="18"
          />
        ) : (
          <NeuIcon className="mr-2" feedback="default">
            get_app
          </NeuIcon>
        )}
        Export CSV
      </NeuButton>
      <CSVLink
        ref={csvLinkRef}
        data={csvData}
        headers={csvHeaders}
        filename={csvFileName}
        style={{ display: 'none' }}
      >
        DOWNLOAD
      </CSVLink>
    </div>
  );
};

const mapReduxStateToProps = (state: RootState) => {
  return {
    dataToCsvExport: state.AdminReducer.dataToCsvExport,
    isLoading: state.AdminReducer.isLoadingCsvExport
  };
};
export default connect(mapReduxStateToProps)(ExportCsvBtn);
