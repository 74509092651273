import { FC, Dispatch, SetStateAction } from 'react';

import { NeuOption, NeuLabel } from '@neutron/react';

import { Dropdown, IDropdownProps } from '../../shared/dropdowns';

import { ITemplate } from '../../../config/interfaces';

interface RoundTemplateSelectProps extends IDropdownProps {
  options: ITemplate[];
  searchResults?: ITemplate[];
  selectedTemplate?: ITemplate;
  handleTemplateSelect: (template: ITemplate) => void;
  setActiveSearch: Dispatch<SetStateAction<string>>;
  setQuery: Dispatch<SetStateAction<string>>;
}

const RoundTemplateSelect: FC<RoundTemplateSelectProps> = ({
  focused,
  options = [],
  query,
  searchResults,
  selectedTemplate,
  handleTemplateSelect,
  setActiveSearch,
  setQuery,
  ...rest
}) => {
  return (
    <div className="d-flex flex-column">
      <div
        style={{
          width: 267,
          height: 22
        }}
      >
        <NeuLabel
          color="#191919"
          content="Template"
          position="fixed"
          for="default"
        />
      </div>
      <Dropdown
        classes="pl-0"
        width="96%"
        styles={{ paddingTop: '3px' }}
        focused={focused}
        query={query}
        {...rest}
      >
        {focused && searchResults && searchResults.length > 0 ? (
          [...searchResults].map((template: ITemplate) => {
            const selected = template.name === selectedTemplate?.name;
            return (
              <NeuOption
                className={`dropdown-hover-item${selected ? ' selected' : ''}`}
                key={template.templateConfigId}
                value={template.name}
                onClick={() => {
                  handleTemplateSelect(template);
                  setActiveSearch('');
                  setQuery('');
                }}
              >
                {template.name}
              </NeuOption>
            );
          })
        ) : searchResults && searchResults.length === 0 ? (
          <NeuOption className="dropdown-search-no-result">
            No results found!
          </NeuOption>
        ) : (
          [...options].map((template: ITemplate) => {
            const selected = template.name === selectedTemplate?.name;
            return (
              <NeuOption
                className={`dropdown-hover-item${selected ? ' selected' : ''}`}
                key={template.templateConfigId}
                value={template.name}
                onClick={() => handleTemplateSelect(template)}
              >
                {template.name}
              </NeuOption>
            );
          })
        )}
      </Dropdown>
    </div>
  );
};

export default RoundTemplateSelect;
