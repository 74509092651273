/**
 * External Imports
 */
import { NeuLabel } from '@neutron/react';
/**
 * Style Imports
 */
import styled from 'styled-components';

export const DateContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 97%;
`;

export const SelectContainer = styled.div`
  width: 48.5%;
`;

export const InputHeading = styled(NeuLabel)`
  display: inline-block;
  font-size: 0.875rem;
  line-height: 22px;
  width: 413px;
`;
