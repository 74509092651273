import { FC, useEffect, useState, Dispatch, SetStateAction } from 'react';
import { useLocation } from 'react-router-dom';

import {
  NeuContainer,
  NeuSubLeftNav,
  NeuItem,
  NeuStepper,
  NeuLabel
} from '@neutron/react';
import { useAnalyticsApi } from '@shared-web-analytics/react/dist';

import { ChartLegend, CircPieChart } from './metricsChart';
import { logListClick } from '../../utils/analyticsHelpers';

interface SideNavProps {
  completeCount: number;
  completePercent: number;
  todoCount: number;
  todoPercent: number;
  totalCount: number;
  unableCount?: number;
  newHires?: number;
  selectedTab: string;
  setSelectedTab: Dispatch<SetStateAction<string>>;
}

const SideNav: FC<SideNavProps> = ({
  completeCount,
  completePercent,
  todoCount,
  todoPercent,
  totalCount,
  newHires,
  unableCount,
  selectedTab,
  setSelectedTab
}) => {
  const [data, setData] = useState<number[]>([]);

  const { logTrackingEvent } = useAnalyticsApi();

  const changeTab = (tab: string) => {
    switch (tab) {
      case 'To Do':
        logTrackingEvent(logListClick('to-do'));
        break;
      case 'Completed':
        logTrackingEvent(logListClick('completed'));
        break;
      case 'New Hires':
        logTrackingEvent(logListClick('new-hires'));
        break;
      default:
        console.log('No event for tab filter');
    }
    setSelectedTab(tab);
  };

  const { pathname } = useLocation();

  useEffect(() => {
    if (unableCount !== undefined) {
      setData([completeCount, todoCount, unableCount || 0]);
    } else {
      setData([completeCount, todoCount]);
    }
  }, [completeCount, todoCount, unableCount]);

  return (
    <NeuSubLeftNav
      id="Side-Nav"
      name="MENU"
      color="plain"
      side="left"
      style={{ marginTop: '-3px', zIndex: 1 }}
    >
      <NeuItem
        id="Side-Nav-All-Label"
        color={selectedTab === 'All' ? 'primary-10' : 'plain-0'}
        className="pl-3"
        onClick={() => changeTab('All')}
      >
        <NeuStepper slot="start" />
        <NeuLabel>All ({totalCount})</NeuLabel>
      </NeuItem>
      <NeuItem
        id="Side-Nav-To-Do-Label"
        color={selectedTab === 'To Do' ? 'primary-10' : 'plain-0'}
        className="pl-3"
        onClick={() => changeTab('To Do')}
      >
        <NeuStepper slot="start" />
        <NeuLabel> To Do ({todoCount}) </NeuLabel>
      </NeuItem>
      <NeuItem
        id="Side-Nav-Completed-Label"
        color={selectedTab === 'Completed' ? 'primary-10' : 'plain-0'}
        className="pl-3"
        onClick={() => changeTab('Completed')}
      >
        <NeuStepper slot="start" />
        <NeuLabel>Completed ({completeCount})</NeuLabel>
      </NeuItem>
      {/* ******************************************* */}
      {/* ********** Removing till Phase II ********* */}
      {/* ******************************************* */}
      {/* {pathname.includes('/patients') && (
        <NeuItem
          id="Side-Nav-Unable-Label"
          color={selectedTab === 'Unable' ? 'primary-10' : 'plain-0'}
          class="pl-3"
          onClick={() => changeTab('Unable')}
        >
          <NeuStepper slot="start" />
          <NeuLabel>Unable to Round ({unableCount})</NeuLabel>
        </NeuItem>
      )} */}
      {pathname.includes('/employees') && (
        <NeuItem
          id="Side-Nav-New-Hire-Label"
          color={selectedTab === 'New Hires' ? 'primary-10' : 'plain-0'}
          class="pl-3"
          onClick={() => changeTab('New Hires')}
        >
          <NeuStepper slot="start" />
          <NeuLabel>New Hires ({newHires})</NeuLabel>
        </NeuItem>
      )}
      <NeuContainer class="px-0 mx-0">
        {/* dont draw the census metrics chart if no data */}
        {data.length > 0 && <CircPieChart data={data} />}
        {data.length > 0 && (
          <ChartLegend
            completeCount={completeCount}
            completePercent={completePercent}
            todoCount={todoCount}
            todoPercent={todoPercent}
          />
        )}
      </NeuContainer>
    </NeuSubLeftNav>
  );
};

export default SideNav;
