// react
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import { NeuButton, NeuIcon, NeuItem, NeuLabel, NeuList } from '@neutron/react';
// component
import SearchField from '../searchHeader/searchField';
// redux
import { openModal, setModalType } from '../../redux/actions/Modal.action';
import {
  editSelectedQuestion,
  getTemplateById
} from '../../redux/actions/Template.action';
// styling
import {
  EditTemplateView,
  HeaderText,
  QuestionBankQuestionCard,
  QuestionBankQuestionOverlayCard,
  QuestionBankView,
  QuestionCardHeader,
  QuestionNameLabel,
  QuestionNumberLabel,
  QuestionSideStrip,
  Tag,
  TagContainer,
  TemplateHeaderView,
  TemplateInfo,
  TemplateInfoContainer,
  TemplateQuestionCard,
  TemplateView,
  TitleLabel,
  UpDownStrip,
  UtilityBar
} from './EditMasterTemplate.styles';
import { useUserRolesRef } from '../../services/UserRoles/userRoles';

interface EditMasterTemplateProps {
  questionsOfTemplate?: Array<[]>;
  selectedTemplate?: any;
  allQuestions?: any;
  setEditTemplateFlag: Dispatch<SetStateAction<boolean>>;
}

const EditMasterTemplate: FC<EditMasterTemplateProps> = ({
  questionsOfTemplate = [],
  selectedTemplate,
  allQuestions,
  setEditTemplateFlag
}) => {
  const dispatch = useDispatch();
  const [selectedQuestion, setSelectedQuestion] = useState<any>();
  const [questionsOfTemplateState, setQuestionsOfTemplateState] = useState<any>(
    []
  );
  const [searchTerm, setSearchTerm] = useState<string>('');

  const [questionBankFilteredQuestions, setQuestionBankFilteredQuestions] =
    useState<any>([]);
  const [questionBankQuestionsToDisplay, setQuestionBankQuestionsToDisplay] =
    useState<any>([]);

  const { isEnterpriseAdmin } = useUserRolesRef();

  useEffect(() => {
    if (questionsOfTemplate) {
      setQuestionsOfTemplateState(questionsOfTemplate);
    }
  }, [questionsOfTemplate]);
  useEffect(() => {
    if (selectedTemplate && allQuestions) {
      const filteredArray = allQuestions.filter(
        (value: any) =>
          !questionsOfTemplateState.includes(value) &&
          value.roundingType === selectedTemplate.roundingType
      );
      setQuestionBankFilteredQuestions(filteredArray);
    }
  }, [selectedTemplate, allQuestions, questionsOfTemplateState]);

  useEffect(() => {
    const q = new RegExp(searchTerm.trim().replace(/\\/g, ''), 'i');
    const filtered = questionBankFilteredQuestions.filter((question: any) =>
      `${question.questionTitle}`.match(q)
    );
    setQuestionBankQuestionsToDisplay(filtered);
  }, [searchTerm, questionBankFilteredQuestions]);

  const handleOnFocus = (question: any) => {
    setSelectedQuestion(question);
  };

  const moveItem = (from: any, to: any) => {
    // remove `from` item and store it
    const tempQuestionsOfTemplate = questionsOfTemplateState;
    const f = tempQuestionsOfTemplate.splice(from, 1)[0];
    // insert stored item into position `to`
    tempQuestionsOfTemplate.splice(to, 0, f);
    setQuestionsOfTemplateState([...tempQuestionsOfTemplate]);
  };
  const moveUpDown = (selectedQuestionObject: any, updown: string) => {
    questionsOfTemplateState.every((question: any) => {
      if (question === selectedQuestionObject) {
        let to = null;
        if (updown === 'up') {
          if (questionsOfTemplateState.indexOf(question) !== 0) {
            to = questionsOfTemplateState.indexOf(question) - 1;
          }
        } else if (
          questionsOfTemplateState.length - 1 !==
          questionsOfTemplateState.indexOf(question)
        ) {
          to = questionsOfTemplateState.indexOf(question) + 1;
        }
        if (to !== null) {
          moveItem(questionsOfTemplateState.indexOf(question), to);
          return false;
        }
      }
      return question;
    });
  };

  const handleRemoveQuestion = (question: any) => {
    setQuestionsOfTemplateState(
      questionsOfTemplateState.filter((item: any) => item !== question)
    );
  };

  const handleAddQuestionTotemplate = (question: any) => {
    const tempQuestionsOfTemplateState = questionsOfTemplateState;
    tempQuestionsOfTemplateState.unshift(question);
    setQuestionsOfTemplateState([...tempQuestionsOfTemplateState]);
  };

  const handlePostTemplate = (archivedFlag: any) => {
    const { archived } = archivedFlag;
    const questions: any[] = [];
    const conditions: any[] = [];
    questionsOfTemplateState.forEach((question: any) => {
      questions.push({
        id: question.questionId,
        templateLevel: selectedTemplate.templateLevel,
        hidden: null
      });
      conditions.push({ ask: question.questionId, flags: [] });
    });

    if (archived) {
      if (isEnterpriseAdmin) {
        dispatch(
          getTemplateById({
            templateId: selectedTemplate.templateId,
            updateTemplateConfigFlag: false,
            updateMasterTemplateFlag: true,
            questions,
            isPublished: true,
            conditions,
            archived
          })
        );
      } else console.log('Access denied !!');
    } else {
      dispatch(
        getTemplateById({
          templateId: selectedTemplate.templateId,
          updateTemplateConfigFlag: selectedTemplate.templateLevel !== 'master',
          updateMasterTemplateFlag: selectedTemplate.templateLevel === 'master',
          templateLevel: selectedTemplate.templateLevel,
          templateLevelId: selectedTemplate.templateLevelId,
          isPublished: true,
          questions,
          conditions,
          archived
        })
      );
      setEditTemplateFlag(false);
    }
  };

  const handleCancel = () => {
    setEditTemplateFlag(false);
    // appInsights.trackEvent({
    //   name: 'Master_Template_Canceled'
    // });
  };

  const editQuestionOnClick = (id?: string) => {
    if (id) dispatch(editSelectedQuestion(id));
    dispatch(setModalType('AddQuestion'));
    dispatch(openModal());
  };

  const sortTags = (inputArray: string[]) => {
    return [...inputArray].sort((a, b) => {
      // const numA = Number(a);
      // const numB = Number(b);

      if (!Number.isNaN(+a) && !Number.isNaN(+b)) {
        return +a - +b;
      }

      if (!Number.isNaN(+a)) {
        return -1;
      }

      if (!Number.isNaN(+b)) {
        return 1;
      }

      return a.toLowerCase().localeCompare(b.toLowerCase());
    });
  };

  return (
    <EditTemplateView>
      <div>
        <TemplateView>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginBottom: 10
            }}
          >
            <TemplateHeaderView>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between'
                }}
              >
                <HeaderText>Template</HeaderText>
                <QuestionNumberLabel>
                  {questionsOfTemplateState?.length} Questions
                </QuestionNumberLabel>
              </div>
              <TemplateInfoContainer>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    justifyContent: 'space-between'
                  }}
                >
                  <TitleLabel>
                    Master Template
                    <TemplateInfo>{selectedTemplate?.name}</TemplateInfo>
                  </TitleLabel>
                  <TitleLabel style={{ marginLeft: 15 }}>
                    Level
                    <TemplateInfo>
                      {selectedTemplate.templateLevel}
                    </TemplateInfo>
                  </TitleLabel>
                  <TitleLabel style={{ marginLeft: 15 }}>
                    Level ID
                    <TemplateInfo>
                      {selectedTemplate.templateLevelId}
                    </TemplateInfo>
                  </TitleLabel>
                </div>
              </TemplateInfoContainer>
            </TemplateHeaderView>
          </div>
          <div style={{ maxHeight: `calc(100vh - 450px)`, overflow: 'scroll' }}>
            <NeuList
              data-testid="template-questions"
              color="white"
              lines="none"
            >
              {questionsOfTemplateState.length > 0 &&
                questionsOfTemplateState?.map((question: any) => {
                  return (
                    <NeuItem key={question?.questionId}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          height: 'auto'
                        }}
                      >
                        <UpDownStrip>
                          <NeuIcon
                            color="gray-30"
                            small
                            style={{ marginTop: 10, marginRight: 3 }}
                            onClick={() => moveUpDown(question, 'up')}
                          >
                            arrow_upward
                          </NeuIcon>
                          <NeuIcon
                            color="gray-30"
                            small
                            style={{ marginRight: 3 }}
                          >
                            drag_indicator
                          </NeuIcon>
                          <NeuIcon
                            color="gray-30"
                            small
                            style={{ marginRight: 3 }}
                            onClick={() => moveUpDown(question, 'down')}
                          >
                            arrow_downward
                          </NeuIcon>
                        </UpDownStrip>
                        <TemplateQuestionCard>
                          <QuestionCardHeader>
                            <div
                              style={{ display: 'flex', flexDirection: 'row' }}
                            >
                              <NeuLabel
                                color="blue-80"
                                style={{
                                  fontWeight: 700,
                                  fontSize: 14,
                                  marginTop: 0.5,
                                  marginRight: 10
                                }}
                              >
                                {questionsOfTemplateState.indexOf(question) + 1}
                              </NeuLabel>
                              <QuestionNameLabel color="blue-60" aria-multiline>
                                {question?.questionTitle}
                              </QuestionNameLabel>
                              {(question.questionTags?.includes('LOCKED') &&
                                isEnterpriseAdmin) ||
                              !question.questionTags?.includes('LOCKED') ? (
                                <NeuButton
                                  color="primary"
                                  fill="flat"
                                  onClick={() => handleRemoveQuestion(question)}
                                  style={{
                                    marginLeft: 326,
                                    marginTop: -15,
                                    position: 'absolute'
                                  }}
                                >
                                  <NeuIcon small style={{ marginRight: 3 }}>
                                    cancel
                                  </NeuIcon>
                                  Remove
                                </NeuButton>
                              ) : (
                                ''
                              )}
                            </div>
                          </QuestionCardHeader>
                          <NeuList color="white" lines="none">
                            {question?.questionParts?.map(
                              (questionPart: any) => {
                                return (
                                  <NeuItem
                                    key={questionPart.questionPartId}
                                    style={{
                                      backgroundColor: 'white',
                                      fontWeight: 500,
                                      fontSize: 12,
                                      lineHeight: '20px',
                                      color: '#4B4B4B'
                                    }}
                                  >
                                    {questionPart.question}
                                  </NeuItem>
                                );
                              }
                            )}
                          </NeuList>
                          <TagContainer>
                            {sortTags(question?.questionTags).map(
                              (tag: string) => (
                                <Tag key={tag}>{`${tag}`}</Tag>
                              )
                            )}
                          </TagContainer>
                        </TemplateQuestionCard>
                      </div>
                    </NeuItem>
                  );
                })}
            </NeuList>
          </div>
        </TemplateView>
        <UtilityBar>
          {isEnterpriseAdmin && (
            <NeuButton
              color="plain-0"
              fill="flat"
              onClick={() => handlePostTemplate({ archived: true })}
              style={{
                marginTop: 10,
                position: 'absolute'
              }}
            >
              <NeuIcon color="plain-0">delete</NeuIcon>
              Remove
            </NeuButton>
          )}
          <NeuButton
            color="plain-0"
            fill="flat"
            onClick={() => handleCancel()}
            style={{
              marginLeft: 246,
              marginTop: 10,
              position: 'absolute'
            }}
          >
            Cancel
          </NeuButton>
          <NeuButton
            color="primary"
            fill="raised"
            onClick={() => handlePostTemplate({ archived: false })}
            style={{
              marginLeft: 340,
              marginTop: 10,
              position: 'absolute'
            }}
          >
            Update
          </NeuButton>
        </UtilityBar>
      </div>
      <QuestionBankView>
        <div
          style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}
        >
          <HeaderText>Question Bank</HeaderText>
          <div
            style={{
              display: 'flex',
              height: '24px',
              marginLeft: 220,
              marginTop: 10
            }}
          >
            {isEnterpriseAdmin && (
              <NeuButton size="small" onClick={() => editQuestionOnClick()}>
                <NeuIcon small>add</NeuIcon>
                Add Question
              </NeuButton>
            )}
          </div>
        </div>
        <SearchField searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
        <NeuList
          data-testid="bank-questions"
          color="white"
          lines="none"
          style={{
            maxHeight: '100%',
            overflowY: 'auto',
            marginTop: 10,
            overflowX: 'hidden'
          }}
        >
          {questionBankQuestionsToDisplay.length > 0 &&
            questionBankQuestionsToDisplay.map((question: any) => {
              return (
                <NeuItem
                  key={question?.questionId}
                  onMouseEnter={() => handleOnFocus(question)}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      height: 'auto',
                      minHeight: 101
                    }}
                  >
                    <QuestionSideStrip>
                      <NeuIcon
                        color="gray-30"
                        small
                        style={{ marginRight: 3, marginTop: 35 }}
                      >
                        drag_indicator
                      </NeuIcon>
                    </QuestionSideStrip>
                    <div style={{ minHeight: 101, height: 'auto' }}>
                      <QuestionBankQuestionCard style={{ minHeight: 101 }}>
                        {selectedQuestion === question && (
                          <QuestionBankQuestionOverlayCard
                            style={{
                              alignItems: 'center',
                              justifyContent: 'space-around',
                              backgroundColor: 'rgba(173,216,230,0.90)'
                            }}
                          >
                            <NeuButton
                              color="primary"
                              fill="flat"
                              onClick={() =>
                                handleAddQuestionTotemplate(question)
                              }
                            >
                              Add Question to Template
                            </NeuButton>
                            {isEnterpriseAdmin && (
                              <NeuButton
                                color="primary"
                                fill="flat"
                                onClick={() =>
                                  editQuestionOnClick(question.questionId)
                                }
                              >
                                Edit Question
                              </NeuButton>
                            )}
                          </QuestionBankQuestionOverlayCard>
                        )}
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                        >
                          <QuestionNameLabel
                            color="blue-60"
                            style={{ padding: 16 }}
                          >
                            {question?.questionTitle}
                          </QuestionNameLabel>
                          <NeuList color="white" lines="none">
                            {question?.questionParts?.map(
                              (questionPart: any) => (
                                <NeuItem
                                  key={questionPart.questionPartId}
                                  style={{
                                    overflow: 'hidden !important',
                                    backgroundColor: 'white',
                                    fontWeight: 500,
                                    fontSize: 12,
                                    lineHeight: '20px',
                                    color: '#4B4B4B',
                                    paddingBottom: 10
                                  }}
                                >
                                  {questionPart.question}
                                </NeuItem>
                              )
                            )}
                          </NeuList>
                        </div>
                        <TagContainer>
                          {sortTags(question?.questionTags).map(
                            (tag: string) => (
                              <Tag key={tag}>{`${tag}`}</Tag>
                            )
                          )}
                        </TagContainer>
                      </QuestionBankQuestionCard>
                    </div>
                  </div>
                </NeuItem>
              );
            })}
        </NeuList>
      </QuestionBankView>
    </EditTemplateView>
  );
};

const mapReduxStateToProps = (state: any) => {
  return {
    selectedTemplate: state.TemplateReducer.selectedTemplate,
    questionsOfTemplate: state.TemplateReducer.questionsOfTemplate,
    allQuestions: state.TemplateReducer.allQuestions
  };
};
export default connect(mapReduxStateToProps)(EditMasterTemplate);
