import { RefObject } from 'react';
import { closeModal } from '../../redux/actions/Modal.action';
import {
  updateUnlistedEmployeeModalFlag,
  updateUnlistedPatientModalFlag
} from '../../redux/actions/User.action';
import store from '../../redux/store';

export const handleModal = (ref: RefObject<HTMLElement | null>) => {
  ref?.current?.classList.add('hide-modal');
  setTimeout(() => store.dispatch(closeModal()), 300);
};

export const handleUnlistedModal = (ref: RefObject<HTMLElement | null>) => {
  const modalName = ref?.current?.id;
  ref?.current?.classList.add('hide-modal');
  setTimeout(() => {
    if (modalName === 'Unlisted-Patient-Modal') {
      store.dispatch(updateUnlistedPatientModalFlag(false));
    } else {
      store.dispatch(updateUnlistedEmployeeModalFlag(false));
    }
  }, 300);
};
