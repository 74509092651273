import { FC } from 'react';
import {
  NeuAvatar,
  NeuButton,
  NeuContainer,
  NeuIcon,
  NeuLabel
} from '@neutron/react';

interface IConfirmToastProps {
  msg: string;
  warning?: string | null;
  confirmText: string;
  closeText: string;
  confirm: () => void;
  handleClose: () => void;
}

const ConfirmToast: FC<IConfirmToastProps> = ({
  msg,
  warning,
  confirmText,
  closeText,
  confirm,
  handleClose
}) => (
  <>
    <NeuLabel
      className="w-100 mb-2 toast-small-text"
      color="primary"
      slot="title"
    >
      <NeuAvatar className="mb-3 mx-auto" color="primary">
        <NeuIcon>info</NeuIcon>
      </NeuAvatar>
      {msg}
    </NeuLabel>
    <NeuLabel
      className="w-100 mt-2 toast-small-text"
      color="plain-100"
      slot="subtitle"
    >
      {warning}
    </NeuLabel>
    <NeuContainer className="flex-row justify-content-between" slot="action">
      <NeuButton
        color="secondary"
        size="small"
        fill="raised"
        onClick={() => confirm()}
      >
        {confirmText}
      </NeuButton>
      <NeuButton
        color={undefined}
        size="small"
        fill="raised"
        onClick={() => handleClose()}
      >
        {closeText}
      </NeuButton>
    </NeuContainer>
  </>
);
export default ConfirmToast;
