/**
 * External Imports
 */
import { FC, useState, useEffect, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import { NeuIcon, NeuButton, NeuRadio, NeuRadioGroup } from '@neutron/react';
/**
 * Internal Imports
 */
import UserInfo from './UserInfo';

import { RootState } from '../../../redux/store';
/**
 * Global Type Definition Imports
 */
import { AuthUserProps, IFacility } from '../../../config/interfaces';
/**
 * Style Imports
 */
import {
  getUserRoles,
  getFacilities,
  getDivisions,
  postUserAccess,
  postUserAccessRevoke
} from '../../../redux/actions/User.action';
import MultiSelectDropdown from '../../MultiSelect/MultiSelectDropdown';
import Loading from '../../shared/Loading';
import { handleModal } from '../../../services/ModalPortal/utils';
/**
 * Style Imports
 */
import {
  ProvisioningCard,
  CardHeader,
  ModalCardContent,
  InputHeading,
  RadioContainer,
  RadioLabel,
  ModalFooter,
  ModalTitle
} from './UserModal.styles';

interface ProvisioningModalProps {
  userRoles: [];
  facilities: [];
  divisions: [];
  loadingUserRoles: boolean;
  loadingFacilities: boolean;
  loadingDivisions: boolean;
  selectedUser: AuthUserProps;
}
const AddProvisioningModal: FC<ProvisioningModalProps> = ({
  userRoles = [],
  facilities = [],
  divisions = [],
  loadingUserRoles = false,
  loadingFacilities = false,
  loadingDivisions = false,
  selectedUser
}) => {
  /** State variables */
  const dispatch = useDispatch();
  const [showAccessLevel, setShowAccessLevel] = useState<boolean>(true);
  const [accessLevelList, setAccessLevelList] = useState([]);
  const [defaultSelection, setDefaultSelection] = useState([]);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [selectedAccessLevel, setSelectedAccessLevel] = useState<string>('');
  const [userRoleList, setUserRoleList] = useState([]);
  const [selectedUserRole, setSelectedUserRole] = useState<string[]>([]);
  const [defaultUserRoles, setDefaultUserRoles] = useState([]);
  const [showButton, setShowButton] = useState<boolean>(false);
  const modalContentRef = useRef<HTMLNeuCardElement>(null);

  /** Data fetching */
  useEffect(() => {
    dispatch(getUserRoles());
    dispatch(getFacilities());
    dispatch(getDivisions());
  }, []);

  const showSubmitButton = () => {
    if (selectedUserRole.length > 0 && selectedAccessLevel) {
      if (selectedAccessLevel === 'master') {
        return true;
      }
      if (selectedAccessLevel !== 'master' && selectedItems.length > 0) {
        return true;
      }
      return false;
    }
    return false;
  };

  const onAccessLevelChange = (selectedLevel: string) => {
    setShowAccessLevel(true);
    if (selectedLevel === 'division') {
      if (selectedUser) {
        // Filtered list
        const filteredDivisions = divisions.filter((value: IFacility) => {
          const optionID = value.id;
          return !selectedUser?.access?.accessLevelIds.some(
            (item: String) => item === optionID
          );
        });
        setAccessLevelList(filteredDivisions);
        // Selected list
        const selectedDivisions = divisions.filter((value: IFacility) => {
          const optionID = value.id;
          return selectedUser?.access?.accessLevelIds.some(
            (item: String) => item === optionID
          );
        });
        setDefaultSelection(selectedDivisions);
      } else {
        setAccessLevelList(divisions);
      }
    } else if (selectedLevel === 'facility') {
      if (selectedUser) {
        // Filtered list
        const filteredFacilities = facilities.filter((value: IFacility) => {
          const optionID = value.id;
          return !selectedUser?.access?.accessLevelIds.some(
            (item: String) => item === optionID
          );
        });
        setAccessLevelList(filteredFacilities);
        // Selected list
        const selectedFacilities = facilities.filter((value: IFacility) => {
          const optionID = value.id;
          return selectedUser?.access?.accessLevelIds.some(
            (item: String) => item === optionID
          );
        });
        setDefaultSelection(selectedFacilities);
      } else {
        setAccessLevelList(facilities);
      }
    } else {
      setShowAccessLevel(false);
    }
    // Reset selected items when facility/division change
    if (selectedItems && selectedItems.length > 0) {
      setDefaultSelection([]);
    }
    setSelectedAccessLevel(selectedLevel);
  };

  useEffect(() => {
    if (showSubmitButton()) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  }, [selectedUserRole, selectedAccessLevel, selectedItems]);

  useEffect(() => {
    if (selectedUser) {
      // Access level radio
      setSelectedAccessLevel(selectedUser?.access?.accessLevel);
      onAccessLevelChange(selectedUser?.access?.accessLevel);
      // Set user role
      if (selectedUser.roles && selectedUser.roles.length > 0) {
        const defaultRoles = userRoles.filter(
          (item: { id: string; value: string }) =>
            selectedUser.roles.includes(item?.id)
        );
        const availableRoles = userRoles.filter(
          (item: { id: string; value: string }) =>
            !selectedUser.roles.includes(item?.id)
        );
        setUserRoleList(availableRoles);
        setDefaultUserRoles(defaultRoles);
      }
    }
  }, [selectedUser, facilities, divisions, userRoles]);

  /** CTA block */
  const handleSelected = (selected: IFacility[]) => {
    const selectedIdArray =
      selected &&
      selected.length > 0 &&
      selected.map((item: IFacility) => item.id);
    if (selectedIdArray) setSelectedItems(selectedIdArray);
  };

  const handleSelectedUserRoles = (
    selected: { id: string; value: string }[]
  ) => {
    const selectedRoles =
      selected &&
      selected.length > 0 &&
      selected.map((item: { id: string; value: string }) => item.id);
    if (selectedRoles) setSelectedUserRole(selectedRoles);
    // appInsights.trackEvent({
    //   name: 'Config_Edited'
    // });
  };

  const handleRevoke = () => {
    dispatch(postUserAccessRevoke({ userId: selectedUser.hcaid }));
    setSelectedAccessLevel('');
    setDefaultSelection([]);
    setSelectedUserRole([]);
    setDefaultUserRoles([]);
    setTimeout(() => {
      handleModal(modalContentRef);
    }, 500);
    // appInsights.trackEvent({
    //   name: 'Config_Deleted'
    // });
  };
  const handleSubmit = () => {
    if (showButton) {
      // appInsights.trackEvent({
      //   name: 'Config_Created'
      // });
      const postParams = {
        employeeId: selectedUser.hcaid,
        access: {
          accessLevel: selectedAccessLevel,
          accessLevelIds:
            selectedAccessLevel === 'master' ? ['HCA'] : selectedItems
        },
        roles: selectedUserRole
      };
      dispatch(postUserAccess(postParams));
      setShowButton(false);
      setTimeout(() => {
        handleModal(modalContentRef);
      }, 500);
    }
  };
  /** CTA block - End */
  return (
    <ProvisioningCard
      className="show-modal"
      id="Modal-Provision"
      type="submit"
      maxWidth="413px"
      minHeight="100vh"
      ref={modalContentRef}
    >
      <CardHeader>
        <ModalTitle>Edit User</ModalTitle>
        <NeuButton fill="flat" onClick={() => handleModal(modalContentRef)}>
          <NeuIcon large color="primary">
            close
          </NeuIcon>
          Close
        </NeuButton>
      </CardHeader>
      {loadingUserRoles || loadingFacilities || loadingDivisions ? (
        <ModalCardContent>
          <Loading />
        </ModalCardContent>
      ) : (
        <>
          <ModalCardContent>
            <UserInfo userDetails={selectedUser} />
            <InputHeading>User Role</InputHeading>
            <MultiSelectDropdown
              options={userRoleList}
              defaultselection={defaultUserRoles}
              selected={handleSelectedUserRoles}
              id="Modal-Provision-Select-User-Role"
              showArrow={false}
              searchable={false}
              chipsInside={false}
            />

            <InputHeading>Access Level</InputHeading>
            <NeuRadioGroup
              onNeuChange={e => onAccessLevelChange(e.detail.value)}
              name="accesLevel"
              value={selectedAccessLevel || selectedUser?.access?.accessLevel}
            >
              <RadioContainer>
                <NeuRadio value="facility">Facility</NeuRadio>
                <RadioLabel>Facility</RadioLabel>
                <NeuRadio value="division">Division</NeuRadio>
                <RadioLabel>Division</RadioLabel>
                <NeuRadio value="master">Corporate</NeuRadio>
                <RadioLabel>Corporate</RadioLabel>
              </RadioContainer>
            </NeuRadioGroup>

            {showAccessLevel && accessLevelList && (
              <>
                <InputHeading>
                  {selectedAccessLevel.charAt(0).toUpperCase() +
                    selectedAccessLevel.slice(1)}
                </InputHeading>
                <MultiSelectDropdown
                  options={accessLevelList}
                  defaultselection={defaultSelection}
                  selected={handleSelected}
                  id="facility-division-select"
                  showArrow={false}
                  searchable={false}
                  chipsInside={false}
                />
              </>
            )}
          </ModalCardContent>
          <ModalFooter small>
            <NeuButton color="danger" onClick={handleRevoke}>
              Revoke Access
            </NeuButton>
            <NeuButton
              color="primary"
              style={{ float: 'right' }}
              onClick={handleSubmit}
              disabled={!showButton}
            >
              Submit
            </NeuButton>
          </ModalFooter>
        </>
      )}
    </ProvisioningCard>
  );
};

const mapReduxStateToProps = (state: RootState) => {
  return {
    userRoles: state.UserReducer?.userRoles,
    facilities: state.UserReducer?.facilities,
    divisions: state.UserReducer?.divisions,
    loadingUserRoles: state.UserReducer?.loadingUserRoles,
    loadingFacilities: state.UserReducer?.loadingFacilities,
    loadingDivisions: state.UserReducer?.loadingDivisions,
    modalState: state.ModalReducer.modal,
    selectedUser: state.ModalReducer.modalData
  };
};

export default connect(mapReduxStateToProps)(AddProvisioningModal);
