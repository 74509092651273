import { ChangeEventHandler, FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { BlueChip } from '../shared/chips/blueChip';
import '../../styles/MultiSelectDropdown.scss';

const Flex = styled.div`
  display: flex;
  flex-wrap: no-wrap;
  flex-direction: row;
`;
const ChipWrapper = styled.span``;

/** Child component - in order to maintain state transitions in place */
interface IMultiSelectDropdownWithChip {
  options: {}[];
  selections: {}[];
  id: string;
  showArrow: boolean;
  searchTerm: string;
  searchable: boolean;
  chipsInside: boolean;
  placeholderText?: string;
  updateDropdownList: Function;
  selectedListItem: Function;
  removedListItem: EventListener;
  onSearchChange: ChangeEventHandler;
}
export const MultiSelectDropdownWithChip: FC<IMultiSelectDropdownWithChip> = ({
  options = [],
  selections = [],
  id = '',
  showArrow = false,
  searchTerm = '',
  searchable = false,
  chipsInside = true,
  placeholderText = '',
  updateDropdownList = () => null,
  selectedListItem = () => null,
  removedListItem = () => null,
  onSearchChange = () => null
}) => {
  const [showHide, setShowHide] = useState(false);
  const [optionsListTop, setOptionsListTop] = useState(50);
  const mainRef = useRef<HTMLDivElement>(null);
  const childRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (e: any) => {
    if (mainRef.current && !mainRef.current.contains(e.target)) {
      setShowHide(false);
    }
  };
  const handleClickInside = () => setShowHide(true);
  const handleShowHide = () => setShowHide(!showHide);

  useEffect(() => {
    let top = 50;
    if (mainRef.current) {
      const { height } = mainRef.current.getBoundingClientRect();
      top = height;
    }
    setOptionsListTop(top + 5);
  }, [selections]);

  useEffect(() => {
    window.addEventListener('mousedown', handleClickOutside);
    return () => window.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div id="Mulitselect-Dropdown">
      {!chipsInside &&
        selections.map((p: any) => {
          return (
            <ChipWrapper
              key={p.id}
              onClick={() => {
                updateDropdownList([
                  ...selections.filter((someOption: any) => {
                    return p.id !== someOption.id;
                  })
                ]);
                removedListItem(p);
              }}
            >
              <BlueChip key={p.id} name={p.value} icon=" " />
            </ChipWrapper>
          );
        })}
      <Flex id="multiselectParent" className="multiselectParent" ref={mainRef}>
        <Flex
          className="multiselectChild"
          id={`${id}-MultiselectChild`}
          ref={childRef}
          onClick={handleClickInside}
        >
          <>
            {chipsInside &&
              selections.map((p: any) => {
                return (
                  <Flex key={`${p.value}${p.id}`} className="selectedItems">
                    {p.value}
                    <i
                      className="material-icons closeIcon"
                      onClick={() => {
                        updateDropdownList([
                          ...selections.filter((someOption: any) => {
                            return p.id !== someOption.id;
                          })
                        ]);
                        removedListItem(p);
                      }}
                      aria-hidden="true"
                    >
                      remove_circle_outline
                    </i>
                  </Flex>
                );
              })}
            {searchable && (
              <input
                type="text"
                style={{ border: 'none', width: '100%' }}
                onChange={onSearchChange}
                value={searchTerm}
              />
            )}
            {!chipsInside && !searchable && placeholderText && (
              <span>{placeholderText}</span>
            )}
          </>
        </Flex>
        {showHide && options.length > 0 && (
          <div style={{ top: optionsListTop }} className="optionsList">
            {options &&
              options.map((option: any) => {
                return (
                  <Flex
                    key={`${option.value}${option.id}`}
                    className="optionsItem"
                    onClick={e => {
                      e.stopPropagation();
                      if (
                        selections.some(
                          (selection: any) => selection.id === option.id
                        )
                      ) {
                        return true;
                      }
                      updateDropdownList([...selections, option]);
                      selectedListItem(option);
                      return true;
                    }}
                  >
                    {option.value}
                  </Flex>
                );
              })}
          </div>
        )}

        {showArrow && (
          <Flex className="iconArrow" onClick={handleShowHide}>
            <i className="material-icons" id={`${id}:multiSelectCaret`}>
              {!showHide || options.length === 0
                ? 'arrow_drop_down'
                : 'arrow_drop_up'}
            </i>
          </Flex>
        )}
      </Flex>
    </div>
  );
};

/** Parent component */
interface IMultiSelectDropdown {
  options: {}[];
  defaultselection: {}[];
  id: string;
  showArrow: boolean;
  searchable?: boolean;
  chipsInside?: boolean;
  selected: Function;
  placeholderText?: string;
}

const MultiSelectDropdown: FC<IMultiSelectDropdown> = ({
  options = [],
  defaultselection = [],
  id = '',
  showArrow = false,
  selected = () => null,
  searchable = false,
  chipsInside = true,
  placeholderText = ''
}) => {
  const [accessLevelList, setAccessLevelList] = useState<any>([]);
  const [selectedItems, setSelectedItems] = useState<any>([]);
  const [searchItem, setSearchItem] = useState<string>('');
  const [rendered, setRendered] = useState(false);

  useEffect(() => {
    setRendered(true);
  }, []);

  useEffect(() => {
    setAccessLevelList(options);
  }, [options]);

  useEffect(() => {
    setSelectedItems(defaultselection);
    if (rendered) selected(defaultselection);
    if (defaultselection.length > 0) {
      const filteredList = options.filter((value: any) => {
        return !defaultselection.some((item: any) => item.id === value.id);
      });

      setAccessLevelList(filteredList);
    }
  }, [defaultselection]);

  useEffect(() => {
    if (searchable && !searchItem) {
      const resetAccessLevelList = options.filter((value: any) => {
        const optionID = value.id;
        return !selectedItems.some((item: any) => item.id === optionID);
      });

      setAccessLevelList(resetAccessLevelList);
    }
  }, [searchItem]);

  const handleUpdate = (data: {}[]) => {
    setSelectedItems(data);
    selected(data);
  };

  const handleSelect = (data: { id: any }) => {
    const filtered = accessLevelList.filter(
      (option: { id: any }) => data.id !== option.id
    );

    setAccessLevelList(filtered);
    setSearchItem('');
  };

  const handleRemove = (data: any) => {
    setAccessLevelList([data, ...accessLevelList]);
  };

  const onInputChange = (event: any) => {
    if (searchable) {
      const searchTerm = event.target.value
        ? event.target.value.trim().toLowerCase()
        : '';
      setSearchItem(searchTerm);
      const resetAccessLevelList = options.filter((option: any) => {
        const optionID = option.id;
        return !selectedItems.some(
          (selection: any) => selection.id === optionID
        );
      });
      const filtered = resetAccessLevelList.filter((item: any) =>
        item.value.toLowerCase().includes(searchTerm)
      );

      setAccessLevelList(filtered);
    }
  };

  return (
    <MultiSelectDropdownWithChip
      options={accessLevelList}
      selections={selectedItems.sort(
        (
          a: { id: string; value: string; division?: string },
          b: { id: string; value: string; division?: string }
        ) => (a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1)
      )}
      id={id || ''}
      showArrow={showArrow}
      searchTerm={searchItem}
      searchable={searchable}
      chipsInside={chipsInside}
      placeholderText={placeholderText || ''}
      updateDropdownList={handleUpdate}
      selectedListItem={handleSelect}
      removedListItem={handleRemove}
      onSearchChange={onInputChange}
    />
  );
};

export default MultiSelectDropdown;
