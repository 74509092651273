import styled from 'styled-components';
import { NeuIcon, NeuLabel } from '@neutron/react';

export const StyledBoldMoreLabel = styled(NeuLabel)`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 22px;
  font-weight: bold;
  padding-left: 0;
  margin-right: 2px;
`;

export const StyledPlusIcon = styled(NeuIcon)`
  margin-left: -0.2rem;
  margin-top: 1px;
  width: 0.8rem;
  height: 0.8rem;
  font-size: 0.8rem;
  font-weight: bold;
`;

export const StyledSpan = styled('span')`
  font-size: 0.8rem;
  display: flex;
  align-items: center;
`;
