/**
 * External Imports
 */
import {
  Dispatch,
  RefObject,
  SetStateAction,
  useEffect,
  useState
} from 'react';
import { connect } from 'react-redux';
import { NeuCard, NeuContainer, NeuIcon, NeuLabel } from '@neutron/react';
/**
 * Internal Imports
 */
import { RootState } from '../../../../redux/store';

import { date } from '../../../../utils/helpers';
/**
 * Global Type Definition Imports
 */
import { Issue, Stoplight } from '../../../../config/interfaces';
/**
 * Style Imports
 */
import {
  CustomPaperClipIcon,
  IdLabel,
  NeuIconStatus,
  StoplightStatus
} from './AttachedCard.styles';
import { SCLogo } from '../categorySelect/CategorySelect.styles';
/**
 * Static File Imports
 */
import serviceCentralLogoImg from '../../../../assets/images/service_central_icon.png';

interface IAttachedCard {
  attached?: boolean;
  attachToItemCompleted?: boolean;
  item: Issue & Stoplight;
  scrollContainerRef?: RefObject<HTMLNeuContainerElement>;
  serviceCentralCategories: string[];
  slide: string;
  type: 'issue' | 'stoplight';
  handleOpen?: (id: string) => void;
  handleRemoveAttached?: (
    event: React.MouseEvent<HTMLNeuIconElement>,
    item: Issue & Stoplight
  ) => void;
  setItemsToAttach: (item: Issue & Stoplight) => void;
  setNewlyAttachedOpen: Dispatch<SetStateAction<boolean>>;
}

const AttachedCard = ({
  attached,
  attachToItemCompleted,
  item,
  scrollContainerRef,
  serviceCentralCategories,
  slide,
  type,
  handleOpen,
  handleRemoveAttached,
  setItemsToAttach,
  setNewlyAttachedOpen
}: IAttachedCard) => {
  const [hover, setHover] = useState(false);

  useEffect(() => {
    if (slide === 'hide') setHover(false);
  }, [slide]);

  return (
    <NeuCard
      id={`${type}-card-${type === 'issue' ? item.taskId : item.id}`}
      className={`d-flex mx-0 py-0${
        attached || !item.isAttached
          ? ' hover-card hover-card__issue'
          : item.isAttached
          ? ' hover-card__disabled'
          : ''
      }`}
      data-testid={`${type}-card-${type === 'issue' ? item.taskId : item.id}`}
      minHeight="9rem"
      onClick={
        attached && handleOpen
          ? () => handleOpen(type === 'issue' ? item.taskId : item.id)
          : !item.isAttached
          ? () => {
              setItemsToAttach(item);
              setNewlyAttachedOpen(true);
              if (scrollContainerRef?.current) {
                setTimeout(
                  () =>
                    scrollContainerRef?.current?.parentElement?.scrollTo({
                      top: 0,
                      behavior: 'smooth'
                    }),
                  50
                );
              }
            }
          : undefined
      }
      onMouseOver={() => setHover(true)}
      onMouseLeave={slide === 'hide' ? () => setHover(false) : undefined}
    >
      <NeuContainer className="flex-row align-items-center pl-0">
        <NeuContainer
          className="align-items-start pl-0"
          style={{ overflow: 'hidden' }}
        >
          <NeuLabel className="mt-0 custom-input-heading">
            {type === 'issue' ? (
              item.completed ? (
                'Completed | '
              ) : (
                'Opened | '
              )
            ) : (
              <>
                <NeuIconStatus
                  color={
                    item.status === 'Completed'
                      ? 'green-60'
                      : item.status === 'In Progress'
                      ? 'yellow-30'
                      : 'red-60'
                  }
                >
                  circle
                </NeuIconStatus>
                <StoplightStatus>{` ${item.status} | `}</StoplightStatus>
              </>
            )}
            <IdLabel>{item.friendlyId || 'Unknown'}</IdLabel>
            {item.completed && item.completedDate
              ? ` - ${date.datePrettier(item.completedDate)}`
              : ''}
          </NeuLabel>
          <NeuLabel
            className="d-flex mt-0 custom-input-heading bold"
            style={{ minHeight: '1.25rem' }}
          >
            {type === 'issue' &&
              ((item.category &&
                serviceCentralCategories.includes(item.category)) ||
                (item.taskSubtype &&
                  serviceCentralCategories.includes(item.taskSubtype)) ||
                (item.historicCategory &&
                  serviceCentralCategories.includes(
                    item.historicCategory
                  ))) && (
                <SCLogo
                  width="25px"
                  alt="service central logo"
                  src={serviceCentralLogoImg}
                />
              )}
            {attached
              ? item.category || item.taskSubtype || 'Unknown'
              : item.category || item.historicCategory || 'Unknown'}
          </NeuLabel>
          {item.subCategory && (
            <NeuLabel className="mt-0 custom-input-heading bold">
              {item.subCategory}
            </NeuLabel>
          )}
          <NeuLabel className="mt-0 custom-input-heading text-clamp text-clamp__2">
            {type === 'issue' ? item.description : item.details}
          </NeuLabel>
          {/* <NeuLabel className="mt-0 custom-input-heading bold">
                  Origin: {text.capFirst(issue.roundingType)}
                </NeuLabel> */}
          {/* TODO: Not available on DTO yet */}
          {/* <NeuLabel className="mt-0 custom-input-heading bold">
                  Created By: {issue.userFullName}
                </NeuLabel> */}
          <NeuLabel className="mt-0 custom-input-heading bold">
            Created By:{' '}
            {(type === 'issue' ? item.userFullName : item.createdBy) ||
              'Unknown'}
          </NeuLabel>
          <NeuLabel className="mt-0 ml-2 custom-input-heading">
            {/* {date.dayOfYr(new Date())} */}
          </NeuLabel>
        </NeuContainer>
        {!attachToItemCompleted && (
          <NeuContainer
            className="flex-row align-items-center justify-content-end pl-0"
            style={{ flexBasis: '14%' }}
          >
            {attached ? (
              <NeuIcon
                className="ml-3"
                color="danger"
                onClick={
                  handleRemoveAttached
                    ? event => handleRemoveAttached(event, item)
                    : undefined
                }
                style={{ cursor: 'pointer' }}
              >
                remove_circle_icon
              </NeuIcon>
            ) : !item.isAttached ? (
              <NeuIcon
                className="ml-3"
                color="primary"
                style={{ cursor: 'pointer' }}
              >
                {hover ? 'add_circle' : 'add_circle_outline'}
              </NeuIcon>
            ) : (
              <CustomPaperClipIcon className="ml-3" color="gray-70">
                attachment
              </CustomPaperClipIcon>
            )}
          </NeuContainer>
        )}
      </NeuContainer>
    </NeuCard>
  );
};

const mapReduxStateToProps = (state: RootState) => ({
  slide: state.ToastReducer.slide
});

export default connect(mapReduxStateToProps)(AttachedCard);
