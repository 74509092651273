import ACTIONS from './actionType';
import { IToastOptionsData } from '../../config/interfaces';

// TOAST actions
export const slideIn = () => ({ type: ACTIONS.TOAST.SLIDE_IN_TOAST });
export const slideOut = () => ({ type: ACTIONS.TOAST.SLIDE_OUT_TOAST });
export const hide = () => ({ type: ACTIONS.TOAST.HIDE_TOAST });
export const setToastOptions = (data: IToastOptionsData) => ({
  type: ACTIONS.TOAST.SET_TOAST_OPTIONS,
  data
});
export const setToastType = (data: { type: string }) => ({
  type: ACTIONS.TOAST.SET_TOAST_TYPE,
  data
});
