/**
 * External Imports
 */
import { NeuItem, NeuInput, NeuLabel } from '@neutron/react';
/**
 * Style Imports
 */
import styled from 'styled-components';

interface DropdownProps {
  showPopover: boolean;
}

export const InputHeading = styled(NeuLabel)`
  display: inline-block;
  font-size: 0.875rem;
  line-height: 22px;
  width: 413px;
  margin-top: 12px;
`;
export const ModalInput = styled(NeuInput)`
  width: 97%;
  height: 42px;
`;

export const DropDownSearchContainer = styled.div<DropdownProps>`
  box-shadow: 0px 2px 4px rgba(25, 25, 25, 0.2);
  border-radius: 5px;
  width: 97%;
  max-height: 24em;
  z-index: 10;
  position: absolute;
  top: 42px;
  background: white !important;
  opacity: 1;
  overflow: scroll;
  ::-webkit-scrollbar {
    width: 3px;
  }
  ::-webkit-scrollbar-thumb {
    background: rgb(224, 224, 224);
    border-radius: 10px;
  }
  display: ${props => (props.showPopover ? 'block' : 'none')};
`;

export const DropItem = styled(NeuItem)`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  height: 80px;
  padding: 0.22rem;
  opacity: 1;
`;
