/**
 * External Imports
 */
import {
  FC,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { connect, useDispatch } from 'react-redux';
import { NeuContainer, NeuDivider, NeuIcon } from '@neutron/react';
import { NeuInputChangeEventDetail } from '@neutron/core';
/**
 * Internal Imports
 */
import AttachedCard from '../attachedCard';

import { RootState } from '../../../../redux/store';
import {
  setModalType,
  setModalData,
  setModalAction,
  openModal
} from '../../../../redux/actions/Modal.action';
import {
  clearSearchStoplights,
  getStoplightById,
  searchStoplight
} from '../../../../redux/actions/Stoplight.action';
import {
  clearSearchIssues,
  getIssueById,
  saveModalState,
  searchIssues
} from '../../../../redux/actions/Task.action';

import { useDebounceValue } from '../../../../utils/debouncers';
/**
 * Global Type Definition Imports
 */
import {
  AuthUserProps,
  Issue,
  Stoplight,
  TaskPost
} from '../../../../config/interfaces';
/**
 * Style Imports
 */
import {
  InputHeading,
  ModalInput
} from '../../stoplightModal/StoplightModal.styles';
import {
  AttachedContainer,
  NoneAttachedLabel,
  ShowHideSpan,
  ViewIssuesLabel
} from './SearchView.styles';

const SearchView: FC<{
  attachedDisplay: (Issue & Stoplight)[];
  attachToItem: TaskPost & Stoplight;
  attachToItemCompleted?: boolean;
  authorizedUser: AuthUserProps;
  issueById: Issue;
  prevAttached: string[];
  searchedIssues: Issue[];
  searchedStoplight: [];
  serviceCentralCategories: string[];
  type: 'issue' | 'stoplight';
  handleRemoveAttached: (
    e: MouseEvent<HTMLNeuIconElement>,
    item: Issue & Stoplight
  ) => void;
  setItemsToAttach: (item: Issue & Stoplight) => void;
}> = ({
  attachedDisplay,
  attachToItem,
  attachToItemCompleted,
  authorizedUser,
  issueById,
  prevAttached,
  searchedIssues,
  searchedStoplight,
  serviceCentralCategories,
  type,
  handleRemoveAttached,
  setItemsToAttach
}) => {
  const dispatch = useDispatch();
  // search and attached states
  const [searchState, setSearchState] = useState<boolean>(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentlyAttachedOpen, setCurrentlyAttachedOpen] = useState(true);
  const [newlyAttachedOpen, setNewlyAttachedOpen] = useState(true);

  const scrollContainerRef = useRef<HTMLNeuContainerElement>(null);

  const dbSearchTerm = useDebounceValue(searchTerm, 250);

  const searched = useMemo(
    () =>
      type === 'issue'
        ? searchedIssues.map((issue: Issue) => ({
            ...issue,
            id: issue.taskId
          }))
        : searchedStoplight,
    [searchedIssues, searchedStoplight]
  ) as (Issue & Stoplight)[];

  const handleOpenIssue = (id: string) => {
    // getting issue clicked upon to open
    dispatch(saveModalState({ redirect: true }));
    dispatch(getIssueById(id));
  };

  const handleSearching = (e: CustomEvent<NeuInputChangeEventDetail>) => {
    const target = e.target as HTMLInputElement;
    setSearchState(true);
    setSearchTerm(target.value);
  };

  // Open stoplight modal from issue panel
  const openStoplight = useCallback(() => {
    if (attachToItem.stoplightId) {
      dispatch(saveModalState({ redirect: true }));
      dispatch(getStoplightById(attachToItem.stoplightId));
      dispatch(setModalType('Edit Stoplight'));
      dispatch(setModalAction('VIEW'));
      dispatch(openModal());
    }
  }, []);

  useEffect(() => {
    return type === 'issue'
      ? () => {
          dispatch(clearSearchIssues());
        }
      : () => {
          dispatch(clearSearchStoplights());
        };
  }, []);

  // watching for the issue by id then sending modalData
  useEffect(() => {
    if (Object.keys(issueById).length > 0) {
      dispatch(setModalData(issueById));
      const category = issueById.category || issueById.taskSubtype;
      if (category && serviceCentralCategories.includes(category)) {
        dispatch(setModalType('View Issue'));
      } else {
        dispatch(
          setModalType(issueById.completed ? 'View Issue' : 'Edit Issue')
        );
      }
    }
  }, [issueById]);

  // search
  useEffect(() => {
    if (dbSearchTerm.length > 1 && /^([0-9]{2,})+$/i.test(dbSearchTerm)) {
      if (type === 'issue') {
        const queryParams = {
          query: dbSearchTerm,
          origin: attachToItem.origin || '',
          page: 0
        };
        dispatch(searchIssues(queryParams));
      } else {
        const queryParams = {
          facilityId: authorizedUser.facilityId,
          query: dbSearchTerm,
          origin: attachToItem.roundingType,
          page: 0
        };
        dispatch(searchStoplight(queryParams));
      }
    } else {
      if (type === 'issue') {
        dispatch(clearSearchIssues());
      }
      if (type === 'stoplight') {
        dispatch(clearSearchStoplights());
      }
    }
  }, [dbSearchTerm, attachToItem.origin]);

  const filteredSearched = useMemo(
    () =>
      searched.filter(
        (item: Issue & Stoplight) =>
          !attachedDisplay.some(
            (attached: Issue & Stoplight) => attached.id === item.id
          )
      ),
    [attachedDisplay, searched]
  );

  return (
    <NeuContainer
      id="modal-search"
      className="mb-2 mt-4 px-0"
      ref={scrollContainerRef}
    >
      {attachToItemCompleted && prevAttached.length === 0 ? (
        <NoneAttachedLabel className="mt-9">
          {type === 'issue' ? 'No Issues' : 'No Stoplight'}
        </NoneAttachedLabel>
      ) : (
        <>
          {!attachToItemCompleted && (
            <InputHeading>
              {type === 'issue'
                ? 'Attach an Issue (Optional)'
                : 'Attach a Stoplight (Optional)'}
            </InputHeading>
          )}
          <div id="Modal-Search-Attach" className="mb-3">
            {!attachToItemCompleted && (
              <ModalInput
                className="w-100"
                id="Modal-Search-Attach-Input"
                // disabled={!stoplightObject.category && !stoplightObject.category}
                type="search"
                name="issue"
                autocomplete="off"
                placeholder={
                  // !stoplightObject.category
                  // ? 'Must select stoplight category' :
                  'Start typing ID'
                }
                inputmode="search"
                enterkeyhint="search"
                autocorrect="off"
                spellcheck
                value={searchState ? searchTerm : null}
                onNeuChange={searchState ? handleSearching : () => {}}
                onNeuFocus={() => {
                  setSearchState(true);
                  setSearchTerm('');
                }}
              />
            )}
          </div>
          <NeuContainer className="p-0">
            {attachedDisplay.filter((item: Issue & Stoplight) =>
              prevAttached.some((id: string) => item.id === id)
            ).length > 0 && (
              <>
                <ViewIssuesLabel
                  className="d-flex justify-content-between"
                  color="primary-80"
                >
                  Currently Attached
                  <ShowHideSpan
                    className="d-flex align-items-center"
                    onClick={() =>
                      setCurrentlyAttachedOpen(!currentlyAttachedOpen)
                    }
                  >
                    {currentlyAttachedOpen ? 'Hide' : 'Show'}
                    <NeuIcon>
                      {currentlyAttachedOpen ? 'expand_less' : 'expand_more'}
                    </NeuIcon>
                  </ShowHideSpan>
                </ViewIssuesLabel>
                <NeuDivider
                  className="my-3"
                  color="gray-30"
                  style={{ width: '98%' }}
                />
              </>
            )}
            <AttachedContainer className="py-0 px-3">
              {currentlyAttachedOpen &&
                attachedDisplay
                  .filter((item: Issue & Stoplight) =>
                    prevAttached.some((id: string) => item.id === id)
                  )
                  .map((item: Issue & Stoplight) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <AttachedCard
                      attached
                      attachToItemCompleted={attachToItemCompleted}
                      item={item}
                      key={item.id}
                      scrollContainerRef={scrollContainerRef}
                      type={type}
                      handleOpen={
                        type === 'issue' ? handleOpenIssue : openStoplight
                      }
                      handleRemoveAttached={handleRemoveAttached}
                      serviceCentralCategories={serviceCentralCategories}
                      setItemsToAttach={setItemsToAttach}
                      setNewlyAttachedOpen={setNewlyAttachedOpen}
                    />
                  ))}
            </AttachedContainer>
            {!attachToItemCompleted &&
              attachedDisplay.filter(
                (item: Issue & Stoplight) =>
                  !prevAttached.some((id: string) => item.id === id)
              ).length > 0 && (
                <>
                  <ViewIssuesLabel
                    className="d-flex justify-content-between mt-2"
                    color="primary-80"
                  >
                    Newly Attached
                    <ShowHideSpan
                      className="d-flex align-items-center"
                      onClick={() => setNewlyAttachedOpen(!newlyAttachedOpen)}
                    >
                      {newlyAttachedOpen ? 'Hide' : 'Show'}
                      <NeuIcon>
                        {newlyAttachedOpen ? 'expand_less' : 'expand_more'}
                      </NeuIcon>
                    </ShowHideSpan>
                  </ViewIssuesLabel>
                  <NeuDivider
                    className="my-3"
                    color="gray-30"
                    style={{ width: '96%' }}
                  />
                  <AttachedContainer className="py-0 px-3">
                    {newlyAttachedOpen &&
                      attachedDisplay
                        .filter(
                          (item: Issue & Stoplight) =>
                            !prevAttached.some((id: string) => item.id === id)
                        )
                        .map((item: Issue & Stoplight) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <AttachedCard
                            attached
                            attachToItemCompleted={attachToItemCompleted}
                            item={item}
                            key={item.id}
                            scrollContainerRef={scrollContainerRef}
                            type={type}
                            handleRemoveAttached={handleRemoveAttached}
                            serviceCentralCategories={serviceCentralCategories}
                            setItemsToAttach={setItemsToAttach}
                            setNewlyAttachedOpen={setNewlyAttachedOpen}
                          />
                        ))}
                  </AttachedContainer>
                </>
              )}
          </NeuContainer>
          {filteredSearched.length > 0 && (
            <ViewIssuesLabel className="mt-1">
              {filteredSearched.length} Result
              {filteredSearched.length > 1 && 's'}
            </ViewIssuesLabel>
          )}
          <NeuContainer className="py-0 px-3">
            {filteredSearched.map((item: Issue & Stoplight) => (
              <AttachedCard
                attachToItemCompleted={attachToItemCompleted}
                item={item}
                key={item.id}
                scrollContainerRef={scrollContainerRef}
                type={type}
                serviceCentralCategories={serviceCentralCategories}
                setItemsToAttach={setItemsToAttach}
                setNewlyAttachedOpen={setNewlyAttachedOpen}
              />
            ))}
          </NeuContainer>
        </>
      )}
    </NeuContainer>
  );
};

const mapReduxStateToProps = (state: RootState) => ({
  authorizedUser: state.AuthorizedUser.authorizedUser,
  issueById: state.TaskReducer.issueById,
  searchedIssues: state.TaskReducer.searchedIssues,
  searchedStoplight: state.StoplightReducer.searchedStoplight,
  serviceCentralCategories: state.ConfigReducer.serviceCentralCategories
});

export default connect(mapReduxStateToProps)(SearchView);
