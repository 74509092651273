import styled from 'styled-components';
import { NeuButton, NeuImage } from '@neutron/react';

export const StyledDiv = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;
export const BackgroundImage = styled(NeuImage)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
`;

export const ErrorContainer = styled.div`
  position: absolute;
  z-index: 2;
  top: 15%;
  left: 10%;
`;
export const Text = styled.p`
  margin: 15px 0 !important;
  font-size: 25px !important;
`;

export const H1 = styled.h1`
  font-size: 125px !important;
`;

export const H4 = styled.h4`
  font-size: 50px !important;
`;

export const FeatureNotEnabledContainer = styled(StyledDiv)<{
  offsetHeight: number;
}>`
  height: calc(100vh - ${({ offsetHeight = 0 }) => offsetHeight}px);
`;
