import { call, put } from 'redux-saga/effects';
import { postData } from '../../services/API/axios';

import ACTIONS from '../actions/actionType';
import API from '../../services/API';
import { toast } from '../../services/Toast';
import { getAuthorizedUser } from '../actions/Auth.action';
import store from '../store';

export function* addFavorite(action) {
  const url = API.addFavoriteUnitsUrl;
  const {
    data: { userId }
  } = action;
  try {
    const { data, status } = yield call(postData, url, action.data);
    if (status === 200) {
      // appInsights.trackEvent({
      //   name: 'Primary_Unit_Added'
      // });
      yield put({
        type: ACTIONS.ACCOUNT.POST_ADD_PRIMARY_SUCCESS,
        data
      });
      store.dispatch(getAuthorizedUser(userId));
    } else {
      // appInsights.trackEvent({
      //   name: 'Primary_Unit_Adding_Failed'
      // });
      toast('Favorite Failed to Post!', 'error', null, 500, true);
      yield put({
        type: ACTIONS.ACCOUNT.POST_ADD_PRIMARY_FAILURE,
        data
      });
    }
  } catch (error) {
    console.log(error);
    toast('Favorite Failed to Post!', 'error', null, 500, true);
  }
}

export function* removeFavorite(action) {
  const url = API.removeFavoriteUnitsUrl;
  const {
    data: { userId }
  } = action;
  try {
    const { data, status } = yield call(postData, url, action.data);
    if (status === 200) {
      // appInsights.trackEvent({
      //   name: 'Primary_Unit_Removed'
      // });
      yield put({
        type: ACTIONS.ACCOUNT.POST_REMOVE_PRIMARY_SUCCESS,
        data
      });
      store.dispatch(getAuthorizedUser(userId));
    } else {
      // appInsights.trackEvent({
      //   name: 'Primary_Unit_Removal_Failed'
      // });
      toast('Failed to Remove Favorite!', 'error', null, 500, true);
      yield put({
        type: ACTIONS.ACCOUNT.POST_REMOVE_PRIMARY_FAILURE,
        data
      });
    }
  } catch (error) {
    toast('Failed to Remove Favorite!', 'error', null, 500, true);
    console.log(error);
  }
}

export function* updateCurrentFacility(action) {
  const url = API.updateCurrentFacilityUrl;
  const {
    data: { userId }
  } = action;
  try {
    const { data, status } = yield call(postData, url, action.data);
    if (status === 200) {
      yield put({
        type: ACTIONS.ACCOUNT.POST_CURRENT_FACILITY_SUCCESS,
        data
      });
      store.dispatch(getAuthorizedUser(userId));
    } else {
      toast('Failed to Update Facility!', 'error', null, 500, true);
      yield put({
        type: ACTIONS.ACCOUNT.POST_CURRENT_FACILITY_FAILURE,
        data
      });
    }
  } catch (error) {
    console.log(error);
    toast('Failed to Update Facility!', 'error', null, 500, true);
  }
}
