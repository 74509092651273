import ACTIONS from './actionType';

// User actions
export const getPatientsToRound = data => {
  return { type: ACTIONS.USER.GET_PATIENTS_TO_ROUND, data };
};

export const getRounds = data => {
  return { type: ACTIONS.USER.GET_ROUNDS, data };
};

export const getUserRoles = data => {
  return { type: ACTIONS.USER.GET_USER_ROLES, data };
};

export const getFacilities = data => {
  return { type: ACTIONS.USER.GET_FACILITIES, data };
};

export const getDivisions = data => {
  return { type: ACTIONS.USER.GET_DIVISIONS, data };
};

export const postUserAccess = data => {
  return { type: ACTIONS.USER.POST_USER_ACCESS, data };
};

export const postUserAccessRevoke = data => {
  return { type: ACTIONS.USER.POST_USER_ACCESS_REVOKE, data };
};

export const getDischarge = data => {
  return { type: ACTIONS.USER.GET_DISCHARGE_PATIENT, data };
};

export const getStoreDischargeParams = data => {
  return { type: ACTIONS.USER.GET_DISCHARGE_PATIENT_PARAMS, data };
};

export const setSelectedPatient = data => {
  return { type: ACTIONS.USER.SET_SELECTED_PATIENT, data };
};
export const updateUnlistedPatientRoundParams = data => {
  return { type: ACTIONS.USER.UPDATE_UNLISTED_PATIENT_ROUND_PARAMS, data };
};

export const setUserSectionType = data => {
  return { type: ACTIONS.USER.SET_USER_ACTION_TYPE, data };
};

export const resetUserSectionType = () => {
  return { type: ACTIONS.USER.RESET_USER_ACTION_TYPE };
};

export const addEmployeeToUser = data => {
  return { type: ACTIONS.USER.ADD_EMPLOYEE_TO_USER, data };
};

export const removeEmployeeFromUser = data => {
  return { type: ACTIONS.USER.REMOVE_EMPLOYEE_FROM_USER, data };
};
export const removeUserAsDelegate = data => {
  return { type: ACTIONS.USER.REMOVE_USER_AS_DELEGATE, data };
};
export const setSelectedEmployee = data => {
  return { type: ACTIONS.USER.SET_SELECTED_EMPLOYEE, data };
};
export const updateUnlistedPatientModalFlag = data => {
  return { type: ACTIONS.USER.UPDATE_UNLISTED_PATIENT_MODAL_FLAG, data };
};
export const updateUnlistedEmployeeModalFlag = data => {
  return { type: ACTIONS.USER.UPDATE_UNLISTED_EMPLOYEE_MODAL_FLAG, data };
};
export const clearSelectedPatient = () => {
  return { type: ACTIONS.USER.CLEAR_SELECTED_PATIENT };
};

export const clearPatientsToRound = () => {
  return { type: ACTIONS.USER.CLEAR_PATIENTS_TO_ROUND };
};
export const updateUserPic = data => {
  return { type: ACTIONS.USER.POST_UPDATE_USER_PIC, data };
};
export const getPatientsToRoundForCensus = data => {
  return { type: ACTIONS.USER.GET_PATIENTS_TO_ROUND_FOR_CENSUS, data };
};

export const addDelegateToUser = data => {
  return { type: ACTIONS.USER.ADD_DELEGATE_TO_USER, data };
};

export const removeDelegateFromUser = data => {
  return { type: ACTIONS.USER.REMOVE_DELEGATE_FROM_USER, data };
};

export const clearSelectedEmployee = () => {
  return { type: ACTIONS.USER.CLEAR_SELECTED_EMPLOYEE };
};

export const postEmployeeListConfig = data => {
  return { type: ACTIONS.USER.POST_EMPLOYEE_CONFIG, data };
};

export const getEmployeeNotes = data => {
  return { type: ACTIONS.USER.GET_EMPLOYEE_NOTES, data };
};
