import { FC, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Navigate, Outlet, Route, Routes, useParams } from 'react-router-dom';

import ReusableTabs from '../../components/reusableTabs/ReusableTabs';
import EmployeeRoundsReport from './employee/RoundsReport';
import PatientRoundsReport from './patient/RoundsReport';
import EmployeeFrequencyReport from './employee/FrequencyReport';
import CensusReport from './patient/CensusReport';
import ComplianceReport from './patient/ComplianceReport';

import { RootState } from '../../redux/store';
import {
  setEmployeeRoundsSearchQuery,
  setPatientRoundsSearchQuery
} from '../../redux/actions/Report.action';
import {
  resetUserSectionType,
  setUserSectionType
} from '../../redux/actions/User.action';

import { subNavReportLinkObjects } from '../../Route';
import { Tab } from '../../config/interfaces';
import { useUserRolesRef } from '../../services/UserRoles/userRoles';
import { useDebounceValue } from '../../utils/debouncers';

const Reports: FC<{ authRoles: Array<string> }> = ({ authRoles }) => {
  const dispatch = useDispatch();
  const { isPatientRounder, isEmployeeRounder } = useUserRolesRef();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const dbSearchTerm = useDebounceValue(searchTerm, 250);

  const { '*': view = '' } = useParams();

  useEffect(() => {
    dispatch(setUserSectionType('reports'));
    return () => {
      dispatch(resetUserSectionType());
    };
  }, []);

  const authorizedPatientSubTabs: Tab[] = [];
  const authorizedEmployeeSubTabs: Tab[] = [];

  subNavReportLinkObjects.forEach(route => {
    authRoles.forEach(role => {
      if (route.allowedRoles.includes(role) && route.type === 'patient') {
        authorizedPatientSubTabs.push({
          label: route.navItemText,
          path: route.url
        });
      }
      if (route.allowedRoles.includes(role) && route.type === 'employee') {
        authorizedEmployeeSubTabs.push({
          label: route.navItemText,
          path: route.url
        });
      }
    });
  });

  const reusableTabItems = {
    base: view.includes('patient') ? '/reports/patient' : '/reports/employee',
    links: view.includes('patient')
      ? authorizedPatientSubTabs
      : authorizedEmployeeSubTabs
  };

  useEffect(() => {
    if (view === 'patient/rounds') {
      dispatch(setPatientRoundsSearchQuery(dbSearchTerm));
    } else if (
      view === 'employee' ||
      view === 'employee/rounds' ||
      view === 'employee/frequency'
    ) {
      dispatch(setEmployeeRoundsSearchQuery(dbSearchTerm));
    }
    return () => {
      dispatch(setPatientRoundsSearchQuery(''));
      dispatch(setEmployeeRoundsSearchQuery(''));
    };
  }, [dbSearchTerm]);

  return (
    <div>
      <ReusableTabs tabItems={reusableTabItems} />
      <Routes>
        {isPatientRounder && (
          <Route path="patient" element={<Outlet />}>
            <Route index element={<ComplianceReport />} />
            <Route path="compliance" element={<ComplianceReport />} />
            <Route
              path="rounds"
              element={
                <PatientRoundsReport
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                />
              }
            />
            <Route
              path="census"
              element={
                <CensusReport
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                />
              }
            />
          </Route>
        )}
        {isEmployeeRounder && (
          <Route path="employee" element={<Outlet />}>
            <Route
              index
              element={
                <EmployeeRoundsReport
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                />
              }
            />
            <Route
              path="rounds"
              element={
                <EmployeeRoundsReport
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                />
              }
            />
            <Route
              path="frequency"
              element={
                <EmployeeFrequencyReport
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                />
              }
            />
          </Route>
        )}
        <Route
          path="*"
          element={
            view.includes('patient') && isPatientRounder ? (
              <Navigate to="patient/compliance" replace />
            ) : (
              <Navigate to="employee/rounds" replace />
            )
          }
        />
      </Routes>
    </div>
  );
};

const mapReduxStateToProps = (state: RootState) => ({
  authRoles: state.AuthorizedUser.authorizedUser.roles
});

export default connect(mapReduxStateToProps)(Reports);
