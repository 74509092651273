import ACTIONS from './actionType';

// Modal actions
export const openModal = data => {
  return { type: ACTIONS.MODAL.OPEN_MODAL, data };
};
export const closeModal = data => {
  return { type: ACTIONS.MODAL.CLOSE_MODAL, data };
};

export const setModalType = data => {
  return { type: ACTIONS.MODAL.SET_MODAL_TYPE, data };
};

export const setModalAction = data => {
  return { type: ACTIONS.MODAL.SET_MODAL_ACTION, data };
};

export const setModalData = data => {
  return { type: ACTIONS.MODAL.SET_MODAL_DATA, data };
};
