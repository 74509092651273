import styled from 'styled-components';

export const ReportContainer = styled.div`
  height: calc(100vh - 208px);
  display: flex;
  flex-direction: column;
`;

export const CReportContainer = styled.div`
  height: calc(100vh - 208px);
  display: flex;
  flex-direction: column;
`;
