import {
  Comment,
  Filter,
  GetTaskParams,
  Stoplight
} from '../../config/interfaces';
import ACTIONS from './actionType';

export const getStoplightByLocation = (data: GetTaskParams) => {
  return { type: ACTIONS.STOPLIGHT.GET_STOPLIGHTS_BY_LOCATION, data };
};

export const getAllStoplightByLocation = (data: GetTaskParams) => {
  return { type: ACTIONS.STOPLIGHT.GET_ALL_STOPLIGHTS_BY_LOCATION, data };
};

export const getStoplightById = (data: string) => {
  return { type: ACTIONS.STOPLIGHT.GET_STOPLIGHT_BY_ID, data };
};

export const addStoplightToSharingQ = (data: {
  userName: string;
  email: string[];
}) => {
  return { type: ACTIONS.STOPLIGHT.ADD_STOPLIGHT_TO_SHARING_Q, data };
};

export const postNewStoplight = (data: Stoplight) => {
  return { type: ACTIONS.STOPLIGHT.POST_NEW_STOPLIGHT, data };
};

export const updateStoplight = (data: Stoplight) => {
  return { type: ACTIONS.STOPLIGHT.UPDATE_STOPLIGHT, data };
};

export const deleteStoplight = (data: string) => {
  return { type: ACTIONS.STOPLIGHT.DELETE_STOPLIGHT, data };
};

export const searchStoplight = (data: {
  facilityId: string;
  query: string;
  origin: string;
  page: number;
}) => {
  return { type: ACTIONS.STOPLIGHT.SEARCH_STOPLIGHTS, data };
};

export const clearSearchStoplights = () => {
  return { type: ACTIONS.STOPLIGHT.CLEAR_SEARCH_STOPLIGHTS };
};

// Clear stoplight response
export const clearStoplightResponse = () => {
  return { type: ACTIONS.STOPLIGHT.CLEAR_STOPLIGHT_RESPONSE };
};

export const postCommentToStoplight = (data: {
  id: string;
  comment: Comment;
}) => {
  return { type: ACTIONS.STOPLIGHT.POST_COMMENT_TO_STOPLIGHT, data };
};

export const setStoplightFilter = (data: Filter) => {
  return { type: ACTIONS.STOPLIGHT.SET_STOPLIGHT_FILTER, data };
};

export const clearStoplightFilter = () => {
  return { type: ACTIONS.STOPLIGHT.CLEAR_STOPLIGHT_FILTER };
};

export const setStoplightSort = (data: {
  sortKey: string;
  sortDir: string;
}) => {
  return { type: ACTIONS.STOPLIGHT.SET_STOPLIGHT_SORT, data };
};

export const setCurrentStoplightPage = (data: number) => {
  return { type: ACTIONS.STOPLIGHT.SET_CURRENT_STOPLIGHT_PAGE, data };
};

export const clearSelectedStoplight = () => {
  return { type: ACTIONS.STOPLIGHT.CLEAR_SELECTED_STOPLIGHT };
};

export const setStoplightSearchQuery = (data: string) => {
  return { type: ACTIONS.STOPLIGHT.SET_STOPLIGHT_SEARCH_QUERY, data };
};
