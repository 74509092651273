import styled from 'styled-components';
import { NeuLabel, NeuList, NeuItem, NeuIcon, NeuInput } from '@neutron/react';

interface DropdownProps {
  showPopover: boolean;
}

export const StyledDiv = styled.div`
  position: relative;
  width: 100%;
  left: 0px;
  margin-top: 50px;
  background: #f6f6f6;
  box-shadow: 0px 2px 4px rgba(25, 25, 25, 0.2);
`;

export const ContentContainer = styled.div`
  display: flex;
  height: calc(100vh - 260px);
`;
export const WrapperContainer = styled.div`
  display: flex;
  width: 100%;
  height: calc(100vh - 230px);
  padding: 40px 15px;
  overflow-y: scroll;
`;
export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: auto;
`;
export const FacilityInput = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
`;
export const UnitListContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;
export const UnitListContainerLeft = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  margin-top: 16px;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(25, 25, 25, 0.2);
  border-radius: 5px;
`;
export const UnitListContainerRight = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  margin-top: 16px;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(25, 25, 25, 0.2);
  border-radius: 5px;
  margin-left: 30px;
`;

export const InputHeading = styled(NeuLabel)`
  display: inline-block;
  font-size: 0.875rem;
  line-height: 22px;
  width: 90%;
  margin-top: 12px;
`;
export const UnitList = styled(NeuList)`
  max-height: 280px;
  overflow-y: scroll;
  scroll-behavior: smooth;
  margin-bottom: 33px;
  cursor: auto;
`;
export const UnitItemHeading = styled(NeuItem)`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: #4b4b4b;
`;
export const UnitItem = styled(NeuItem)`
  cursor: auto;
`;
export const UnitLabel = styled(NeuLabel)`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: auto;
`;
export const IconFavoriteUnit = styled(NeuIcon)`
  width: 24px;
  height: 24px;
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  color: #b68d41;
  float: right;
  cursor: pointer;
`;
export const SpinnerContainer = styled.span`
  margin-left: 10px;
`;
export const ModalInput = styled(NeuInput)`
  width: 100%;
  min-height: 42px;
`;
export const DropDownSearchContainer = styled.div<DropdownProps>`
  box-shadow: 0px 2px 4px rgba(25, 25, 25, 0.2);
  border-radius: 5px;
  width: 97%;
  min-height: 27px;
  max-height: 160px;
  z-index: 10;
  position: absolute;
  top: 42px;
  background: white !important;
  opacity: 1;
  overflow-y: scroll;
  scroll-behavior: smooth;
  display: ${props => (props.showPopover ? 'block' : 'none')};
`;
export const DropItem = styled(NeuItem)`
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 26px;
  padding: 1px;
  opacity: 1;
  overflow: hidden;
`;
