import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { connect, useDispatch } from 'react-redux';
import { add, format } from 'date-fns';

import SearchHeader from '../../../components/searchHeader';
import EmployeeFrequencyReportTable from '../../../components/customTables/employeeFrequencyReportTable/EmployeeFrequencyReportTable';

import { RootState } from '../../../redux/store';
import { clearEmployeeSearch } from '../../../redux/actions/Employee.action';
import {
  clearEmployeeFreq,
  getEmployeeFreq,
  getEmployeeFreqReport
} from '../../../redux/actions/Report.action';

import {
  AuthUserProps,
  DatesStartEnd,
  EmpFreq,
  RawEmpFreq
} from '../../../config/interfaces';

import { date } from '../../../utils/helpers';

import { ReportContainer } from '../styles/ReportsViews.styles';

interface FreqReportProps {
  authorizedUser: AuthUserProps;
  currentEmployeeFreqReportPage: number;
  employeeFreqReport: RawEmpFreq[];
  reportSearchQuery: string;
  searchTerm: string;
  sortKey: string;
  sortDir: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;
}

const FreqReport: FC<FreqReportProps> = ({
  authorizedUser,
  currentEmployeeFreqReportPage,
  employeeFreqReport,
  reportSearchQuery,
  searchTerm,
  sortKey,
  sortDir,
  setSearchTerm
}) => {
  const dispatch = useDispatch();
  const today = new Date();
  const [customDates, setCustomDates] = useState<DatesStartEnd>({
    startDate: format(
      new Date(today.getFullYear(), today.getMonth(), 1),
      'yyyy-MM-dd'
    ),
    endDate: format(today, 'yyyy-MM-dd')
  });

  useEffect(() => {
    return () => {
      dispatch(clearEmployeeSearch());
      dispatch(clearEmployeeFreq());
    };
  }, []);

  useEffect(() => {
    dispatch(
      getEmployeeFreq({
        facilityId: authorizedUser?.facilityId,
        query: reportSearchQuery || '',
        sortBy: sortKey,
        sortOrder: sortDir,
        startday: date.formatFilterStartDayToUTCTimestamp(
          customDates.startDate.replace(/-/g, '')
        ),
        endday: date.formatFilterEndDayToUTCTimestamp(
          format(
            add(date.localDayOfYearDate(customDates.endDate), { days: 1 }),
            'yyyy-MM-dd'
          ).replace(/-/g, '')
        ),
        page: currentEmployeeFreqReportPage
      })
    );
  }, [
    authorizedUser?.facilityId,
    customDates,
    reportSearchQuery,
    sortKey,
    sortDir,
    currentEmployeeFreqReportPage
  ]);

  const listToDisplay: EmpFreq[] = useMemo(() => {
    if (employeeFreqReport?.length) {
      return employeeFreqReport?.map((freq: RawEmpFreq) => ({
        facilityId: freq?.facilityId || '',
        department: freq?.employeeDept || '',
        employee: `${freq?.employeeFirstName} ${freq?.employeeLastName}` || '',
        employeeId: freq?.employeeId || '',
        employeeFirstName: freq?.employeeFirstName || '',
        employeeLastName: freq?.employeeLastName || '',
        count: freq?.count || 0
      }));
    }
    return [];
  }, [employeeFreqReport]);

  const handleExportCsv = useCallback(() => {
    dispatch(
      getEmployeeFreqReport({
        facilityId: authorizedUser?.facilityId,
        query: reportSearchQuery || '',
        sortBy: sortKey,
        sortOrder: sortDir,
        startday: date.formatFilterStartDayToUTCTimestamp(
          customDates.startDate.replace(/-/g, '')
        ),
        endday: date.formatFilterEndDayToUTCTimestamp(
          format(
            add(date.localDayOfYearDate(customDates.endDate), { days: 1 }),
            'yyyy-MM-dd'
          ).replace(/-/g, '')
        )
      })
    );
  }, [
    authorizedUser?.facilityId,
    customDates,
    reportSearchQuery,
    sortKey,
    sortDir
  ]);

  return (
    <ReportContainer id="Employee-Rounds-Report-Container">
      <SearchHeader
        customDates={customDates}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        handleExportCsv={handleExportCsv}
        setCustomDates={setCustomDates}
      />
      <EmployeeFrequencyReportTable frequencyReportList={listToDisplay} />
    </ReportContainer>
  );
};

const mapReduxStateToProps = (state: RootState) => ({
  authorizedUser: state.AuthorizedUser.authorizedUser,
  currentEmployeeFreqReportPage:
    state.ReportReducer.currentEmployeeFreqReportPage,
  employeeFreqReport: state.ReportReducer.employeeFreqReport,
  reportSearchQuery: state.ReportReducer.reportSearchQuery,
  sortKey: state.ReportReducer.sortFreqKey,
  sortDir: state.ReportReducer.sortFreqDir
});

export default connect(mapReduxStateToProps)(FreqReport);
