import styled from 'styled-components';

// Neutron components
import { NeuContainer, NeuItem, NeuLabel, NeuList } from '@neutron/react';

// styled components
const StyledLegendDataText = styled.p`
  font-size: 0.8rem;
  font-weight: 800;
`;

const StyledLegendList = styled(NeuList)`
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
  font-size: 0.7rem;
`;

const StyledLegendListItem = styled(NeuItem)`
  border-bottom: none !important;
`;

const StyledLegendListCont = styled(NeuContainer)`
  flex-direction: row;
  align-items: center;
`;

const StyledLegendSquare = styled(NeuContainer)`
  height: 0.75rem;
  width: 0.75rem;
  margin: 0 1rem;
  padding-right: 0;
  display: inline-flex;
  ${({ color }: { color: string }) => `
background: ${color};
`}
`;

const StyledLegendLabel = styled(NeuLabel)`
  display: inline-flex;
  flex-direction: column;
  font-size: 0.8rem;
`;

export {
  StyledLegendDataText,
  StyledLegendLabel,
  StyledLegendList,
  StyledLegendListItem,
  StyledLegendListCont,
  StyledLegendSquare
};
