/**
 * External Imports
 */
import { FC, useMemo, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { NeuContainer, NeuAvatar, NeuLabel } from '@neutron/react';
import { orderBy } from 'lodash';
import { format } from 'date-fns';

import { useAnalyticsApi } from '@shared-web-analytics/react/dist';

/**
 * Internal Imports
 */
import Table from '../../shared/table';

import { RootState } from '../../../redux/store';
import { clearEmployeeRoundHistory } from '../../../redux/actions/Report.action';
import { clearTasksByEmployeeId } from '../../../redux/actions/Task.action';
import {
  getEmployeeNotes,
  setSelectedEmployee
} from '../../../redux/actions/User.action';

import { logRoundProfile } from '../../../utils/analyticsHelpers';
import { useDebounceValue } from '../../../utils/debouncers';
import {
  date,
  handleBlobPicUrl,
  userProfilePicUrl,
  yearsOfService
} from '../../../utils/helpers';
import defaultImage from '../../../assets/images/defaultImage.png';

/**
 * Global Type Definition Imports
 */
import { Employee, RoundHistory } from '../../../config/interfaces';

/**
 * Style Imports
 */
import {
  ToDoRow,
  CompleteRow,
  ToDoLabel
} from '../censusTable/CensusTable.styles';
import {
  StyledLabel,
  StyledTableHeading
} from '../../shared/table/Table.styles';
import {
  AvatarDiv,
  EmployeeInfoDiv,
  EmployeeAvatar,
  DeptStartContainer,
  StartDateContainer
} from './CSRNListTable.styles';

interface CSRNListTableProps {
  csrnFilter: string;
  csrnRounds: Array<Employee & RoundHistory>;
  searchTerm: string;
  sasToken: string;
  selectedDeptsFromStore: string[];
}

const CSRNListTable: FC<CSRNListTableProps> = ({
  csrnFilter,
  csrnRounds,
  searchTerm,
  sasToken,
  selectedDeptsFromStore
}) => {
  const dispatch = useDispatch();

  const [sortDir, setSortDir] = useState<'desc' | 'asc'>('asc');
  const [sortKey, setSortKey] = useState<string>('lastRoundDate');
  const [active, setActive] = useState<string>('Last Round');

  const { logTrackingEvent } = useAnalyticsApi();

  const dbSearchTerm = useDebounceValue(searchTerm, 250);

  const navigate = useNavigate();

  const openEmployeeProfile = (selectedEmployee: Employee) => {
    dispatch(clearEmployeeRoundHistory());
    dispatch(clearTasksByEmployeeId());
    dispatch(setSelectedEmployee(selectedEmployee));
    dispatch(getEmployeeNotes(selectedEmployee.hcaid));
    logTrackingEvent(logRoundProfile('csrn'));
    navigate('/support/profile/csc');
  };

  const sortByHeading = (key: string, column: string) => {
    if (key === sortKey && sortDir === 'asc') {
      setSortDir('desc');
    } else {
      setSortDir('asc');
    }
    setActive(column);
    setSortKey(key);
  };

  const filteredCSRNRounds = useMemo(() => {
    const csrnRoundsFilteredByDept = csrnRounds.filter(employee => {
      const employeeDept =
        (employee.adHoc ? employee?.employeeDept : employee?.dept) || 'Unknown';
      return selectedDeptsFromStore.some(
        selectedDept => selectedDept === employeeDept
      );
    });

    const csrnRoundSubjectsAndAdHoc = csrnRoundsFilteredByDept.reduce(
      (
        acc: {
          csrnSubjects: (Employee & RoundHistory)[];
          adHoc: (Employee & RoundHistory)[];
        },
        employee: Employee & RoundHistory
      ) =>
        employee.adHoc
          ? { ...acc, adHoc: [...acc.adHoc, employee] }
          : { ...acc, csrnSubjects: [...acc.csrnSubjects, employee] },
      { csrnSubjects: [], adHoc: [] }
    );

    const csrnSubjectsToRound = csrnRoundSubjectsAndAdHoc.csrnSubjects;
    const csrnAdHocsToRound = csrnRoundSubjectsAndAdHoc.adHoc;

    let employeesToShow;
    switch (csrnFilter) {
      case 'no-round':
        employeesToShow = csrnSubjectsToRound.filter(
          employee =>
            !employee?.lastRoundDate ||
            (employee?.lastRoundDate &&
              date.isOverInDays(employee.lastRoundDate, 30))
        );
        break;
      case 'recently-completed':
        employeesToShow = [
          ...csrnSubjectsToRound.filter(
            employee =>
              employee?.lastRoundDate &&
              !date.isOverInWeeks(employee.lastRoundDate, 2)
          ),
          ...csrnAdHocsToRound
        ];
        break;
      case 'new-nurses':
        employeesToShow = csrnSubjectsToRound.filter(employee =>
          employee.subjectClassifications?.includes('NewNurse')
        );
        break;
      case 'new-to-hca-nurses':
        employeesToShow = csrnSubjectsToRound.filter(employee =>
          employee.subjectClassifications?.includes('NewToHCA')
        );
        break;
      case 'new-to-dept-nurses':
        employeesToShow = csrnSubjectsToRound.filter(employee =>
          employee.subjectClassifications?.includes('NewToDepartment')
        );
        break;
      case 'travel-nurses':
        employeesToShow = csrnSubjectsToRound.filter(() => false);
        break;
      default:
        employeesToShow = csrnSubjectsToRound;
    }

    const q = new RegExp(dbSearchTerm.trim().replace(/\\/g, ''), 'i');
    return employeesToShow.filter(
      employee =>
        `${employee.firstName} ${employee.lastName}`.match(q) ||
        employee.hcaid.match(q) ||
        `${employee.lastName} ${employee.firstName}`.match(q)
    );
  }, [csrnFilter, csrnRounds, dbSearchTerm, selectedDeptsFromStore]);

  const sortedCSRNRounds = useMemo(() => {
    const filteredCSRNRoundsCopy = [...filteredCSRNRounds];
    const orderBySortDir = sortDir === 'asc' ? 'asc' : 'desc';
    return filteredCSRNRoundsCopy.length > 0
      ? orderBy(
          filteredCSRNRoundsCopy,
          [round => (round as any)[sortKey]?.toLowerCase() || ''],
          [orderBySortDir]
        )
      : filteredCSRNRoundsCopy;
  }, [filteredCSRNRounds, sortDir, sortKey]);

  const isTableEmpty = useMemo(
    () => !dbSearchTerm && sortedCSRNRounds.length === 0,
    [dbSearchTerm, sortedCSRNRounds]
  );

  const isTableSearchEmpty = useMemo(
    () => dbSearchTerm && sortedCSRNRounds.length === 0,
    [dbSearchTerm, sortedCSRNRounds]
  );

  const cols =
    '{ "Employee":"22%","Department":"20%","RN Experience":"14%","Dept Start":"14%","Rounded By":"18%","Last Round":"12%"}';

  const headers: JSX.Element[] = [
    <StyledTableHeading
      id="CSRN-Table-Employee-Column"
      key="Employee"
      slot="Employee"
      icon={
        !(active === 'Employee') ? 'asc' : sortDir === 'asc' ? 'desc' : 'asc'
      }
      onClick={() => sortByHeading('fullName', 'Employee')}
      active={active === 'Employee'}
    >
      <span className="text-clamp text-clamp__1 break-all">Employee</span>
    </StyledTableHeading>,
    <StyledTableHeading
      id="CSRN-Table-Department-Column"
      key="Department"
      slot="Department"
      icon={
        !(active === 'Department') ? 'asc' : sortDir === 'asc' ? 'desc' : 'asc'
      }
      onClick={() => sortByHeading('dept', 'Department')}
      active={active === 'Department'}
    >
      <span className="text-clamp text-clamp__1 break-all">Department</span>
    </StyledTableHeading>,
    <StyledTableHeading
      id="CSRN-Table-RN-Experience-Column"
      data-testid="CSRN-Table-RN-Experience-Column-Heading"
      key="RN Experience"
      slot="RN Experience"
      icon={
        !(active === 'RN Experience')
          ? 'asc'
          : sortDir === 'asc'
          ? 'asc'
          : 'desc'
      }
      onClick={() => sortByHeading('rnExperienceDate', 'RN Experience')}
      active={active === 'RN Experience'}
    >
      <span className="text-clamp text-clamp__1 break-all">RN Experience</span>
    </StyledTableHeading>,
    <StyledTableHeading
      id="CSRN-Table-Dept-Start-Column"
      key="Dept Start"
      slot="Dept Start"
      icon={
        !(active === 'Dept Start') ? 'asc' : sortDir === 'asc' ? 'desc' : 'asc'
      }
      onClick={() => sortByHeading('effectiveStartDate', 'Dept Start')}
      active={active === 'Dept Start'}
    >
      <span className="text-clamp text-clamp__1 break-all">Dept Start</span>
    </StyledTableHeading>,
    <StyledTableHeading
      id="CSRN-Table-Rounded-By-Column"
      key="Rounded By"
      slot="Rounded By"
      icon={
        !(active === 'Rounded By') ? 'asc' : sortDir === 'asc' ? 'desc' : 'asc'
      }
      onClick={() => sortByHeading('lastRoundedBy', 'Rounded By')}
      active={active === 'Rounded By'}
    >
      <span className="text-clamp text-clamp__1 break-all">Rounded By</span>
    </StyledTableHeading>,
    <StyledTableHeading
      id="CSRN-Table-Last-Round-Column"
      key="Last Round"
      slot="Last Round"
      icon={
        !(active === 'Last Round') ? 'asc' : sortDir === 'asc' ? 'desc' : 'asc'
      }
      onClick={() => sortByHeading('lastRoundDate', 'Last Round')}
      active={active === 'Last Round'}
    >
      <span className="text-clamp text-clamp__1 break-all">Last Round</span>
    </StyledTableHeading>
  ];

  const formattedEmployeeRow = (employee: Employee & RoundHistory) => {
    return (
      <>
        <EmployeeInfoDiv className="h-100 px-0" slot="Employee">
          <AvatarDiv>
            <NeuAvatar
              color="gray-60"
              style={{ height: '40px', width: '40px' }}
            >
              {sasToken ? (
                <EmployeeAvatar
                  src={
                    handleBlobPicUrl(employee.pictureUrl, sasToken) ??
                    userProfilePicUrl(employee.hcaid, sasToken)
                  }
                  alt="Employee Image"
                  onError={(e: any) => {
                    e.currentTarget.src = defaultImage;
                    return null;
                  }}
                />
              ) : (
                <EmployeeAvatar src={defaultImage} alt="Default Image" />
              )}
            </NeuAvatar>
          </AvatarDiv>
          <NeuContainer>
            <ToDoLabel color="primary-70">
              {employee.firstName} {employee.lastName}
            </ToDoLabel>
            <StyledLabel>{employee.hcaid}</StyledLabel>
          </NeuContainer>
        </EmployeeInfoDiv>
        <NeuLabel color="gray-90" slot="Department">
          {employee.adHoc ? employee.employeeDept : employee.dept}
        </NeuLabel>
        <DeptStartContainer slot="RN Experience">
          <StartDateContainer>
            {employee.rnExperienceDate && employee.rnExperienceDate !== 'NULL'
              ? yearsOfService(employee.rnExperienceDate)
              : 'Unknown'}
          </StartDateContainer>
        </DeptStartContainer>
        <NeuLabel className="d-flex" slot="Dept Start">
          {employee.effectiveStartDate && employee.effectiveStartDate !== 'NULL'
            ? date.datePrettyString(employee.effectiveStartDate)
            : 'Unknown'}
        </NeuLabel>
        <NeuLabel slot="Rounded By">
          {employee.lastRoundDate &&
          employee.lastRoundedBy &&
          employee.lastRoundedBy !== 'Unknown'
            ? employee.lastRoundedBy
            : employee.lastRoundDate &&
              employee.lastRoundedBy &&
              employee.lastRoundedBy === 'Unknown'
            ? 'Unknown'
            : ''}
        </NeuLabel>
        <NeuLabel slot="Last Round">
          {employee.lastRoundedBy &&
          employee.lastRoundedBy !== 'Unknown' &&
          (!employee.lastRoundDate ||
            (employee.lastRoundDate &&
              !date.isOrbitTimestamp(employee.lastRoundDate)))
            ? 'Unknown'
            : employee.lastRoundedBy && employee.lastRoundDate
            ? format(date.parseDate(employee.lastRoundDate), 'MM/dd/yyyy') // make unknown if name and no date
            : ''}
        </NeuLabel>
      </>
    );
  };
  const formatRows = (
    employeeRounds: (Employee & RoundHistory)[],
    type: string
  ) => {
    return employeeRounds.map((employee: Employee & RoundHistory) =>
      type === 'Completed' ? (
        <CompleteRow
          id={`Completed-Row-${employee.hcaid}`}
          columns={cols}
          size="large"
          onClick={() => openEmployeeProfile(employee)}
          key={employee.hcaid}
        >
          {formattedEmployeeRow(employee)}
        </CompleteRow>
      ) : (
        type === 'To Do' && (
          <ToDoRow
            id={`ToDo-Row-${employee.hcaid}`}
            data-testid={`ToDo-Row-${employee.hcaid}`}
            key={employee.hcaid}
            columns={cols}
            size="large"
            onClick={() => openEmployeeProfile(employee)}
          >
            {formattedEmployeeRow(employee)}
          </ToDoRow>
        )
      )
    );
  };
  const toDoRows = formatRows(sortedCSRNRounds, 'To Do');
  // const completeRows = formatRows(completedRoundsState, 'Completed');

  const rows: (boolean | JSX.Element)[] = [...toDoRows];

  return (
    <Table
      cols={cols}
      headers={headers}
      rows={rows}
      isTableEmpty={isTableEmpty}
      isTableEmptySearch={isTableSearchEmpty}
      customClass="csc-list-table"
    />
  );
};
const mapReduxStateToProps = (state: RootState) => ({
  sasToken: state.ConfigReducer.sasToken,
  selectedDeptsFromStore: state.EmployeeReducer.selectedDepts
});

export default connect(mapReduxStateToProps)(CSRNListTable);
