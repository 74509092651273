import { FC, useMemo } from 'react';
import { NeuInput } from '@neutron/react';
import { format, sub } from 'date-fns';

import {
  DateContainer,
  SelectContainer,
  MonthHeading
} from './MonthSelectsSection.styles';

const MonthSelectsSection: FC<{
  month: string;
  style?: {};
  handleSelects: (e: any) => void;
}> = ({ month, style, handleSelects }) => {
  const minMonth = useMemo(
    () => format(sub(new Date(), { years: 2 }), 'yyyy-MM'),
    []
  );
  const maxMonth = useMemo(() => format(new Date(), 'yyyy-MM'), []);

  return (
    <DateContainer id="Reports-Month-Selection" style={style}>
      <SelectContainer>
        <MonthHeading>Month</MonthHeading>
        <NeuInput
          id="Reports-Month-Selection-Start-Day"
          name="month"
          type="month"
          autocomplete="off"
          inputmode="search"
          enterkeyhint="search"
          autocorrect="off"
          onNeuChange={handleSelects}
          placeholder="Select a month"
          value={month}
          min={minMonth}
          max={maxMonth}
        />
      </SelectContainer>
    </DateContainer>
  );
};

export default MonthSelectsSection;
