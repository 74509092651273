/**
 * External Exports
 */
import { FC, useEffect, useMemo, useState } from 'react';
import { NeuIcon, NeuButton, NeuCardTitle } from '@neutron/react';
import { parse, format } from 'date-fns';

import { useAnalyticsApi } from '@shared-web-analytics/react/dist';
/**
 * Internal Exports
 */
import { BlueChip } from '../../shared/chips/blueChip';
import { EmployeeSharedWithProps } from './FeedbackModal';

import { logTaskClick } from '../../../utils/analyticsHelpers';
import { abbreviatedName, toTitleCase } from '../../../utils/helpers';
/**
 * Gloabl Type Definition Imports
 */
import { FeedbackPost, Unit } from '../../../config/interfaces';
/**
 * Style Imports
 */
import {
  CardHeader,
  ModalCardContent,
  ModalFooter,
  CheckIcon,
  TitleSpanView,
  FeedbackViewDiv,
  FeedbackViewSubDiv,
  FeedbackViewDiv2Column,
  LabelHeading,
  LabelContent,
  FooterDiv,
  DateDiv,
  LabelContentPatient,
  LabelContentBold,
  CompletedTextDiv
} from './FeedbackModal.styles';

interface IFeedbackModalViewProps {
  feedbackInfo: FeedbackPost;
  employeeSharedWith: EmployeeSharedWithProps[] | undefined;
  onClose: () => void;
  userSection: string;
  units: Unit[];
  associatedPatient: {}[];
  handleRedirect: () => void;
}
const FeedbackModalView: FC<IFeedbackModalViewProps> = ({
  feedbackInfo,
  employeeSharedWith,
  onClose,
  userSection,
  units,
  associatedPatient,
  handleRedirect
}) => {
  const {
    taskSubtype,
    patientFirstName,
    patientLastName,
    room,
    unitId,
    employeeFullName,
    employeeId,
    taskCategory,
    category,
    subCategory,
    description,
    completedDate,
    completed,
    employeeLastName,
    employeeFirstName,
    department,
    bed,
    roundId,
    userFullName
  } = feedbackInfo;
  const [completedDateFormatted, setCompletedDateFormatted] = useState('');

  const { logTrackingEvent } = useAnalyticsApi();

  useEffect(() => {
    if (completedDate) {
      const newdate = parse(completedDate, 'yyyyMMddHHmmss', new Date());
      const formatted = format(newdate, 'MM-dd-yyyy @ hh:mm aa');
      setCompletedDateFormatted(formatted);
    }
  }, [completedDate]);

  const handleClose = () => {
    logTrackingEvent(logTaskClick('feedback', 'close'));
    onClose();
  };

  const unitName = useMemo(() => {
    const foundUnit: Unit[] = units.filter(
      (unit: Unit) => unit.unitId === unitId
    );
    return foundUnit[0]?.unit;
  }, [units]);

  return (
    <>
      <CardHeader>
        <NeuCardTitle>
          <TitleSpanView>{`${toTitleCase(userSection)} | ${toTitleCase(
            taskSubtype
          )}`}</TitleSpanView>
        </NeuCardTitle>
        <NeuButton
          id="Feedback-Modal-Close-Button"
          fill="flat"
          onClick={handleClose}
        >
          <NeuIcon large color="primary">
            close
          </NeuIcon>
          Close
        </NeuButton>
      </CardHeader>
      <ModalCardContent>
        <FeedbackViewDiv2Column>
          {userSection === 'patient' ? (
            <FeedbackViewSubDiv>
              <LabelHeading>Patient</LabelHeading>
              <LabelContentPatient
                onClick={() => handleRedirect()}
                cursorType={
                  !!(
                    roundId &&
                    associatedPatient &&
                    associatedPatient.length === 1
                  )
                }
                color={
                  roundId && associatedPatient && associatedPatient.length === 1
                    ? '#206DA3'
                    : '#191919'
                }
              >
                {!patientLastName || !patientFirstName
                  ? 'Unknown'
                  : abbreviatedName(patientFirstName, patientLastName)}
              </LabelContentPatient>
            </FeedbackViewSubDiv>
          ) : (
            <div>
              <LabelHeading>Employee</LabelHeading>
              <LabelContentPatient
                // TODO: Why is blue link turned off on emp feedback
                // onClick={() => handleRedirect()}
                // cursorType={!!(roundId && employeeId)}
                // color={roundId && employeeId ? '#206DA3' : '#191919'}
                color="#191919"
              >
                {`${
                  employeeLastName ? `${toTitleCase(employeeLastName)}, ` : ''
                }
                  ${employeeFirstName ? toTitleCase(employeeFirstName) : ''}`}
                {`${!employeeLastName && !employeeFirstName ? 'Unknown' : ''}`}
                {`${employeeId ? ` | ${employeeId.toUpperCase()}` : ''}`}
              </LabelContentPatient>
            </div>
          )}
          {userSection === 'patient' && (
            <FeedbackViewSubDiv>
              <LabelHeading>Room</LabelHeading>
              <LabelContentBold>
                {room ? `${room}${bed || ''}` : 'N/A'}
              </LabelContentBold>
            </FeedbackViewSubDiv>
          )}
        </FeedbackViewDiv2Column>
        {userSection === 'patient' ? (
          <FeedbackViewDiv>
            <LabelHeading>Unit</LabelHeading>
            <LabelContentBold>{`${
              unitId ? unitName : 'Unknown'
            }`}</LabelContentBold>
          </FeedbackViewDiv>
        ) : (
          <FeedbackViewDiv>
            <LabelHeading>Department</LabelHeading>
            <LabelContentBold>{department || 'Unknown'}</LabelContentBold>
          </FeedbackViewDiv>
        )}
        {userSection === 'patient' && (
          <FeedbackViewDiv>
            <LabelHeading>Employee</LabelHeading>
            <LabelContent>
              <BlueChip
                name={`${employeeFullName} ${
                  employeeId ? employeeId.toUpperCase() : ''
                }`}
                removable={false}
              />
            </LabelContent>
          </FeedbackViewDiv>
        )}
        <FeedbackViewDiv>
          <LabelHeading>Creator</LabelHeading>
          <LabelContentBold className="my-1">{userFullName}</LabelContentBold>
        </FeedbackViewDiv>
        {!category ? (
          <FeedbackViewDiv>
            <LabelHeading>Feedback Category</LabelHeading>
            <LabelContentBold>{taskCategory.join(', ')}</LabelContentBold>
          </FeedbackViewDiv>
        ) : (
          <>
            <FeedbackViewDiv>
              <LabelHeading>Feedback Category & Subcategory</LabelHeading>
              <LabelContentBold>
                {category}
                {subCategory}
              </LabelContentBold>
            </FeedbackViewDiv>{' '}
          </>
        )}
        {/* TODO: Add subcategory */}
        <FeedbackViewDiv>
          <LabelHeading>Details</LabelHeading>
          <LabelContentBold>{description || 'Unknown'}</LabelContentBold>
        </FeedbackViewDiv>
        <FeedbackViewDiv>
          <LabelHeading>Share With</LabelHeading>
          <LabelContentBold>
            {employeeSharedWith && employeeSharedWith.length > 0
              ? employeeSharedWith.map((item: EmployeeSharedWithProps) => {
                  return (
                    <BlueChip
                      name={`${item.fullName} ${item.employeeId}`}
                      removable={false}
                    />
                  );
                })
              : 'N/A'}
          </LabelContentBold>
        </FeedbackViewDiv>
      </ModalCardContent>
      <ModalFooter small>
        {completed && (
          <FooterDiv>
            <CheckIcon large>check_circle</CheckIcon>
            <LabelContent>
              <CompletedTextDiv>Completed</CompletedTextDiv>
              <DateDiv>{completedDateFormatted}</DateDiv>
            </LabelContent>
          </FooterDiv>
        )}
      </ModalFooter>
    </>
  );
};

export default FeedbackModalView;
