import { useDispatch } from 'react-redux';
import { FC, useCallback, useRef, useState } from 'react';

import { NeuButton, NeuInput, NeuOption } from '@neutron/react';

import { postMasterTemplate } from '../../redux/actions/Template.action';
import { Dropdown } from '../shared/dropdowns';
import { formatRoundingType } from '../../utils/helpers';

import { InputHeading } from './NewMasterTemplate.styles';

const { v1: uuidv1 } = require('uuid');

const NewMasterTemplate: FC<{
  templateTypes: string[];
}> = ({ templateTypes }) => {
  const dispatch = useDispatch();
  const [selectedTemplateType, setSelectedTemplateType] =
    useState<string>('Patient');
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [open, setOpen] = useState(false);

  const templateName = useRef<string>('');

  const handleInputs = (e: any) => {
    const { name } = e.target;
    const { value } = e.target;
    switch (name) {
      case 'templateName':
        templateName.current = value;
        break;

      default: {
        console.log('error');
      }
    }
    if (templateName.current?.length !== 0) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  };

  const handleDropdownChange = useCallback((e: any, name: string) => {
    const { value } = e.target;
    switch (name) {
      case 'templateType':
        setSelectedTemplateType(value);
        break;

      default: {
        console.log('error');
      }
    }
  }, []);

  const handleNewMasterTemplate = () => {
    const newTemplateId = uuidv1();
    const payload = {
      templateId: newTemplateId,
      templateConfigId: newTemplateId,
      name: templateName.current,
      questions: [],
      roundingType: selectedTemplateType.toLowerCase(),
      conditions: [],
      configuration: { questions: {} },
      templateLevel: 'master',
      templateLevelId: 'HCA'
    };
    dispatch(
      postMasterTemplate({
        updatedData: payload,
        createTemplateFlag: true,
        updateTemplateFlag: false
      })
    );
    // appInsights.trackEvent({
    //   name: 'Master_Template_Created'
    // });
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginRight: 40,
            marginLeft: 10,
            flex: '0 0 25%'
          }}
        >
          <InputHeading>Template Type</InputHeading>
          <Dropdown
            classes="pl-0"
            name="templateType"
            open={open}
            placeholder={selectedTemplateType}
            setOpen={setOpen}
            styles={{ paddingTop: '7px' }}
          >
            {templateTypes.map((template: any = {}) => (
              <NeuOption
                className={`dropdown-hover-item${
                  template === selectedTemplateType ? ' selected' : ''
                }`}
                value={template}
                key={template}
                onClick={e => handleDropdownChange(e, 'templateType')}
              >
                {formatRoundingType(template)}
              </NeuOption>
            ))}
          </Dropdown>
        </div>
        <div
          style={{ display: 'flex', flexDirection: 'column', flex: '0 0 25%' }}
        >
          <InputHeading>Template Name</InputHeading>
          <NeuInput
            id="New-Master-Template-Name-Input"
            type="text"
            name="templateName"
            autocomplete="off"
            placeholder="Template Name"
            inputmode="text"
            enterkeyhint="next"
            autocorrect="off"
            // required
            clearable
            spellcheck
            onNeuInput={handleInputs}
          />
        </div>
      </div>
      <NeuButton
        id="Add-Button"
        class="mr-5"
        color="primary"
        fill="raised"
        style={{ marginRight: 'auto', marginLeft: 10, marginTop: 10 }}
        onClick={!isDisabled ? handleNewMasterTemplate : undefined}
        disabled={isDisabled}
      >
        Create Template
      </NeuButton>
    </div>
  );
};

export default NewMasterTemplate;
