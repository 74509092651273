import { call, put, select } from 'redux-saga/effects';

import { getData } from '../../services/API/axios';

import ACTIONS from '../actions/actionType';
import API from '../../services/API';
import { toast } from '../../services/Toast';
import { toTitleCase, unCamelCase } from '../../utils/helpers';

// Admin sagas
export function* getUsers(action) {
  const params = new URLSearchParams(action.data); // making query string
  const url = `${API.getUsersUrl}?${params.toString()}`;
  try {
    const response = yield call(getData, url);
    if (response.status === 200) {
      const cleanData =
        response.data.paginatedUserList?.map(user => ({
          ...user,
          fullName: `${user.firstName} ${user.lastName}`
        })) || [];

      return yield put({
        type: ACTIONS.ADMIN.GET_USERS_SUCCESS,
        data: {
          usersList: cleanData,
          totalCount: response.data.totalUsersCount
        }
      });
    }
    yield put({
      type: ACTIONS.ADMIN.GET_USERS_FAIL
    });
    return toast(`Failed to retrieve users!`, 'error', 1200, 350);
  } catch (error) {
    console.log(error);
    yield put({
      type: ACTIONS.ADMIN.GET_USERS_FAIL
    });
    return toast(`Network Error!`, 'error', 1200, 350);
  }
}

export function* getUsersToExport(action) {
  const params = new URLSearchParams(action.data); // making query string
  const url = `${API.getUsersUrl}?${params.toString()}`;
  try {
    const response = yield call(getData, url);

    if (response.status === 200) {
      const cleanData = [];

      if (response?.data.paginatedUserList.length) {
        const facilities = yield select(
          state => state.ConfigReducer.facilities
        );
        let facility = '';
        let position = '';

        response.data.paginatedUserList.forEach(item => {
          const cleanedItem = {};
          cleanedItem.employee = toTitleCase(
            `${item.firstName} ${item.lastName}`
          );
          cleanedItem['3/4 ID'] = item.hcaid;
          cleanedItem.userRole = item.roles.map(role => unCamelCase(role));
          cleanedItem.accessLevel =
            item.access.accessLevel === 'master'
              ? 'Corporate'
              : toTitleCase(item.access.accessLevel);

          if (item.access.accessLevel === 'facility') {
            const facilitiesArray = [];
            item.access.accessLevelIds.forEach(id => {
              const facilityObj = facilities.find(o => o.facilityId === id);
              facilitiesArray.push(facilityObj?.facility);
              facility = facilitiesArray.toString();
            });
          } else {
            facility = item.access.accessLevelIds.toString();
          }
          cleanedItem.accessPlaces = facility;
          if (item.title) {
            position = `${item.title}, ${item.dept}`;
          } else position = 'Unknown';
          cleanedItem.position = position;

          cleanData.push(cleanedItem);
        });
      }
      yield put({
        type: ACTIONS.ADMIN.GET_CSV_TO_EXPORT_SUCCESS,
        data: cleanData
      });
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: ACTIONS.ADMIN.GET_CSV_TO_EXPORT_FAIL
    });
  }
}

export function* getEncounters(action) {
  const params = new URLSearchParams(action.data); // making query string

  const url = `${API.getEncountersUrl}?${params.toString()}`;
  try {
    const response = yield call(getData, url);

    if (response.status === 200) {
      yield put({
        type: ACTIONS.ADMIN.GET_ENCOUNTERS_SUCCESS,
        data: response.data
      });
    } else if (response.status === 404) {
      // no data for given input
      yield put({
        type: ACTIONS.ADMIN.GET_ENCOUNTERS_SUCCESS,
        data: []
      });
    } else {
      yield put({
        type: ACTIONS.ADMIN.GET_ENCOUNTERS_FAIL,
        data: response.data
      });
    }
  } catch (error) {
    yield put({
      type: ACTIONS.ADMIN.GET_ENCOUNTERS_FAIL,
      data: error
    });
  }
}
export function* getApiVersion() {
  const url = API.getApiVersionUrl;
  try {
    const response = yield call(getData, url);
    if (response.status === 200) {
      yield put({
        type: ACTIONS.ADMIN.GET_API_VERSION_SUCCESS,
        data: response.data
      });
    }
  } catch (error) {
    console.log(error);
  }
}
