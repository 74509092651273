import styled from 'styled-components';

import { NeuTableBody, NeuTable } from '@neutron/react';

export const MyEmployeesContainer = styled.div`
  width: 100%;
`;

export const RoundingConfigContainer = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-between;
  padding: 15px;
  box-shadow: 0px 2px 4px rgba(25, 25, 25, 0.2);
  border-radius: 5px 5px 0px 0px;
  border-bottom: 1px solid #b0b0b0;
`;

export const RoundingConfigHeader = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: #4b4b4b;
`;

export const DropdownOptions = styled.div`
  width: 350px;
`;

export const MyEmployeesTable = styled(NeuTable)`
  background: #ffffff;
  border-radius: 5px;
  height: calc(100% - 100px);
`;

export const MyEmployeesTableBody = styled(NeuTableBody)`
  height: calc(100% - 52px);
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: rgb(224, 224, 224);
    border-radius: 10px;
  }
`;

export const EmployeeSlot = styled.div`
  display: flex;
  gap: 5px;
`;

export const EmployeeAvatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  alignitems: center;
`;

export const EmployeeNameInTable = styled.p`
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #093865;
`;

export const TextInTable = styled.p`
  font-size: 14px;
  font-style: normal;
  display: flex;
  align-items: center;
  letter-spacing: 0px;
  text-align: left;
  color: #4b4b4b;
`;

export const Meta = styled.div`
  padding-top: 5px;
  display: flex;
  alignitems: center;
`;

export const ManagerNameInTable = styled.p`
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #093865;
`;

export const RemoveButton = styled.div`
  display: flex;
  alignitems: center;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #ce3637;
  display: flex;
  align-items: center;
  :hover {
    cursor: pointer;
  }
`;
