import { FC, Dispatch, SetStateAction } from 'react';

import IssuesTable from '../../components/customTables/issuesTable';
import SearchHeader from '../../components/searchHeader';

import { TaskContainer } from './styles/PatientViews.styles';

interface IssuesPageProps {
  searchTerm: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;
}

const PatientIssues: FC<IssuesPageProps> = ({ searchTerm, setSearchTerm }) => {
  return (
    <TaskContainer id="Patient-Issues">
      <SearchHeader searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      <IssuesTable searchTerm={searchTerm} />
    </TaskContainer>
  );
};

export default PatientIssues;
