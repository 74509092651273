/**
 * External Imports
 */
import { FC, useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { NeuTab, NeuIcon } from '@neutron/react';
import { useAnalyticsApi } from '@shared-web-analytics/react/dist';

/**
 * Internal Imports
 */

import { logTableStart } from '../../../utils/analyticsHelpers';
import { setLastPath } from '../../../utils/helpers';

/**
 * Styling Imports
 */
import {
  PopItem,
  IconPositioning,
  InlineRelativeContainer,
  PopContent,
  SupportDropDownContainer
} from '../../header/mainNav/MainNav.styles';
import { useUserRolesRef } from '../../../services/UserRoles';
import { clearSelectedEmployee } from '../../../redux/actions/User.action';

const SupportDropdown: FC<{}> = () => {
  const dispatch = useDispatch();
  const [supportDropdownState, setSupportDropdownState] = useState(false);
  const [, setShowPopover] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { logTrackingEvent } = useAnalyticsApi();

  const {
    isEnterpriseAdmin,
    isMultipleRounders,
    isOnlyTwoTypeRounders,
    isNonSupportRounder,
    isAllSupportRounder,
    isCSRNRounder,
    isCNEdRounder
  } = useUserRolesRef();
  return (
    <>
      {(isEnterpriseAdmin || (isNonSupportRounder && isAllSupportRounder)) && (
        <>
          <NeuTab
            id="Supports-Tab"
            onBlur={() => setSupportDropdownState(false)}
            navy
            active={pathname.includes('support')}
            onClick={() => setSupportDropdownState(!supportDropdownState)}
          >
            <IconPositioning>
              Support
              <NeuIcon small>arrow_drop_down</NeuIcon>
            </IconPositioning>
          </NeuTab>
          <InlineRelativeContainer onBlur={() => setShowPopover(false)}>
            <SupportDropDownContainer showReportDropdown={supportDropdownState}>
              <PopItem
                button
                onMouseDown={() => {
                  dispatch(clearSelectedEmployee());
                  setLastPath('/support/csc/list');
                  navigate('/support/csc/list');
                  logTrackingEvent(logTableStart('/support/csc/list'));
                }}
              >
                <PopContent>CSC Rounding</PopContent>
              </PopItem>
              <PopItem
                button
                onMouseDown={() => {
                  dispatch(clearSelectedEmployee());
                  setLastPath('/support/cned/list');
                  navigate('/support/cned/list');
                  logTrackingEvent(logTableStart('/support/cned/list'));
                }}
              >
                <PopContent>CNEd Rounding</PopContent>
              </PopItem>
            </SupportDropDownContainer>
          </InlineRelativeContainer>
        </>
      )}
      {!isEnterpriseAdmin &&
        ((!isMultipleRounders && isCSRNRounder) ||
          (isOnlyTwoTypeRounders && isAllSupportRounder) ||
          (isMultipleRounders && !isAllSupportRounder && isCSRNRounder)) && (
          <Link
            onClick={() => {
              setLastPath('/support/csc/list');
            }}
            key="CSC-Tab"
            id="CSC-Tab"
            to="/support/csc/list"
          >
            <NeuTab
              active={pathname.includes('support') && pathname.includes('csc')}
              navy
            >
              CSC Rounding
            </NeuTab>
          </Link>
        )}
      {!isEnterpriseAdmin &&
        ((!isMultipleRounders && isCNEdRounder) ||
          (isOnlyTwoTypeRounders && isAllSupportRounder) ||
          (isMultipleRounders && !isAllSupportRounder && isCNEdRounder)) && (
          <Link
            onClick={() => {
              setLastPath('/support/cned/list');
            }}
            key="CNED-Tab"
            id="CNED-Tab"
            to="/support/cned/list"
          >
            <NeuTab
              active={pathname.includes('support') && pathname.includes('cned')}
              navy
            >
              CNEd Rounding
            </NeuTab>
          </Link>
        )}
    </>
  );
};

export default SupportDropdown;
