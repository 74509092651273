/**
 * External Imports
 */
import { FC, useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';

import { NeuTitle, NeuTab, NeuIcon, NeuLabel } from '@neutron/react';
import { useAnalyticsApi } from '@shared-web-analytics/react/dist';

/**
 * Internal Imports
 */
import { IMainNavLinkObject, mainNavLinkObjects } from '../../../Route';

import { RootState } from '../../../redux/store';
import { getApiVersion } from '../../../redux/actions/Admin.action';
import { clearUser, setSessionId } from '../../../redux/actions/Auth.action';
import { clearRoundState } from '../../../redux/actions/Round.action';
import {
  openModal,
  setModalType,
  setModalAction
} from '../../../redux/actions/Modal.action';
import Events from '../../../services/Analytics';

import { useFeatures } from '../../../services/Features/features';

import { logAdHocClick } from '../../../utils/analyticsHelpers';
import {
  handleBlobPicUrl,
  setLastPath,
  userProfilePicUrl
} from '../../../utils/helpers';

/**
 * Global Type Definition Imports
 */
import { LinkObj } from '../../../config/interfaces';

/**
 * Styling Imports
 */
import { EmployeeAvatar } from '../../customTables/employeesTable/EmployeesTable.styles';
import {
  AddNewButton,
  PopItem,
  PopIcon,
  LogoLink,
  NavTabs,
  UserControls,
  MainToolbar,
  Avatar,
  LogoImg,
  DropDownContainer,
  PopContent
} from './MainNav.styles';

/**
 * Static File Imports
 */
import defaultImage from '../../../assets/images/defaultImage.png';
import HelpDropdown from '../../customDropdowns/NavDropdown/HelpDropdown';
import packageInfo from '../../../../package.json';
import { useUserRolesRef } from '../../../services/UserRoles';

interface MainNavProps {
  apiVersion: string;
  authUserRoles: Array<string>;
  sasToken: string;
  userCoid: string;
  userId: string;
  userImageUrl: string;
  userName: string;
}

const MainNav: FC<MainNavProps> = ({
  apiVersion,
  authUserRoles,
  sasToken,
  userCoid,
  userId,
  userImageUrl,
  userName
}) => {
  const dispatch = useDispatch();
  const {
    isEnterpriseAdmin,
    isPatientRounder,
    isCSRNRounder,
    isCNEdRounder,
    isEmployeeRounder,
    isAllSupportRounder,
    isMultipleRounders,
    isOnlyTwoTypeRounders,
    isSupportRounder,
    isTemplateConfigurator,
    isUserAdmin
  } = useUserRolesRef();

  const [popoverState, setShowPopover] = useState(false);
  const [apiVersionString, setApiVersionString] = useState(packageInfo.version);

  const { logTrackingEvent } = useAnalyticsApi();
  const { isCNEdEnabled } = useFeatures();

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const logOutUser = () => {
    window.localStorage.removeItem('authToken');
    window.localStorage.removeItem('orbitLastPath');
    window.localStorage.removeItem('34');
    sessionStorage.removeItem('sessionId');
    dispatch(setSessionId(null));
    dispatch(clearUser());
    navigate('/login');
    console.log('User Logged Out');
  };

  const handleVersionChange = () => {
    if (apiVersion && apiVersionString === packageInfo.version) {
      setApiVersionString(`API version ${apiVersion}`);
    } else {
      setApiVersionString(packageInfo.version);
    }
  };
  const toggleModal = (mType: string) => {
    if (
      mType === 'Add Issue' ||
      mType === 'Feedback' ||
      mType === 'Add Stoplight'
    ) {
      logTrackingEvent(logAdHocClick(mType, 'add'));
      dispatch(setModalAction('UNIVERSAL-ADD'));
    }
    dispatch(setModalType(mType));
    dispatch(openModal());
    setShowPopover(!popoverState);
  };
  useEffect(() => {
    dispatch(getApiVersion());
  }, []);

  const returnRouteLinks = (route: LinkObj, isAllowed: boolean) => {
    const trimRoute = route.url.split('/')[1]; // take first part of pathname

    if (route.navItemText && isAllowed) {
      return (
        <Link
          onClick={() => {
            if (route.navItemText === 'Employees') {
              logTrackingEvent([
                {
                  module: Events.Employee.Module,
                  screen: Events.Employee.Screen.List.Name,
                  eventName: null,
                  eventType: 'start',
                  org: {
                    orgId: userCoid,
                    orgLevel: 'Facility'
                  }
                }
              ]);
            } else if (route.navItemText === 'Patients') {
              logTrackingEvent([
                {
                  module: Events.Patient.Module,
                  screen: Events.Patient.Screen.Census.Name,
                  eventName: null,
                  eventType: 'start',
                  org: {
                    orgId: userCoid,
                    orgLevel: 'Facility'
                  }
                }
              ]);
            }
            setLastPath(route.url);
          }}
          key={`${route.navItemText}"-Tab"`}
          id={`${route.navItemText}"-Tab"`}
          to={route.url}
        >
          <NeuTab active={pathname.includes(trimRoute)} navy>
            {route.navItemText}
          </NeuTab>
        </Link>
      );
    }
    return null;
  };

  const createLinks = (links: IMainNavLinkObject[]) => {
    if (authUserRoles) {
      const approvedRoutes: (JSX.Element | null)[] = [];
      links.forEach(routeItem => {
        let isAllowed = false;
        if (
          routeItem.allowedRoles.some(role => authUserRoles?.includes(role))
        ) {
          isAllowed = true;
          if (routeItem.component) {
            approvedRoutes.push(routeItem.component);
          } else {
            approvedRoutes.push(returnRouteLinks(routeItem, isAllowed));
          }
        }
      });

      return approvedRoutes;
    }
    return null;
  };

  return (
    <MainToolbar id="MainNav">
      <LogoLink nav href="#!" ariaLabel="Title Link to Homepage">
        <NeuTitle className="neu-text-center neu-text-md-left" color="plain-0">
          <LogoImg
            height="40px"
            src="/assets/Orbit_Logo.png"
            alt="Orbit Logo"
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            Orbit
            <NeuLabel onClick={handleVersionChange} style={{ fontSize: 10 }}>
              {apiVersionString}
            </NeuLabel>
          </div>
        </NeuTitle>
      </LogoLink>
      <NavTabs
        class="d-md-block mx-4 d-md-left"
        onClick={() => {
          dispatch(clearRoundState());
        }}
      >
        {createLinks(mainNavLinkObjects)}
        {(isEnterpriseAdmin ||
          !(
            isAllSupportRounder ||
            (!isMultipleRounders && isSupportRounder)
          )) && (
          <NeuTab
            onClick={() => {
              window.open('https://resolve.hcahealthcare.cloud/', '_blank');
            }}
            navy
          >
            <div
              id="Resolve-Icon"
              className="d-flex align-items-center flex-row-reverse justify-content-space-between"
            >
              Resolve
              <NeuIcon style={{ marginLeft: '3px' }} small>
                launch
              </NeuIcon>
            </div>
          </NeuTab>
        )}
        <HelpDropdown key="Help-Tab" />
      </NavTabs>

      {(isEnterpriseAdmin ||
        !(
          (!isMultipleRounders && isTemplateConfigurator) ||
          (!isMultipleRounders && isUserAdmin) ||
          (isCSRNRounder && !isMultipleRounders) ||
          (((isOnlyTwoTypeRounders && isAllSupportRounder) ||
            (isCNEdRounder && !isMultipleRounders)) &&
            !isCNEdEnabled)
        )) && (
        <div
          id="Universal-Add-New-Btn"
          style={{ position: 'relative' }}
          onBlur={() => setShowPopover(false)}
        >
          <AddNewButton
            data-testid="Universal-Add-New-Btn"
            onClick={() => setShowPopover(true)}
          >
            <NeuIcon>add</NeuIcon>Add New
          </AddNewButton>

          <DropDownContainer showPopover={popoverState}>
            <PopItem
              id="Universal-Add-Issue"
              button
              onMouseDown={() => toggleModal('Add Issue')}
            >
              <PopContent justify="start">
                <PopIcon>flag</PopIcon>
                Add Issue
              </PopContent>
            </PopItem>
            <PopItem
              id="Universal-Add-Feedback"
              button
              onMouseDown={() => toggleModal('Feedback')}
            >
              <PopContent justify="start">
                <PopIcon>campaign</PopIcon>Add Feedback
              </PopContent>
            </PopItem>
            {(isEnterpriseAdmin ||
              !(
                (isOnlyTwoTypeRounders && isAllSupportRounder) ||
                (isCNEdRounder && !isMultipleRounders)
              )) && (
              <PopItem
                id="Universal-Add-Stoplight"
                button
                onMouseDown={() => toggleModal('Add Stoplight')}
              >
                <PopContent justify="start">
                  <PopIcon>traffic</PopIcon>Add Stoplight
                </PopContent>
              </PopItem>
            )}
          </DropDownContainer>
        </div>
      )}

      <Link
        to={
          isEnterpriseAdmin
            ? '/account/facility-and-unit-settings'
            : isEmployeeRounder
            ? '/account/employee-rounding-settings'
            : (!isMultipleRounders && isCSRNRounder) ||
              (isPatientRounder && isCSRNRounder)
            ? '/account/employee-rounding-settings/csc-list'
            : (!isMultipleRounders && isCNEdRounder) ||
              (isPatientRounder && isCNEdRounder)
            ? '/account/employee-rounding-settings/cned-list'
            : '/account/facility-and-unit-settings'
        }
        onClick={() =>
          isEnterpriseAdmin
            ? setLastPath('/account/facility-and-unit-settings')
            : isEmployeeRounder
            ? setLastPath('/account/employee-rounding-settings')
            : (!isMultipleRounders && isCSRNRounder) ||
              (isPatientRounder && isCSRNRounder)
            ? setLastPath('/account/employee-rounding-settings/csc-list')
            : isCNEdRounder || (isPatientRounder && isCNEdRounder)
            ? setLastPath('/account/employee-rounding-settings/cned-list')
            : setLastPath('/account/facility-and-unit-settings')
        }
      >
        <UserControls
          id="User-Account"
          style={{ textDecoration: 'none' }}
          user={`${userName} | Account`}
          logout={process.env.REACT_APP_ENV !== 'production'}
          onNeuChange={logOutUser}
        >
          <Avatar
            slot="avatar"
            color="gray-60"
            style={{ height: '40px', width: '40px' }}
          >
            {sasToken && userImageUrl ? (
              <EmployeeAvatar
                src={
                  handleBlobPicUrl(userImageUrl, sasToken) ??
                  userProfilePicUrl(userId, sasToken)
                }
                alt="User"
                onError={(e: any) => {
                  e.currentTarget.src = defaultImage;
                  return null;
                }}
              />
            ) : (
              <EmployeeAvatar src={defaultImage} alt="Default Image" />
            )}
          </Avatar>
          {/* Commenting out until Notifications are incorporated  */}
          {/* <NeuDrawerButton drawer="second" slot="notification">
            <NeuNotification>
              <NeuBadge slot="alerts" color="danger">
                8
              </NeuBadge>
            </NeuNotification>
          </NeuDrawerButton> */}
        </UserControls>
      </Link>
    </MainToolbar>
  );
};

const mapReduxStateToProps = (state: RootState) => {
  return {
    apiVersion: state.AdminReducer.apiVersion,
    authUserRoles: state.AuthorizedUser.authorizedUser?.roles,
    modalState: state.ModalReducer.modal,
    sasToken: state.ConfigReducer.sasToken,
    userCoid: state.AuthorizedUser.authorizedUser.coid,
    userId: state.AuthorizedUser.authorizedUser.hcaid,
    userImageUrl: state.AuthorizedUser.authorizedUser.pictureUrl
  };
};

export default connect(mapReduxStateToProps)(MainNav);
