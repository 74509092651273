import { FC, SetStateAction, Dispatch } from 'react';
import { useDispatch } from 'react-redux';

import { NeuSubLeftNav, NeuItem, NeuStepper, NeuLabel } from '@neutron/react';

import { setAdminUserTab } from '../../../../redux/actions/Admin.action';

interface SideNavProps {
  selectedTab: string;
  setSelectedTab: Dispatch<SetStateAction<string>>;
}

const SideNav: FC<SideNavProps> = ({ selectedTab, setSelectedTab }) => {
  const dispatch = useDispatch();
  const changeTab = (tab: string) => {
    dispatch(setAdminUserTab(tab));
    setSelectedTab(tab);
  };

  return (
    <NeuSubLeftNav
      id="Admin-Side-Nav"
      name="MENU"
      color="plain"
      side="left"
      style={{ marginTop: '-3px' }}
    >
      <NeuItem
        id="Admin-Side-Nav-All"
        color={selectedTab === 'allUsers' ? 'primary-10' : 'plain-0'}
        className="pl-3"
        onClick={() => changeTab('allUsers')}
      >
        <NeuStepper slot="start" />
        <NeuLabel>All Users</NeuLabel>
      </NeuItem>
      <NeuItem
        id="Admin-Side-Nav-Patient-Rounders"
        color={selectedTab === 'patientRounder' ? 'primary-10' : 'plain-0'}
        className="pl-3"
        onClick={() => changeTab('patientRounder')}
      >
        <NeuStepper slot="start" />
        <NeuLabel>Patient Rounders</NeuLabel>
      </NeuItem>
      <NeuItem
        id="Admin-Side-Nav-Employee-Rounders"
        color={selectedTab === 'employeeRounder' ? 'primary-10' : 'plain-0'}
        className="pl-3"
        onClick={() => changeTab('employeeRounder')}
      >
        <NeuStepper slot="start" />
        <NeuLabel> Employee Rounders </NeuLabel>
      </NeuItem>
      <NeuItem
        id="Admin-Side-Nav-Validators"
        color={selectedTab === 'validationRounder' ? 'primary-10' : 'plain-0'}
        className="pl-3"
        onClick={() => changeTab('validationRounder')}
      >
        <NeuStepper slot="start" />
        <NeuLabel>Validation Rounder</NeuLabel>
      </NeuItem>
      <NeuItem
        id="Admin-Side-Nav-Configurators"
        color={selectedTab === 'csrnRounder' ? 'primary-10' : 'plain-0'}
        className="pl-3"
        onClick={() => changeTab('csrnRounder')}
      >
        <NeuStepper slot="start" />
        <NeuLabel>CSC Rounder</NeuLabel>
      </NeuItem>
      <NeuItem
        id="Admin-Side-Nav-Configurators"
        color={selectedTab === 'cnedRounder' ? 'primary-10' : 'plain-0'}
        className="pl-3"
        onClick={() => changeTab('cnedRounder')}
      >
        <NeuStepper slot="start" />
        <NeuLabel>CNEd Rounder</NeuLabel>
      </NeuItem>
      <NeuItem
        id="Admin-Side-Nav-Configurators"
        color={
          selectedTab === 'templateConfigurator' ? 'primary-10' : 'plain-0'
        }
        className="pl-3"
        onClick={() => changeTab('templateConfigurator')}
      >
        <NeuStepper slot="start" />
        <NeuLabel>Configurators</NeuLabel>
      </NeuItem>
      <NeuItem
        id="Admin-Side-Nav-User-Admins"
        color={selectedTab === 'userAdmin' ? 'primary-10' : 'plain-0'}
        className="pl-3"
        onClick={() => changeTab('userAdmin')}
      >
        <NeuStepper slot="start" />
        <NeuLabel>User Admins</NeuLabel>
      </NeuItem>
      <NeuItem
        id="Admin-Side-Nav-Enterprise"
        color={selectedTab === 'enterpriseAdmin' ? 'primary-10' : 'plain-0'}
        className="pl-3"
        onClick={() => changeTab('enterpriseAdmin')}
      >
        <NeuStepper slot="start" />
        <NeuLabel>Enterprise Admins</NeuLabel>
      </NeuItem>
    </NeuSubLeftNav>
  );
};

export default SideNav;
