import { FC, ReactElement } from 'react';

import FeatureNotEnabled from '../errorPage/FeatureNotEnabled';

interface IFeatureRouteProps {
  component: ReactElement;
  isFeatureEnabled: boolean;
  offsetHeight: number;
}

const FeatureRoute: FC<IFeatureRouteProps> = ({
  component,
  isFeatureEnabled,
  offsetHeight
}) => {
  /**
   * Check if Feature is enabled if so show component if not show Feature screen
   */
  return isFeatureEnabled ? (
    component
  ) : (
    <FeatureNotEnabled offsetHeight={offsetHeight} />
  );
};

export default FeatureRoute;
