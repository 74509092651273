/**
 * External Imports
 */
import { FC, useEffect, useState, Dispatch, SetStateAction } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
/**
 * Internal Imports
 */
import SideNav from '../../components/sideNav/adminSideNav/usersListSideNav';
import SearchHeader from '../../components/searchHeader';
import UsersTable from '../../components/customTables/usersTable';

import { RootState } from '../../redux/store';
import {
  clearAdminUserFilter,
  getUsers,
  getUsersToExport
} from '../../redux/actions/Admin.action';
import { getFacilities } from '../../redux/actions/User.action';

import { useDebounceValue } from '../../utils/debouncers';
import { isHca34 } from '../../utils/helpers';
/**
 * Global Type Definition Imports
 */
import { Filter } from '../../config/interfaces';
/**
 * Style Imports
 */
import { UsersListContainer } from './styles/AdminViews.styles';

interface UsersListPageProps {
  searchTerm: string;
  adminUserFilter: Filter;
  setSearchTerm: Dispatch<SetStateAction<string>>;
}

const UsersList: FC<UsersListPageProps> = ({
  searchTerm,
  adminUserFilter,
  setSearchTerm
}) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [selectedTab, setSelectedTab] = useState<string>('allUsers');
  const [selectedPage, setCurrentPage] = useState<number>(1);

  // debouncing search terms
  const debouncedUserSearchTerm = useDebounceValue(searchTerm, 275);

  useEffect(() => {
    // clear filters when pathname changes
    dispatch(clearAdminUserFilter());
  }, [pathname]);

  useEffect(() => {
    // clear search when tab changes
    setSearchTerm('');
  }, [selectedTab]);

  useEffect(() => {
    dispatch(getFacilities()); // no params
  }, []);

  useEffect(() => {
    dispatch(
      getUsers({
        page: 0,
        role:
          selectedTab === 'allUsers'
            ? adminUserFilter?.userRole || ''
            : selectedTab,
        accessLevel: adminUserFilter?.accessLevel || '',
        accessLevelId: adminUserFilter?.accessPlace || '',
        name: !isHca34(debouncedUserSearchTerm)
          ? debouncedUserSearchTerm.trim()
          : '',
        hcaId: isHca34(debouncedUserSearchTerm)
          ? debouncedUserSearchTerm.toLowerCase().trim()
          : ''
      })
    );
  }, [selectedTab, debouncedUserSearchTerm, adminUserFilter]);

  useEffect(() => {
    dispatch(
      getUsers({
        page: selectedPage - 1,
        role:
          selectedTab === 'allUsers'
            ? adminUserFilter?.userRole || ''
            : selectedTab,
        accessLevel: adminUserFilter?.accessLevel || '',
        accessLevelId: adminUserFilter?.accessPlace || '',
        name: !isHca34(searchTerm) ? searchTerm.trim() : '',
        hcaId: isHca34(searchTerm) ? searchTerm.toLowerCase().trim() : ''
      })
    );
  }, [selectedPage]);

  const handleExportCsv = async () => {
    await dispatch(
      // get all the pages with selected criteria
      getUsersToExport({
        page: -1,
        role:
          selectedTab === 'allUsers'
            ? adminUserFilter?.userRole || ''
            : selectedTab,
        name: !isHca34(searchTerm) ? searchTerm.trim() : '',
        hcaId: isHca34(searchTerm) ? searchTerm.toLowerCase().trim() : '',
        accessLevel: adminUserFilter?.accessLevel || '',
        accessLevelId: adminUserFilter?.accessPlace || ''
      })
    );

    // there could be more conditions to meet when filter functionality is ready
  };

  return (
    <UsersListContainer id="Admin-User-List">
      <SideNav selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
      <div style={{ width: '100%' }}>
        <SearchHeader
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          handleExportCsv={handleExportCsv}
        />
        <UsersTable setCurrentPage={setCurrentPage} />;
      </div>
    </UsersListContainer>
  );
};

const mapReduxStateToProps = (state: RootState) => {
  return {
    adminUserFilter: state.AdminReducer.adminUserFilter,
    isUsersLoading: state.AdminReducer.isLoading
  };
};

export default connect(mapReduxStateToProps)(UsersList);
