/**
 * External Imports
 */
import { FC, useMemo } from 'react';
import { connect, useDispatch } from 'react-redux';
import { NeuButton } from '@neutron/react';
import { useAnalyticsApi } from '@shared-web-analytics/react/dist';
/**
 * Internal Imports
 */
import { RootState } from '../../../../redux/store';
import { completeRound } from '../../../../redux/actions/Round.action';
import { date } from '../../../../utils/helpers';
import { logRoundClick } from '../../../../utils/analyticsHelpers';
/**
 * Global Type Definition Imports
 */
import { Survey, SurveyAnswers } from '../../../../config/interfaces';
/**
 * Style Imports
 */
import { CustomNeuSpinner } from './CompleteRoundBtn.styles';

interface ICompleteRoundBtnProps {
  chosenSurvey: any;
  currentRound: Survey;
  isEdit: boolean;
  loading: boolean;
  type: string;
}

const CompleteRoundBtn: FC<ICompleteRoundBtnProps> = ({
  chosenSurvey,
  currentRound,
  isEdit,
  loading,
  type
}) => {
  const dispatch = useDispatch();
  const buttonText = isEdit ? 'Update Round' : 'Complete Round';

  const { logTrackingEvent } = useAnalyticsApi();

  const handleComplete = () => {
    if (buttonText === 'Complete Round')
      logTrackingEvent(logRoundClick('complete'));
    dispatch(completeRound({ type }));
  };

  const isAbleRoundSelected = useMemo(() => {
    if (type === 'patient') {
      if (
        ((Object.keys(currentRound).length > 0 &&
          currentRound.answers &&
          currentRound.answers.some(
            (q: SurveyAnswers) =>
              q.answer &&
              q.answer.length > 0 &&
              q.answer[0]?.flag === 'Able Round'
          )) ||
          (Object.keys(currentRound).length > 0 &&
            currentRound.answers &&
            currentRound.answers.some(
              (q: SurveyAnswers) =>
                q.answer &&
                q.answer.length > 0 &&
                (q.answer[0]?.flag === 'Unable to Round' ||
                  q.answer[0]?.flag === 'Unable Round')
            ) &&
            currentRound.answers.some(
              (q: SurveyAnswers) =>
                q.questionPartId === 'ab19e93e-8012-45c9-8405-58e9ede03297' &&
                q.answer &&
                q.answer.length > 0
            )) ||
          (Object.keys(currentRound).length > 0 &&
            currentRound.answers &&
            currentRound.answers.some(
              (q: SurveyAnswers) =>
                q.answer &&
                q.answer.length > 0 &&
                (q.answer[0].flag === 'Unable to Round' ||
                  q.answer[0].flag === 'Unable Round')
            ) &&
            currentRound.answers.some(
              (q: SurveyAnswers) =>
                q.questionPartId === '94d526b0-a84c-11eb-8ff3-9f58e1c7e115' &&
                q.answer &&
                q.answer.length > 0
            ))) &&
        Object.keys(chosenSurvey).length > 0 &&
        chosenSurvey.questions &&
        chosenSurvey.questions
          .filter((question: any) => question.questionTags.includes('REQUIRED'))
          .every((question: any) =>
            currentRound.answers.some(
              ({ questionId, answer }) =>
                question.questionId === questionId &&
                answer &&
                answer.length > 0
            )
          )
      ) {
        return true;
      }
    } else if (type === 'employee' || type === 'csc' || type === 'cned') {
      return (
        Object.keys(chosenSurvey).length > 0 &&
        Object.keys(currentRound).length > 0 &&
        chosenSurvey.questions?.length > 0 &&
        currentRound.answers?.length > 0 &&
        currentRound.answers.some(
          ({ answer }: SurveyAnswers) => answer && answer.length > 0
        ) &&
        chosenSurvey?.questions
          .filter((question: any) => question.questionTags.includes('REQUIRED'))
          .every((question: any) =>
            currentRound.answers.some(
              ({ questionId, answer }) =>
                question.questionId === questionId &&
                answer &&
                answer.length > 0
            )
          )
      );
    }
    return false;
  }, [chosenSurvey, currentRound, type]);

  const currentTime = date.convertToDateTime(new Date());
  const isTimePresent = useMemo(() => {
    if (
      currentRound.roundDate &&
      date.convertToDateTime(date.parseDate(currentRound.roundDate)) <=
        currentTime
    ) {
      return true;
    }
    return false;
  }, [currentRound.roundDate, currentTime]);

  return (
    <NeuButton
      disabled={!isTimePresent || !isAbleRoundSelected}
      id="Complete-Round-Button"
      className="mx-2 ml-8"
      color="green-60"
      fill="raised"
      onClick={() => (isAbleRoundSelected ? handleComplete() : {})}
    >
      {loading && (
        <CustomNeuSpinner
          color="plain-0"
          tiny
          ariaLabel="Loading Spinner"
          default
          role="status"
        />
      )}
      {!loading && buttonText}
    </NeuButton>
  );
};

const mapReduxStateToProps = (state: RootState) => ({
  authorizedUser: state.AuthorizedUser.authorizedUser,
  chosenSurvey: state.TemplateReducer.chosenSurvey,
  loading: state.RoundReducer.loading,
  currentRound: state.RoundReducer.currentRound
});

export default connect(mapReduxStateToProps)(CompleteRoundBtn);
