// Importing action file
import ACTIONS from '../actions/actionType';

import { ActionType } from '../../config/interfaces';

const initialState = {
  locationStoplights: [],
  stoplightById: {},
  totalCount: 0,
  stoplightFilter: {},
  loading: false,
  loadingStoplight: false,
  currentStoplightPage: 1,
  error: '',
  searchedStoplight: [],
  stoplightResponse: null,
  sortKey: 'created',
  sortDir: 'desc',
  stoplightSearchQuery: '',
  stopSharingQ: {}
};

const StoplightReducer = (state = initialState, action: ActionType = {}) => {
  const tempState = { ...state };

  switch (action.type) {
    case ACTIONS.STOPLIGHT.GET_STOPLIGHTS_BY_LOCATION: {
      return {
        ...state,
        loading: true
      };
    }
    case ACTIONS.STOPLIGHT.GET_STOPLIGHTS_BY_LOCATION_SUCCESS: {
      return {
        ...state,
        loading: false,
        locationStoplights: action.data.results,
        totalCount: action.data.totalCount
      };
    }
    case ACTIONS.STOPLIGHT.GET_STOPLIGHTS_BY_LOCATION_FAILED: {
      return {
        ...state,
        loading: false,
        locationStoplights: []
      };
    }
    case ACTIONS.STOPLIGHT.POST_NEW_STOPLIGHT: {
      return {
        ...state,
        loadingStoplight: true
      };
    }
    case ACTIONS.STOPLIGHT.POST_NEW_STOPLIGHT_SUCCESS: {
      return {
        ...state,
        stoplightResponse: action.data
      };
    }
    case ACTIONS.STOPLIGHT.GET_STOPLIGHT_BY_ID: {
      return {
        ...state,
        loadingStoplight: true
      };
    }
    case ACTIONS.STOPLIGHT.GET_STOPLIGHT_BY_ID_SUCCESS: {
      return {
        ...state,
        loadingStoplight: false,
        stoplightById: action.data
      };
    }
    case ACTIONS.STOPLIGHT.UPDATE_STOPLIGHT: {
      return {
        ...state,
        loadingStoplight: true
      };
    }
    case ACTIONS.STOPLIGHT.UPDATE_STOPLIGHT_SUCCESS: {
      return {
        ...state,
        loadingStoplight: false
      };
    }
    case ACTIONS.STOPLIGHT.DELETE_STOPLIGHT: {
      return {
        ...state,
        loadingStoplight: true
      };
    }
    case ACTIONS.STOPLIGHT.DELETE_STOPLIGHT_SUCCESS: {
      return {
        ...state,
        loadingStoplight: false
      };
    }
    case ACTIONS.STOPLIGHT.SEARCH_STOPLIGHTS: {
      return {
        ...state,
        loading: true,
        searchedStoplight: []
      };
    }
    case ACTIONS.STOPLIGHT.CLEAR_SEARCH_STOPLIGHTS: {
      return {
        ...state,
        searchedStoplight: []
      };
    }
    case ACTIONS.STOPLIGHT.SEARCH_STOPLIGHTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        searchedStoplight: action.data
      };
    }
    case ACTIONS.STOPLIGHT.ADD_STOPLIGHT_TO_SHARING_Q: {
      return {
        ...state,
        stopSharingQ: action.data
      };
    }
    case ACTIONS.STOPLIGHT.SHARE_STOPLIGHT_SUCCESS: {
      return {
        ...state,
        loadingStoplight: false,
        stopSharingQ: {}
      };
    }
    case ACTIONS.STOPLIGHT.SHARE_STOPLIGHT_FAILED: {
      return {
        ...state,
        error: action.data
      };
    }
    // Clear stoplight response
    case ACTIONS.STOPLIGHT.CLEAR_STOPLIGHT_RESPONSE: {
      return {
        ...state,
        stoplightResponse: null,
        stoplightById: {}
      };
    }

    case ACTIONS.STOPLIGHT.POST_COMMENT_TO_STOPLIGHT_SUCCESS: {
      return {
        ...state,
        success: action.data
      };
    }

    case ACTIONS.STOPLIGHT.SET_STOPLIGHT_FILTER: {
      return {
        ...state,
        stoplightFilter: action.data,
        currentStoplightPage: 1
      };
    }

    case ACTIONS.STOPLIGHT.CLEAR_STOPLIGHT_FILTER: {
      return {
        ...state,
        stoplightFilter: {},
        currentStoplightPage: 1
      };
    }

    case ACTIONS.STOPLIGHT.SET_STOPLIGHT_SORT: {
      return {
        ...state,
        ...action.data
      };
    }

    case ACTIONS.STOPLIGHT.SET_CURRENT_STOPLIGHT_PAGE: {
      return {
        ...state,
        currentStoplightPage: action.data
      };
    }

    case ACTIONS.STOPLIGHT.CLEAR_SELECTED_STOPLIGHT: {
      return {
        ...state,
        stoplightById: {},
        loadingStoplight: false
      };
    }

    case ACTIONS.STOPLIGHT.SET_STOPLIGHT_SEARCH_QUERY: {
      return {
        ...state,
        stoplightSearchQuery: action.data,
        currentStoplightPage: 1
      };
    }

    default:
      return tempState;
  }
};

export default StoplightReducer;
