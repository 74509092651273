import styled from 'styled-components';
import {
  NeuCard,
  NeuCardHeader,
  NeuInput,
  NeuLabel,
  NeuTextarea,
  NeuCardActions,
  NeuCardContent,
  NeuCardTitle
} from '@neutron/react';

export const ProvisioningCard = styled(NeuCard)`
  display: flex;
  flex-direction: column;
  background: #f6f6f6 !important;
  z-index: 10;
  padding-inline-start: 0;
  padding-inline-end: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin-inline-end: 0;
  margin-top: 0;
`;

export const CardHeader = styled(NeuCardHeader)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  width: 100%;
  --background: white;
`;

export const ModalCardContent = styled(NeuCardContent)`
  flex: 1;
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-behavior: smooth;
  width: 432px;
`;
export const ModalTitle = styled(NeuCardTitle)`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  color: #093865 !important;
`;

export const RadioContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 0.9375rem;
  margin-bottom: 0.9375rem;
  max-width: 413px;
`;

export const InputHeading = styled(NeuLabel)`
  display: inline-block;
  font-size: 0.875rem;
  line-height: 22px;
  width: 413px;
  margin-top: 12px;
`;

export const RadioLabel = styled(NeuLabel)`
  margin-right: 30px;
  margin-left: 3px;
`;

export const ModalInput = styled(NeuInput)`
  width: 97%;
  height: 42px;
`;

export const ModalTextArea = styled(NeuTextarea)`
  width: 97%;
  // height: 88px;
  // margin-bottom: 1.25rem;
  background: #ffffff;
  font-size: 16px;
  line-height: 26px;
  color: #000000;
`;

export const ModalFooter = styled(NeuCardActions)`
  background: white !important;
  bottom: 0;
  height: 69px !important;
`;
