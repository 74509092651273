import styled from 'styled-components';
import {
  NeuCardContent,
  NeuCardTitle,
  NeuContainer,
  NeuIcon,
  NeuLabel
} from '@neutron/react';

export const BlueSpanText = styled.span`
  color: #2075ad !important;
`;

export const GreyCardTitle = styled(NeuCardTitle)`
  color: #686868 !important;
  font-weight: 500;
`;

export const MultiContainer = styled(NeuContainer)`
  width: 25em;
`;

export const NextQuestionIcon = styled(NeuIcon)`
  font-size: 24px;
`;

export const PastAnswerContent = styled(NeuCardContent)`
  flex-basis: 20%;
  margin-top: -0.8rem !important;
  padding-top: 0;
`;

export const PastQuestionTitle = styled(NeuCardTitle)`
  flex-basis: 80%;
  font-size: 1rem !important;
`;

export const GreyPastQuestionTitle = styled(PastQuestionTitle)`
  color: #686868 !important;
  font-size: 0.9rem !important;
  font-weight: 500;
`;

export const PastAnswerText = styled(NeuLabel)`
  font-weight: 600;
  font-size: 1rem;
`;
