import { FC } from 'react';
import { NeuAvatar, NeuIcon, NeuChip } from '@neutron/react';

export const BlueChip: FC<{
  disabled?: boolean | undefined;
  name: string;
  removable?: boolean | undefined;
  icon?: string | undefined;
}> = ({ disabled = false, name, removable = true, icon }) => {
  return (
    <NeuChip
      id={`Blue-Chip-${name}`}
      color={!disabled ? 'primary' : 'gray-70'}
      avatar
      removable={removable ? true : undefined}
      className={`mx-1${icon === ' ' ? ' pl-3 ' : ''}`}
    >
      {name}
      {icon !== ' ' && (
        <NeuAvatar slot="start">
          <NeuIcon>{icon || 'person'}</NeuIcon>
        </NeuAvatar>
      )}
      {removable && <NeuIcon slot="end">remove_circle_outline</NeuIcon>}
    </NeuChip>
  );
};
