/**
 * External Imports
 */
import { FC, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { NeuTableHeading, NeuButton, NeuOption } from '@neutron/react';
/**
 * Internal Imports
 */
import { Dropdown } from '../../shared/dropdowns';
import SearchField from '../../searchHeader/searchField/SearchField';
import FeatureNotEnabled from '../../../pages/errorPage/FeatureNotEnabled';
import Table from '../../shared/table';

import { RootState } from '../../../redux/store';
import { updateCurrentFacility } from '../../../redux/actions/Account.action';
import { setAdminFacility } from '../../../redux/actions/Admin.action';
import {
  getCSRNEmployeesConfig,
  updateCSRNHiddenStatus
} from '../../../redux/actions/Employee.action';

import { useFeatures } from '../../../services/Features/features';
import { useDebounceValue } from '../../../utils/debouncers';
import {
  handleBlobPicUrl,
  toTitleCaseSpecial,
  userProfilePicUrl
} from '../../../utils/helpers';

/**
 * Global Type Definition Imports
 */
import {
  AuthUserProps,
  CsrnEmployee,
  IFacility
} from '../../../config/interfaces';

/**
 * Style Imports
 */
import {
  EmployeeNameInTable,
  TextInTable,
  MyEmployeesContainer,
  RoundingConfigContainer,
  EmployeeAvatar,
  Meta,
  EmployeeSlot,
  ManagerNameInTable,
  RemoveButton,
  ContentHeader,
  EmployeeRow,
  CsrnStyleContainer,
  FacilityInput,
  RoundingConfigHeader
} from './CSRNListSettingsTable.styles';

import defaultImage from '../../../assets/images/defaultImage.png';
import { useUserRolesRef } from '../../../services/UserRoles';

interface CSRNListSettingsProps {
  authorizedUser?: AuthUserProps;
  csrnEmployees: CsrnEmployee[];
  facilities: IFacility[];
  facilityId: string;
  loadingSupportList: boolean;
  sasToken: string;
  userId: string;
}

const CSRNListSettingsTable: FC<CSRNListSettingsProps> = ({
  authorizedUser,
  csrnEmployees,
  facilities,
  facilityId,
  loadingSupportList,
  sasToken,
  userId
}) => {
  const dispatch = useDispatch();
  const {
    isEnterpriseAdmin,
    isAllSupportRounder,
    isCSRNRounder,
    isMultipleRounders,
    isOnlyTwoTypeRounders
  } = useUserRolesRef();

  const [filteredList, setFilteredList] = useState<CsrnEmployee[]>([]);
  const [active, setActive] = useState<string>('lastName');
  const [sortDir, setSortDir] = useState<'desc' | 'asc'>('asc');
  const [isTableEmpty, setIsTableEmpty] = useState(false);
  const [isTableEmptySearch, setIsTableEmptySearch] = useState(false);
  const [selectedFacility, setSelectedFacility] = useState('');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedFacilityName, setSelectedFacilityName] = useState('');
  const [facilitySearchTerm, setFacilitySearchTerm] = useState('');
  const [facilitySearchState, setFacilitySearchState] =
    useState<boolean>(false);
  const [open, setOpen] = useState(false);

  const dbSearchTerm = useDebounceValue(searchTerm, 250);
  const { isCSRNEnabled } = useFeatures();

  const setFacilityForUpdate = (selectedFac: IFacility) => {
    setSelectedFacility(selectedFac.id);
    setSelectedFacilityName(selectedFac.value);
    setFacilitySearchState(false);
    setFacilitySearchTerm('');
  };

  const facilityName = (id: string) => {
    const foundFacility =
      facilities && facilities.filter((item: IFacility) => item.id === id);
    return foundFacility[0]?.value;
  };

  const handleHideEmployee = (employee: CsrnEmployee) => {
    dispatch(
      updateCSRNHiddenStatus({
        userId: authorizedUser?.hcaid,
        employeeId: employee.hcaid,
        isHidden: !employee.isHidden
      })
    );
    setSearchTerm('');
  };

  const sortByHeading = (heading: string) => {
    const stateCopy = filteredList?.length
      ? // eslint-disable-next-line no-unsafe-optional-chaining
        [...filteredList]
      : [];

    if (sortDir === 'desc') {
      setSortDir('asc');
      if (heading === 'isHidden') {
        stateCopy.sort((a: CsrnEmployee, b: CsrnEmployee) =>
          a[heading as keyof CsrnEmployee] > b[heading as keyof CsrnEmployee]
            ? 1
            : -1
        );
      } else {
        stateCopy.sort((a: CsrnEmployee, b: CsrnEmployee) =>
          a[heading as keyof CsrnEmployee]?.toLowerCase() >
          b[heading as keyof CsrnEmployee]?.toLowerCase()
            ? 1
            : -1
        );
      }
      setActive(heading);
    }
    if (sortDir === 'asc') {
      setSortDir('desc');
      if (heading === 'isHidden') {
        stateCopy.sort((a: CsrnEmployee, b: CsrnEmployee) =>
          a[heading as keyof CsrnEmployee] > b[heading as keyof CsrnEmployee]
            ? -1
            : 1
        );
      } else {
        stateCopy.sort((a: CsrnEmployee, b: CsrnEmployee) =>
          a[heading as keyof CsrnEmployee]?.toLowerCase() >
          b[heading as keyof CsrnEmployee]?.toLowerCase()
            ? -1
            : 1
        );
      }
      setActive(heading);
    }

    setFilteredList(stateCopy);
  };

  useEffect(() => {
    if (isCSRNEnabled) dispatch(getCSRNEmployeesConfig(authorizedUser?.hcaid));
  }, [authorizedUser, isCSRNEnabled]);

  useEffect(() => {
    if (selectedFacility && selectedFacility !== facilityId) {
      dispatch(
        updateCurrentFacility({
          userId,
          facilityId: selectedFacility
        })
      );
    }
  }, [selectedFacility]);

  // TODO: Comment out until 'Add Employees' functionality is in scope
  // const openEditModal = () => {
  //   dispatch(setModalType('Add Employees'));
  //   dispatch(openModal());
  // };

  useEffect(() => {
    if (csrnEmployees!.length > 0) {
      // Sorting the list A-Z for initial display
      const sorted = csrnEmployees!.sort((a: CsrnEmployee, b: CsrnEmployee) =>
        a.lastName > b.lastName ? 1 : -1
      );
      setFilteredList(sorted);
      setIsTableEmpty(false);
    } else {
      setIsTableEmpty(true);
      setFilteredList([]);
    }
  }, [csrnEmployees]);

  useEffect(() => {
    setSelectedFacility(facilityId);
    dispatch(setAdminFacility(facilityId));
  }, [facilityId]);

  useEffect(() => {
    const formattedSearchTerm = dbSearchTerm.toLowerCase();
    const filtered = csrnEmployees.filter(
      (employee: CsrnEmployee) =>
        employee.employeeName.toLowerCase().includes(formattedSearchTerm) ||
        employee.hcaid.includes(formattedSearchTerm)
    );
    setFilteredList(filtered);
    if (filtered.length > 0) {
      setIsTableEmptySearch(false);
    } else {
      setIsTableEmptySearch(true);
    }
  }, [dbSearchTerm, csrnEmployees]);

  const columns =
    "{'Employee':'20%', 'Department':'24%','Title':'22%', 'Manager':'20%','Hide':'14%'}";

  const headers: JSX.Element[] = [
    <NeuTableHeading
      id="Csrn-Employee-List-Table-Employee-Column"
      key="Employee"
      slot="Employee"
      icon={
        !(active === 'lastName') ? 'asc' : sortDir === 'asc' ? 'desc' : 'asc'
      }
      onClick={() => sortByHeading('lastName')}
      active={active === 'lastName'}
    >
      Employee
    </NeuTableHeading>,
    <NeuTableHeading
      id="Csrn-Employee-List-Table-Dept-Column"
      key="Department"
      slot="Department"
      icon={!(active === 'dept') ? 'asc' : sortDir === 'asc' ? 'desc' : 'asc'}
      onClick={() => sortByHeading('dept')}
      active={active === 'dept'}
    >
      Department
    </NeuTableHeading>,
    <NeuTableHeading
      id="Csrn-Employee-List-Table-Title-Column"
      key="Title"
      slot="Title"
      icon={!(active === 'title') ? 'asc' : sortDir === 'asc' ? 'desc' : 'asc'}
      onClick={() => sortByHeading('title')}
      active={active === 'title'}
    >
      Title
    </NeuTableHeading>,
    <NeuTableHeading
      id="Csrn-Employee-List-Table-Manager-Column"
      key="Manager"
      slot="Manager"
      icon={
        !(active === 'managerFullName')
          ? 'asc'
          : sortDir === 'asc'
          ? 'desc'
          : 'asc'
      }
      onClick={() => sortByHeading('managerFullName')}
      active={active === 'managerFullName'}
    >
      Manager
    </NeuTableHeading>,
    <NeuTableHeading
      id="Csrn-Employee-List-Table-Hide-Column"
      key="Hide"
      slot="Hide"
      icon={
        !(active === 'isHidden') ? 'asc' : sortDir === 'asc' ? 'desc' : 'asc'
      }
      onClick={() => sortByHeading('isHidden')}
      active={active === 'isHidden'}
    >
      Show on List
    </NeuTableHeading>
  ];

  const formattedCsrnEmployeeRow = (employee: CsrnEmployee) => {
    return (
      <>
        <EmployeeSlot slot="Employee">
          {sasToken ? (
            <EmployeeAvatar
              src={
                handleBlobPicUrl(employee.pictureUrl, sasToken) ??
                userProfilePicUrl(employee.hcaid, sasToken)
              }
              alt="Employee Image"
              onError={e => {
                e.currentTarget.src = defaultImage;
                return null;
              }}
            />
          ) : (
            <EmployeeAvatar src={defaultImage} alt="Default Image" />
          )}
          <div>
            <EmployeeNameInTable>{employee.employeeName}</EmployeeNameInTable>
            <TextInTable>{employee.hcaid}</TextInTable>
          </div>
        </EmployeeSlot>
        <Meta style={{ whiteSpace: 'normal' }} slot="Department">
          <TextInTable>{employee.dept}</TextInTable>
        </Meta>
        <Meta slot="Title">
          <TextInTable style={{ whiteSpace: 'normal' }}>
            {employee.title}
          </TextInTable>
        </Meta>
        <Meta slot="Manager">
          <div>
            <ManagerNameInTable>
              {employee.managerFullName || 'Unknown'}
            </ManagerNameInTable>
            <TextInTable>{employee.managerHcaid}</TextInTable>
          </div>
        </Meta>
        <RemoveButton
          slot="Hide"
          style={{
            color: !employee.isHidden ? '#CE3637' : '#2075AD'
          }}
          onClick={() => handleHideEmployee(employee)}
        >
          {!employee.isHidden ? (
            <NeuButton color="#CE3637" fill="outline" size="small">
              Hide
            </NeuButton>
          ) : (
            <NeuButton color="#2075AD" fill="outline" size="small">
              Show
            </NeuButton>
          )}
        </RemoveButton>
      </>
    );
  };
  const formatRows = (csrnEmployeesList: CsrnEmployee[]) => {
    return csrnEmployeesList.map((employee: CsrnEmployee) => (
      <EmployeeRow
        id={`Employee-Row-${employee.hcaid}`}
        key={employee.hcaid}
        columns={columns}
        size="large"
      >
        {formattedCsrnEmployeeRow(employee)}
      </EmployeeRow>
    ));
  };
  const row = formatRows(filteredList);
  const rows: (boolean | JSX.Element)[] = [...row];
  return (
    <MyEmployeesContainer id="My-Employees-Container">
      <RoundingConfigContainer>
        <ContentHeader>
          {csrnEmployees.length > 1 && (
            <SearchField
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />
          )}
        </ContentHeader>
        {authorizedUser &&
          ((!isMultipleRounders && isCSRNRounder) ||
            (isOnlyTwoTypeRounders && isAllSupportRounder)) && (
            <FacilityInput>
              <RoundingConfigHeader style={{ padding: '0px 16px' }}>
                CSC Facility
              </RoundingConfigHeader>
              <div
                style={{
                  position: 'relative',
                  padding: '8px 16px'
                }}
              >
                <Dropdown
                  classes="pl-0"
                  open={open}
                  placeholder={
                    facilitySearchState
                      ? facilitySearchTerm
                      : selectedFacilityName || facilityName(facilityId)
                  }
                  disabled={
                    !isEnterpriseAdmin &&
                    authorizedUser.access.accessLevelIds.length <= 1
                  }
                  disabledWithTransparent={
                    !isEnterpriseAdmin &&
                    authorizedUser.access.accessLevelIds.length <= 1
                  }
                  width="96%"
                  styles={{ paddingTop: '3px' }}
                  setOpen={setOpen}
                >
                  {facilities.map((facility: IFacility) => (
                    <NeuOption
                      style={{ paddingLeft: '0.75em' }}
                      key={facility.id}
                      value={facility.value}
                      onClick={() => setFacilityForUpdate(facility)}
                    >
                      {toTitleCaseSpecial(facility.value)}
                    </NeuOption>
                  ))}
                </Dropdown>
              </div>
            </FacilityInput>
          )}
      </RoundingConfigContainer>
      {isCSRNEnabled ? (
        <CsrnStyleContainer>
          <Table
            cols={columns}
            headers={headers}
            rows={rows}
            isTableEmpty={isTableEmpty}
            isTableEmptySearch={isTableEmptySearch}
            loading={loadingSupportList}
            customClass="csc-accounts-settings"
          />
        </CsrnStyleContainer>
      ) : (
        <FeatureNotEnabled offsetHeight={263} />
      )}
    </MyEmployeesContainer>
  );
};

const mapReduxStateToProps = (state: RootState) => {
  return {
    authorizedUser: state.AuthorizedUser.authorizedUser,
    csrnEmployees: state.EmployeeReducer.csrnEmployees,
    facilities: state.UserReducer.facilities,
    facilityId: state.AuthorizedUser.authorizedUser.facilityId,
    loadingSupportList: state.EmployeeReducer.loadingSupportList,
    sasToken: state.ConfigReducer.sasToken,
    userId: state.AuthorizedUser.authorizedUser.hcaid
  };
};
export default connect(mapReduxStateToProps)(CSRNListSettingsTable);
