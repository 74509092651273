import styled from 'styled-components';

export const RoundCloseContainer = styled.div`
  align-items: center;
  min-height: 48px;
  position: relative;
`;

export const CloseBtnContainer = styled.div`
  position: absolute;
  left: 0;
`;

export const DateTimeSelectContainer = styled.div`
  padding-bottom: 8px;
  position: relative;
  width: 267px;
`;

export const FutureTimeErrorSpan = styled.span`
  color: #ce3637;
  font-size: 14px;
  font-weight: bold;
  margin-top: 4px;
  position: absolute;
  bottom: -1rem;
`;
