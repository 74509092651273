import { FC, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import {
  NeuTableRow,
  NeuTableHeading,
  NeuButton,
  NeuOption
} from '@neutron/react';

import NoDataSplashView from '../../noDataSplashView/NoDataSplashView';
import { Dropdown } from '../../shared/dropdowns';
import Loading from '../../shared/Loading';

import { RootState } from '../../../redux/store';
import { getEmployeesConfig } from '../../../redux/actions/Employee.action';
import { openModal, setModalType } from '../../../redux/actions/Modal.action';
import {
  postEmployeeListConfig,
  removeEmployeeFromUser
} from '../../../redux/actions/User.action';

import {
  toTitleCase,
  handleBlobPicUrl,
  userProfilePicUrl
} from '../../../utils/helpers';

import { AuthUserProps, Employee } from '../../../config/interfaces';

import {
  EmployeeNameInTable,
  TextInTable,
  MyEmployeesTableBody,
  MyEmployeesTable,
  RemoveButton,
  MyEmployeesContainer,
  RoundingConfigHeader,
  RoundingConfigContainer,
  EmployeeAvatar,
  Meta,
  EmployeeSlot,
  DropdownOptions,
  ManagerNameInTable
} from './MyEmployeeListTable.styles';

import defaultImage from '../../../assets/images/defaultImage.png';

interface MyEmployeesListProps {
  authorizedUser?: AuthUserProps;
  loadingMyEmployees: boolean;
  myEmployeesList?: Employee[];
  sasToken: string;
}

const MyEmployeeListTable: FC<MyEmployeesListProps> = ({
  authorizedUser,
  loadingMyEmployees,
  myEmployeesList,
  sasToken
}) => {
  const dispatch = useDispatch();

  const [filteredList, setFilteredList] = useState<Employee[]>([]);
  const [mounted, setMounted] = useState<boolean>(false);
  const [active, setActive] = useState<string>('lastName');
  const [sortDir, setSortDir] = useState<'desc' | 'asc'>('asc');
  const [selectedRoundingFrequency, setSelectedRoundingFrequency] = useState<
    string | undefined
  >(
    authorizedUser?.employeeInfo?.myRoundingListConfig?.listRoundingFrequency ||
      'monthly'
  );
  const [selectedRetentionStrategy, setSelectedRetentionStrategy] = useState<
    string | undefined
  >(
    authorizedUser?.employeeInfo?.myRoundingListConfig?.newHireStrategy.toString() ||
      ''
  );
  const [isTableEmpty, setIsTableEmpty] = useState(false);
  const [openFreq, setOpenFreq] = useState(false);
  const [openRetention, setOpenRetention] = useState(false);

  const openEditModal = () => {
    dispatch(setModalType('Add Employees'));
    dispatch(openModal());
  };

  const handleRemoveEmployee = (employee: Employee) => {
    const payload = {
      userId: authorizedUser?.hcaid,
      employeeId: employee?.hcaid
    };
    dispatch(removeEmployeeFromUser(payload));
  };

  const sortByHeading = (heading: string) => {
    const stateCopy = myEmployeesList?.length
      ? // eslint-disable-next-line no-unsafe-optional-chaining
        [...myEmployeesList]
      : [];

    if (sortDir === 'desc') {
      setSortDir('asc');
      stateCopy.sort((a: Employee, b: Employee) =>
        a[heading as keyof Employee]?.toLowerCase() >
        b[heading as keyof Employee]?.toLowerCase()
          ? 1
          : -1
      );
      setActive(heading);
    }
    if (sortDir === 'asc') {
      setSortDir('desc');
      stateCopy.sort((a: Employee, b: Employee) =>
        a[heading as keyof Employee]?.toLowerCase() >
        b[heading as keyof Employee]?.toLowerCase()
          ? -1
          : 1
      );
      setActive(heading);
    }

    setFilteredList(stateCopy);
  };
  const handleRoundingSelect = (selected: any) => {
    const selectedRound = selected.target.value;

    setSelectedRoundingFrequency(selectedRound);
  };
  const handleStrategySelect = (selected: any) => {
    const selectedItem = selected.target.value;

    setSelectedRetentionStrategy(selectedItem);
  };

  useEffect(() => {
    dispatch(getEmployeesConfig(authorizedUser?.hcaid));
  }, [authorizedUser]);

  useEffect(() => {
    if (myEmployeesList!.length > 0) {
      // Sorting the list A-Z for initial display
      const sorted = myEmployeesList!.sort((a: Employee, b: Employee) =>
        a.lastName > b.lastName ? 1 : -1
      );
      setFilteredList(sorted);
      setIsTableEmpty(false);
    } else {
      setIsTableEmpty(true);
      setFilteredList([]);
    }
  }, [myEmployeesList]);

  useEffect(() => {
    if (mounted) {
      dispatch(
        postEmployeeListConfig({
          userId: authorizedUser?.hcaid,
          newHireStrategy: [selectedRetentionStrategy],
          listRoundingFrequency: selectedRoundingFrequency
        })
      );
    } else {
      setMounted(true);
    }
  }, [selectedRetentionStrategy, selectedRoundingFrequency]);

  return (
    <MyEmployeesContainer id="My-Employees-Container">
      <RoundingConfigContainer>
        <div>
          <RoundingConfigHeader style={{ padding: '0px 16px' }}>
            Rounding Frequency
          </RoundingConfigHeader>
          <DropdownOptions>
            <Dropdown
              open={openFreq}
              placeholder={toTitleCase(selectedRoundingFrequency) || ''}
              setOpen={setOpenFreq}
            >
              <NeuOption
                className={`dropdown-hover-item${
                  selectedRoundingFrequency === 'monthly' ? ' selected' : ''
                }`}
                value="monthly"
                onClick={handleRoundingSelect}
              >
                Monthly
              </NeuOption>
              <NeuOption
                className={`dropdown-hover-item${
                  selectedRoundingFrequency === 'bi-monthly' ? ' selected' : ''
                }`}
                value="bi-monthly"
                onClick={handleRoundingSelect}
              >
                Bi-Monthly
              </NeuOption>
              <NeuOption
                className={`dropdown-hover-item${
                  selectedRoundingFrequency === 'quarterly' ? ' selected' : ''
                }`}
                value="quarterly"
                onClick={handleRoundingSelect}
              >
                Quarterly
              </NeuOption>
            </Dropdown>
          </DropdownOptions>
        </div>
        <div>
          <RoundingConfigHeader style={{ padding: '0px 16px' }}>
            New Hire Retention Strategy
          </RoundingConfigHeader>
          <DropdownOptions>
            <Dropdown
              placeholder={
                toTitleCase(
                  selectedRetentionStrategy?.toString().replace('-', ' ')
                ) || ''
              }
              open={openRetention}
              setOpen={setOpenRetention}
            >
              <NeuOption
                className={`dropdown-hover-item${
                  selectedRetentionStrategy === '30-days' ? ' selected' : ''
                }`}
                value="30-days"
                onClick={handleStrategySelect}
              >
                30 Days
              </NeuOption>
              <NeuOption
                className={`dropdown-hover-item${
                  selectedRetentionStrategy === '60-days' ? ' selected' : ''
                }`}
                value="60-days"
                onClick={handleStrategySelect}
              >
                60 Days
              </NeuOption>
              <NeuOption
                className={`dropdown-hover-item${
                  selectedRetentionStrategy === '90-days' ? ' selected' : ''
                }`}
                value="90-days"
                onClick={handleStrategySelect}
              >
                90 Days
              </NeuOption>
            </Dropdown>
          </DropdownOptions>
        </div>
        <NeuButton
          style={{ marginRight: '10px', paddingTop: '15px' }}
          color="primary"
          fill="raised"
          onClick={openEditModal}
        >
          Add Employees
        </NeuButton>
      </RoundingConfigContainer>
      <MyEmployeesTable>
        <NeuTableRow
          style={{ backgroundColor: 'white' }}
          columns="{'Employee':'22%', 'Department':'22%','Title':'22%', 'Manager':'22%','Delete':'12%'}"
          header
        >
          <NeuTableHeading
            id="My-Employee-List-Table-Employee-Column"
            slot="Employee"
            icon={
              !(active === 'lastName')
                ? 'asc'
                : sortDir === 'asc'
                ? 'desc'
                : 'asc'
            }
            onClick={() => sortByHeading('lastName')}
            active={active === 'lastName'}
          >
            Employee
          </NeuTableHeading>
          <NeuTableHeading
            id="My-Employee-List-Table-Department-Column"
            slot="Department"
            icon={
              !(active === 'dept') ? 'asc' : sortDir === 'asc' ? 'desc' : 'asc'
            }
            onClick={() => sortByHeading('dept')}
            active={active === 'dept'}
          >
            Department
          </NeuTableHeading>
          <NeuTableHeading
            id="My-Employee-List-Table-Title-Column"
            slot="Title"
            icon={
              !(active === 'title') ? 'asc' : sortDir === 'asc' ? 'desc' : 'asc'
            }
            onClick={() => sortByHeading('title')}
            active={active === 'title'}
          >
            Title
          </NeuTableHeading>
          <NeuTableHeading
            id="My-Employee-List-Table-Manager-Column"
            slot="Manager"
            icon={
              !(active === 'managerFullName')
                ? 'asc'
                : sortDir === 'asc'
                ? 'desc'
                : 'asc'
            }
            onClick={() => sortByHeading('managerFullName')}
            active={active === 'managerFullName'}
          >
            Manager
          </NeuTableHeading>
          <NeuTableHeading
            id="Employee-List-Table-Delete-Column"
            slot="Delete"
            icon="none"
          />
        </NeuTableRow>
        {loadingMyEmployees ? (
          <Loading centered={false} />
        ) : isTableEmpty && !loadingMyEmployees ? (
          <NoDataSplashView type="employee-list" />
        ) : (
          <MyEmployeesTableBody>
            {filteredList?.map((employee: Employee) => {
              return (
                employee && (
                  <NeuTableRow
                    columns="{'Employee':'22%', 'Department':'22%','Title':'22%', 'Manager':'22%','Delete':'12%'}"
                    size="normal"
                    key={employee.hcaid}
                    style={{ alignItems: 'center' }}
                  >
                    <EmployeeSlot slot="Employee">
                      {sasToken ? (
                        <EmployeeAvatar
                          src={
                            handleBlobPicUrl(employee.pictureUrl, sasToken) ??
                            userProfilePicUrl(employee.hcaid, sasToken)
                          }
                          alt="Employee Image"
                          onError={e => {
                            e.currentTarget.src = defaultImage;
                            return null;
                          }}
                        />
                      ) : (
                        <EmployeeAvatar
                          src={defaultImage}
                          alt="Default Image"
                        />
                      )}
                      <div>
                        <EmployeeNameInTable>{`${employee.firstName} ${employee.lastName}`}</EmployeeNameInTable>
                        <TextInTable>{employee.hcaid}</TextInTable>
                      </div>
                    </EmployeeSlot>
                    <Meta slot="Department">
                      <TextInTable>{employee.dept}</TextInTable>
                    </Meta>
                    <Meta slot="Title">
                      <TextInTable>{employee.title}</TextInTable>
                    </Meta>
                    <Meta slot="Manager">
                      <div>
                        <ManagerNameInTable>
                          {employee.managerFullName || 'Unknown'}
                        </ManagerNameInTable>
                        <TextInTable>{employee.managerHcaid}</TextInTable>
                      </div>
                    </Meta>
                    <RemoveButton
                      slot="Delete"
                      onClick={() => handleRemoveEmployee(employee)}
                    >
                      <NeuButton color="#CE3637" fill="outline" size="small">
                        Remove
                      </NeuButton>
                    </RemoveButton>
                  </NeuTableRow>
                )
              );
            })}
          </MyEmployeesTableBody>
        )}
      </MyEmployeesTable>
    </MyEmployeesContainer>
  );
};

const mapReduxStateToProps = (state: RootState) => {
  return {
    authorizedUser: state.AuthorizedUser.authorizedUser,
    loadingMyEmployees: state.EmployeeReducer.loadingMyEmployees,
    myEmployeesList: state.EmployeeReducer.myEmployees,
    sasToken: state.ConfigReducer.sasToken
  };
};
export default connect(mapReduxStateToProps)(MyEmployeeListTable);
