import styled from 'styled-components';

import { NeuInput, NeuIcon } from '@neutron/react';

export const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const SearchInput = styled(NeuInput)<{ width?: number }>`
  padding: 8px 6px 8px 16px;
  position: static;
  margin-left: 15px;
  width: ${props => (props.width ? `${props.width}px` : '375px')} !important;
`;

export const SearchIcon = styled(NeuIcon)`
  background: #2075ad;
  border-radius: 5px;
  width: 30px !important;
  height: 30px !important;
  padding-top: 5px;
  padding-left: 5px;
  position: relative;
  right: 40px;
  z-index: 2;
`;
