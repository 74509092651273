// Importing action file
import ACTIONS from '../actions/actionType';

import { ActionType } from '../../config/interfaces';

const initialState = {
  hidden: true,
  options: {},
  slide: 'hide',
  type: ''
};

/**
 * State dispatch service for updating modal context
 */
const ToastReducer = (state = initialState, action: ActionType = {}) => {
  const tempState = { ...state };

  switch (action.type) {
    case ACTIONS.TOAST.SLIDE_IN_TOAST:
      return { ...tempState, hidden: false, slide: 'show' };
    case ACTIONS.TOAST.SLIDE_OUT_TOAST:
      return { ...tempState, slide: 'hide' };
    case ACTIONS.TOAST.HIDE_TOAST:
      return { ...tempState, hidden: true, options: {}, type: '' };
    case ACTIONS.TOAST.SET_TOAST_OPTIONS:
      return { ...tempState, options: { ...action.data } };
    case ACTIONS.TOAST.SET_TOAST_TYPE:
      return { ...tempState, type: action.data.type };
    default:
      return { ...tempState };
  }
};

export default ToastReducer;
