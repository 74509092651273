import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { connect, useDispatch } from 'react-redux';
import { add, format } from 'date-fns';

import SearchHeader from '../../../components/searchHeader';
import EmployeeRoundsReportTable from '../../../components/customTables/employeeRoundsReportTable';

import { RootState } from '../../../redux/store';
import {
  clearEmployeeRounds,
  clearRoundsReportFilter,
  getEmployeeRounds,
  getEmployeeRoundsReport
} from '../../../redux/actions/Report.action';

import { date } from '../../../utils/helpers';

import {
  AuthUserProps,
  DatesStartEnd,
  EmpRoundsReport,
  Filter
} from '../../../config/interfaces';

import { ReportContainer } from '../styles/ReportsViews.styles';

interface RoundsReportProps {
  authorizedUser: AuthUserProps;
  currentEmployeeRoundsReportPage: number;
  employeeRoundsReport: EmpRoundsReport[];
  reportSearchQuery: string;
  roundsReportFilter: Filter;
  searchTerm: string;
  sortKey: string;
  sortDir: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;
}

const RoundsReport: FC<RoundsReportProps> = ({
  authorizedUser,
  currentEmployeeRoundsReportPage,
  employeeRoundsReport,
  reportSearchQuery,
  roundsReportFilter,
  searchTerm,
  sortKey,
  sortDir,
  setSearchTerm
}) => {
  const dispatch = useDispatch();

  const today = new Date();
  const [customDates, setCustomDates] = useState<DatesStartEnd>({
    startDate: format(
      new Date(today.getFullYear(), today.getMonth(), 1),
      'yyyy-MM-dd'
    ),
    endDate: format(today, 'yyyy-MM-dd')
  });

  useEffect(() => {
    return () => {
      dispatch(clearRoundsReportFilter());
      dispatch(clearEmployeeRounds());
    };
  }, []);

  useEffect(() => {
    dispatch(
      getEmployeeRounds({
        facilityId: authorizedUser?.facilityId,
        query: reportSearchQuery || '',
        roundedBy: roundsReportFilter.employee || '',
        templateName: roundsReportFilter.template || '',
        sortBy: sortKey,
        sortOrder: sortDir,
        startday: date.formatFilterStartDayToUTCTimestamp(
          customDates.startDate.replace(/-/g, '')
        ),
        endday: date.formatFilterEndDayToUTCTimestamp(
          format(
            add(date.localDayOfYearDate(customDates.endDate), { days: 1 }),
            'yyyy-MM-dd'
          ).replace(/-/g, '')
        ),
        page: currentEmployeeRoundsReportPage
      })
    );
  }, [
    authorizedUser?.facilityId,
    customDates,
    reportSearchQuery,
    roundsReportFilter,
    sortKey,
    sortDir,
    currentEmployeeRoundsReportPage
  ]);

  const listToDisplay = useMemo(() => {
    if (employeeRoundsReport?.length) {
      const cleanData = employeeRoundsReport.map((round: EmpRoundsReport) => ({
        ...round,
        department: round?.employeeDept || '',
        employee:
          `${round?.employeeFirstName} ${round?.employeeLastName}` || '',
        roundedBy: `${round?.userFirstName} ${round?.userLastName}`,
        createdUserId: round?.userId || '',
        date: round?.roundDate || '',
        status: round?.completed ? 'Rounded' : 'Not Rounded'
      }));

      return cleanData;
    }
    return [];
  }, [employeeRoundsReport]);

  const handleExportCsv = useCallback(() => {
    dispatch(
      getEmployeeRoundsReport({
        facilityId: authorizedUser?.facilityId,
        query: reportSearchQuery || '',
        roundedBy: roundsReportFilter.employee || '',
        templateName: roundsReportFilter.template || '',
        sortBy: sortKey,
        sortOrder: sortDir,
        startday: date.formatFilterStartDayToUTCTimestamp(
          customDates.startDate.replace(/-/g, '')
        ),
        endday: date.formatFilterEndDayToUTCTimestamp(
          format(
            add(date.localDayOfYearDate(customDates.endDate), { days: 1 }),
            'yyyy-MM-dd'
          ).replace(/-/g, '')
        )
      })
    );
  }, [
    authorizedUser?.facilityId,
    customDates,
    reportSearchQuery,
    roundsReportFilter,
    sortKey,
    sortDir
  ]);

  return (
    <ReportContainer id="Employee-Rounds-Report-Container">
      <SearchHeader
        customDates={customDates}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        handleExportCsv={handleExportCsv}
        setCustomDates={setCustomDates}
      />
      <EmployeeRoundsReportTable roundsReportList={listToDisplay} />
    </ReportContainer>
  );
};

const mapReduxStateToProps = (state: RootState) => ({
  authorizedUser: state.AuthorizedUser.authorizedUser,
  currentEmployeeRoundsReportPage:
    state.ReportReducer.currentEmployeeRoundsReportPage,
  employeeRoundsReport: state.ReportReducer.employeeRoundsReport,
  reportSearchQuery: state.ReportReducer.reportSearchQuery,
  roundsReportFilter: state.ReportReducer.roundsReportFilter,
  sortKey: state.ReportReducer.sortRoundsKey,
  sortDir: state.ReportReducer.sortRoundsDir
});

export default connect(mapReduxStateToProps)(RoundsReport);
