import styled from 'styled-components';

import { NeuLabel } from '@neutron/react';

const BrandLabel = styled(NeuLabel)`
  font-weight: bold;
  font-size: 3rem;
  font-family: HCA-Mark-Bold;
`;

const LoginForm = styled.div`
  width: 16em;
`;

const LoginContainer = styled.div`
  background-color: #f6f6f6;
`;

export { BrandLabel, LoginContainer, LoginForm };
