import {
  useEffect,
  useMemo,
  useRef,
  Dispatch,
  SetStateAction,
  FC
} from 'react';

import {
  StyledDropdownDiv,
  StyledOptionsDiv,
  StyledSelect,
  StyledSelectDiv,
  StyledSelectHiddenDiv,
  StyledSelectHiddenInput
} from './Dropdown.styles';

export interface IDropdownProps {
  id?: string;
  classes?: string;
  closeOnClick?: boolean;
  disabled?: boolean;
  disabledWithTransparent?: boolean;
  errors?: boolean;
  focused?: boolean;
  name?: string;
  open: boolean;
  placeholder: string;
  placeholderAlign?: any;
  query?: string;
  styles?: React.CSSProperties | undefined;
  typeAhead?: boolean;
  width?: string;
  setOpen: Dispatch<SetStateAction<boolean>> | ((bool: boolean) => void);
  setTypeAhead?: (val: string) => void;
  setTypeAheadChange?: (e: any) => void;
}

interface IDropdownWithChildrenProps extends IDropdownProps {
  children: any;
}

export const Dropdown: FC<IDropdownWithChildrenProps> = ({
  id,
  children,
  classes,
  closeOnClick = true,
  disabled,
  disabledWithTransparent = false,
  errors,
  focused,
  name,
  open,
  placeholder,
  placeholderAlign = 'left',
  query = '',
  styles,
  typeAhead,
  width = '100%',
  setOpen,
  setTypeAhead,
  setTypeAheadChange
}) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const closeFromOutside = ({ target }: Event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(target as HTMLDivElement) &&
      !(
        (target as HTMLDivElement).classList.contains('custom-chip') ||
        (target as HTMLDivElement).classList.contains('custom-chip__delete')
      )
    ) {
      setOpen(false);
      if (name && setTypeAhead) setTypeAhead('');
    }
  };
  useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', closeFromOutside);
    }
    return () => document.removeEventListener('mousedown', closeFromOutside);
  }, [open]);

  useEffect(() => {
    if (inputRef.current) inputRef.current.focus();
  }, [focused]);

  const openStyle = useMemo(() => {
    return open
      ? {
          display: 'block',
          width
        }
      : {
          display: 'none',
          width
        };
  }, [open, width]);

  return (
    <StyledDropdownDiv
      id={id}
      className={`custom-dropdown${classes ? ` ${classes}` : ''}`}
      style={styles}
      ref={dropdownRef}
    >
      <StyledSelectDiv
        className="custom-select-box"
        data-testid={`${name}-custom-select-box`}
        disabled={disabled}
        query={query}
        onClick={
          !disabled
            ? () => {
                if (typeAhead) setOpen(true);
                if (!typeAhead) setOpen(!open);
                if (name && setTypeAhead) setTypeAhead(name);
              }
            : () => {}
        }
        aria-hidden="true"
      >
        <StyledSelect
          className={`custom-select${errors ? ' custom-border-danger' : ''}`}
          disabled={disabled && !disabledWithTransparent}
          focused={focused}
          placeholder={!focused ? placeholder : ''}
          textAlign={placeholderAlign}
        >
          <option>{placeholder}</option>
        </StyledSelect>
        {typeAhead && focused ? (
          <StyledSelectHiddenInput
            ref={inputRef}
            typeAhead={typeAhead}
            value={query}
            onChange={setTypeAheadChange}
          />
        ) : (
          <StyledSelectHiddenDiv />
        )}
      </StyledSelectDiv>
      {((children && children.length > 0) || query) && (
        <StyledOptionsDiv
          query
          style={openStyle}
          onClick={closeOnClick ? () => setOpen(false) : undefined}
        >
          {children}
        </StyledOptionsDiv>
      )}
    </StyledDropdownDiv>
  );
};
