/**
 * @description All Events will be written inside this file. Format for this event are as follows
 */

const Events = {
  Employee: {
    Module: 'Employees',
    Screen: {
      Issues: {
        Name: 'Issues',
        Events: {
          Filter_Click: 'FilterClick',
          Add_Issue_Click: 'Add_IssueClick',
          Save_Issue_Click: 'Save_IssueClick',
          Close_Issue_Click: 'Close_IssueClick',
          Download_Click: 'DownloadClick',
          Search_Click: 'SearchClick'
        }
      },
      Feedback: {
        Name: 'Feedback',
        Events: {
          Filter_Click: 'FilterClick',
          Add_Feedback_Click: 'Add_FeedbackClick',
          Save_Feedback_Click: 'Save_FeedbackClick',
          Close_Feedback_Click: 'Close_FeedbackClick',
          Download_Click: 'DownloadClick',
          Search_Click: 'SearchClick'
        }
      },
      Stoplights: {
        Name: 'Stoplight',
        Events: {
          Filter_Click: 'FilterClick',
          Add_Stoplight_Click: 'Add_StoplightClick',
          Save_Stoplight_Click: 'Save_StoplightClick',
          Close_Stoplight_Click: 'Close_StoplightClick',
          Download_Click: 'DownloadClick',
          Search_Click: 'SearchClick'
        }
      },
      List: {
        Name: 'List',
        Events: {
          ToDo_Click: 'To_DoClick',
          Completed_Click: 'CompletedClick',
          NewHires_Click: 'New_HiresClick',
          Search_Click: 'SearchClick'
        }
      },
      Profile: {
        Name: 'Employee Profile',
        Events: {
          Notes_Click: 'NotesClick',
          SaveNotes_Click: 'Save_NotesClick',
          PastRounds_Click: 'Past_RoundsClick',
          PastIssues_Click: 'Past_IssuesClick',
          PastFeedback_Click: 'Past_FeedbackClick',
          StartaNewRound_Click: 'Start_a_New_RoundClick',
          CompleteRound_Click: 'Complete_RoundClick',
          CancelRound_Click: 'Cancel_RoundClick',
          Calendar_Click: 'CalendarClick'
        }
      }
    }
  },
  Patient: {
    Module: 'Patients',
    Screen: {
      Issues: {
        Name: 'Issues',
        Events: {
          Filter_Click: 'FilterClick',
          Add_Issue_Click: 'Add_IssueClick',
          Save_Issue_Click: 'Save_IssueClick',
          Close_Issue_Click: 'Close_IssueClick',
          Download_Click: 'DownloadClick',
          Search_Click: 'SearchClick'
        }
      },
      Feedback: {
        Name: 'Feedback',
        Events: {
          Filter_Click: 'FilterClick',
          Add_Feedback_Click: 'Add_FeedbackClick',
          Save_Feedback_Click: 'Save_FeedbackClick',
          Close_Feedback_Click: 'Close_FeedbackClick',
          Download_Click: 'DownloadClick',
          Search_Click: 'SearchClick'
        }
      },
      Stoplights: {
        Name: 'Stoplight',
        Events: {
          Filter_Click: 'FilterClick',
          Add_Stoplight_Click: 'Add_StoplightClick',
          Save_Stoplight_Click: 'Save_StoplightClick',
          Close_Stoplight_Click: 'Close_StoplightClick',
          Download_Click: 'DownloadClick',
          Search_Click: 'SearchClick'
        }
      },
      Census: {
        Name: 'Census',
        Events: {
          ToDo_Click: 'To_DoClick',
          Completed_Click: 'CompletedClick',
          Search_Click: 'SearchClick'
        }
      },
      Profile: {
        Name: 'Patient Profile',
        Events: {
          Notes_Click: 'NotesClick',
          SaveNotes_Click: 'Save_NotesClick',
          PastRounds_Click: 'Past_RoundsClick',
          PastIssues_Click: 'Past_IssuesClick',
          PastFeedback_Click: 'Past_FeedbackClick',
          StartaNewRound_Click: 'Start_a_New_RoundClick',
          CompleteRound_Click: 'Complete_RoundClick',
          CancelRound_Click: 'Cancel_RoundClick',
          Yesterday_Click: 'YesterdayClick'
        }
      }
    }
  },
  CSRN: {
    Module: 'Clinical Support Rounding',
    Screen: {
      List: {
        Name: 'CSRN List',
        Events: {
          CSRN_All_Click: 'CSRN_AllClick',
          No_Round_Click: 'No_RoundClick',
          Recently_Completed_Click: 'Recently_CompletedClick',
          New_Nurses_Click: 'New_NursesClick',
          New_to_HCA_Nurses_Click: 'New_to_HCA_NursesClick',
          New_to_Dept_Nurses_Click: 'New_to_Dept_NursesClick',
          Search_Click: 'SearchClick'
        }
      },
      Profile: {
        Name: 'Employee Profile',
        Events: {
          Notes_Click: 'NotesClick',
          SaveNotes_Click: 'Save_NotesClick',
          PastRounds_Click: 'Past_RoundsClick',
          StartaNewRound_Click: 'Start_a_New_RoundClick',
          CompleteRound_Click: 'Complete_RoundClick',
          CancelRound_Click: 'Cancel_RoundClick',
          Calendar_Click: 'CalendarClick'
        }
      }
    }
  },
  CNEd: {
    Module: 'Clinical Nurse Educator',
    Screen: {
      List: {
        Name: 'CNEd List',
        Events: {
          CNEd_All_Click: 'CNEd_AllClick',
          No_Round_Click: 'No_RoundClick',
          Recently_Completed_Click: 'Recently_CompletedClick',
          New_Nurses_Click: 'New_NursesClick',
          New_to_HCA_Nurses_Click: 'New_to_HCA_NursesClick',
          New_to_Dept_Nurses_Click: 'New_to_Dept_NursesClick',
          Search_Click: 'SearchClick'
        }
      },
      Issues: {
        Name: 'Issues',
        Events: {
          Filter_Click: 'FilterClick',
          Add_Issue_Click: 'Add_IssueClick',
          Save_Issue_Click: 'Save_IssueClick',
          Close_Issue_Click: 'Close_IssueClick',
          Download_Click: 'DownloadClick',
          Search_Click: 'SearchClick'
        }
      },
      Feedback: {
        Name: 'Feedback',
        Events: {
          Filter_Click: 'FilterClick',
          Add_Feedback_Click: 'Add_FeedbackClick',
          Save_Feedback_Click: 'Save_FeedbackClick',
          Close_Feedback_Click: 'Close_FeedbackClick',
          Download_Click: 'DownloadClick',
          Search_Click: 'SearchClick'
        }
      },
      Profile: {
        Name: 'Employee Profile',
        Events: {
          Notes_Click: 'NotesClick',
          SaveNotes_Click: 'Save_NotesClick',
          PastRounds_Click: 'Past_RoundsClick',
          StartaNewRound_Click: 'Start_a_New_RoundClick',
          CompleteRound_Click: 'Complete_RoundClick',
          CancelRound_Click: 'Cancel_RoundClick',
          Calendar_Click: 'CalendarClick'
        }
      }
    }
  },
  Report: {
    Module: 'Reports',
    Screen: {
      Insights: {
        Name: 'HRInsights',
        Events: {
          HRInsights_Click: 'HR_InsightsClick'
        }
      },
      COG: {
        Name: 'COG Patient Reports',
        Events: {
          COGPatientReport_Click: 'COG_Patient_ReportClick'
        }
      },
      EmployeeRounds: {
        Name: 'Employee Rounds',
        Events: {
          DateRange_Click: 'Date_RangeClick',
          Filter_Click: 'FilterClick',
          ExportCSV_Click: 'Export_CSVClick',
          Search_Click: 'SearchClick'
        }
      },
      EmployeeFreq: {
        Name: 'Employee Frequency',
        Events: {
          DateRange_Click: 'Date_RangeClick',
          Filter_Click: 'FilterClick',
          ExportCSV_Click: 'Export_CSVClick',
          Search_Click: 'SearchClick'
        }
      },
      PatientCompliance: {
        Name: 'Patient Compliance',
        Events: {
          DateRange_Click: 'Date_RangeClick',
          Info_Click: 'InfoClick',
          ExportCSV_Click: 'Export_CSVClick',
          Location_Dropdown_Click: 'Location_DropdownClick'
        }
      },
      PatientRounds: {
        Name: 'Patient Rounds',
        Events: {
          Filter_Click: 'FilterClick',
          Info_Click: 'InfoClick',
          ExportCSV_Click: 'Export_CSVClick',
          Search_Click: 'SearchClick',
          Location_Dropdown_Click: 'Location_DropdownClick'
        }
      },
      PatientCensus: {
        Name: 'Patient Census',
        Events: {
          Calendar_Click: 'CalendarClick',
          Info_Click: 'InfoClick',
          ExportCSV_Click: 'Export_CSVClick',
          Search_Click: 'SearchClick',
          Location_Dropdown_Click: 'Location_DropdownClick'
        }
      }
    }
  },
  Help: {
    Module: 'Help',
    Screen: {
      Sharepoint: {
        Name: 'Orbit Sharepoint Site',
        Events: {
          OrbitSharepointSite_Click: 'Orbit_SharepointClick'
        }
      },
      HS: {
        Name: 'HealthStream',
        Events: {
          HealthStream_Click: 'HealthStreamClick'
        }
      },
      HR: {
        Name: 'HR Employee Rounding',
        Events: {
          HREmployeeRounding_Click: 'HR_Employee_RoundingClick'
        }
      }
    }
  },
  AddNew: {
    Module: 'Add New',
    Screen: {
      AddNewIssue: {
        Name: 'Add New Issue',
        Events: {
          AddNewIssue_Click: 'Add_New_IssueClick',
          SaveIssue_Click: 'Save_IssueClick',
          CloseIssue_Click: 'Close_IssueClick'
        }
      },
      AddNewFeedback: {
        Name: 'Add New Feedback',
        Events: {
          AddNewFeedback_Click: 'Add_New_FeedbackClick',
          SaveFeedback_Click: 'Save_FeedbackClick',
          CloseFeedback_Click: 'Close_FeedbackClick'
        }
      },
      AddNewStoplight: {
        Name: 'Add New Stoplight',
        Events: {
          AddNewStoplight_Click: 'Add_New_StoplightClick',
          SaveStoplight_Click: 'Save_StoplightClick',
          CloseStoplight_Click: 'Close_StoplightClick'
        }
      }
    }
  }
};

export default Events;
