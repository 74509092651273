import ACTIONS from './actionType';

// Report actions
export const searchPatientHistory = data => {
  return { type: ACTIONS.REPORT.SEARCH_PATIENT_HISTORY, data };
};

export const clearPatientSearch = data => {
  return { type: ACTIONS.REPORT.CLEAR_PATIENT_SEARCH, data };
};

export const getPatientRoundHistory = data => {
  return { type: ACTIONS.REPORT.GET_PATIENT_ROUND_HISTORY, data };
};

export const clearPatientRoundHistory = data => {
  return { type: ACTIONS.REPORT.CLEAR_PATIENT_ROUND_HISTORY, data };
};

export const getEmployeeRoundHistory = data => {
  return { type: ACTIONS.REPORT.GET_EMPLOYEE_ROUND_HISTORY, data };
};

export const clearEmployeeRoundHistory = data => {
  return { type: ACTIONS.REPORT.CLEAR_EMPLOYEE_ROUND_HISTORY, data };
};

export const getEmployeeRoundsReport = data => {
  return { type: ACTIONS.REPORT.GET_EMPLOYEE_ROUNDS_REPORT, data };
};

export const getPatientRoundsReport = data => {
  return { type: ACTIONS.REPORT.GET_PATIENT_ROUNDS_REPORT, data };
};

export const getEmployeeFreqReport = data => {
  return { type: ACTIONS.REPORT.GET_EMPLOYEE_FREQ_REPORT, data };
};

export const getPatientRounds = data => {
  return { type: ACTIONS.REPORT.GET_PATIENT_ROUNDS, data };
};

export const setPatientRoundsPage = data => {
  return { type: ACTIONS.REPORT.SET_CURRENT_PATIENT_ROUNDS_PAGE, data };
};

export const setPatientRoundsSearchQuery = data => {
  return { type: ACTIONS.REPORT.SET_PATIENT_ROUNDS_SEARCH_QUERY, data };
};

export const setPatientRoundsSort = data => {
  return { type: ACTIONS.REPORT.SET_PATIENT_ROUNDS_SORT, data };
};

export const getEmployeeRounds = data => {
  return { type: ACTIONS.REPORT.GET_EMPLOYEE_ROUNDS, data };
};

export const setEmployeeRoundsPage = data => {
  return { type: ACTIONS.REPORT.SET_CURRENT_EMPLOYEE_ROUNDS_PAGE, data };
};

export const setEmployeeRoundsSearchQuery = data => {
  return { type: ACTIONS.REPORT.SET_EMPLOYEE_ROUNDS_SEARCH_QUERY, data };
};

export const setEmployeeRoundsSort = data => {
  return { type: ACTIONS.REPORT.SET_EMPLOYEE_ROUNDS_SORT, data };
};

export const clearEmployeeRounds = data => {
  return { type: ACTIONS.REPORT.CLEAR_EMPLOYEE_ROUNDS, data };
};

export const getEmployeeFreq = data => {
  return { type: ACTIONS.REPORT.GET_EMPLOYEE_FREQ, data };
};

export const setEmployeeFreqPage = data => {
  return { type: ACTIONS.REPORT.SET_CURRENT_EMPLOYEE_FREQ_PAGE, data };
};

export const setEmployeeFreqSearchQuery = data => {
  return { type: ACTIONS.REPORT.SET_EMPLOYEE_FREQ_SEARCH_QUERY, data };
};

export const setEmployeeFreqSort = data => {
  return { type: ACTIONS.REPORT.SET_EMPLOYEE_FREQ_SORT, data };
};

export const clearEmployeeFreq = data => {
  return { type: ACTIONS.REPORT.CLEAR_EMPLOYEE_FREQ, data };
};

export const setRoundsReportFilter = data => {
  return { type: ACTIONS.REPORT.SET_ROUNDS_REPORT_FILTER, data };
};

export const clearRoundsReportFilter = () => {
  return { type: ACTIONS.REPORT.CLEAR_ROUNDS_REPORT_FILTER };
};

export const getComplianceReport = data => {
  return { type: ACTIONS.REPORT.GET_COMPLIANCE_REPORT, data };
};

export const getCensusReport = data => {
  return { type: ACTIONS.REPORT.GET_CENSUS_REPORT, data };
};
