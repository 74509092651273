import styled from 'styled-components';

export const ModalContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  min-height: 100vh;
  width: 100vw;
  z-index: 15;
  background: rgba(189, 195, 199, 0.5);

  .show-modal {
    animation-direction: forwards;
    animation-duration: 325ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-name: slide-in;
  }

  .hide-modal {
    animation-direction: forwards;
    animation-duration: 300ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-name: slide-out;
  }

  @keyframes slide-in {
    0% {
      right: -485px;
    }
    10% {
      right: -441px;
    }
    20% {
      right: -392px;
    }
    30% {
      right: -343px;
    }
    40% {
      right: -294px;
    }
    50% {
      right: -245px;
    }
    60% {
      right: -196px;
    }
    70% {
      right: -147px;
    }
    80% {
      right: -98px;
    }
    90% {
      right: -49px;
    }
    100% {
      right: 0px;
    }
  }

  @keyframes slide-out {
    0% {
      right: 0px;
    }
    10% {
      right: -49px;
    }
    20% {
      right: -98px;
    }
    30% {
      right: -147px;
    }
    40% {
      right: -196px;
    }
    50% {
      right: -245px;
    }
    60% {
      right: -294px;
    }
    70% {
      right: -343px;
    }
    80% {
      right: -392px;
    }
    90% {
      right: -441px;
    }
    100% {
      right: -485px;
    }
  }
`;
