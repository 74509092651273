import styled from 'styled-components';
import { NeuLabel } from '@neutron/react';

export const InputHeading = styled(NeuLabel)`
  display: inline-block;
  font-size: 0.875rem;
  line-height: 22px;
  width: 413px;
  margin-top: 12px;
`;

export const TextDiv = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  color: #191919;
`;
