import styled from 'styled-components';
import { NeuLabel } from '@neutron/react';

export const DateContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const SelectContainer = styled.div``;

export const MonthHeading = styled(NeuLabel)`
  display: inline-block;
  font-size: 0.875rem;
  line-height: 22px;
`;
