import { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  MessageContainer,
  StyledDiv,
  StyledImage,
  SubTitle
} from './NoDataSplashView.styles';
import Loading from '../shared/Loading';
import patient from '../../assets/images/Patient.png';
import clock from '../../assets/images/Clock.png';
import leadership from '../../assets/images/Leadership.png';
import magnifyingGlass from '../../assets/images/Magnifying_Glass.png';
import sad from '../../assets/images/Sad.png';
import User from '../../assets/images/User.png';

interface NoDataSplashViewProps {
  isLoadingEmployeesToRound?: any;
  isLoadingGetPatientsToRound?: any;
  type: string;
}

const NoDataSplashView: FC<NoDataSplashViewProps> = ({
  isLoadingEmployeesToRound,
  isLoadingGetPatientsToRound,
  type
}) => {
  const [imageLocation, setImageLocation] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [subTitle, setSubTitle] = useState<string>('');
  const view = window.localStorage.getItem('orbitLastPath');

  useEffect(() => {
    switch (type) {
      case 'patientCensus': {
        setImageLocation(patient);
        setTitle('No patients to round on');
        setSubTitle('This list refreshes every time you view it');
        break;
      }
      case 'employeeCensus': {
        setImageLocation(leadership);
        setTitle('No employees to round on');
        setSubTitle('This list refreshes every time you view it');
        break;
      }
      case 'employeeCensusSearch': {
        setImageLocation(sad);
        setTitle('No results found');
        setSubTitle('There are no results that match your criteria');
        break;
      }
      case 'patientCensusSearch': {
        setImageLocation(sad);
        setTitle('No results found');
        setSubTitle('There are no results that match your criteria');
        break;
      }
      case 'patientCensusCompleted': {
        setImageLocation(clock);
        setTitle('No recent patient rounds');
        setSubTitle('We only show rounds since midnight');
        break;
      }
      case 'employeeCensusCompleted': {
        setImageLocation(clock);
        setTitle('No recent employee rounds');
        setSubTitle('No rounds have been done in the current rounding period');
        break;
      }
      case 'issue': {
        setImageLocation(magnifyingGlass);
        setTitle('No issues');
        setSubTitle(
          `There are no issues for the ${
            view === '/patients/issues' ? `unit(s)` : `employee list`
          } you have selected`
        );
        break;
      }
      case 'issueSearch': {
        setImageLocation(magnifyingGlass);
        setTitle('No issues found');
        setSubTitle(
          'There are no results that match your criteria\nCheck your spelling or remove some filtering options'
        );
        break;
      }
      case 'feedback': {
        setImageLocation(magnifyingGlass);
        setTitle('No feedback');
        setSubTitle(
          `There are no feedbacks for the ${
            view === '/patients/feedback' ? `unit(s)` : `employee list`
          } you have selected`
        );
        break;
      }
      case 'feedbackSearch': {
        setImageLocation(magnifyingGlass);
        setTitle('No feedback found');
        setSubTitle(
          'There are no results that match your criteria\nCheck your spelling or remove some filtering options'
        );
        break;
      }
      case 'stoplight': {
        setImageLocation(magnifyingGlass);
        setTitle('No stoplights');
        setSubTitle(
          'There are no stoplights for the facility you have selected'
        );
        break;
      }
      case 'stoplightSearch': {
        setImageLocation(magnifyingGlass);
        setTitle('No stoplights found');
        setSubTitle(
          'There are no results that match your criteria\nCheck your spelling or remove some filtering options'
        );
        break;
      }
      case 'dischargeSearch': {
        setImageLocation(sad);
        setTitle('No results found');
        setSubTitle('There are no results that match your criteria');
        break;
      }
      case 'users': {
        setImageLocation(User);
        setTitle('No users found');
        setSubTitle('This list refreshes every time you view it');
        break;
      }
      case 'questionBank': {
        setImageLocation(User);
        setTitle('No questions found');
        setSubTitle('This list refreshes every time you view it');
        break;
      }
      case 'questionBankSearch': {
        setImageLocation(User);
        setTitle('No results found');
        setSubTitle('There are no results that match your criteria');
        break;
      }
      case 'encounters': {
        setImageLocation(User);
        setTitle('No encounters found');
        setSubTitle(
          'There are no results that match your criteria\nCheck your spelling or remove some filtering options'
        );
        break;
      }
      case 'discharge': {
        setImageLocation(sad);
        setTitle('No results found');
        setSubTitle('There are no patients for the facility you have selected');
        break;
      }
      case 'censusReport':
      case 'complianceReport':
      case 'roundsReport': {
        setImageLocation(sad);
        setTitle('No results found');
        setSubTitle('There are no results that match your criteria');
        break;
      }
      case 'frequencyReport': {
        setImageLocation(sad);
        setTitle('No results found');
        setSubTitle('There are no results that match your criteria');
        break;
      }
      case 'employee-list': {
        setImageLocation(User);
        setTitle('There are no employees on your list');
        setSubTitle(
          'You can add employees by clicking the "Add Employees" button'
        );
        break;
      }
      case 'delegate-list': {
        setImageLocation(User);
        setTitle('You have no delegates');
        setSubTitle(
          'You can add delegates by clicking the "Add Delegates" button'
        );
        break;
      }
      case 'delegated-to-me': {
        setImageLocation(User);
        setTitle('No lists are delegated to you');
        setSubTitle('You can only be delegated to a list by the list owner');
        break;
      }
      default:
        setImageLocation(leadership);
        setTitle('You have reached this table in error');
    }
  }, []);

  // TODO: does this need support
  return (type === 'patientCensus' && isLoadingGetPatientsToRound) ||
    (type === 'employeeCensus' && isLoadingEmployeesToRound) ? (
    <MessageContainer id="No-Data-Splash-Page-Loading">
      <div style={{ width: 100, height: 100, marginLeft: 100 }}>
        <Loading centered={false} />
      </div>
    </MessageContainer>
  ) : (
    <MessageContainer id="No-Data-Splash-Page">
      <StyledImage
        id="No-Data-Splash-Page-Image"
        src={imageLocation}
        alt="icon"
      />
      <StyledDiv id="No-Data-Splash-Page-Title">
        {title}
        <SubTitle id="No-Data-Splash-Page-Sub-Title">{`${subTitle}`}</SubTitle>
      </StyledDiv>
    </MessageContainer>
  );
};

const mapReduxStateToProps = (state: any) => {
  return {
    isLoadingGetPatientsToRound: state.UserReducer.isLoadingGetPatientsToRound,
    isLoadingEmployeesToRound: state.EmployeeReducer.isLoadingEmployeesToRound
  };
};

export default connect(mapReduxStateToProps)(NoDataSplashView);
