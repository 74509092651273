import { FC } from 'react';
import { connect, useDispatch } from 'react-redux';
import { NeuButton, NeuIcon } from '@neutron/react';

import { useAnalyticsApi } from '@shared-web-analytics/react/dist';

import { RootState } from '../../../redux/store';
import {
  openModal,
  setModalType,
  setModalAction
} from '../../../redux/actions/Modal.action';

import { logTaskClick } from '../../../utils/analyticsHelpers';

interface IFeedbackBtnProps {
  modalState: boolean;
}
const FeedbackBtn: FC<IFeedbackBtnProps> = ({ modalState }) => {
  const dispatch = useDispatch();

  const { logTrackingEvent } = useAnalyticsApi();

  const toggleModal = (mType: string) => {
    if (modalState === false) {
      logTrackingEvent(logTaskClick('feedback', 'add'));
      dispatch(setModalAction('ADD')); // For feedback add modal
      dispatch(setModalType(mType));
      dispatch(openModal());
    }
  };

  return (
    <NeuButton
      id="Feedback-Button"
      class="mr-5"
      color="primary"
      fill="raised"
      onClick={() => toggleModal('Feedback')}
    >
      <NeuIcon class="mr-2" feedback="default">
        add
      </NeuIcon>
      Add Feedback
    </NeuButton>
  );
};

const mapReduxStateToProps = (state: RootState) => {
  return {
    modalState: state.ModalReducer.modal
  };
};

export default connect(mapReduxStateToProps)(FeedbackBtn);
