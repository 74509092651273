import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiInstance } from '../../services/API/axios';

function InjectAxiosInterceptors() {
  const navigate = useNavigate();

  useEffect(() => {
    apiInstance.setupInterceptors(navigate);
  }, [navigate]);
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
}

export default InjectAxiosInterceptors;
