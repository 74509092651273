import styled from 'styled-components';
import { NeuLabel, NeuIcon, NeuTableRow } from '@neutron/react';

export const ToDoLabel = styled(NeuLabel)`
  font-size: 14px;
  line-height: 22px;
  font-weight: bold;
`;

export const ToDoIcon = styled(NeuIcon)`
  font-size: 12px;
`;
export const ToDoRow = styled(NeuTableRow)`
  background-color: white;
  height: 64px;
  align-items: center;
`;

export const CompleteRow = styled(NeuTableRow)`
  background-color: #f6f6f6;
  height: 64px;
  align-items: center;
`;

export const CompleteIcon = styled(NeuIcon)`
  font-size: 24px;
  width: 24px;
`;

export const UnableRow = styled(NeuTableRow)`
  background-color: white;
  height: 64px;
  align-items: center;
`;

export const UnableStayLabel = styled(NeuLabel)`
  font-size: 14px;
  line-height: 22px;
  font-weight: bold;
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  padding-left: 6px;
`;
