import { useEffect, useState } from 'react';

const useDebounceValue = (value: any, timeout: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedValue(value);
    }, timeout);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [value]);

  return debouncedValue;
};

const useDebounceFunc = (fn: any, timeout: number) => {
  let timeoutId: NodeJS.Timeout;
  return (...args: any[]) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn(...args), timeout);
  };
};

export { useDebounceValue, useDebounceFunc };
