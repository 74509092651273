import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo
} from 'react';
import { connect, useDispatch } from 'react-redux';
import { format } from 'date-fns';

import PatientRoundsReportTable from '../../../components/customTables/patientRoundsReportTable';
import SearchHeader from '../../../components/searchHeader';

import { RootState } from '../../../redux/store';
import {
  clearRoundsReportFilter,
  getPatientRounds,
  getPatientRoundsReport
} from '../../../redux/actions/Report.action';

import { date } from '../../../utils/helpers';

import {
  AuthUserProps,
  Filter,
  RoundsReport as IRoundsReport,
  Unit
} from '../../../config/interfaces';

import { ReportContainer } from '../styles/ReportsViews.styles';

interface RoundsReportProps {
  patientRoundsReport: IRoundsReport[];
  reportSearchQuery: string;
  searchTerm: string;
  authorizedUser: AuthUserProps;
  selectedUnits: Unit[];
  roundsReportFilter: Filter;
  currentPatientRoundsReportPage: number;
  sortKey: string;
  sortDir: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;
}

const RoundsReport: FC<RoundsReportProps> = ({
  patientRoundsReport,
  reportSearchQuery,
  searchTerm,
  authorizedUser,
  selectedUnits,
  roundsReportFilter,
  currentPatientRoundsReportPage,
  sortKey,
  sortDir,
  setSearchTerm
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearRoundsReportFilter());
    };
  }, []);

  useEffect(() => {
    const today = new Date();
    const firstDayOfMonth = format(
      new Date(today.getFullYear(), today.getMonth(), 1),
      'yyyy-MM-dd'
    );
    const todayDate = format(
      new Date(today.getFullYear(), today.getMonth(), today.getDate()),
      'yyyy-MM-dd'
    );

    // fetch data from month to date by default
    dispatch(
      getPatientRounds({
        facilityId: authorizedUser?.facilityId,
        unitId: selectedUnits.map(item => item.id),
        query: reportSearchQuery || '',
        roundedBy: roundsReportFilter.employee || '',
        sortBy: sortKey,
        sortOrder: sortDir,
        startday: date.formatFilterStartDate(
          roundsReportFilter.startDate || firstDayOfMonth
        ),
        endday: date.formatFilterEndDate(
          roundsReportFilter.endDate || todayDate
        ),
        page: currentPatientRoundsReportPage
      })
    );
  }, [
    authorizedUser,
    reportSearchQuery,
    roundsReportFilter,
    selectedUnits,
    sortKey,
    sortDir,
    currentPatientRoundsReportPage
  ]);

  const listToDisplay = useMemo(() => {
    if (patientRoundsReport?.length) {
      const roundCleanData =
        selectedUnits?.length > 0
          ? patientRoundsReport?.reduce(
              (cleanData: IRoundsReport[], round: IRoundsReport) => {
                // Note: there must be a standardization for detecting the 'unable to round' records.
                // The below logic will cover the existing data.
                return [
                  ...cleanData,
                  {
                    roundId: round?.roundId || '',
                    unit: round?.unit || '',
                    room: round?.room || '',
                    patientId: round?.patientId || '',
                    patient: `${round?.patientFirstName} ${round?.patientLastName}`,
                    patientFirstName: round?.patientFirstName || '',
                    patientLastName: round?.patientLastName || '',
                    patientMRN: round?.patientMRN || '',
                    createdUserName: `${round?.userFirstName} ${round?.userLastName}`,
                    createdUserId: round?.userId || '',
                    accountNumber: round?.accountNum || '',
                    facilityId: round?.facilityId || '',
                    status:
                      round.status === 'rounded'
                        ? 'Rounded'
                        : 'Unable To Round',
                    date:
                      round?.roundDate &&
                      date.isOrbitTimestamp(round?.roundDate)
                        ? date.parseDate(round?.roundDate).toLocaleString([], {
                            day: '2-digit',
                            month: '2-digit',
                            year: '2-digit'
                          })
                        : '',
                    time:
                      round?.roundDate &&
                      date.isOrbitTimestamp(round?.roundDate)
                        ? date.parseDate(round?.roundDate).toLocaleString([], {
                            hour: '2-digit',
                            minute: '2-digit'
                          })
                        : '',
                    roundDate: round?.roundDate || ''
                  }
                ];
              },
              []
            )
          : [];
      return roundCleanData;
    }
    return [];
  }, [patientRoundsReport, selectedUnits]);

  const handleExportCsv = useCallback(() => {
    const today = new Date();
    const firstDayOfMonth = format(
      new Date(today.getFullYear(), today.getMonth(), 1),
      'yyyy-MM-dd'
    );
    const todayDate = format(
      new Date(today.getFullYear(), today.getMonth(), today.getDate()),
      'yyyy-MM-dd'
    );

    dispatch(
      getPatientRoundsReport({
        facilityId: authorizedUser?.facilityId,
        unitId: selectedUnits.map(item => item.id),
        query: reportSearchQuery || '',
        roundedBy: roundsReportFilter.employee || '',
        sortBy: sortKey,
        sortOrder: sortDir,
        startday: date.formatFilterStartDate(
          roundsReportFilter.startDate || firstDayOfMonth
        ),
        endday: date.formatFilterEndDate(
          roundsReportFilter.endDate || todayDate
        )
      })
    );
  }, [
    authorizedUser,
    reportSearchQuery,
    roundsReportFilter,
    selectedUnits,
    sortKey,
    sortDir
  ]);

  return (
    <ReportContainer id="Patient-Rounds-Report-Container">
      <SearchHeader
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        handleExportCsv={handleExportCsv}
      />
      <PatientRoundsReportTable roundsReportList={listToDisplay} />
    </ReportContainer>
  );
};

const mapReduxStateToProps = (state: RootState) => {
  return {
    patientRoundsReport: state.ReportReducer.patientRoundsReport,
    reportSearchQuery: state.ReportReducer.reportSearchQuery,
    currentPatientRoundsReportPage:
      state.ReportReducer.currentPatientRoundsReportPage,
    sortKey: state.ReportReducer.sortRoundsKey,
    sortDir: state.ReportReducer.sortRoundsDir,
    roundsReportFilter: state.ReportReducer.roundsReportFilter,
    selectedUnits: state.ConfigReducer.selectedUnits,
    authorizedUser: state.AuthorizedUser.authorizedUser
  };
};

export default connect(mapReduxStateToProps)(RoundsReport);
