import { NavigateFunction } from 'react-router-dom';

export const handleLastPath = (
  authRoles: String[],
  navigate: NavigateFunction
) => {
  const lastPath = window.localStorage.getItem('orbitLastPath');

  if (lastPath) {
    return navigate(lastPath);
  }
  if (
    authRoles.includes('enterpriseAdmin') ||
    authRoles.includes('patientRounder')
  ) {
    return navigate('/patients/census');
  }
  if (authRoles.includes('employeeRounder')) {
    return navigate('/employees/list');
  }
  if (authRoles.includes('validationRounder')) {
    return navigate('/validation/');
  }
  if (authRoles.includes('csrnRounder')) {
    return navigate('/support/csc/list');
  }
  if (authRoles.includes('cnedRounder')) {
    return navigate('/support/cned/list');
  }
  if (authRoles.includes('userAdmin')) {
    return navigate('/admin/users');
  }
  if (authRoles.includes('templateConfigurator')) {
    return navigate('/admin/areas');
  }
  return navigate('/404');
};
