import { FC, Dispatch, SetStateAction } from 'react';

import FeedbackTable from '../../components/customTables/feedbackTable';
import SearchHeader from '../../components/searchHeader';

import { TaskContainer } from './styles/Support.styles';

interface FeedbackPageProps {
  searchTerm: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;
}

const SupportFeedback: FC<FeedbackPageProps> = ({
  searchTerm,
  setSearchTerm
}) => {
  return (
    <TaskContainer id="Support-Feedback">
      <SearchHeader searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      <FeedbackTable searchTerm={searchTerm} />
    </TaskContainer>
  );
};

export default SupportFeedback;
