import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { NeuButton } from '@neutron/react';

import { updateUnlistedPatientModalFlag } from '../../../redux/actions/User.action';

const UnlistedRoundBtn: FC<{}> = () => {
  const dispatch = useDispatch();

  const roundUnlistedPatient = () => {
    dispatch(updateUnlistedPatientModalFlag(true));
  };

  return (
    <NeuButton
      id="Unlisted-Round-Button"
      style={{ marginRight: '10px' }}
      color="primary"
      fill="raised"
      onClick={roundUnlistedPatient}
    >
      Round on Unlisted Patient
    </NeuButton>
  );
};

export default UnlistedRoundBtn;
